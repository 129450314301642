/*
 * @Author: Code-HHX
 * @Date: 2021-12-16 11:05:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-28 10:57:03
 * @Description:全局函数 (根据接口返回状态提示)
 */

exports.install = function (Vue, options) {
	Vue.prototype.$toast = function (res) {
		if (res.code == 200) {
			this.$bvToast.toast(res.message, {
				title: `Tips`,
				variant: 'success',
				solid: true,
				toaster: 'b-toaster-bottom-right',
			})
		} else if (res.code == 400) {
			this.$bvToast.toast(res.message, {
				title: `Tips`,
				variant: 'warning',
				solid: true,
				toaster: 'b-toaster-bottom-right',
			})
		} else {
			this.$bvToast.toast(res.message, {
				title: `Tips`,
				variant: 'danger',
				solid: true,
				toaster: 'b-toaster-bottom-right',
			})
		}
	}
	Vue.prototype.$toastBack = function (res) {
		//全局函数2 (返回上一页后根据接口返回状态提示)
		this.$router.go(-1)
		setTimeout(() => {
			this.$toast(res)
		}, 500)
	}

	//全局询问框
	Vue.prototype.$querybox = function (title) {
		return new Promise((resolve, reject) => {
			this.$bvModal
				.msgBoxConfirm(title, {
					title: 'Operation Tips',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					resolve(value)
				})
				.catch((err) => {
					reject(err)
				})
		})
	}

	Vue.prototype.$toastWarning = function (message) {
		//全局函数3 （警告状态提示）
		this.$bvToast.toast(message, {
			title: 'Tips',
			variant: 'warning',
			solid: true,
			toaster: 'b-toaster-bottom-right',
		})
	}
}

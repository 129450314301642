import { format } from "./time";
import axios from "../config/http.js";
import baseUrl from "../config/env.js";
import qs from "qs";

async function user_region() {
  return {
    success: true,
    data: [
      { ranking: 1, region: "California", pacent: "21.21%" },
      { ranking: 2, region: "Alabama", pacent: "19.21%" },
      { ranking: 3, region: "Florida", pacent: "18.99%" },
      { ranking: 4, region: "Mississippi", pacent: "17.55%" },
      { ranking: 5, region: "Hawaii", pacent: "2.31%" },
      { ranking: 6, region: "Indiana", pacent: "1.52%" },
      { ranking: 7, region: "Massachusetts", pacent: "1.23%" },
      { ranking: 8, region: "Michigan", pacent: "1.21%" },
      { ranking: 9, region: "Connecticut", pacent: "0.91%" },
      { ranking: 10, region: "Colorado", pacent: "0.21%" },
    ],
  };
}

// type 1|2|3
function statisticsUser(params = {}) {
  let d = JSON.parse(JSON.stringify(params));
  d["createTime"] = format(d["createTime"]);
  d["endTime"] = format(d["endTime"]);
  return axios.fetchGet(baseUrl + "/statistics/statisticsUser", d);
}

async function user(params = {}) {
  return axios.fetchGet(baseUrl + "/user/activeUsers", params);
}
async function flavor(params = {}) {
  return axios.fetchGet(baseUrl + "/statistics/flavorStatistics", params);
}
async function selectNewFeedback(params = {}) {
  return axios.fetchGet(baseUrl + "/statistics/selectNewFeedback", params);
}
function selectUserAndSmokeGrowthRate(params){
  return axios.fetchGet(baseUrl + '/statistics/selectUserAndSmokeGrowthRate', params);
}
function smokeBombStatistics(params){
  return axios.fetchGet(baseUrl + '/statistics/smokeBombStatistics', params);
}
function selectInactiveIcon(params){
  return axios.fetchGet(baseUrl + '/statistics/selectInactiveIcon', params);
}


async function activeUsers(params = {}) {
  axios.fetchGet(baseUrl + "/statistics/activeUsers", params);
  return {
    success: true,
    data: {
      series: [[198, 256, 301, 368, 458, 450, 460, 398, 320, 298, 189, 180]],
      labels: [
        "02:00",
        "04:00",
        "06:00",
        "08:00",
        "10:00",
        "12:00",
        "14:00",
        "16:00",
        "18:00",
        "20:00",
        "22:00",
        "24:00",
      ],
    },
  };
}

async function newUsers(params = {}) {
  axios.fetchGet(baseUrl + "/statistics/newUsers", params);
  return {
    success: true,
    data: {
      series: [[10, 50, 65, 70, 300, 350, 330, 230, 360, 240, 46, 30]],
      labels: [
        "02:00",
        "04:00",
        "06:00",
        "08:00",
        "10:00",
        "12:00",
        "14:00",
        "16:00",
        "18:00",
        "20:00",
        "22:00",
        "24:00",
      ],
    },
  };
}

async function selectUserReport (params){
    return axios.fetchGet(baseUrl + '/statistics/selectUserReport',params)
}
async function selectUserMobilePhoneList (params){
    return axios.fetchGet(baseUrl + '/statistics/selectUserMobilePhoneList',params)
}
async function ageDistributionList (params){
    return axios.fetchGet(baseUrl + '/statistics/ageDistributionList',params)
}
async function activeReportList (params){
    return axios.fetchGet(baseUrl + '/statistics/activeReportList',params)
}

async function cartridge(params) {
  return axios.fetchGet(baseUrl + "/statistics/batchStatisticsList", params);
}
async function batchReportList(params) {
  return axios.fetchGet(baseUrl + "/statistics/batchReportList", params);
}

export default {
  statisticsUser,
  user,
  user_region,
  activeUsers,
  newUsers,
  cartridge,
  selectUserReport,
  selectUserMobilePhoneList,
  ageDistributionList,
  activeReportList,
  batchReportList,
  flavor,
  selectNewFeedback,
  selectUserAndSmokeGrowthRate,
  smokeBombStatistics,
  selectInactiveIcon
};

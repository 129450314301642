var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-body" },
    [
      _c("b-col", { attrs: { lg: "12" } }, [
        _c(
          "p",
          {
            staticClass: "title",
            staticStyle: { "font-size": "16px", "font-weight": "bolder" }
          },
          [
            _c("span", {
              staticStyle: {
                display: "inline-block",
                width: "10px",
                height: "20px",
                "vertical-align": "text-top",
                "background-color": "#626ed4"
              }
            }),
            _vm._v(" progress bar ")
          ]
        )
      ]),
      _c(
        "ul",
        { staticClass: "steps" },
        _vm._l(_vm.SetData, function(item, index) {
          return _c(
            "li",
            { key: item + index, class: { active: _vm.Steps === index } },
            [_vm._v(" " + _vm._s(item) + " ")]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
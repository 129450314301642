<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="submit_form" @reset="reset_form">
              <!-- 厂家名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Manufacturer Name"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="manufacturer-name"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="manufacturer-name"
                      v-model="typeform.name"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <!-- <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Manufacturer Code"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="manufacturer-code"
                  >
                    <b-form-input
                      id="manufacturer-code"
                      v-model="typeform.code"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div> -->
              <!-- 厂家地区 -->
              <!-- <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Manufacturer Region"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="manufacturer-area"
                    label-class="requiredRow"
                  >
                    <b-form-select
                      id="manufacturer-area"
                      v-model="typeform.areaEncodingId"
                      :options="types.areaEncodingList"
                      value-field="id"
                      text-field="cityName"
                      required
                      oninvalid="setCustomValidity('Please select');"
                      oninput="setCustomValidity('');"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </div> -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Contacts"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="contacts"
                  >
                    <b-form-input
                      id="contacts"
                      v-model="typeform.contacts"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Email"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="typeform.email"
                      placeholder="Please enter"
                      type="email"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Contact Number"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="contact-number"
                  >
                    <b-form-input
                      id="contact-number"
                      v-model="typeform.phone"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Contact Address"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="contact-address"
                  >
                    <b-form-input
                      id="contact-address"
                      v-model="typeform.address"
                      placeholder="Please enter"
                      trim
                      oninput="if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 所属客户 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Affiliated Customer"
                    label-cols-lg="4"
                    label-align-lg="right"
                    label-for="customer"
                    label-class="requiredRow"
                  >
                    <b-form-select
                      id="customer"
                      v-model="typeform.supplierId"
                      :options="types.allSupplier"
                      value-field="id"
                      text-field="brandName"
                      required
                      oninvalid="setCustomValidity('Please select');"
                      oninput="setCustomValidity('');"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 备注 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Notes"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="flavor-remark"
                  >
                    <b-form-textarea
                      id="remark"
                      v-model="typeform.remarks"
                      placeholder="Enter Notes"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Confirm</button>
                  <button type="reset" class="btn btn-secondary m-l-5 ml-1">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "Manufacturer",
  page: {
    title: "Manufacturer",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Manufacturer",
      items: [
        {
          text: "Data Dictionary",
          // href: "/"
        },
        {
          text: "Manufacturer",
          // href: "/"
        },
        {
          text: /add/i.test(location.href)
            ? "Manufacturer Create"
            : "Manufacturer Modify",
          active: true,
        },
      ],
      typeform: {
        //"id":3,
        name: "",
        //areaEncodingId: "",
        contacts: "",
        phone: "",
        email: "",
        address: "",
        remarks: "",
        supplierId: "",
      },
      types: {
        areaEncodingList: [{ cityName: "Please select", id: "" }],
        allSupplier: [{ brandName: "Please select", id: "" }],
      },
      submit: false,
      typesubmit: false,
    };
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      if (this.$route.query.id) {
        this.$api.Flavor.manufacturerFind(this.$route.query).then((res) => {
          if (res.success) {
            this.typeform = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
      this.$api.Dropdown.allCountry().then((res) => {
        if (res.success) {
          this.types.areaEncodingList = this.types.areaEncodingList.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    add() {
      this.$api.Flavor.manufacturerAdd(this.typeform).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    update() {
      this.$api.Flavor.manufacturerUpdate(this.typeform).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    rangeform(e) {
      this.submit = true;
    },
    submit_form() {
      this.typesubmit = true;
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important;
}
.dropzone .dz-message {
  margin: 1em 0;
}
.dropzone .dz-message {
  font-size: 16px !important;
}
.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}
.dropzone .dz-preview {
  margin: -6px;
}
</style>

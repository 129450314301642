var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Scope Of Pushed:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "scope",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "scope",
                      options: _vm.types.states,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    model: {
                      value: _vm.form.state,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "state", $$v)
                      },
                      expression: "form.state"
                    }
                  })
                ],
                1
              ),
              _vm.form.state == 1
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Specify User:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "specify",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          options: _vm.types.retrievalUserList,
                          multiple: true,
                          placeholder: "Please select",
                          label: "email",
                          "track-by": "id",
                          required: ""
                        },
                        on: { input: _vm.selectRetrievalUser },
                        model: {
                          value: _vm.inputText,
                          callback: function($$v) {
                            _vm.inputText = $$v
                          },
                          expression: "inputText"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Push The Title:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "title",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title",
                      placeholder: "Please enter",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Push Content:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "pushContent",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("div", {
                    staticStyle: { "max-width": "1260px" },
                    attrs: { id: "wangeEditor" }
                  })
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "remarks"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remarks",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
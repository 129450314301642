var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("User Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("User Photo:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm.info.picture
                        ? _c("img", {
                            staticClass: "avatar-xs rounded-circle mr-2",
                            attrs: { src: _vm.info.picture, alt: "" }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Email:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.email))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Nickname:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.nickName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Birth:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.dateOfBirth))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Gender:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.gender))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Binded Battery:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.binding))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Smoke History:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.isSmoke ? "YES" : "NO"))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Vape History:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.isElectricSmoke ? "YES" : "NO"))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Device Control")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Dose control:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("800 ")])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Depleted period:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("8:00-21:00")])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Temperature control:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("750 ℉")])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Bound device infor")])
                ])
              ])
            ],
            1
          ),
          _c("br"),
          _c("b-table", {
            ref: "device_table",
            attrs: {
              id: "device_table",
              "head-variant": "light",
              striped: "",
              fields: _vm.deviceFields,
              items: _vm.device_provider,
              "per-page": _vm.device.pageSize,
              "current-page": _vm.device.pageNum,
              busy: _vm.device_isBusy
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(index)",
                fn: function(data) {
                  return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
                }
              }
            ])
          }),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    !_vm.device_totalRows
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.device_totalRows,
                                "aria-controls": "device_table"
                              },
                              model: {
                                value: _vm.device.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.device, "pageNum", $$v)
                                },
                                expression: "device.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Usage record")])
                ])
              ])
            ],
            1
          ),
          _c("b-table", {
            ref: "SmokeBomb_table",
            attrs: {
              "head-variant": "light",
              striped: "",
              fields: _vm.usageRecordFields,
              id: "SmokeBomb_table",
              items: _vm.SmokeBomb_provider,
              "per-page": _vm.SmokeBomb.pageSize,
              "current-page": _vm.SmokeBomb.pageNum,
              busy: _vm.SmokeBom_isBusy
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(index)",
                fn: function(data) {
                  return [_vm._v(" " + _vm._s(data.index + 1) + " ")]
                }
              }
            ])
          }),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    !_vm.SmokeBomb_totalRows
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.SmokeBomb_totalRows,
                                "aria-controls": "SmokeBomb_table"
                              },
                              model: {
                                value: _vm.SmokeBomb.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.SmokeBomb, "pageNum", $$v)
                                },
                                expression: "SmokeBomb.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
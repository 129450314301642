import { format } from "./time";
import axios from "../config/http.js";
import baseUrl from "../config/env.js";

async function list(params, ctx) {
  //axios.fetchGet(baseUrl + '/category/list',params)
  return {
    success: true,
    total: 3,
    data: [
      { id: 1, name: "The popular science" },
      { id: 2, name: "Common sense" },
    ],
  };
}

function add(params) {
  return axios.fetchPost(baseUrl + "/category/add", params);
}

function find(params) {
  return axios.fetchGet(baseUrl + "/category/findById", params);
}

function update(params) {
  return axios.fetchPost(baseUrl + "/category/updateById", params);
}

function disable(params) {
  return axios.fetchPost(baseUrl + "/category/disable", params);
}

function del(params) {
  return axios.fetchPost(baseUrl + "/category/deleteById", params);
}

export default {
  list,
  find,
  add,
  update,
  del,
  disable,
};

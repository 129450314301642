/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:06
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-30 14:45:23
 * @Description:
 */
import { format } from './time'
import axios from '../config/http.js'
import axios1 from '../config/http-json.js'
import baseUrl from '../config/env.js'

// account manage
async function list(params, ctx) {
	return axios.fetchGet(baseUrl + '/platform/list', params)
}

function enableOrDisable(params = {}) {
	return axios.fetchPost(baseUrl + '/platform/enableOrProhibit', params)
}

function add(params) {
	return axios.fetchPost(baseUrl + '/platform/addPlatform', params)
}

function findById(params) {
	return axios.fetchPost(baseUrl + '/platform/selectByPlatformId', params)
}

function updateById(params) {
	return axios.fetchPost(baseUrl + '/platform/updateById', params)
}

function changePasswordById(params) {
	return axios.fetchPost(baseUrl + '/platform/changePasswordById', params)
}

// role manage
async function roleList(params, ctx) {
	return axios.fetchGet(baseUrl + '/role/list', params)
}

function roleAdd(params) {
	return axios1.fetchPost(baseUrl + '/role/add', params)
}

function roleFindById(params) {
	return axios.fetchGet(baseUrl + '/role/findById', params)
}

function roleUpdateById(params) {
	return axios1.fetchPost(baseUrl + '/role/update', params)
}

function findAllMenu(params) {
	return axios.fetchGet(baseUrl + '/role/findAllMenu', params)
}
function findUserMenu(params) {
	return axios.fetchGet(baseUrl + '/role/findUserMenu', params)
}
function selectAllPlatformButton(params) {
	return axios.fetchGet(baseUrl + '/role/selectAllPlatformButton', params)
}

//查询Boss的App菜单权限
function selectDeveloperMenu(params = {}) {
	return axios.fetchGet(baseUrl + '/role/selectDeveloperMenu', params)
}

//#region 系统管理-菜单管理

//查询菜单分页列表
function selectMenuList(params = {}) {
	return axios.fetchGet(baseUrl + '/menu/selectMenuList', params)
}
//添加或修改菜单详情
function addMenuDetail(params = {}) {
	return axios1.fetchPost(baseUrl + '/menu/addMenuDetail', params)
}
//根据系统类型，父级菜单查询按钮组
function selectButtonGroup(params = {}) {
	return axios1.fetchPost(baseUrl + '/menu/selectButtonGroup', params)
}
//新增或修改菜单按钮
function addMenuButton(params = {}) {
	return axios1.fetchPost(baseUrl + '/menu/addMenuButton', params)
}
//#endregion

export default {
	list,
	enableOrDisable,
	add,
	findById,
	updateById,
	changePasswordById,
	roleList,
	roleAdd,
	roleFindById,
	roleUpdateById,
	findAllMenu,
	findUserMenu,
	selectAllPlatformButton,
	selectMenuList,
	addMenuDetail,
	selectButtonGroup,
	addMenuButton,
	selectDeveloperMenu,
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              !_vm.editId
                ? _c(
                    "b-row",
                    { staticClass: "align-items-center" },
                    [
                      _c("b-col", { attrs: { lg: "12" } }, [
                        _c("div", { staticClass: "blockquote" }, [
                          _c("h4", [_vm._v("Basic Info")])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Customer Name:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "customer-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "customer-name",
                      placeholder: "Please enter",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.form.brandName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "brandName", $$v)
                      },
                      expression: "form.brandName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Customer Nickname:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "Nickname",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "Nickname",
                      placeholder: "Please enter",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.form.nickName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "nickName", $$v)
                      },
                      expression: "form.nickName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Customer Area:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "customer-area",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.types.allCountry,
                      "value-field": "id",
                      "text-field": "cityName",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.areaEncodingId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "areaEncodingId", $$v)
                      },
                      expression: "form.areaEncodingId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Customer Type:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "customer-type",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.types.customerTypes,
                      "value-field": "value",
                      "text-field": "text",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.customerType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "customerType", $$v)
                      },
                      expression: "form.customerType"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Customer Role:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "customer-role",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "customer-role",
                      options: _vm.types.supplierRoleList,
                      "value-field": "id",
                      "text-field": "roleName",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.supplierRoleId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierRoleId", $$v)
                      },
                      expression: "form.supplierRoleId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Customer Photo:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "logo"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.picture
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "2px dashed #ccc"
                                    },
                                    attrs: { src: _vm.form.picture }
                                  }),
                                  !_vm.editId
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "vertical-align": "bottom"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v("Picture name : ")]
                                          ),
                                          _vm._v(_vm._s(_vm.photoName))
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v(" upload picture ")
                              ])
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Contacts:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "contacts"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "contacts",
                      trim: "",
                      placeholder: "Please enter",
                      oninput: "if(value.length > 20)value = value.slice(0, 20)"
                    },
                    model: {
                      value: _vm.form.contactName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "contactName", $$v)
                      },
                      expression: "form.contactName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Email:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "email"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "email",
                      type: "email",
                      trim: "",
                      placeholder: "Please enter",
                      oninput: "if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Contact Number:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "contact-number"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "contact-number",
                      trim: "",
                      placeholder: "Please enter",
                      oninput: "if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Contact Address:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "contact-address"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "contact-address",
                      trim: "",
                      placeholder: "Please enter",
                      oninput:
                        "if(value.length > 100)value = value.slice(0, 100)"
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Remark:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "remark"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remark",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              !_vm.editId
                ? _c(
                    "b-row",
                    { staticClass: "align-items-center" },
                    [
                      _c("b-col", { attrs: { lg: "12" } }, [
                        _c("div", { staticClass: "blockquote" }, [
                          _c("h4", [_vm._v("Account Info")])
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.editId
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Login Account:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "login-account",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "login-account",
                          placeholder: "Please enter",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.editId
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Login Password:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "login-password",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "login-password",
                          placeholder: "Please enter",
                          type: "password",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.editId
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Confirm Password:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "login-password",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "login-password",
                          placeholder: "Please enter",
                          type: "password",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                        },
                        model: {
                          value: _vm.confirmPassword,
                          callback: function($$v) {
                            _vm.confirmPassword = $$v
                          },
                          expression: "confirmPassword"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="typeForm">
              <!-- 口味名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="flavor Name"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="flavor-name"
                  >
                    <!-- <b-form-input  id="flavor-name"  v-model="flavorName" trim> </b-form-input> -->
                    <input
                      v-model="typeform.name"
                      type="text"
                      class="form-control col-md-9"
                      placeholder="Type something"
                      name="name"
                      :class="{ 'is-invalid': typesubmit && $v.typeform.name.$error }"
                    />
                    <div v-if="typesubmit && $v.typeform.name.$error" class="invalid-feedback">
                      <span v-if="!$v.typeform.name.required">This value is required.</span>
                    </div>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 口味图片 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="flavor Image"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="flavor-name"
                  >
                    <div class="mb-1">
                      <!-- file upload -->
                      <vue-dropzone id="dropzone" ref="myVueDropzone" :options="dropzoneOptions" ></vue-dropzone>
										
											
                      
                    </div>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 口味备注 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Flavor Notes"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="flavor-remark"
                  >
                    <textarea
                      v-model="flavorRemark"
                      class="form-control"
                      :maxlength="225"
                      rows="3"
                      placeholder
                    ></textarea>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <button type="submit" class="btn btn-primary" @click="addFlavor">Submit</button>
                  <button type="reset" class="btn btn-secondary m-l-5 ml-1">Cancel</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>


<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import vue2Dropzone from "vue2-dropzone";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  name: "flavor",
  page: {
    title: "Form Validation",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader
  },

  data() {
    return {
      // 口味备注
      flavorRemark: "",
      // 口味名称参数
      // flavorName: "",
      // 口味图片上传参数
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }
      },
      title: "flavor",
      items: [
        {
          text: "Veltrix",
          // href: "/"
        },
        {
          text: "Forms",
          // href: "/"
        },
        {
          text: "flavor",
          active: true
        }
      ],
      range: {
        minlen: "",
        maxlength: "",
        between: "",
        minval: "",
        maxval: "",
        rangeval: "",
        expr: ""
      },
      typeform: {
        name: "",
        password: "",
        confirmPassword: "",
        email: "",
        url: "",
        digit: "",
        number: "",
        alphanum: "",
        textarea: ""
      },
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    range: {
      minlen: { required, minLength: minLength(6) },
      maxlength: { required, maxLength: maxLength(6) },
      between: { required, minLength: minLength(5), maxLength: maxLength(10) },
      minval: { required, minValue: minValue(6) },
      maxval: { required, maxValue: maxValue(6) },
      rangeval: { required, minValue: minValue(6), maxValue: maxValue(100) },
      expr: { required }
    },
    typeform: {
      name: { required },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      email: { required, email },
      url: { required, url },
      digit: { required, numeric },
      number: { required, numeric },
      alphanum: { required, alphaNum },
      textarea: { required }
    }
  },
  methods: {
    upload(e) {
      window.console.log(file);
    },

    addFlavor() {
      console.log(this.typeform.name);
      console.log(this.flavorRemark);
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    }
  }
};
</script>


<style lang="scss">
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important;
}
.dropzone .dz-message {
  margin: 1em 0;
}
.dropzone .dz-message {
  font-size: 16px !important;
}
.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Key Word:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "key-word",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "key-word",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.sensitiveWord,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sensitiveWord", $$v)
                          },
                          expression: "form.sensitiveWord"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Risk Level:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "risk-level",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "risk-level",
                          options: _vm.types.riskLevelList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('');"
                        },
                        model: {
                          value: _vm.form.riskLevel,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "riskLevel", $$v)
                          },
                          expression: "form.riskLevel"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Keyword Category:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "keyword-category",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "keyword-category",
                          options: _vm.types.keywordCategoryList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('');"
                        },
                        model: {
                          value: _vm.form.sensitiveWordCategoryId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sensitiveWordCategoryId", $$v)
                          },
                          expression: "form.sensitiveWordCategoryId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Status:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "status",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: {
                            name: "check-button",
                            switch: "",
                            size: "lg",
                            value: "1",
                            "unchecked-value": "0"
                          },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        },
                        [
                          _c("b", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.form.status == 0 ? "Disable" : "Enable"
                                )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
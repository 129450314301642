var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12", xs: "12" } }, [
            _c("div", { ref: "editor2", attrs: { id: "editor2" } })
          ])
        ],
        1
      ),
      _c("p"),
      _c(
        "b-row",
        { staticClass: "text-center col-lg-12" },
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Application Name:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "application-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "application-name",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    },
                    model: {
                      value: _vm.form.applicationName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "applicationName", $$v)
                      },
                      expression: "form.applicationName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Putaway Version:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "putaway-version",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "putaway-version",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 50)value = value.slice(0, 20)"
                    },
                    model: {
                      value: _vm.form.version,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "version", $$v)
                      },
                      expression: "form.version"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Push The Path:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "path",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "path",
                      options: _vm.types.pathList,
                      "value-field": "id",
                      "text-field": "name",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.path,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "path", $$v)
                      },
                      expression: "form.path"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Affiliated Customer:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "customer"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { id: "customer", trim: "" },
                    model: {
                      value: _vm.form.supplierName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierName", $$v)
                      },
                      expression: "form.supplierName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Installation Package:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "package"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle ",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary ",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v(" upload ")
                              ])
                            ]
                          ),
                          _vm.form.route
                            ? _c("span", [
                                _vm._v(" " + _vm._s(_vm.routeName) + " ")
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Content",
                    "label-cols-lg": "2",
                    "label-align-sm": "right",
                    "label-for": "content"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "content",
                      placeholder: "Enter content",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Status:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "status"
                  }
                },
                [
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "custom-control-inline pt-2",
                      attrs: { name: "status", value: "1" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("putaway")]
                  ),
                  _c(
                    "b-form-radio",
                    {
                      staticClass: "custom-control-inline pt-2",
                      attrs: { name: "status", value: "0" },
                      model: {
                        value: _vm.form.status,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status"
                      }
                    },
                    [_vm._v("sold out")]
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes",
                    "label-cols-lg": "2",
                    "label-align-sm": "right",
                    "label-for": "remark"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remark",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
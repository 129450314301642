var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card mini-stat bg-primary text-white" }, [
    _c("div", { staticClass: "card-body" }, [
      _c("div", { staticClass: "mb-4" }, [
        _c("div", { staticClass: "float-left mini-stat-img mr-4" }, [
          _c("img", { attrs: { src: "" + _vm.image, alt: "" } })
        ]),
        _c(
          "h5",
          { staticClass: "font-size-16 text-uppercase mt-0 text-white-50" },
          [_vm._v(_vm._s(_vm.title))]
        ),
        _c("h4", { staticClass: "font-weight-medium font-size-24" }, [
          _vm._v(" " + _vm._s(_vm.value) + " "),
          _vm.subText >= 0
            ? _c("i", { staticClass: "mdi mdi-arrow-up text-success ml-2" })
            : _c("i", { staticClass: "mdi mdi-arrow-down text-success ml-2" }),
          _c("i", {
            directives: [
              {
                name: "b-popover",
                rawName: "v-b-popover.hover.bottom",
                value: _vm.popoverText,
                expression: "popoverText",
                modifiers: { hover: true, bottom: true }
              }
            ],
            staticClass: "ti-help-alt",
            staticStyle: {
              cursor: "pointer",
              "margin-left": "10px",
              "font-size": "20px"
            }
          })
        ]),
        _c(
          "div",
          {
            class: "mini-stat-label bg-" + _vm.color,
            staticStyle: { top: "40px" }
          },
          [
            _c("p", { staticClass: "mb-0" }, [
              _vm._v(_vm._s(_vm.subText) + "%")
            ])
          ]
        )
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pt-2" }, [
      _c("div", { staticClass: "float-right" }, [
        _c("a", { staticClass: "text-white-50", attrs: { href: "#" } }, [
          _c("i", { staticClass: "mdi mdi-arrow-right h5" })
        ])
      ]),
      _c("p", { staticClass: "text-white-50 mb-0 mt-1" }, [
        _vm._v("Since last month")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


async function list(params,ctx){
    return axios.fetchGet(baseUrl + '/flavor/list',params)
}

function add(params){
    return axios.fetchPost(baseUrl + '/flavor/add', params);
}

function update(params){
    return axios.fetchPost(baseUrl + '/flavor/update', params);
}

function enableOrDisable(params){
    return axios.fetchPost(baseUrl + '/flavor/deleteById', params);
}

// ingredient 成分接口
async function ingredientList(params,ctx){
    return axios.fetchGet(baseUrl + '/componentEncoding/list',params)
}

function ingredientAdd(params){
    return axios.fetchPost(baseUrl + '/componentEncoding/add', params);
}

function ingredientUpdate(params){
    return axios.fetchPost(baseUrl + '/componentEncoding/update', params);
}

function ingredientEnableOrDisable(params){
    return axios.fetchPost(baseUrl + '/componentEncoding/deleteById', params);
}
// 厂家接口
async function manufacturerList(params,ctx){
    return axios.fetchGet(baseUrl + '/manufacturerEncoding/list',params)
}
 
function manufacturerAdd(params){
    return axios.fetchPost(baseUrl + '/manufacturerEncoding/add', params);
}

function manufacturerFind(params){
   return axios.fetchGet(baseUrl + '/manufacturerEncoding/findById',params)
}

function manufacturerUpdate(params){
    return axios.fetchPost(baseUrl + '/manufacturerEncoding/update', params);
}

// brand 品牌接口
async function brandList(params,ctx){
    return axios.fetchGet(baseUrl + '/brand/list',params)
}

function brandAdd(params){
    return axios.fetchPost(baseUrl + '/brand/add', params);
}

function brandUpdate(params){
    return axios.fetchPost(baseUrl + '/brand/update', params);
}

// 雾化丝种类
async function atomizedWireList(params,ctx){
    return axios.fetchGet(baseUrl + '/atomizingWire/list',params)
}

function atomizedWireAdd(params){
    return axios.fetchPost(baseUrl + '/atomizingWire/add', params);
}

function atomizedWireUpdate(params){
    return axios.fetchPost(baseUrl + '/atomizingWire/updateById', params);
}
function atomizedWireEnableOrDisable(params){
    return axios.fetchPost(baseUrl + '/atomizingWire/deleteById', params);
}
// 溶剂类型
async function solventList(params,ctx){
    return axios.fetchGet(baseUrl + '/solventEncoding/list',params)
}

function solventAdd(params){
    return axios.fetchPost(baseUrl + '/solventEncoding/add', params);
}

function solventUpdate(params){
    return axios.fetchPost(baseUrl + '/solventEncoding/updateById', params);
}
function solventEnableOrDisable(params){
    return axios.fetchPost(baseUrl + '/solventEncoding/deleteById', params);
}
// 烟弹类型
async function cartridgeList(params,ctx){
    return axios.fetchGet(baseUrl + '/typeEncoding/list',params)
}

function cartridgeAdd(params){
    return axios.fetchPost(baseUrl + '/typeEncoding/add', params);
}

function cartridgeFind(params){
   return axios.fetchGet(baseUrl + '/typeEncoding/findById',params)
}

function cartridgeUpdate(params){
    return axios.fetchPost(baseUrl + '/typeEncoding/update', params);
}
function cartridgeEnableOrDisable(params){
    return axios.fetchPost(baseUrl + '/typeEncoding/deleteById', params);
}
// 地区管理
async function areaList(params,ctx){
    return axios.fetchGet(baseUrl + '/areaEncoding/list',params)
}

function areaAdd(params){
    return axios.fetchPost(baseUrl + '/areaEncoding/add', params);
}

function areaUpdate(params){
    return axios.fetchPost(baseUrl + '/areaEncoding/update', params);
}
function areaEnableOrDisable(params){
    return axios.fetchPost(baseUrl + '/areaEncoding/deleteById', params);
}


export default {
    list,
    add,
    update,
    enableOrDisable,
    ingredientList,
    ingredientAdd,
    ingredientUpdate,
    ingredientEnableOrDisable,
    brandList,
    brandAdd,
    brandUpdate,
    atomizedWireList,
    atomizedWireAdd,
    atomizedWireUpdate,
    atomizedWireEnableOrDisable,
    solventList,
    solventAdd,
    solventUpdate,
    solventEnableOrDisable,
    cartridgeList,
    cartridgeAdd,
    cartridgeFind,
    cartridgeUpdate,
    cartridgeEnableOrDisable,
    areaList,
    areaAdd,
    areaUpdate,
    areaEnableOrDisable,
    manufacturerList,
    manufacturerAdd,
    manufacturerFind,
    manufacturerUpdate
}

const basicColumChart = {
  chartOptions: {
    chart:{
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#626ed4"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%'
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
    },
  },
  series: [
    {
      name: '',
      data: []},
  ],
};
const activeUsersLineChart = {
  data: {
    series: [[]],
    labels: [],
  },
};

const newUsersLineChart = {
  data: {
    series: [[]],
    labels: [],
  },
};

const ageUsersBarChart = {
  data: {
    series: [[400, 300, 500, 155, 110, 40]],
    labels: ["18-24", "25-29", "30-39", "40-49", "50 up", "un know"],
  },
  options: {
    seriesBarDistance: 10,
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "20px",
      offsetX: 0,
      offsetY: 10,
    },
  },
};

const simplePieChart = {
  series: [],
  //   series: [65, 13, 12, 10],
  chartOptions: {
    labels: [],
    // labels: ["Huwei Mate 10", "Vivo X5", "GALAXY Note 5", "xiaomi"],
    // colors: ["#6873cb", "#38a4f8", "#02a499", "#f8b425"],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "20px",
      offsetX: 0,
      offsetY: 10,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 250,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  },
};

const regionRankingData = [
  { ranking: 1, region: "California", pacent: "21.21%" },
  { ranking: 2, region: "Alabama", pacent: "19.21%" },
  { ranking: 3, region: "Florida", pacent: "18.99%" },
  { ranking: 4, region: "Mississippi", pacent: "17.55%" },
  { ranking: 5, region: "Hawaii", pacent: "2.31%" },
  { ranking: 6, region: "Indiana", pacent: "1.52%" },
  { ranking: 7, region: "Massachusetts", pacent: "1.23%" },
  { ranking: 8, region: "Michigan", pacent: "1.21%" },
  { ranking: 9, region: "Connecticut", pacent: "0.91%" },
  { ranking: 10, region: "Colorado", pacent: "0.21%" },
];

export {
  activeUsersLineChart,
  newUsersLineChart,
  ageUsersBarChart,
  regionRankingData,
  simplePieChart,
  basicColumChart,
};

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- 设备信息 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Device Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Device NO.:</label>
            <span class="p-3">{{ info.deviceNumber }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Device Coding:</label>
            <span class="p-3">{{ info.deviceCoding }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Device Status:</label>
            <span class="p-3">{{
              info.deviceStatus == 1
                ? "Activated"
                : info.deviceStatus == 2
                ? "Unactivated"
                : info.deviceStatus == 3
                ? "Disabled"
                : "Abnormal"
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Device Location:</label>
            <span class="p-3">{{ info.address }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <!-- 烟弹信息 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Cartridge info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Cartridge Number：</label>
            <span class="p-3">{{ info.smokeRecordNumber }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Model Name：</label>
            <span class="p-3">{{ info.modelName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Brand Name：</label>
            <span class="p-3">{{ info.brandName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Cartridge Type：</label>
            <span class="p-3">{{ info.type }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Formula：</label>
            <p v-for="(item, i) in info.formulaList" :key="i">
              {{ item.component }} {{ item.proportion }}%
            </p>
          </b-col>
          <b-col>
            <label class="col-form-label">Flavor：</label>
            <span class="p-3">{{ info.flavor }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Dose：</label>
            <span class="p-3">{{ info.dose }}ml</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Cap/Day:</label>
            <span class="p-3">{{ info.daySeconds }}s</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Consistence:</label>
            <span class="p-3">{{ info.consistence }}%</span>
          </b-col>
          <b-col>
            <label class="col-form-label">medicalCare:</label>
            <span class="p-3">{{ info.medicalCare == 1 ? "No" : "Yes" }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Solvent：</label>
            <p v-for="(item, i) in info.solventEncodingList" :key="i">
              {{ item.solventName }} {{ item.proportion }}%
            </p>
          </b-col>
          <b-col>
            <label class="col-form-label">Atomizing Wire Type：</label>
            <span class="p-3">{{ info.atomizingWireType }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Power：</label>
            <span class="p-3">{{ info.power }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Batch：</label>
            <span class="p-3">{{ info.productionBatch }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Max Frequency：</label>
            <span class="p-3">{{ info.maxFrequency }}s</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Unused Times：</label>
            <span class="p-3">{{ info.unusedTimes }}s</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Report Type：</label>
            <span class="p-3">{{ info.testReportType }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Report Link：</label>
            <span class="p-3">{{ info.reportLink }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card>
      <!-- 操作日志 -->
      <br />
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Operation log</h4>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Cartridge Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Cartridge Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Cartridge Details",
          active: true,
        },
      ],
      info: {
        deviceNumber: null,
        smokeRecordModelId: 0,
        deviceStatus: null,
        picture: null,
        companyIcon: null,
        calculation: null,
        address: null,
        deviceCoding: null,
        id: 0,
        type: "",
        smokeRecordNumber: "",
        brandName: "1",
        dose: 1,
        tobaccoTar: 0,
        flavor: "1",
        placeOfOrigin: "1",
        city: null,
        madeinName: "1",
        frequency: 0,
        maxFrequency: 1,
        unusedTimes: 1,
        productionBatch: "",
        consistence: "1",
        isPreheat: null,
        solvent: "1",
        atomizingWireType: "",
        reportLink: "1",
        reportEnclosure: "",
        explains: null,
        formulaList: [],
        preheatTime: null,
        seconds: null,
        proportion: "1",
        solvent1: "1",
        proportion1: "1",
        solvent2: "1",
        proportion2: "1",
        normalTemperature: null,
        softTemperature: null,
        wildTemperature: null,
        power: "",
        daySeconds: null,
        worktemp: "0",
        temperature4: null,
        temperature5: null,
        temperature6: null,
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.Cartridge.find(this.$route.query).then((res) => {
          if (res.success) {
            this.info = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
  },
};
</script>

import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


function list(params,ctx){
   return axios.fetchGet(baseUrl + '/useGuide/list',params)
}

function add(params){
    return axios.fetchPost(baseUrl + '/useGuide/saveUseGuide', params);
}

function find(params){
   return axios.fetchGet(baseUrl + '/messages/findById',params)
}

function update(params){
    return axios.fetchPost(baseUrl + '/useGuide/updateUseGuide', params);
}

function del(params){
    return axios.fetchPost(baseUrl + '/messages/deleteById', params);
}

function selectUserList(){
   return axios.fetchGet(baseUrl + '/messages/selectUserList',params)
}


function enableOrDisable(params={}){
   return axios.fetchPost(baseUrl + '/useGuide/enableOrDisable', params);
}


export default {
    list,
    find,
    add,
    update,
    del,
    selectUserList,
    enableOrDisable
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Question Group Name:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "title",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title",
                      placeholder: "Please enter",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                    },
                    model: {
                      value: _vm.form.problemGroupName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "problemGroupName", $$v)
                      },
                      expression: "form.problemGroupName"
                    }
                  }),
                  _c("span", [_vm._v("Title is up to 100 characters long")])
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Question Category:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "categoryId",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "categoryId",
                      options: _vm.types.questionCategorys,
                      "value-field": "id",
                      "text-field": "typeName",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    model: {
                      value: _vm.form.problemTypeId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "problemTypeId", $$v)
                      },
                      expression: "form.problemTypeId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Question Setting:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "setting",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: {
                        "margin-bottom": "10px",
                        "text-decoration": "none"
                      },
                      attrs: { variant: "link" },
                      on: { click: _vm.addProblemName }
                    },
                    [_vm._v("Add a question")]
                  ),
                  _vm._l(_vm.form.problemName, function(item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "inner mb-3 row" },
                      [
                        _c(
                          "div",
                          { staticClass: "col-md-8 col-8" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Question" + (index + 1) + ":",
                                  "label-cols-lg": "2",
                                  "label-align-lg": "right",
                                  "label-class": "requiredRow"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder: "Please enter",
                                    required: "",
                                    oninvalid:
                                      "setCustomValidity('Please enter');",
                                    oninput:
                                      "setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                                  },
                                  model: {
                                    value: item.problemName,
                                    callback: function($$v) {
                                      _vm.$set(item, "problemName", $$v)
                                    },
                                    expression: "item.problemName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-md-8 col-8" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "Answer" + (index + 1) + ":",
                                  "label-cols-lg": "2",
                                  "label-align-lg": "right",
                                  "label-class": "requiredRow"
                                }
                              },
                              [
                                _c("b-form-radio-group", {
                                  attrs: {
                                    id: "btn-radios-2",
                                    options: _vm.answerList,
                                    buttons: "",
                                    "button-variant": "outline-primary",
                                    name: "radio-btn-outline",
                                    disabled: ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.form.problemName.length > 1
                          ? _c(
                              "div",
                              { staticClass: "col-md-8 col-8" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "",
                                      "label-cols-lg": "2",
                                      "label-align-lg": "right"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticStyle: {
                                          "text-decoration": "none"
                                        },
                                        attrs: { variant: "link" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteProblemName(index)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Status:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "status",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      id: "radio-group-1",
                      options: _vm.types.statuss,
                      name: "radio-options",
                      required: ""
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Affiliated Customer:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "customer",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "customer",
                      options: _vm.types.allSupplier,
                      "value-field": "id",
                      "text-field": "brandName",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.supplierId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierId", $$v)
                      },
                      expression: "form.supplierId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "remarks"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remarks",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
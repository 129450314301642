<script>
import { chartConfig, voltageData } from "@/config/echarts-data";
const echarts = require('echarts/lib/echarts');
const graphic = require("echarts/lib/util/graphic");

export default {
  name: "HistoryCurve",
  props: {
    power: {
      type: Array,
      default: () => []
    },
    maxPower: {
      type: Number,
      default: 10
    },
    resistance: {
      type: Number,
      default: 1.2
    },
    width:{
      type: String,
      default: '500px'
    }

  },
  data() {
    return {
      curveChart: null
    };
  },
  mounted() {
    this.refreshVoltageCurve()
  },
  methods: {
    onWindowSizeChanged() {
      this.$nextTick(()=> {
      this.curveChart.resize();
    this.refreshVoltageCurve()
      })
    },
    refreshVoltageCurve() {
      this.$nextTick(() => {
        if (this.$refs.voltageOne) {
          this.curveChart = echarts.init(this.$refs.voltageOne);
          window.addEventListener("resize", this.onWindowSizeChanged);
        }
        const power = this.power;
        if (!power) {
          return;
        }

        const curveColor = "#86A1A9";
        const curveColorLinearGradient = ["rgba(192, 222, 226, 1)", "rgba(246, 250, 250, 1)"];
        const voltageData = chartConfig();
        voltageData.yAxis.min = 0.01;
        voltageData.yAxis.max = 10;
        voltageData.series[0].data = power
        voltageData.color[0] = curveColor;
        voltageData.series[0].label.color = curveColor;
        voltageData.series[0].areaStyle.color = graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: curveColorLinearGradient[0] //c0dee2
              },
              {
                offset: 1,
                color: curveColorLinearGradient[1] //f6fafa
              }
            ]
        );
        this.curveChart.setOption(voltageData, true);
      });
    }
  },
  watch: {
    power(value) {
      this.refreshVoltageCurve()
    }
  },
  computed:{
    curveStyle() {
        return {
          width:this.width,
          height:'200px'
        }
      
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowSizeChanged);
    this.curveChart.dispose()
    this.curveChart = null
  }
};
</script>

<template>
  <!-- 曲线1 -->
  <div
      id="voltageOne"
      ref="voltageOne"
      class="voltage-one"
      :style="curveStyle"
  ></div>
</template>

<style scoped >
</style>
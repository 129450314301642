<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- Flavor Name 口味名称-->
        <b-form-group
          label="Flavor Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="model-name"
            v-model="form.modelName"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 128)value = value.slice(0, 128)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- Flavor Type 烟弹类型 -->
        <b-form-group
          label="Flavor Type:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="cartridge-type"
          label-class="requiredRow"
        >
          <b-form-select
            id="cartridge-type"
            v-model="form.type"
            :options="types.TypeEncoding"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
            @change="changeType(form.type)"
          ></b-form-select>
        </b-form-group>

        <!-- Device Model 适用设备 -->
        <b-form-group
          label="Device Model:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="device-model"
          label-class="requiredRow"
        >
          <b-form-select
            id="device-model"
            v-model="form.deviceModelId"
            :options="types.deviceList"
            value-field="id"
            text-field="modelName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          ></b-form-select>
        </b-form-group>

        <!-- Custom Temp 是否可自定义温度 -->
        <b-form-group
          label="Custom Temp:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="custom-temp"
          label-class="requiredRow"
          v-if="form.deviceModelId != 8"
        >
          <b-form-checkbox
            id="custom-temp"
            v-model="form.customTemperature"
            name="customTemperature"
            :value="0"
            :unchecked-value="1"
            switch
            size="lg"
            style="margin-top: 5px"
          >
            {{ form.customTemperature == 0 ? "Yes" : "No" }}
          </b-form-checkbox>
        </b-form-group>

        <!-- Suggested Price 建议零售价 -->
        <b-form-group
          label="Suggested Price:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="suggested-price"
          label-class="requiredRow"
        >
          <b-form-input
            id="suggested-price"
            v-model="form.suggestRetailPrice"
            type="number"
            min="0"
            step="0.01"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- Actual Selling Price 实际销售价格 -->
        <b-form-group
          label="Actual Selling Price:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="actual-selling-price"
          label-class="requiredRow"
        >
          <b-form-input
            id="actual-selling-price"
            v-model="form.retailPrice"
            required
            type="number"
            min="0"
            step="0.01"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- Brand Name 所属品牌-->
        <b-form-group
          label="Brand Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="brand-name"
          label-class="requiredRow"
        >
          <b-form-select
            id="brand-name"
            v-model="form.brand"
            :options="types.brandNames"
            @change="onChangeBrand(form.brand)"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          ></b-form-select>
        </b-form-group>

        <!-- Product Genre Tags 商品标签 -->
        <b-form-group
          label="Product Genre Tags:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="product-genre-tags"
          label-class="requiredRow"
        >
          <multiselect
            id="product-genre-tags"
            v-model="selectProductGenre"
            :options="types.productGenreList"
            track-by="id"
            label="labelName"
            placeholder="Please select"
            :searchable="false"
            :close-on-select="false"
            :multiple="true"
            :max="maxFive"
            @input="selectGenre"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </multiselect>
        </b-form-group>

        <!-- Usage Effects 使用感受标签 -->
        <b-form-group
          label="Usage Effects:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="usage-effects"
          label-class="requiredRow"
        >
          <multiselect
            id="usage-effects"
            v-model="selectUsageEffects"
            :options="types.usageEffectsList"
            track-by="id"
            label="labelName"
            placeholder="Please select"
            :searchable="false"
            :close-on-select="false"
            :multiple="true"
            :max="maxThree"
            @input="selectEffects"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </multiselect>
        </b-form-group>

        <!-- Flavor Profiles 口感标签 -->
        <b-form-group
          label="Flavor Profiles:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="flavor-profiles"
          label-class="requiredRow"
        >
          <multiselect
            v-model="selectFlavorProfiles"
            :options="types.flavorProfilesList"
            track-by="id"
            label="labelName"
            placeholder="Please select"
            :searchable="false"
            :close-on-select="false"
            :multiple="true"
            :max="maxThree"
            @input="selectProfiles"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </multiselect>
        </b-form-group>

        <!-- Intensity 强度 -->
        <b-form-group
          label="Intensity:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="intensity"
          label-class="requiredRow"
        >
          <b-form-select
            id="intensity"
            v-model="form.strengthId"
            :options="types.IntensityList"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
            @change="changeType(form.type)"
          ></b-form-select>
        </b-form-group>

        <!-- Flavor Picture 烟油口味图片 -->
        <b-form-group
          label="Flavor Picture:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="logo"
          label-class="requiredRow"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
            :required="!editId"
            oninvalid="setCustomValidity('Please upload');"
            oninput="setCustomValidity('');"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.flavorPicture" style="position: relative">
                <i
                  class="ion ion-md-close-circle"
                  style="
                    position: absolute;
                    left: 70px;
                    top: 5px;
                    font-size: 25px;
                  "
                  @click="onDeleteFlavorPicture"
                />
                <img
                  :src="form.flavorPicture"
                  style="width: 100px; height: 100px; border: 2px dashed #ccc"
                />
                <span
                  style="margin-left: 10px; vertical-align: bottom"
                  v-if="photoName"
                >
                  <span style="font-weight: 500">Picture name : </span
                  >{{ photoName }}</span
                >
              </div>
              <label
                for="filestyle"
                style="margin-bottom: 0"
                class="btn btn-secondary"
                v-if="!form.flavorPicture"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span v-if="!form.flavorPicture">
                Recommended size of picture: 512 pixels * 512 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
          <span class="buttonText" style="position: relative; top: 10px">
            Tips：Product Icon for "My Product History"
          </span>
        </b-form-group>

        <!-- Carousel 商品轮播图 -->
        <b-form-group
          label="Carousel:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="carousel"
          label-class="requiredRow"
        >
          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.commodityCarouselMap" style="display: flex">
                <div
                  v-for="(item, index) of form.commodityCarouselMap"
                  :key="index"
                  style="width: 100px; position: relative; margin-right: 10px"
                >
                  <i
                    class="ion ion-md-close-circle"
                    style="
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      font-size: 25px;
                      z-index: 999;
                    "
                    @click="onDeleteCarouselMap(item, index)"
                  />
                  <video
                    v-if="/mp4/.test(item)"
                    :src="item"
                    style="width: 100px; height: 100px; border: 2px dashed #ccc"
                  ></video>
                  <img
                    v-else
                    :src="item"
                    style="width: 100px; height: 100px; border: 2px dashed #ccc"
                  />
                </div>
              </div>
              <label
                style="margin-bottom: 0"
                class="btn btn-secondary"
                v-if="form.commodityCarouselMap.length < 10"
                @click="onClickUploadCarousel"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span v-if="form.commodityCarouselMap.length < 10">
                Recommended size of picture: 750 pixels * 750 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
          <span class="buttonText" style="position: relative; top: 10px">
            Tips：Picture and video for 1st section
          </span>
        </b-form-group>

        <!-- Creators Notes 创作故事 -->
        <b-form-group
          label="Creators Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="creators-notes"
          label-class="requiredRow"
        >
          <b-form-textarea
            id="creators-notes"
            v-model="form.commodityCreationStory"
            placeholder="Enter Notes"
            rows="3"
            required
            max-rows="15"
          ></b-form-textarea>
        </b-form-group>

        <!-- Details 商品详情图 -->
        <b-form-group
          label="Details:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="details"
          label-class="requiredRow"
        >
          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.commodityDetailsMap" style="display: flex">
                <div
                  v-for="(item, index) of form.commodityDetailsMap"
                  :key="index"
                  style="width: 100px; position: relative; margin-right: 10px"
                >
                  <i
                    class="ion ion-md-close-circle"
                    style="
                      position: absolute;
                      right: 5px;
                      top: 5px;
                      font-size: 25px;
                    "
                    @click="onDeleteDetailsMap(item, index)"
                  />
                  <img
                    :src="item"
                    style="width: 100px; height: 100px; border: 2px dashed #ccc"
                  />
                </div>
              </div>
              <label
                for="filestyle-3"
                style="margin-bottom: 0"
                class="btn btn-secondary"
                v-if="form.commodityDetailsMap.length < 10"
                @click="onClickUploadDetails"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span v-if="form.commodityDetailsMap.length < 10">
                Recommended image width 750px, supporting JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- Public Display 是否上架 -->
        <b-form-group
          label="Public Display:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="on-sale"
        >
          <b-form-checkbox
            id="on-sale"
            v-model="form.onSale"
            name="onSale"
            :value="0"
            :unchecked-value="1"
            switch
            size="lg"
            style="margin-top: 5px"
          >
            {{ form.onSale == 0 ? "Yes" : "No" }}
          </b-form-checkbox>
        </b-form-group>

        <!-- Find it link 购物车URL -->
        <b-form-group
          label="Find it link:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="cart-url"
        >
          <b-form-input
            id="cart-url"
            v-model="form.cartUrl"
            placeholder="Please enter"
            maxlength="100"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></b-form-input>
        </b-form-group>

        <!-- Product listing priority 排序 -->
        <b-form-group
          label="Product listing priority:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="flavor-sort"
          label-class="requiredRow"
        >
          <b-form-input
            id="flavor-sort"
            required
            v-model="form.flavorSort"
            placeholder="Please enter"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\d]/g,'')"
          ></b-form-input>
        </b-form-group>

        <!-- Consistence 烟油浓稠度 -->
        <b-form-group
          v-if="form.type == 1 || form.type == 5"
          label="Consistence:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="consistence"
          label-class="requiredRow"
        >
          <b-form-input
            id="consistence"
            v-model="form.consistence"
            required
            type="number"
            min="0"
            step="0.0001"
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3')"
          ></b-form-input>
        </b-form-group>

        <!-- Medical Care 是否医用-->
        <b-form-group
          v-if="form.type == 5"
          label="Medical Care:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="medical"
          label-class="requiredRow"
        >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.medicalCare"
            name="medicalCare"
            value="0"
            required
            >YES</b-form-radio
          >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.medicalCare"
            name="medicalCare"
            value="1"
            required
            >NO</b-form-radio
          >
        </b-form-group>

        <!-- Seconds/Day 吸入上限设置 -->
        <b-form-group
          v-if="form.type == 5"
          label="Seconds/Day:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="daySeconds"
          label-class="requiredRow"
        >
          <b-form-input
            id="remark"
            v-model="form.daySeconds"
            placeholder="Please enter"
            maxlength="20"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></b-form-input>
        </b-form-group>

        <!-- remarks 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary" :disabled="confirmDisabled"
            >Confirm</b-button
          >
          <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
        </b-form-group>
      </b-form>
      <!-- <p>{{form}}</p> -->
    </b-card>
    <UploadFile
      :showUploadModal="showUploadModal"
      :oldFileList="editUploadList"
      :path="uploadPath"
      :fileType="fileType"
      :maxNumber="maxNumber"
      @FinishEvent="finishEvent"
      @CancelEvent="cancelEvent"
    ></UploadFile>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady, upload_img } from "@/api/upload";
import Video from "../../../../../组件/Admin/src/router/views/ui/video.vue";
import UploadFile from "@/components/modal/upload-file.vue";

/**
 * Cartridge Model Add
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
    Video,
    UploadFile,
  },
  data() {
    return {
      title: this.editId ? "Flavor Modify" : "Flavor Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: this.editId ? "Flavor Modify" : "Flavor Create",
          active: true,
        },
      ],
      maxFive: 5,
      maxThree: 3,
      form: {
        supplierId: localStorage.supplierId,

        modelName: "", //口味名称
        type: "", //烟弹类型
        deviceModelId: "", //使用设备
        customTemperature: 1, //是否可自定义温度 0是 1否
        suggestRetailPrice: "", //建议零售价
        retailPrice: "", //实际零售价
        commodityLabelId: "", //商品标签编号
        experienceFeelingsLabelId: "", //使用感受标签编号
        tasteLabelId: "", //口感标签编号
        strengthId: "", //强度标签编号
        brand: "", //所属品牌
        flavorPicture: "", //烟油口味图片
        commodityCarouselMap: [], //商品轮播图
        commodityCreationStory: "", //创作故事
        commodityDetailsMap: [], //商品详情图
        onSale: 1, //是否上架 0是 1否
        cartUrl: "", //购物车URL
        flavorSort: "", //排序
        remarks: "", //备注

        consistence: 0, //烟油浓稠度
        medicalCare: "", //是否医用
        daySeconds: "", //吸入上限设置
        deleteFileUrl: [], //删除照片集合 服务端处理
      },
      reportType: "",
      photoName: "",
      types: {
        brandNames: [{ text: "Please select", value: "" }],
        flavorOptions: [],
        cartridgeComponentData: [{ text: "Please select", value: "" }],
        SolventEncoding: [{ text: "Please select", value: 0 }],
        TypeEncoding: [{ text: "Please select", value: "" }],
        statuss: [
          { text: "true", value: 1, disabled: false },
          { text: "false", value: 0, disabled: false },
        ],
        deviceList: [{ modelName: "Please select", id: "" }], //适用设备列表
        productGenreList: [], //商品标签列表
        usageEffectsList: [], //使用感受标签列表
        flavorProfilesList: [], //口感标签列表
        IntensityList: [{ text: "Please select", value: "" }], //强度标签列表
      },
      selectProductGenre: [],
      selectUsageEffects: [],
      selectFlavorProfiles: [],

      show: true, // 表单显示
      editor: ClassicEditor,
      showReportLink: false,
      showReportEnclosure: false,
      flavor: [],
      flavorLabel: "",
      editId: window.location.search,

      showUploadModal: false,
      editUploadList: [],
      uploadPath: "",
      fileType: /image|video|mp4/,
      maxNumber: 5,

      confirmDisabled: false,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {},
  async mounted() {
    if (/edit/i.test(window.location.href)) {
      await this.show1();
    }
    this.init();
  },
  methods: {
    init() {
      let that = this;
      this.$api.Dropdown.brandList().then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.brandNames.push({
              text: res.data[i].brandName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });

      this.$api.Dropdown.selectTypeByOil().then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.TypeEncoding.push({
              text: res.data[i].typeName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });

      //加载商品标签列表
      this.$api.Dropdown.selectAllCommodityLabel({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          that.types.productGenreList = res.data;
          if (this.form.commodityLabelId) {
            that.types.productGenreList.forEach((element) => {
              that.form.commodityLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectProductGenre.push(element);
                }
              });
            });
          }
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载使用感受标签列表
      this.$api.Dropdown.selectAllExperienceFeelingsLabel({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          that.types.usageEffectsList = res.data;

          that.types.usageEffectsList.forEach((element) => {
            if (that.form.experienceFeelingsLabelId) {
              that.form.experienceFeelingsLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectUsageEffects.push(element);
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载口感标签列表
      this.$api.Dropdown.selectAllTasteLabel({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.flavorProfilesList = res.data;

          that.types.flavorProfilesList.forEach((element) => {
            if (that.form.tasteLabelId) {
              that.form.tasteLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectFlavorProfiles.push(element);
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载强度标签列表
      this.$api.Dropdown.selectAllStrength({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.IntensityList.push({
              text: res.data[i].labelName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载适用设备列表
      this.$api.Dropdown.selectAllDeviceModel().then((res) => {
        if (res.success && res.data) {
          this.types.deviceList = this.types.deviceList.concat(res.data);
        } else {
          if (res.message) {
            this.$toast(res);
          }
        }
      });
    },
    async show1() {
      let that = this;
      if (this.$route.query.id) {
        await this.$api.CartridgeModel.find(this.$route.query).then((res) => {
          if (res.success) {
            this.form = res.data;
            if (res.data.flavorPicture) {
              let arr = res.data.flavorPicture.split("/");
              this.photoName = arr[arr.length - 1];
            }
            if (res.data.commodityCarouselMap == null)
              this.form.commodityCarouselMap = [];
            if (res.data.commodityDetailsMap == null)
              this.form.commodityDetailsMap = [];
            this.form.deleteFileUrl = [];
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReady: onReady,
    onSubmit(evt) {
      evt.preventDefault();

      this.confirmDisabled = true;
      setTimeout(() => {
        this.confirmDisabled = false;
      }, 500);

      if (this.selectProductGenre.length > 0) {
        this.form.commodityLabelId = "";
        for (let i = 0; i < this.selectProductGenre.length; i++) {
          this.form.commodityLabelId += this.selectProductGenre[i].id + ",";
        }
        this.form.commodityLabelId = this.form.commodityLabelId.substring(
          0,
          this.form.commodityLabelId.length - 1
        );
      } else {
        this.$bvToast.toast("Please select Product Genre Tags.");
        return;
      }
      if (this.selectUsageEffects.length > 0) {
        this.form.experienceFeelingsLabelId = "";
        for (let i = 0; i < this.selectUsageEffects.length; i++) {
          this.form.experienceFeelingsLabelId +=
            this.selectUsageEffects[i].id + ",";
        }
        this.form.experienceFeelingsLabelId =
          this.form.experienceFeelingsLabelId.substring(
            0,
            this.form.experienceFeelingsLabelId.length - 1
          );
      } else {
        this.$bvToast.toast("Please select Usage Effects.");
        return;
      }
      if (this.selectFlavorProfiles.length > 0) {
        this.form.tasteLabelId = "";
        for (let i = 0; i < this.selectFlavorProfiles.length; i++) {
          this.form.tasteLabelId += this.selectFlavorProfiles[i].id + ",";
        }
        this.form.tasteLabelId = this.form.tasteLabelId.substring(
          0,
          this.form.tasteLabelId.length - 1
        );
      } else {
        this.$bvToast.toast("Please select Flavor Profiles.");
        return;
      }

      if (this.form.commodityCarouselMap.length <= 0) {
        this.$bvToast.toast("Please upload Carousel.");
        return;
      }
      if (this.form.commodityDetailsMap.length <= 0) {
        this.$bvToast.toast("Please upload Details.");
        return;
      }

      if (/edit/i.test(window.location.href)) {
        this.$api.CartridgeModel.update(this.form).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$bvToast.toast(res);
            }
          }
        });
      } else {
        this.$api.CartridgeModel.add(this.form).then((res) => {
          if (res.success) {
            this.$toastBack(res);
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      (this.form = {
        supplierId: localStorage.supplierId,

        modelName: "", //口味名称
        type: "", //烟弹类型
        deviceModelId: "", //使用设备
        customTemperature: 1, //是否可自定义温度 0是 1否
        suggestRetailPrice: "", //建议零售价
        retailPrice: "", //实际零售价
        commodityLabelId: "", //商品标签编号
        experienceFeelingsLabelId: "", //使用感受标签编号
        tasteLabelId: "", //口感标签编号
        strengthId: "", //强度标签编号
        brand: "", //所属品牌
        flavorPicture: "", //烟油口味图片
        commodityCarouselMap: [], //商品轮播图
        commodityCreationStory: "", //创作故事
        commodityDetailsMap: [], //商品详情图
        onSale: 1, //是否上架 0是 1否
        cartUrl: "", //购物车URL
        flavorSort: "", //排序
        remarks: "", //备注

        consistence: 0, //烟油浓稠度
        medicalCare: "", //是否医用
        daySeconds: "", //吸入上限设置
        deleteFileUrl: [], //删除照片集合 服务端处理
      }),
        (this.photoName = "");
      this.show = false;
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    selectGenre(val) {
      this.form.commodityLabelId = val.map((item) => {
        return item;
      });
    },
    selectEffects(val) {
      this.form.experienceFeelingsLabelId = val.map((item) => {
        return item;
      });
    },
    selectProfiles(val) {
      this.form.tasteLabelId = val.map((item) => {
        return item;
      });
    },
    changeType(type) {
      // this.types.flavorOptions = [];
      if (type) {
        if (type == 1) {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 1;
          this.form.daySeconds = "";
        } else if (type == 4) {
          // this.flavorLabel = "Graphic Name:";
          this.form.medicalCare = 1;
          this.form.consistence = "";
          this.form.daySeconds = "";
        } else if (type == 5) {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 0;
        } else {
          // this.flavorLabel = "Flavor:";
          this.form.medicalCare = 1;
          this.form.consistence = "";
          this.form.daySeconds = "";
        }
      }
    },
    //上传烟油口味图片
    upload(e) {
      let file = e.target.files[0];
      if (file == undefined) return;
      this.photoName = file.name;
      if (/image/.test(file.type)) {
        //增加上传中loading...提示
        upload_img(file).then((res) => {
          if (res.success) {
            this.form.flavorPicture = res.data;
          }
        });
      } else {
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    //商品口味图片
    onDeleteFlavorPicture() {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(that.form.flavorPicture);
            that.form.flavorPicture = "";
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //删除商品轮播图
    onDeleteCarouselMap(item, index) {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(item);
            that.form.commodityCarouselMap.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //删除商品详情图
    onDeleteDetailsMap(item, index) {
      let that = this;
      this.$bvModal
        .msgBoxConfirm("Are you sure?", {
          title: "Operation tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            that.form.deleteFileUrl.push(item);
            that.form.commodityDetailsMap.splice(index, 1);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //#region 图片上传组件函数
    onClickUploadCarousel() {
      this.editUploadList = this.form.commodityCarouselMap;
      this.showUploadModal = true;
      this.uploadPath = "Carousel";
      this.maxNumber = 10;
    },
    onClickUploadDetails() {
      this.editUploadList = this.form.commodityDetailsMap;
      this.fileType = /image/;
      this.showUploadModal = true;
      this.uploadPath = "Details";
      this.maxNumber = 5;
    },
    finishEvent(fileList, deleteFileList, type) {
      if (type == "Carousel") {
        this.form.commodityCarouselMap = fileList;
      } else if (type == "Details") {
        this.form.commodityDetailsMap = fileList;
      }
      deleteFileList.forEach((element) => {
        this.form.deleteFileUrl.push(element);
      });

      this.fileType = /image|video|mp4/;
      this.maxNumber = 5;
      this.uploadPath = "";
      this.editUploadList = [];
      this.showUploadModal = false;
    },
    cancelEvent() {
      this.fileType = /image|video|mp4/;
      this.maxNumber = 5;
      this.uploadPath = "";
      this.editUploadList = [];
      this.showUploadModal = false;
    },
    //#endregion

    onChangeBrand() {
      let that = this;
      //加载商品标签列表
      this.$api.Dropdown.selectAllCommodityLabel({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          that.types.productGenreList = res.data;
          if (this.form.commodityLabelId) {
            that.types.productGenreList.forEach((element) => {
              that.form.commodityLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectProductGenre.push(element);
                }
              });
            });
          }
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载使用感受标签列表
      this.$api.Dropdown.selectAllExperienceFeelingsLabel({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          that.types.usageEffectsList = res.data;

          that.types.usageEffectsList.forEach((element) => {
            if (that.form.experienceFeelingsLabelId) {
              that.form.experienceFeelingsLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectUsageEffects.push(element);
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载口感标签列表
      this.$api.Dropdown.selectAllTasteLabel({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.flavorProfilesList = res.data;

          that.types.flavorProfilesList.forEach((element) => {
            if (that.form.tasteLabelId) {
              that.form.tasteLabelId.forEach((item) => {
                if (element.id == item) {
                  that.selectFlavorProfiles.push(element);
                }
              });
            }
          });
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });

      //加载强度标签列表
      this.$api.Dropdown.selectAllStrength({
        supplierId: this.form.brand,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.IntensityList = [];
          for (let i = 0; i < res.data.length; i++) {
            this.types.IntensityList.push({
              text: res.data[i].labelName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$toast(res.message);
          }
        }
      });
    },
  },
};
</script>

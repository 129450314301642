<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />
		
		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<!-- 标题 -->
						<b-col lg="4">
							<b-form-group
								label="Title"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="title"
							>
								<b-form-input id="title" v-model="search_condition.title" trim></b-form-input>
							</b-form-group>
						</b-col>
						
						<!-- 状态 -->
						<b-col lg="4">
							<b-form-group
								label="Status"
								label-cols-sm="4"
								label-align-sm="right"
							>
								<b-form-select 
                                     v-model="search_condition.status"
                                     :options="types.statuss"
                                     value-field="value"
                                     text-field="text"
								></b-form-select>
							</b-form-group>
						</b-col>
						
						<!-- 分类 -->
						<b-col lg="4">
							<b-form-group
								label="Category"
								label-cols-sm="4"
								label-align-sm="right"
							>
								<b-form-select 
                                     v-model="search_condition.category"
                                    :options="types.Categorys"
                                     value-field="id"
                                     text-field="name"
								>
                                </b-form-select>
							</b-form-group>
						</b-col>
						
						<b-col lg="4">
							<b-form-group
								label="Select Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="select-time"
							>
								<date-picker
                                    v-model="selectTime"
									format="MM/DD/YYYY"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="4">
                              <b-button variant="primary" @click="search()">Search</b-button>
						</b-col>

					</b-row>
				</b-card>
				
				<b-card>
					<!-- table -->
					<b-button @click="gotoAdd" variant="primary">Create</b-button>
					<div class="table-responsive mb-0">
						<b-table  
                            show-empty
                            empty-text="no records"
                            id="table" 
                            head-variant="light"
                            ref="table"
                            :busy="isBusy"
							:striped="true"
							:items="provider" 
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy" 
							:sort-desc.sync="sortDesc" 
							:filter="filter" 
							:filter-includedfields="filterOn" 
							@filtered="onFiltered"
						>
                            <template v-slot:table-busy>
                              <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                              </div>
                            </template>

							<template v-slot:cell(index)="data">
								{{ data.index + (search_condition.pageNum-1)*search_condition.pageSize+1 }}
							</template>
							
							<template v-slot:cell(nickName)="data">
								<img :src="`${data.item.picture}`" alt class="avatar-xs rounded-circle mr-2" />
								{{ data.item.nickName }}
							</template>
							
							<template v-slot:cell(actions)="row">


                             <b-button-group>
								<b-button size="sm" variant="primary"
									@click="edit(row.item, row.index, $event.target)" class="mr-1">
									edit
								</b-button>

								<b-button 
                                    v-if="row.item.status"
                                    size="sm" 
                                    variant="danger"
									@click="disable(row.item, row.index, $event.target)" class="mr-1">
                                    deplete
								</b-button>

								<b-button 
                                    v-else
                                    size="sm" 
                                    variant="info"
									@click="enable(row.item, row.index, $event.target)" class="mr-1">
                                    enable
								</b-button>
                             </b-button-group>



							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data" >
						<b-col>
							<div class="dataTables_paginate paging_simple_numbers float-right">

								<ul class="pagination  mb-0" v-if="rows!=0">
									<b-pagination 
                                        v-model="search_condition.pageNum" 
										:per-page="10"
										:total-rows="rows" 
										aria-controls="table"
                                    >
                                    </b-pagination>
								</ul>

							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
	</Layout>
</template>
<script>
	import Layout from "../../layouts/main";
	import PageHeader from "@/components/page-header";
	
	import DatePicker from "vue2-datepicker";

	/**
	 * Article component
	 */
	export default {
		components: {
			Layout,
			PageHeader,
			DatePicker
		},
		data() {
			return {
				page: {
					title: "Article",
					items: [{
							text: "UNI-CORE",
							// href: "/"
						},
						{
							text: "Content Manage",
							// href: "/"
						},
						{
							text: "Article",
							active: true
						}
					]
				},
                totalRows: 0,
                currentPage: 1,
                perPage: 10,
                filter: null,
                filterOn: [],
                sortBy: "id",
                sortDesc: false,
                fields: [
                    {key: 'index', label: '#', class: 'text-center'},
                    {key: 'title', label: 'Title', class: 'text-center'},
                    {key: 'category', label: 'Category', class: 'text-center'},
                    {key: 'user', label: 'Publish user', class: 'text-center'},
                    {key: 'status', label: 'Status',
                        formatter: (value) => {
                            return value == 1 ? 'Removed' : 'Added';
                        }
                    },
                    {key: 'createTime', label: 'Publish Time', class: 'text-center'},
                    {key: 'actions', label: 'Opreration', class: 'text-center'},
                ],
                search_condition:{
                    pageNum : 1,
                    pageSize : 10, 

                    createTime: "",
                    endTime:"", 

                    title: '',
                    category: '',
                    user: '',
                    status: '',
                    createTime: '',
                },
                isBusy: false,
                has_data:false,
                selectTime:[],
                types:{
                    Categorys:[
                       {id:"",name:''},
                    ],
                    statuss:[
                       { text: '', value: 2, disabled: false },
                       { text: 'Added', value: 1, disabled: false },
                       { text: 'Removed', value:0, disabled: false },
                    ],
                },

			};
		},
        watch:{
           selectTime:function(val){
                this.search_condition.createTime=val[0]
                this.search_condition.endTime=val[1]
           },
        },
		computed: {
			rows(){
				return this.totalRows;
			}
		},
		mounted() {
            this.init()
		},
		created() {
		},
		methods: {
          provider(ctx){
               this.isBusy = true 
               return this.$api.Message.list(this.search_condition,ctx)
               .then(x=>{
                        this.isBusy =  false
                        if (x.total){
                            this.has_data = true
                            this.totalRows =x.total;
                            return x.data
                        }else{
                            this.has_data=false
                            return []
                        }
                })
               .catch(e=>{
                   this.isBusy =  false
                   this.has_data=false
                   return []
                })
            },
            init(){
                this.$api.Category.list().then(res=>{
                    if (res.success) {
                        this.types.Categorys=this.types.Categorys.concat(res.data)
                    }
                })
            },

			show(item, index, button) {
				this.$router.push('ArticleInfo?id='+item.id);
			},
			edit(item, index, button) {
				this.$router.push('ArticleEdit?id='+item.id);
			},
            search(){
                this.search_condition.pageNum= 1;
                this.$refs.table.refresh()
			},
            del(a,b,c){
                this.$api.Message.del({id:a.id}).then((res)=>{
                    if(res.message){
              this.$bvToast.toast(res.message);
            }
                    if (res.success){
                        this.$refs.table.refresh()
                    }
               })
            },
            disable(a,b,c){
                this.$api.Message.disable({id:a.id}).then((res)=>{
                    if(res.message){
              this.$bvToast.toast(res.message);
            }
                    if (res.success){
                        this.$refs.table.refresh()
                    }
               })
            },
            enable(a,b,c){
                this.$api.Message.enable({id:a.id}).then((res)=>{
                    if(res.message){
              this.$bvToast.toast(res.message);
            }
                    if (res.success){
                        this.$refs.table.refresh()
                    }
               })
            },

			onFiltered(filteredItems) {
				this.totalRows = filteredItems.length;
				this.search_condition.pageNum= 1;
			},
			gotoAdd(){
				this.$router.push('ArticleAdd');
			},
		}
	};
</script>

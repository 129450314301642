import moment from "moment"


//2020-05-13T16:00:00.000Z
//2020-05-15 00:00:00
function  format(t=""){
    return t && moment(t).format('YYYY-MM-DD HH:mm:ss')
}

function now(){
    return moment().format('YYYYMMDDhhmmss')
}


export {
    format,
    now,
}

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>User Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">User Photo:</label>
            <span class="p-3"
              ><img
                v-if="info.picture"
                :src="info.picture"
                alt
                class="avatar-xs rounded-circle mr-2"
            /></span>
          </b-col>
          <b-col>
            <label class="col-form-label">Email:</label>
            <span class="p-3">{{ info.email }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Nickname:</label>
            <span class="p-3">{{ info.nickName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Birth:</label>
            <span class="p-3">{{ info.dateOfBirth }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Gender:</label>
            <span class="p-3">{{ info.gender }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Binded Battery:</label>
            <span class="p-3">{{ info.binding }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Smoke History:</label>
            <span class="p-3">{{ info.isSmoke ? "YES" : "NO" }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Vape History:</label>
            <span class="p-3">{{ info.isElectricSmoke ? "YES" : "NO" }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- 设备控制 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Device Control</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <!-- 计量控制 -->
          <b-col>
            <label class="col-form-label">Dose control:</label>
            <span class="p-3">800 </span>
          </b-col>
          <!-- 禁用时段 -->
          <b-col>
            <label class="col-form-label">Depleted period:</label>
            <span class="p-3">8:00-21:00</span>
          </b-col>
          <!-- 温度控制 -->
          <b-col>
            <label class="col-form-label">Temperature control:</label>
            <span class="p-3">750 ℉</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- 绑定的设备信息 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Bound device infor</h4>
          </div>
        </b-col>
      </b-row>
      <br />

      <b-table
        id="device_table"
        head-variant="light"
        striped
        :fields="deviceFields"
        ref="device_table"
        :items="device_provider"
        :per-page="device.pageSize"
        :current-page="device.pageNum"
        :busy="device_isBusy"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>

      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="!device_totalRows">
              <b-pagination
                v-model="device.pageNum"
                :per-page="10"
                :total-rows="device_totalRows"
                aria-controls="device_table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <!-- end card -->

    <!-- 使用记录 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Usage record</h4>
          </div>
        </b-col>
      </b-row>
      <b-table
        head-variant="light"
        striped
        :fields="usageRecordFields"
        id="SmokeBomb_table"
        ref="SmokeBomb_table"
        :items="SmokeBomb_provider"
        :per-page="SmokeBomb.pageSize"
        :current-page="SmokeBomb.pageNum"
        :busy="SmokeBom_isBusy"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>
      </b-table>

      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="!SmokeBomb_totalRows">
              <b-pagination
                v-model="SmokeBomb.pageNum"
                :per-page="10"
                :total-rows="SmokeBomb_totalRows"
                aria-controls="SmokeBomb_table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <!-- end card -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Usage Record Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "Usage Record Details",
          active: true,
        },
      ],
      // 绑定设备
      deviceFields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "deviceNumber", label: "Device NO.", class: "text-center" },
        { key: "deviceCoding", label: "Device Coding", class: "text-center" },
        {
          key: "deviceLocation",
          label: "Device Location",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 0 ? "Activate" : "Active";
          },
        },
        { key: "activationTime", label: "Activate Time", class: "text-center" },
      ],
      // 使用记录
      usageRecordFields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "smokeRecordNumber",
          label: "Cartridge NO.",
          class: "text-center",
        },
        { key: "deviceNumber", label: "Device NO.", class: "text-center" },
        { key: "flavor", label: "Cartridge Name", class: "text-center" },
        { key: "createTime", label: "Used On", class: "text-center" },
        { key: "address", label: "Location", class: "text-center" },
        {
          key: "totalDuration",
          label: "TotalDuration（s）",
          class: "text-center",
        },
      ],
      info: {
        binding: 0,
        createTime: "",
        dateOfBirth: "",
        email: "",
        frequency: 0,
        gender: 0,
        id: 0,
        isElectricSmoke: 0,
        isSmoke: 0,
        nickName: "",
        picture: "",
        source: "",
      },
      device: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      device_isBusy: false,
      device_totalRows: 0,
      SmokeBomb: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      SmokeBom_isBusy: false,
      SmokeBomb_totalRows: 0,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    device_provider(ctx) {
      this.device_isBusy = true;
      return this.$api.userManage.getUserDevice(this.device, ctx).then((x) => {
        this.device_totalRows = x.total;
        this.device_isBusy = false;
        return x.data;
      });
    },
    SmokeBomb_provider(ctx) {
      this.SmokeBom_isBusy = true;
      return this.$api.userManage
        .findSmokeBombList(this.SmokeBomb, ctx)
        .then((x) => {
          this.SmokeBomb_totalRows = x.total;
          this.SmokeBom_isBusy = false;
          return x.data;
        });
    },
    init() {
      if (this.$route.query.id) {
        this.$api.userManage.getUser(this.$route.query).then((res) => {
          if (res.success) {
            this.info = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-lg-12">
        <div class="card wrap1 p-3">


          <div class="col-lg-12">
            <b-form-group
              label="Authorization question name"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-form-input class="pr-0" 
                  v-model="form.title" 
                  required 
                  placeholder="Enter Question"
              ></b-form-input>
            </b-form-group>
          </div>


            <b-form-group
              label="Authorization question"
              label-cols-sm="3"
              label-align-sm="right"
            >
              <b-button @click="AddQuestion()" class="col-lg-1 mb-3 ml-5" variant="primary">Add</b-button>
          </b-form-group>

          <div class="wrap2 col-lg-12" >

            <div class="col-lg-12" v-for="(item,ind) in form.content" :key="ind">
              <div class="card-body m-2" style="border:1px solid #ddd">
                <form>
                  <!-- 问题名称 -->
                  <div class="form-group">
                    <b-col lg="12" class="d-flex num">

                      <div class="num1">Question {{ind+1}}</div>


                        <div class="col-md-9 col-8">
                            <b-form-group
                              label="Authorization answer"
                              label-cols-sm="3"
                              label-align-sm="right"
                            >
                              <b-form-input 
                                  v-model="item.title" 
                                  required 
                                  placeholder="Enter question"
                                class="inner form-control"
                              ></b-form-input>
                          </b-form-group>
                        </div>

                        <div class="col-md-1 col-1">
                                  <b-button 
                                   @click="delQuestion(ind)" 
                                   variant="danger" 
                                   class="btn-margin btn btn-primary btn-block inner"
                              >Del</b-button>
                        </div>


                    </b-col>
                  </div>

                  <!-- 添加回答 -->
                  <div class="form-group">
                    <b-col lg="10" >
                      <b-button variant="success" @click="add_answer(item)">Add Answer</b-button>
                    </b-col>
                  </div>

                  <!-- aaa-->
                   <b-col lg="12">
                      <div >
                        <div v-for="(field, index) in item.q" :key="ind+'_'+index" >
                          
                            
                          <b-col class="d-flex  "  >


                            <b-form-input  
                             v-model="field.content"
                             class="mr-5  mb-3 offset-3" 
                             required 
                              placeholder="Enter answer"
                            >
                            </b-form-input>

                             <b-form-checkbox
                                    v-model="field.status"
                                    name="checkbox-1"
                                    value="1"
                                    unchecked-value="0"
                              >
                               unlock
                              </b-form-checkbox>


                          </b-col>
                        
                        </div>
                      </div>
                   </b-col>

                    <b-row class="col-lg-12" v-if="item.q.length>0">
                        <b-col lg="12">
                            <b-form-group >
                                <b-button class="mb-3" variant="success" @click="del_answer(item)">Del</b-button>
                           </b-form-group>
                        </b-col>
                    </b-row>

                  <!-- aaa -->


				

                </form>
              </div>




            </div>


            <b-row class="col-lg-12">
                <b-col lg="12">
                     <b-form-group
                            label="Status"
                            label-cols-lg="2"
					                  label-align-lg="right"
                     >	
                            <b-form-radio-group
                                id="radio-group-1"
                                v-model="form.status"
                                :options="types.status"
                                name="cartridge-status"
                                style="padding-top: 5px;"
                            >
                            </b-form-radio-group>
                     </b-form-group>
                </b-col>
            </b-row>


            <b-row class="col-lg-12">
                <b-col lg="12">
				<!-- 问题分类 -->
				<b-form-group
					label="Category:" 
					label-cols-lg="2"
					label-align-lg="right" 
					label-for="categoryId">

                    <b-form-select 
                          v-model="form.categoryId"
                         :options="types.questionCategorys"
                         value-field="id"
                         text-field="name"
                    >
                    </b-form-select>

                 </b-form-group>


                </b-col>
            </b-row>



            <b-row class="text-center col-lg-12">
                <b-col lg="12">
                    <b-form-group  >
                        <b-button @click="onSubmit" type="submit" variant="primary">Confirm</b-button> 
                        <b-button @click="onReset" type="reset" variant="danger" class="m-l-5 ml-1">Cancel</b-button>
                    </b-form-group>
                </b-col>
            </b-row>




          </div>
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->


  </Layout>
</template>


<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import vue2Dropzone from "vue2-dropzone";
import { BEmbed } from "bootstrap-vue";

import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum
} from "vuelidate/lib/validators";

export default {
  name: "unlockAdd",
  // page: {
  //   title: "Form Validation",
  //   meta: [{ name: "description", content: appConfig.description }]
  // },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
    "b-embed": BEmbed
  },

  data() {
    return {
      title: "Questions",
      items: [
        {
          text: "Health Manage",
          // href: "/"
        },
        {
          text: "Questions List",
          active: false
        },
        {
          text: "Questions Create",
          active: true
        }
      ],
      form: {
          name:"",
          status:0,
          categoryId:"",
          content:[],
      },
      types:{
      status:[
           { text: 'active', value: 1, disabled: false },
           { text: 'inactive', value:0, disabled: false },
      ],
        questionCategorys: [
           {id:"",name:''},
        ],
 
      },
      };
  },
  computed:{
      is_add:function(){
           return /add/i.test(location.href)
      }
  },
  mounted(){
      this.init();
  },

  methods: {
   init(){
        this.$api.Category.list().then(res=>{
            if (res.success) {
                this.types.questionCategorys=this.types.questionCategorys.concat(res.data)
            }
        })
        if (this.is_add){

        }else{
            if (this.$route.query.id){
                this.$api.Question.find(this.$route.query)
                .then(res=>{
                    if (res.success){
                        this.form=res.data
                    }else{
                        if(res.message){
              this.$bvToast.toast(res.message);
            }
                    }
                })
            }else{
                this.$bvToast.toast("id?");
            }

        }
   },
    AddQuestion() {
      this.form.content.push({
          id:'',
          title:'',
          q:[]
     });
    },
    delQuestion(data) {
      if (confirm("Are you sure you want to delete this question?"))
        this.form.content.splice(data, 1);
    },
    add_answer(item) {
      item.q.push({content:"",status:0});
    },
    del_answer(item,data2) {
      if (confirm("Are you sure you want to delete this answer?"))
        item.q.pop();
    },
    onSubmit(evt) {
        evt.preventDefault()
        console.log(this.form)
        this.$api.Question.add(this.form).then(res=>{
            if(res.message){
              this.$bvToast.toast(res.message);
            }
            if (res.success){
                this.$router.go(-1);
            }
        })
    },
    onReset(evt) {
        evt.preventDefault()
            this.form={
                  name:"",
                  status:0,
                  categoryId:"",
                  content:[],
            }
    },
  }
};
</script>


<style lang="scss">
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important; 
}
.dropzone .dz-message {
  margin: 1em 0;
}
.dropzone .dz-message {
  font-size: 16px !important;
}
.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}
.inputWidth {
  display: flex;

  > .form-control {
    width: 100% !important;
  }
  .btn-margin {
    margin-left: 150px;
  }
}
.wrap1 {
  /* border: 1px solid black;*/
}
.wrap2 {
  display: flex;
  flex-wrap: wrap;
  
}
.num {
  align-content: center !important;
  
  >div.num1{
    font-weight: 500;
    margin-right: 8px;
    margin-top: 8px;
  }
}

</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "page-topbar" } }, [
    _c("div", { staticClass: "navbar-header" }, [
      _c("div", { staticClass: "d-flex" }, [
        _c(
          "div",
          { staticClass: "navbar-brand-box" },
          [
            _c(
              "router-link",
              { staticClass: "logo logo-dark", attrs: { tag: "a", to: "/" } },
              [
                _c("span", { staticClass: "logo-sm" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/微信图片_20200803182357.png"),
                      alt: "",
                      height: "22"
                    }
                  })
                ]),
                _c("span", { staticClass: "logo-lg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/微信图片_20200803182357.png"),
                      alt: "",
                      height: "17"
                    }
                  })
                ])
              ]
            ),
            _c(
              "router-link",
              { staticClass: "logo logo-light", attrs: { tag: "a", to: "/" } },
              [
                _c("span", { staticClass: "logo-sm" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/微信图片_20200803182357.png"),
                      alt: "",
                      height: "22"
                    }
                  })
                ]),
                _c("span", { staticClass: "logo-lg" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/微信图片_20200803182357.png"),
                      alt: "",
                      height: "18"
                    }
                  })
                ])
              ]
            )
          ],
          1
        ),
        _c(
          "button",
          {
            staticClass: "btn btn-sm px-3 font-size-24 header-item",
            attrs: { type: "button", id: "vertical-menu-btn" },
            on: {
              click: function($event) {
                return _vm.toggleMenu()
              }
            }
          },
          [_c("i", { staticClass: "mdi mdi-menu" })]
        )
      ]),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _vm._m(0),
          _c("div", { staticClass: "dropdown d-none d-lg-inline-block" }, [
            _c(
              "button",
              {
                staticClass: "btn header-item noti-icon",
                attrs: { type: "button" },
                on: { click: _vm.initFullScreen }
              },
              [_c("i", { staticClass: "mdi mdi-fullscreen" })]
            )
          ]),
          _c(
            "b-dropdown",
            {
              staticClass: "d-inline-block",
              attrs: {
                right: "",
                "toggle-class": "header-item",
                variant: "white"
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function() {
                    return [
                      _c("img", {
                        staticClass: "rounded-circle header-profile-user",
                        attrs: {
                          src: require("@/assets/images/users/avatar-4.jpg"),
                          alt: "Header Avatar"
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c(
                "a",
                { staticClass: "dropdown-item d-block", attrs: { href: "#" } },
                [
                  _c(
                    "span",
                    { staticClass: "badge badge-success float-right" },
                    [_vm._v("11")]
                  ),
                  _c("i", {
                    staticClass:
                      "mdi mdi-settings font-size-17 align-middle mr-1"
                  }),
                  _vm._v(" Settings ")
                ]
              ),
              _c("div", { staticClass: "dropdown-divider" }),
              _c(
                "a",
                {
                  staticClass: "dropdown-item text-danger",
                  attrs: { href: "/logout" }
                },
                [
                  _c("i", {
                    staticClass:
                      "ti-power-off font-size-17 align-middle mr-1 text-danger"
                  }),
                  _vm._v(" Logout ")
                ]
              )
            ]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "dropdown d-inline-block d-lg-none ml-2" },
      [
        _c(
          "button",
          {
            staticClass: "btn header-item noti-icon",
            attrs: {
              type: "button",
              id: "page-header-search-dropdown",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false"
            }
          },
          [_c("i", { staticClass: "mdi mdi-magnify" })]
        ),
        _c(
          "div",
          {
            staticClass:
              "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0",
            attrs: { "aria-labelledby": "page-header-search-dropdown" }
          },
          [
            _c("form", { staticClass: "p-3" }, [
              _c("div", { staticClass: "form-group m-0" }, [
                _c("div", { staticClass: "input-group" }, [
                  _c("input", {
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: "Search ...",
                      "aria-label": "Recipient's username"
                    }
                  }),
                  _c("div", { staticClass: "input-group-append" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "submit" }
                      },
                      [_c("i", { staticClass: "mdi mdi-magnify" })]
                    )
                  ])
                ])
              ])
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-box" }, [
    _c("div", { staticStyle: { "margin-top": "120px" } }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-6 col-lg-4 col-xl-3" }, [
          _c(
            "div",
            { staticClass: "overflow-hidden" },
            [
              _vm._m(0),
              _c(
                "b-form",
                { on: { submit: _vm.tryToLogIn } },
                [
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: "Enter UserName",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                        },
                        model: {
                          value: _vm.loginForm.username,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "username", $$v)
                          },
                          expression: "loginForm.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          type: "password",
                          placeholder: "Enter password",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 40)value = value.slice(0, 40)"
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "7" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: "Enter code",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 4)value = value.slice(0, 4)"
                                },
                                model: {
                                  value: _vm.loginForm.verificationCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.loginForm,
                                      "verificationCode",
                                      $$v
                                    )
                                  },
                                  expression: "loginForm.verificationCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { md: "5" } }, [
                        _c("img", {
                          attrs: { title: "Change One", src: _vm.codeUrl },
                          on: { click: _vm.getCode }
                        })
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            type: "submit",
                            variant: "primary",
                            size: "lg"
                          }
                        },
                        [_vm._v("LOGIN")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "mt-5 text-center" }, [
            _c(
              "p",
              { staticClass: "mb-0", staticStyle: { color: "#ffffff" } },
              [
                _vm._v(
                  " " +
                    _vm._s(new Date().getFullYear()) +
                    " © UNI-CORE Crafted with "
                ),
                _c("i", { staticClass: "mdi mdi-heart text-danger" }),
                _vm._v(" by UNI-CORE ")
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-primary text-center p-4" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/微信图片_20200803182357.png"),
          height: "50",
          alt: "logo"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
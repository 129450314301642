<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Order Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="order-name"
              >
                <b-form-input
                  id="order-name"
                  v-model="search_condition.orderName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Order No."
                label-cols-sm="4"
                label-align-sm="right"
                label-for="order-number"
              >
                <b-form-input
                  id="order-number"
                  trim
                  v-model="search_condition.number"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Model Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="model-name"
              >
                <b-form-input
                  id="model-name"
                  trim
                  v-model="search_condition.modelName"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Production Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="create-time"
              >
                <date-picker
                  v-model="createTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="order-status"
              >
                <b-form-select
                  id="order-status"
                  v-model="search_condition.status"
                  :options="types.statuss"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'waxCartsPkg::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              id="device-table"
              head-variant="light"
              show-empty
              empty-text="no records"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'waxCartsPkg::Details'"
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Details
                  </b-button>
                  <b-button
                    v-has="'waxCartsPkg::Close'"
                    v-if="
                      row.item.status == 0 ||
                      row.item.status == 11 ||
                      row.item.status == 21
                    "
                    size="sm"
                    variant="danger"
                    @click="onClickClose(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Close
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Cartridge Batch component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Wax Carts Pkg",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Wax Carts Pkg",
          active: true,
        },
      ],
      itemList: [
        {
          title: "Smoke bomb",
          src: require("@/assets/images/mode-type/img_01.jpg"),
        },
        {
          title: "Plaster",
          src: require("@/assets/images/mode-type/img_02.jpg"),
        },
        {
          title: "Disposable cartridge",
          src: require("@/assets/images/mode-type/img_03.jpg"),
        },
      ],
      readySelectItem: 0,
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "orderName", label: "Package Order Name", class: "text-center" },
        //{ key: "number", label: "Order No.", class: "text-center" },
        { key: "modelName", label: "Model Name", class: "text-center" },
        { key: "statusName", label: "Status", class: "text-center" },
        {
          // 预生产数量
          key: "batchQuantity",
          label: "Planned Packing Quantity",
          class: "text-center",
        },
        {
          // 实际数量
          key: "normalQuantity",
          label: "Actual Quantity",
          class: "text-center",
        },

        { key: "operationName", label: "Created By", class: "text-center" },
        {
          key: "manufacturerName",
          label: "Manufacturer",
          class: "text-center",
        },
        {
          key: "productionTime",
          label: "Production Time",
          class: "text-center",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTimes(value);
            } else {
              return "";
            }
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        orderName: "",
        number: "",
        modelName: "",
        startTime: "",
        endTime: "",
        status: "",
        type: "", // 膏弹
        orderType: 1, // 包装订单
      },
      createTime: ["", ""],
      types: {
        statuss: [
          { text: "Please select", value: "", disabled: false },
          {
            text: "Be Comfirm",
            value: 0,
            disabled: false,
          },
          { text: "In Production", value: 1, disabled: false },
          { text: "Completed", value: 2, disabled: false },
        ],
        cartridgeType: [{ name: "Please select", id: "" }],
      },
      isBusy: false,
      has_data: false,
    };
  },
  watch: {
    createTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.$api.Dropdown.selectAllCartridgeType({ status: "1" }).then((res) => {
        if (res.success && res.data) {
          this.types.cartridgeType = this.types.cartridgeType.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.emptyCartridges
        .selectAllOrder(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
      //this.$bvToast.toast(JSON.stringify(this.search_condition));
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("waxCartsPkgAdd");
    },
    show(item, index, button) {
      this.$router.push("waxCartsPkgInfo?id=" + item.id);
    },
    del(a, b, c) {
      this.$api.CartridgeBatch.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
    selectItem(item, index) {
      this.readySelectItem = index;
    },
    //#region 关闭订单
    onClickClose(item, index, button) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to close the order?", {
          title: "Operation Tips",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$api.emptyCartridges
              .cancelEmptyCartridgeOrderById({ id: item.id })
              .then((res) => {
                if (res.success) {
                  this.$refs.table.refresh();
                }
                if (res.message) {
                  this.$bvToast.toast(res.message);
                }
              })
              .catch((res) => {
                this.$bvToast.toast(res.message);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    //#endregion
  },
};
</script>

<style lang="scss">
.modal-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0px;

  .modal-item {
    text-align: center;
    cursor: pointer;
  }
}
.selected {
  border: 1px solid;
}
</style>
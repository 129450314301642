const sparklineChartData = {
  sparkChart1: [6, 2, 8, 4, 3, 8, 1, 3, 6, 5, 9, 2, 8, 1, 4, 8, 9, 8, 2, 1],
  sparkChart2: [6, 2, 8, 4, -3, 8, 1, -3, 6, -5, 9, 2, -8, 1, 4, 8, 9, 8, 2, 1],
  sparkChart3: [6, 2, 8, 4, 3, 8, 1, 3, 6, 5, 9, 2, 8, 1, 4, 8, 9, 8, 2, 1],
  reflineStyle: {
    strokeOpacity: 1,
    strokeDasharray: "3, 3",
  },
  style: {
    stroke: "#02a499",
    fill: "#02a499",
  },
};

const salesDonutChart = {
  data: {
    series: [54, 28, 17],
    labels: [1, 2, 3],
  },
  options: {
    donut: true,
    showLabel: false,
  },
};

const ageBarChart = {
  data: {
    series: [[10, 400, 300, 700, 155, 110, 30]],
    labels: ["17 down", "18-24", "25-29", "30-39", "40-49", "50 up", "un know"],
  },
  options: {
    seriesBarDistance: 10,
    reverseData: true,
    horizontalBars: true,
    axisY: {
      offset: 70,
    },
    height: 450,
  },
};

const radialBarChart = {
  chartOptions: {
    plotOptions: {
      radialBar: {
        hollow: {
          size: "45%",
        },
        dataLabels: {
          value: {
            // show: false
          },
          name: {
            show: false,
          },
        },
      },
    },
    colors: ["#02a499"],
  },
};

// 烟弹库存数据
const inventoryCartridgeBar = {
  chartOptions: {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "65%",
      },
    },
    dataLabels: {
      enabled: false,
      //enabledOnSeries: [3]
    },
    stroke: {
      width: [0, 2, 4, 6],
      curve: "smooth",
    },
    colors: ["#02a499", "#38a4f8", "#dee2e6", "#aaaaff"],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    legend: {
      offsetY: 5,
    },
    yaxis: {
      title: {
        text: " (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f3fa",
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter(y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0);
          }
          return y;
        },
      },
    },
  },
  series: [
    {
      name: "Active",
      type: "column",
      data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30, 31],
    },
    {
      name: "Activated",
      type: "column",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 28, 29, 34],
    },
    {
      name: "Consume",
      type: "column",
      data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 35, 29, 37],
    },
  ],
};

// 口味排行
const flavorData = [
  { ranking: 1, flavor: "Apple", pacent: "21.21%" },
  { ranking: 2, flavor: "Orange", pacent: "19.21%" },
  { ranking: 3, flavor: "Grape", pacent: "18.99%" },
  { ranking: 4, flavor: "Coke", pacent: "17.55%" },
  { ranking: 5, flavor: "Mango", pacent: "2.31%" },
];

// 最近活跃用户
const activeUsersData = [
  {
    id: "14256",
    image: require("@/assets/images/users/avatar-2.jpg"),
    name: "Philip Smead",
    date: "5/5/2020",
    amount: "94",
    status: "Active",
  },
  {
    id: "14257",
    image: require("@/assets/images/users/avatar-3.jpg"),
    name: "Brent Shipley",
    date: "1/5/2020",
    amount: "112",
    status: "New",
  },
  {
    id: "14258",
    image: require("@/assets/images/users/avatar-4.jpg"),
    name: "Robert Sitton",
    date: "27/4/2020",
    amount: "116",
    status: "Active",
  },
  {
    id: "14259",
    image: require("@/assets/images/users/avatar-5.jpg"),
    name: "Alberto Jackson",
    date: "25/4/2020",
    amount: "109",
    status: "New",
  },
  {
    id: "14260",
    image: require("@/assets/images/users/avatar-6.jpg"),
    name: "David Sanchez",
    date: "15/4/2020",
    amount: "94",
    status: "Active",
  },
  {
    id: "14261",
    image: require("@/assets/images/users/avatar-7.jpg"),
    name: "Philip Smead",
    date: "15/4/2020",
    amount: "94",
    status: "New",
  },
  {
    id: "14258",
    image: require("@/assets/images/users/avatar-8.jpg"),
    name: "Robert Sitton",
    date: "27/4/2020",
    amount: "116",
    status: "Active",
  },
  {
    id: "14259",
    image: require("@/assets/images/users/avatar-4.jpg"),
    name: "Alberto Jackson",
    date: "25/4/2020",
    amount: "109",
    status: "Active",
  },
  {
    id: "14260",
    image: require("@/assets/images/users/avatar-6.jpg"),
    name: "David Sanchez",
    date: "15/4/2020",
    amount: "94",
    status: "Active",
  },
  {
    id: "14261",
    image: require("@/assets/images/users/avatar-7.jpg"),
    name: "Philip Smead",
    date: "15/4/2020",
    amount: "94",
    status: "New",
  },
];

// 烟弹未激活数量折线图
const selectInactiveIcon = {
  series: [
    {
      name: "count",
      data: [],
    },
  ],
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: { categories: [] }
  },
};

// 导出
export {
  sparklineChartData,
  salesDonutChart,
  radialBarChart,
  ageBarChart,
  inventoryCartridgeBar,
  flavorData,
  activeUsersData,
  selectInactiveIcon,
};

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- Flavor Name 烟弹型号名称-->
        <b-form-group
          label="Wax Type:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="model-name"
            v-model="form.pasteTypeName"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- 所属客户 -->
        <b-form-group
          label="Affiliated Customer:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer"
          label-class="requiredRow"
        >
          <b-form-select
            id="customer"
            v-model="form.supplierId"
            :options="types.allSupplier"
            value-field="id"
            text-field="brandName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- Brand Name 品牌商-->
        <b-form-group
          label="Type Description:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="brand-name"
        >
          <b-form-input
            id="brand-name"
            v-model="form.typeDescription"
            oninput="if(value.length > 100)value = value.slice(0, 100)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- remarks 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
        </b-form-group>
      </b-form>
      <!-- <p>{{form}}</p> -->
    </b-card>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady, upload_img } from "@/api/upload";

/**
 * Cartridge Model Add
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: this.editId ? "Wax Type Modify" : "Wax Type Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: this.editId ? "Wax Type Modify" : "Wax Type Create",
          active: true,
        },
      ],
      form: {
        supplierId: "",
        pasteTypeName: "",
        typeDescription: "",
        remarks: "",
      },
      types: {
        allSupplier: [{ brandName: "Please select", id: "" }],
      },
      show: true, // 表单显示
      editor: ClassicEditor,
      editId: window.location.search,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    this.init();
  },
  mounted() {
    if (/edit/i.test(window.location.href)) {
      this.form = {
        id: this.$route.params.id,
        pasteTypeName: this.$route.params.pasteTypeName,
        typeDescription: this.$route.params.typeDescription,
        remarks: this.$route.params.remarks,
        supplierId: this.$route.params.supplierId,
      };
    }
  },
  methods: {
    onReady: onReady,
    init() {
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (/edit/i.test(window.location.href)) {
        this.$api.warmUpMode.updatePasteTypeById(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$api.warmUpMode.savePasteType(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        supplierId: "",
        pasteTypeName: "",
        typeDescription: "",
        remarks: "",
      };
      this.show = false;
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
  },
};
</script>

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="4">
							<b-form-group
								label="Email"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="email"
							>
								<b-form-input
									v-model="search_condition.email"
									id="email"
									trim
									type="email"
									oninput="if(value.length > 30)value = value.slice(0, 30)"
								></b-form-input>
							</b-form-group>
						</b-col>
						<b-col lg="4">
							<b-form-group
								label="Nickname"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="nick-name"
							>
								<b-form-input
									id="nick-name"
									trim
									v-model="search_condition.nickName"
									oninput="if(value.length > 20)value = value.slice(0, 20)"
								></b-form-input>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Registration Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="select-time"
							>
								<date-picker
									v-model="selectTime"
									format="MM/DD/YYYY"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<b-col lg="4">
							<b-form-group
								label="Gender"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="gender"
							>
								<b-form-select
									id="gender"
									v-model="search_condition.gender"
									:options="gender"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="3">
							<!-- <b-form-group label-cols-sm="4" label-align-sm="right"> -->
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
							<!-- </b-form-group> -->
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<b-button-group>
						<b-button
							v-has="'userAccounts::Export'"
							@click="export_checkout"
							variant="info"
							:disabled="exportDisabled"
							>Export</b-button
						>
					</b-button-group>
					<!-- table -->
					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="no records"
							id="device-table"
							head-variant="light"
							:striped="true"
							ref="table"
							:busy="isBusy"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">{{
								data.index +
								(search_condition.pageNum - 1) *
									search_condition.pageSize +
								1
							}}</template>

							<template v-slot:cell(picture)="data">
								<img
									v-if="data.item.picture"
									:src="`${data.item.picture}`"
									alt
									class="avatar-xs rounded-circle mr-2"
								/>
								<img
									v-else
									src="../../../../assets/face.jpg"
									alt
									class="avatar-xs rounded-circle mr-2"
								/>
								<!-- {{ data.item.nickName }} -->
							</template>
							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-if="row.item.status"
										v-has="'userAccounts::Disable'"
										size="sm"
										variant="danger"
										@click="
											disableOrEnable(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Disable</b-button
									>

									<b-button
										v-else
										v-has="'userAccounts::Enable'"
										size="sm"
										variant="info"
										@click="
											disableOrEnable(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Enable</b-button
									>

									<b-button
										v-has="'userAccounts::Details'"
										size="sm"
										variant="primary"
										@click="
											show(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
										>Details</b-button
									>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									></b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- end main -->

		<!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import { download } from '@/api/excel'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'
/**
 * User component
 */
export default {
	components: {
		DatePicker,
		Layout,
		PageHeader,
		timeZoneConversion,
	},
	data() {
		return {
			title: 'User Accounts',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'User Mgmt',
					// href: "/"
				},
				{
					text: 'User Accounts',
					active: true,
				},
			],
			tableData: [],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			pageOptions: [10, 25, 50, 100],
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				// { key: "id", label: "UserId", class: "text-center" },
				{ key: 'picture', label: 'Avatar', class: 'text-center' },
				{ key: 'source', label: 'Source', class: 'text-center' },
				{ key: 'nickName', label: 'Nickname', class: 'text-center' },
				//		{key: 'source', label: 'Source',
				//			formatter: (value) => {
				//				return value == 0 ? 'Platform' : 'Supplier';
				//			}
				//		},
				// {
				//   key: "gender",
				//   label: "Gender",
				//   formatter: (value) => {
				//     return value == 1 ? "Female" : value == 0 ? "Male" : "Secret";
				//   },
				// },
				{
					key: 'binding',
					label: 'Batteries Own by User',
					class: 'text-center',
				},
				{
					key: 'status',
					label: 'Status',
					class: 'text-center',
					formatter: (value) => {
						return value == 1 ? 'Enabled' : 'Disabled'
					},
				},
				// { key: "createTime", label: "Registration Time", class: "text-center" },
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],
			selectTime: [],
			gender: [
				{ text: 'Please select', value: '', disabled: false },
				{ text: 'Male', value: 0, disabled: false },
				{ text: 'Female', value: 1, disabled: false },
			], //平台
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				createTime: '',
				endTime: '',
				email: '',
				gender: '',
				nickName: '',
				// supplierId: localStorage.supplierId,
			},
			exportDisabled: true,
			isBusy: false,
			has_data: false,
		}
	},
	watch: {
		selectTime: function (val) {
			this.search_condition.createTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		rows() {
			return this.totalRows
		},
	},
	mounted() {},
	created() {},
	methods: {
		provider(ctx) {
			this.isBusy = true
			return this.$api.userManage
				.userList(this.search_condition, ctx)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						this.tableData = x.data
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		gotoAdd() {
			this.$router.push('cartridgeListAdd')
		},
		del(a, b, c) {
			this.$api.userManage.deleteUser({ id: a.id }).then((res) => {
				if (res.message) {
					this.$bvToast.toast(res.message)
				}
				if (res.success) {
					this.$refs.table.refresh()
				}
			})
		},

		disableOrEnable(a, b, c) {
			this.$api.userManage
				.disableOrEnable({ id: a.id, status: a.status === 0 ? 1 : 0 })
				.then((res) => {
					if (res.message) {
						this.$bvToast.toast(res.message)
					}
					if (res.success) {
						this.$refs.table.refresh()
					}
				})
		},
		resetPassword(a, b, c) {
			this.$api.userManage.resetPassword({ id: a.id }).then((res) => {
				if (res.message) {
					this.$bvToast.toast(res.message)
				}
				if (res.success) {
					// this.$refs.table.refresh()
					this.$bvToast.toast('new password 666666')
				}
			})
		},
		export_excel() {
			// this.$api.excel.download(this.tableData, "user");
			this.$api.userManage.export_excel(this.search_condition)
		},
		// 导出校验
		export_checkout() {
			//时间范围三个月内
			if (
				this.search_condition.createTime &&
				this.search_condition.endTime
			) {
				let three = 90 * 24 * 3600 * 1000
				let date = new Date(this.search_condition.endTime).getTime()
				let threeMonths = date - three
				if (
					threeMonths >
					new Date(this.search_condition.createTime).getTime()
				) {
					this.$bvToast.toast(
						'Please select the time frame within 3 months'
					)
					return
				}
			}
			this.export_excel()
		},
		show(item, index, button) {
			this.$router.push('userAccountsInfo?id=' + item.id)
		},
		edit(item, index, button) {
			this.$router.push('UserEdit?id=' + item.id)
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
			this.searchCheck()
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		searchCheck() {
			//校验是否存在搜索条件
			if (
				this.search_condition.email === '' &&
				this.search_condition.gender === '' &&
				this.search_condition.nickName === '' &&
				this.search_condition.createTime === '' &&
				this.search_condition.endTime === ''
			) {
				this.exportDisabled = true
				// this.$bvToast.toast("Please select the search criteria first");
			} else {
				this.exportDisabled = false
			}
		},
	},
}
</script>

import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"

// system log
async function systemList(params,ctx){
    return axios.fetchGet(baseUrl + '/logData/adminOperatingList',params)
}
//server log
async function serverList(params,ctx){
   return axios.fetchGet(baseUrl + '/logData/exceptionLog',params)
}

export default {
    systemList,
    serverList
}

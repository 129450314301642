var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Basic Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("User Name:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.userName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Brand:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.brandName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Model:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.deviceModel))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device MAC:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.deviceNumber))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Firmware:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.firmwareVersion))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Cartridge Batch No:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.cartridgeNumber))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Cartridge Model:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.cartridgeModel))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Version Of App:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.softwareVersion))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("br")
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Test Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Pre-Time(S):")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.warmUpTimes))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Pre-Temp(℉):")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.warmUpTemperature))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Heating Duration(S):")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.duration))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Use Time(S):")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(
                        _vm._s(
                          _vm.form.createTime
                            ? _vm.getLocalTimes(_vm.form.createTime)
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Temp Curve(℉):")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.heatingTemperature))
                    ])
                  ]),
                  _c("b-col"),
                  _c("b-col", { attrs: { md: "12" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Resistance Curve:")
                    ]),
                    _c("span", { staticClass: "p-4" }, [
                      _vm._v(_vm._s(_vm.form.resistance))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-button",
            {
              attrs: {
                size: "lg",
                type: "reset",
                variant: "outline-secondary"
              },
              on: { click: _vm.onReset }
            },
            [_vm._v("Back To List")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
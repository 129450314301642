<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Order Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Order Name:</label>
            <span class="p-3">{{ form.orderName }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Order No.:</label>
            <span class="p-3">{{ form.orderNumber }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Device Model:</label>
            <span class="p-3">{{ form.deviceModelName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Quantity:</label>
            <span class="p-3">{{ form.batchQuantity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Time:</label>
            <span class="p-3">{{ form.productionTime }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Clear Data:</label>
            <span class="p-3">{{
              form.isClearData == 0 ? "Yes" : form.isClearData == 1 ? "No" : ""
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Status:</label>
            <span class="p-3">{{ formatterStatus(form.status) }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Remarks:</label>
            <span class="p-3">{{ form.remarks }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
      <br />
      <b-row class="align-items-center" v-if="form.status == 1">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Production Result</h4>
          </div>
        </b-col>
      </b-row>
      <b-container v-if="form.status == 1">
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Actual Quantity:</label>
            <span class="p-3">{{ form.actualQuantity }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-button variant="outline-secondary" @click="returnPage">Go Back</b-button>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Device Order Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Device Order Details",
          active: true,
        },
      ],
      form: {},
      types: {
        statuss: [
          {
            text: "To be produced",
            value: 0,
            disabled: false,
          },
          {
            text: "Production of complete",
            value: 1,
            disabled: false,
          },
        ],
      },
    };
  },
  computed: {},

  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.Device.selectByOrderId(this.$route.query).then((res) => {
          if (res.success && res.data) {
            this.form = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    formatterStatus(value) {
      let a = "";
      this.types.statuss.filter((item) => {
        if (value === item.value) {
          a = item.text;
        }
      });
      return a;
    },
    returnPage() {
      this.$router.go(-1);
    },
  },
};
</script>
<style>
.blockquote {
  padding: 10px 10px;
  border-left: 5px solid #aaaaff;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group
          v-if="!is_add"
          label="ID"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name1"
        >
          <b-form-input
            id="model-name1"
            disabled
            v-model="form.id"
            placeholder=""
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="type"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name2"
        >
          <b-form-input
            id="model-name2"
            v-model="form.type"
            placeholder="Enter type"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="unusedTimes"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name3"
        >
          <b-form-input
            id="model-name3"
            v-model="form.unusedTimes"
            placeholder="Enter unusedTimes"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="madeinName"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name4"
        >
          <b-form-input
            id="model-name4"
            v-model="form.madeinName"
            placeholder="Enter madeinName"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="worktemp"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name5"
        >
          <b-form-input
            id="model-name5"
            v-model="form.worktemp"
            placeholder="Enter worktemp"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="normalTemperature"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name6"
        >
          <b-form-input
            id="model-name6"
            v-model="form.normalTemperature"
            placeholder="Enter normalTemperature"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="wildTemperature"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name7"
        >
          <b-form-input
            id="model-name7"
            v-model="form.wildTemperature"
            placeholder="Enter wildTemperature"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="softTemperature"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.softTemperature"
            required
            placeholder="Enter softTemperature"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="temperature4"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name8"
        >
          <b-form-input
            id="model-name8"
            v-model="form.temperature4"
            placeholder="Enter temperature4"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="temperature5"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name9"
        >
          <b-form-input
            id="model-name9"
            v-model="form.temperature5"
            placeholder="Enter temperature5"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="temperature6"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name10"
        >
          <b-form-input
            id="model-name10"
            v-model="form.temperature6"
            placeholder="Enter temperature6"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="placeOfOrigin"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name11"
        >
          <b-form-input
            id="model-name11"
            v-model="form.placeOfOrigin"
            placeholder="Enter placeOfOrigin"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="City"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name12"
        >
          <b-form-input
            id="model-name12"
            v-model="form.city"
            placeholder="Enter City"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Address"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-name13"
        >
          <b-form-input
            id="model-name13"
            v-model="form.address"
            required
            placeholder="Enter Address"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="picture:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="picture"
        >
          <img
            :src="form.picture"
            alt
            class="img-fluid img-thumbnail rounded-circle avatar-lg"
          />

          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event, 'picture')"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span>
                Recommended size of picture: 512 pixels * 512 pixels, supporting
                JPG and PNG formats, within 2m.
              </span>
            </span>
          </div>
        </b-form-group>

        <b-form-group
          label="companyIcon:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="companyIcon"
        >
          <img
            :src="form.companyIcon"
            alt
            class="img-fluid img-thumbnail rounded-circle avatar-lg"
          />

          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event, 'companyIcon')"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText"> upload companyIcon </span>
              </label>
              <span>
                Recommended size of picture: 512 pixels * 512 pixels, supporting
                JPG and PNG formats, within 2m.
              </span>
            </span>
          </div>
        </b-form-group>

        <b-form-group
          label="daySeconds"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.daySeconds"
            required
            placeholder="Enter daySeconds"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="seconds"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.seconds"
            required
            placeholder="Enter Seconds"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="deviceCoding"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.deviceCoding"
            required
            placeholder="Enter deviceCoding"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="deviceNumber"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.deviceNumber"
            required
            placeholder="Enter  deviceNumber"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="deviceStatus"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.deviceStatus"
            required
            placeholder="Enter deviceStatus "
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="explains"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.explains"
            required
            placeholder="Enter explains"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="power"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.power"
            required
            placeholder="Enter power"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="preheatTime"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.preheatTime"
            required
            placeholder="Enter preheatTime"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="productionBatch"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.productionBatch"
            required
            placeholder="Enter productionBatch"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="proportion"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.proportion"
            required
            placeholder="Enter proportion"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="proportion1"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.proportion1"
            required
            placeholder="Enter proportion1"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="proportion2"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.proportion2"
            required
            placeholder="Enter proportion2"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="smokeRecordModelId"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.smokeRecordModelId"
            required
            placeholder="Enter smokeRecordModelId"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="smokeRecordNumber"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.smokeRecordNumber"
            required
            placeholder="Enter smokeRecordNumber"
          ></b-form-input>
        </b-form-group>

        <!-- Model Encoding 烟弹型号编码-->
        <b-form-group
          label="Model Encoding:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model-encoding"
        >
          <b-form-input
            id="model-encoding"
            v-model="form.modelEncoding"
            required
            placeholder="Enter Model Encoding"
          ></b-form-input>
        </b-form-group>

        <!-- Brand Name 品牌商-->
        <b-form-group
          label="Brand Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="brand-name"
        >
          <multiselect
            v-model="form.brandName"
            :options="types.brandNames"
          ></multiselect>
        </b-form-group>

        <!-- Cartridge Type 烟弹类型-->
        <b-form-group
          label="Cartridge Type:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="cartridge-type"
        >
          <multiselect
            v-model="form.cartridgeType"
            :options="types.cartridgeTypes"
          ></multiselect>
        </b-form-group>

        <!-- Formula  烟油配方-->
        <b-form-group
          class="inner"
          label="Cartridge Formula:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="cartridge-formula"
          description="The first two cigarette oil formulas will be displayed on the APP homepage"
        >
          <div
            v-for="(data, index) in form.formulaList"
            :key="'formulaList_' + index"
            class="inner mb-3 row"
          >
            <div class="col-md-5 col-5">
              <multiselect
                class="inner"
                v-model="data.component"
                :options="types.cartridgeComponentData"
              ></multiselect>
            </div>
            <div class="col-md-5 col-5">
              <b-form-input
                class="inner form-control"
                v-model="data.capacity"
                required
                placeholder="Enter Capacity"
              ></b-form-input>
            </div>
            <div class="col-md-2 col-2">
              <b-button variant="danger" @click="deleteFormula(index)"
                >Delete</b-button
              >
            </div>
          </div>
          <b-button variant="primary" @click="addFormula">Create</b-button>
        </b-form-group>

        <!-- Flavor 烟弹口味 多选select -->
        <b-form-group
          label="Flavor:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="flavor"
        >
          <multiselect
            v-model="form.flavor"
            :options="types.flavorOptions"
            :multiple="true"
          ></multiselect>
        </b-form-group>

        <!-- Dose 烟弹容量-->
        <b-form-group
          label="Dose:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="dose"
        >
          <b-form-input
            id="dose"
            v-model="form.dose"
            required
            placeholder="Enter Cartridge Dose(ml)"
          ></b-form-input>
        </b-form-group>

        <!-- Calculation 每秒吸入剂量 -->
        <b-form-group
          label="Calculation:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="calculation"
        >
          <b-form-input
            id="calculation"
            v-model="form.calculation"
            required
            placeholder="Enter Inhalation dose per second(mg)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Frequency:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="frequency"
        >
          <b-form-input
            id="frequency"
            v-model="form.frequency"
            required
            placeholder="Enter frequency"
          >
          </b-form-input>
        </b-form-group>

        <!-- Max Frequency 吸入上限设置 -->
        <b-form-group
          label="Max Frequency:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="max-frequency"
        >
          <b-form-input
            id="max-frequency"
            v-model="form.maxFrequency"
            required
            placeholder="Enter Maximum number of Cartridge"
          ></b-form-input>
        </b-form-group>

        <!-- Content Of Tobacco 烟油含量 -->
        <b-form-group
          label="Content Of Tobacco:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="tobaccoTar"
        >
          <b-form-input
            id="tobaccoTar"
            v-model="form.tobaccoTar"
            required
            placeholder="Enter Content Of Tobacco(ml)"
          ></b-form-input>
        </b-form-group>

        <!-- Consistence 烟油浓稠度 -->
        <b-form-group
          label="Consistence:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="consistence"
        >
          <b-form-input
            id="consistence"
            v-model="form.consistence"
            required
            placeholder="Enter Consistence"
          ></b-form-input>
        </b-form-group>

        <!-- IsPreheat 烟弹是否预热 -->
        <b-form-group
          label="IsPreheat:"
          label-cols-lg="2"
          label-align-lg="right"
        >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.isPreheat"
            name="isPreheat"
            value="YES"
            >YES</b-form-radio
          >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.isPreheat"
            name="isPreheat"
            value="NO"
            >NO</b-form-radio
          >
        </b-form-group>

        <!-- Medical Care 是否医用-->
        <b-form-group
          label="Medical Care:"
          label-cols-lg="2"
          label-align-lg="right"
        >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.medicalCare"
            name="medicalCare"
            value="YES"
            >YES</b-form-radio
          >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.medicalCare"
            name="medicalCare"
            value="NO"
            >NO</b-form-radio
          >
        </b-form-group>

        <!-- Solvent  烟油溶剂-->
        <b-form-group
          label="Solvent:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="solvent"
        >
          <b-form-input
            id="solvent"
            v-model="form.solvent"
            required
            placeholder="Enter Solvent"
          ></b-form-input>
        </b-form-group>

        <!-- Solvent  烟油溶剂-->
        <b-form-group
          label="Solvent1:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="solvent1"
        >
          <b-form-input
            id="solvent1"
            v-model="form.solvent1"
            placeholder="Enter Solvent1"
          ></b-form-input>
        </b-form-group>

        <!-- Solvent  烟油溶剂-->
        <b-form-group
          label="Solvent2:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="solvent1"
        >
          <b-form-input
            id="solvent2"
            v-model="form.solvent2"
            placeholder="Enter Solvent 2"
          ></b-form-input>
        </b-form-group>

        <!-- Atomizing Wire Type  雾化丝种类-->
        <b-form-group
          label="Atomizing Wire Type:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="atomizingWireType"
        >
          <b-form-input
            id="atomizingWireType"
            v-model="form.atomizingWireType"
            required
            placeholder="Enter Atomizing Wire Type"
          ></b-form-input>
        </b-form-group>

        <!--Report Type  测试报告类型 -->
        <b-form-group
          label="Report Type:"
          label-cols-lg="2"
          label-align-lg="right"
        >
          <b-form-checkbox-group
            v-model="form.reportType"
            id="reportType"
            class="pt-2"
          >
            <b-form-checkbox value="link" @change="changeLink"
              >Link</b-form-checkbox
            >
            <b-form-checkbox value="enclosure" @change="changeEnclosure"
              >Enclosure</b-form-checkbox
            >
          </b-form-checkbox-group>
        </b-form-group>

        <!--Report Link 链接报告 -->
        <b-form-group
          v-if="showReportLink"
          label="Report Link:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="reportLink"
        >
          <b-form-input
            id="reportLink"
            v-model="form.reportLink"
            required
            placeholder="Enter Report Link"
          ></b-form-input>
        </b-form-group>

        <!--Report Enclosure 附件报告 -->
        <b-form-group
          v-if="showReportEnclosure"
          label="Report Enclosure:"
          label-cols-lg="2"
          label-align-lg="right"
        >
          <div id="editor">
            <ckeditor
              v-model="form.reportEnclosure"
              :editor="editor"
              @ready="onReady"
            >
            </ckeditor>
          </div>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady, upload_img } from "@/api/upload";

/**
 * Cartridge Add
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Cartridge Modify",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Cartridge Modify",
          active: true,
        },
      ],
      form: {
        modelName: "",
        modelEncoding: "",
        brandName: null,
        cartridgeType: null,
        flavor: null,
        dose: "",
        calculation: "",
        maxFrequency: "",
        tobaccoTar: "",
        consistence: "",
        isPreheat: "NO",
        medicalCare: "YES",
        solvent: "",
        atomizingWireType: "",
        reportType: [],
        reportLink: "",
        reportEnclosure: "",
        remarks: "",

        //....
        id: "",
        address: "",
        atomizingWireType: "",
        brandName: "",
        calculation: 0,
        city: "",
        companyIcon: "",
        consistence: "",
        daySeconds: 0,
        deviceCoding: "",
        deviceNumber: "",
        deviceStatus: 0,
        dose: 0,
        explains: "",
        flavor: "",
        formulaList: [
          {
            component: "",
            id: 0,
            proportion: 0,
            smokeRecordId: 0,
            status: 0,
          },
          {
            component: "",
            id: 0,
            proportion: 0,
            smokeRecordId: 0,
            status: 0,
          },
        ],
        frequency: 0,
        isPreheat: 0,
        madeinName: "",
        maxFrequency: 0,
        normalTemperature: "",
        picture: "",
        placeOfOrigin: "",
        power: "",
        preheatTime: "",
        productionBatch: "",
        proportion: "",
        proportion1: "",
        proportion2: "",
        reportEnclosure: "",
        reportLink: "",
        seconds: "",
        smokeRecordModelId: 0,
        smokeRecordNumber: "",
        softTemperature: "",
        solvent: "",
        solvent1: "",
        solvent2: "",
        temperature4: "",
        temperature5: "",
        temperature6: "",
        tobaccoTar: 0,
        type: "",
        unusedTimes: 0,
        wildTemperature: "",
        worktemp: "",
      },
      form1: {},
      types: {
        brandNames: [],
        flavorOptions: [],
        cartridgeTypes: [],
        cartridgeComponentData: [],
      },
      show: true, // 表单显示
      editor: ClassicEditor,
      showReportLink: false,
      showReportEnclosure: false,
      is_add: location.href.includes("Add"),
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$api.CartridgeModel.get_types().then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.types = res.data;
        } else {
        }
      });

      if (this.$route.query.id) {
        this.$api.Cartridge.find(this.$route.query).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.form = res.data;
            this.form1 = res.data;
          } else {
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReady: onReady,
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.Cartridge.update(this.form).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: "",
        address: "",
        atomizingWireType: "",
        brandName: "",
        calculation: 0,
        city: "",
        companyIcon: "",
        consistence: "",
        daySeconds: 0,
        deviceCoding: "",
        deviceNumber: "",
        deviceStatus: 0,
        dose: 0,
        explains: "",
        flavor: "",
        formulaList: [
          {
            component: "",
            id: 0,
            proportion: 0,
            smokeRecordId: 0,
            status: 0,
          },
          {
            component: "",
            id: 0,
            proportion: 0,
            smokeRecordId: 0,
            status: 0,
          },
        ],
        frequency: 0,
        isPreheat: 0,
        madeinName: "",
        maxFrequency: 0,
        normalTemperature: "",
        picture: "",
        placeOfOrigin: "",
        power: "",
        preheatTime: "",
        productionBatch: "",
        proportion: "",
        proportion1: "",
        proportion2: "",
        reportEnclosure: "",
        reportLink: "",
        seconds: "",
        smokeRecordModelId: 0,
        smokeRecordNumber: "",
        softTemperature: "",
        solvent: "",
        solvent1: "",
        solvent2: "",
        temperature4: "",
        temperature5: "",
        temperature6: "",
        tobaccoTar: 0,
        type: "",
        unusedTimes: 0,
        wildTemperature: "",
        worktemp: "",
      };
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    /**
     * 添加烟弹配方
     */
    addFormula() {
      this.form.formulaList.push({ component: "", capacity: "" });
    },
    /**
     * 删除烟弹配方
     * @param {Object} index
     */
    deleteFormula(index) {
      if (this.form.formulaList.length > 2) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.form.formulaList.splice(index, 1);
        }
      }
    },
    /**
     * 报告链接事件
     */
    changeLink() {
      this.showReportLink = !this.showReportLink;
    },
    changeEnclosure() {
      this.showReportEnclosure = !this.showReportEnclosure;
    },
    upload(e, k = "companyIcon") {
      let file = e.target.files[0];
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.form[k] = res.data;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support image file");
      }
    },
  },
};
</script>

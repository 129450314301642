import { format } from "./time";
import axios from "../config/http.js";
import baseUrl from "../config/env.js";

// status
// 0是未使用1是使用中、2是未激活
async function list(params, ctx) {
  // let activeTime=format(params.activeTime)
  params.startTime = format(params.startTime);
  params.endTime = format(params.endTime);
  return axios.fetchGet(baseUrl + "/device/list", params);
}

async function find_usage(params, ctx) {
  return axios.fetchGet(baseUrl + "/device/deviceUsageInformationList", params);
}

function add(params) {
  return axios.fetchPost(baseUrl + "/device/add", params);
}

function addBatch(params) {
  return axios.fileUpload(baseUrl + "/device/addBatch", params);
}

function find(params) {
  return axios.fetchGet(baseUrl + "/device/findById", params);
}

function update(params) {
  return axios.fetchPost(baseUrl + "/device/update", params);
}

function del(params) {
  return axios.fetchPost(baseUrl + "/device/updateById", params);
}

function untieDeviceById(params) {
  return axios.fetchPost(baseUrl + "/device/untieDeviceById", params);
}

// 设备型号
function deviceModelList(params) {
  return axios.fetchGet(baseUrl + "/deviceModel/list", params);
}
function deviceModelFindById(params) {
  return axios.fetchGet(baseUrl + "/deviceModel/findById", params);
}
function deviceModelSave(params) {
  return axios.fetchPost(baseUrl + "/deviceModel/save", params);
}
function deviceModelUpdateById(params) {
  return axios.fetchPost(baseUrl + "/deviceModel/updateById", params);
}

// 设备生产订单
function selectAllOrder(params, ctx) {
  return axios.fetchGet(
    baseUrl + "/deviceProductionOrder/selectAllOrder",
    params
  );
}
function deviceProductionOrderSave(params, ctx) {
  return axios.fetchPost(baseUrl + "/deviceProductionOrder/save", params);
}
function selectByOrderId(params, ctx) {
  return axios.fetchGet(
    baseUrl + "/deviceProductionOrder/selectByOrderId",
    params
  );
}
function deviceProductionDistribution(params) {
  return axios.fetchPost(
    baseUrl + "/deviceProductionOrder/deviceProductionDistribution",
    params
  );
}
function cancelDeviceProductionOrder(params) {
  return axios.fetchPost(
    baseUrl + "/deviceProductionOrder/cancelDeviceProductionOrder",
    params
  );
}

export default {
  list,
  find_usage,
  find,
  add,
  addBatch,
  update,
  del,
  deviceModelList,
  deviceModelFindById,
  deviceModelSave,
  deviceModelUpdateById,
  selectAllOrder,
  deviceProductionOrderSave,
  selectByOrderId,
  deviceProductionDistribution,
  cancelDeviceProductionOrder,
  untieDeviceById,
};

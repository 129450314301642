<template>

  <section>
  <b-row >
    <b-col cols="12" xs="12">
        <div id="editor2" ref="editor2"> 
        </div>
    </b-col>
  </b-row>

  <p> </p>
  <b-row class="text-center col-lg-12">
        <b-col lg="12">
            <b-form-group  >
                <b-button 
                @click="save()"  
                variant="primary"
            >Save</b-button> 
            </b-form-group>
        </b-col>
  </b-row>
  </section>

</template>


<script>


import E from 'wangeditor'
import {
    upload_img,
    wang_config,
} from "@/api/upload"

export default {
    name: 'wang',
    data () {
      return {
        editor2: {},
      }
    },
    props: ['value','config'],
    model: {
        prop: 'value',
        event: 'write',
    },
    mounted () {
        this.init_wang(this.value)
    },
    watch: {
      value: function(value) {
        if (value !== this.editor2.txt.html()) {
            this.editor2.txt.html(this.value)
        }
      }
    },
    methods: {
            save(){
                 let t=this.editor2.txt.html()
                 console.log('save',t)
                 this.$emit("save",t)
            },
            init_wang(t=""){
                this.editor2 = new E(this.$refs.editor2)
                this.editor2.customConfig.uploadImgHooks={
                      before: function (xhr, editor, files) {
                      },
                      success: function (xhr, editor, result) {
                           console.log(result)
                          // this.$bvToast.toast(result.message);
                      },
                      fail: function (xhr, editor, result) {
                      },
                      error: function (xhr, editor) {
                      },
                      timeout: function (xhr, editor) {
                      },
                      customInsert: function (insertImg, result, editor) {
                           if (result.success){
                               let url=result.data
                               insertImg(url)
                           }else{

                           }
                      },
                }
                for (let k in wang_config){
                    this.editor2.customConfig[k]=wang_config[k]
                }

                if (this.config){
                    for (let k in this.config){
                        this.editor2.customConfig[k]=this.config[k]
                    }
                }
                this.editor2.customConfig.onchange = (html) => {
                    this.$emit("write",html)
                    //this.agreementContent=html
                }
                this.editor2.create()
                this.editor2.txt.html(t)
                return this.editor2
            },

    }
}
</script>


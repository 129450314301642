import axios from "../config/http.js";
import baseUrl from "../config/env.js";

//  空烟弹生产订单
function selectAllPatchOrder(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectAllPatchOrder", params);
}
function patchOrderSave(params, ctx) {
  return axios.fetchPost(baseUrl + "/patchOrder/save", params);
}
function selectByPatchOrderId(params, ctx) {
  return axios.fetchGet(baseUrl + "/patchOrder/selectByPatchOrderId", params);
}

export default {
  selectAllPatchOrder,
  patchOrderSave,
  selectByPatchOrderId,
};

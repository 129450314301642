<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <b-card>
          <b-form-group
            label="Cartridge Status"
            label-cols-lg="2"
            label-align-lg="right"
          >
            <b-form-radio-group
              id="btn-radios-2"
              v-model="search_condition.status"
              :options="types.statusList"
              buttons
              button-variant="outline-primary"
              name="radio-btn-outline"
            ></b-form-radio-group>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Flavor"
                label-cols-md="4"
                label-align-md="right"
              >
                <b-form-select
                  id="cartridge-model"
                  v-model="search_condition.smokeBombModelId"
                  :options="types.ModelBySupplierId"
                  @change="
                    changeModel(
                      search_condition.smokeBombModelId,
                      types.ModelBySupplierId
                    )
                  "
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- <b-form-group
                label="Flavor"
                label-cols-md="4"
                label-align-md="right"
              >
                <b-form-select
                  id="cartridge-flavor"
                  v-model="search_condition.flavorId"
                  :options="types.flavorOptions"
                ></b-form-select>
              </b-form-group> -->
              <b-form-group
                label="Cartridge Batch"
                label-cols-md="4"
                label-align-lg="right"
              >
                <b-form-select
                  id="cartridge-batch"
                  v-model="search_condition.batchId"
                  :options="types.batchNumberList"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Query Time"
                label-cols-md="4"
                label-align-md="right"
              >
                <b-form-radio-group
                  id="btn-radios-2"
                  v-model="search_condition.type"
                  :options="types.typeList"
                  buttons
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Select Time"
                label-cols-md="4"
                label-align-md="right"
                label-for="select-time"
              >
                <date-picker
                  v-model="selectTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col lg="12">
              <b-button variant="primary" @click="provider()">Search</b-button>
              <b-button @click="reset()" style="margin-left: 30px"
                >Cancel</b-button
              >
              <b-button
                v-has="'consumptionAnalysis::Download the report'"
                v-b-modal.modal-1
                variant="outline-primary"
                style="float: right"
                >Download the report</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-card>
          <apexchart
            class="apex-charts"
            dir="ltr"
            height="380"
            type="bar"
            :series="basicColumChart.series"
            :options="basicColumChart.chartOptions"
            ref="apexChart"
          ></apexchart>
          <br />
          <b-table
            show-empty
            empty-text="no records"
            id="table"
            ref="table"
            head-variant="light"
            :items="tableData"
            :busy="isBusy"
            :striped="true"
            :fields="fields"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" title="Download the report" hide-footer size="xl">
      <b-button variant="outline-primary" @click="getPdf('#pdfDom')"
        >Download to PDF</b-button
      >
      <div id="pdfDom">
        <b-row>
          <b-col lg="12">
            <b-card>
              <p>
                Cartridge Model:
                {{ modelText != "Please select" ? modelText : "" }}
              </p>
              <p>
                Cartridge Batch:
                {{ batchText != "Please select" ? batchText : "" }}
              </p>
              <p>
                Cartridge Flavor:
                {{ flavorText != "Please select" ? flavorText : "" }}
              </p>
              <p style="display: flex">
                <span style="flex: 1"
                  >A total of {{ total }} , {{ brandLength }} batches</span
                >
                <span style="flex: 2">Select Time: {{ timeText }}</span>
              </p>
              <apexchart
                class="apex-charts"
                dir="ltr"
                height="380"
                type="bar"
                :series="basicColumChart.series"
                :options="basicColumChart.chartOptions"
              ></apexchart>
              <br />
              <b-table
                show-empty
                empty-text="no records"
                id="table"
                head-variant="light"
                :items="tableData"
                :busy="isBusy"
                :striped="true"
                :fields="fields"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- end main -->
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import DatePicker from "vue2-datepicker";
import { basicColumChart } from "./data-cartridge";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Cartridge Statistic component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    timeZoneConversion,
  },
  data() {
    return {
      htmlTitle: "Cartridge Statistic",
      title: "Cartridge Statistic",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Statistic analysis",
          // href: "/"
        },
        {
          text: "Cartridge Statistic",
          active: true,
        },
      ],
      basicColumChart: basicColumChart,
      fields: [
        { key: "batchName", label: "Batch Name", class: "text-center" },
        { key: "batchNumber", label: "Batch NO.", class: "text-center" },
        { key: "total", label: "Total", class: "text-center" },
        { key: "inactivated", label: "Unactivated", class: "text-center" },
        { key: "activated", label: "Activated", class: "text-center" },
        { key: "expired", label: "Disabled", class: "text-center" },
        { key: "abnormal", label: "Abnormal", class: "text-center" },
      ],
      selectTime: ["", ""],
      isBusy: false,
      search_condition: {
        status: "",
        startTime: "",
        endTime: "",
        type: "",
        smokeBombModelId: "",
        flavorId: "",
        batchId: "",
        // supplierId: localStorage.supplierId,
      },
      types: {
        batchNumberList: [{ text: "Please select", value: "" }],
        flavorOptions: [{ text: "Please select", value: "" }],
        ModelBySupplierId: [{ text: "Please select", value: "" }],
        typeList: [
          { text: "Today", value: "1" },
          { text: "Week", value: "2" },
          { text: "Month", value: "3" },
        ],
        statusList: [
          { text: "All", value: "0" },
          { text: "Activated", value: "1" },
          { text: "Unactivated", value: "2" },
          { text: "Expired", value: "3" },
          { text: "Abnormal", value: "4" },
        ],
      },
      tableData: [],
      total: 0,
      brandLength: 0,
      timeText: "",
      modelText: "",
      flavorText: "",
      batchText: "",
    };
  },

  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {},
  mounted() {
    this.init();
    this.provider();
  },
  methods: {
    provider() {
      this.isBusy = true;
      this.$api.Statistics.batchReportList(this.search_condition).then(
        (res) => {
          this.isBusy = false;
          if (res.success) {
            this.basicColumChart.chartOptions.xaxis.categories = [];
            this.basicColumChart.series[0].data = [];
            this.basicColumChart.series[1].data = [];
            this.basicColumChart.series[2].data = [];
            this.basicColumChart.series[3].data = [];
            this.total = 0;
            this.brandLength = 0;
            if (res.data.length) {
              res.data.forEach((item) => {
                this.basicColumChart.chartOptions.xaxis.categories.push(
                  // item.batchName + " " + item.createTime
                  item.batchName
                );
                this.basicColumChart.series[0].data.push(item.inactivated);
                this.basicColumChart.series[1].data.push(item.activated);
                this.basicColumChart.series[2].data.push(item.expired);
                this.basicColumChart.series[3].data.push(item.abnormal);
                this.total += item.total;
              });
              this.$refs.apexChart.refresh();
              this.brandLength = res.data.length;
            }
            this.$refs.apexChart.refresh();
            this.timeText = this.selectTime.length
              ? this.search_condition.startTime +
                "~" +
                this.search_condition.endTime
              : "";
            this.types.ModelBySupplierId.forEach((item) => {
              if (item.value == this.search_condition.smokeBombModelId) {
                this.modelText = item.text;
              }
            });
            this.types.flavorOptions.forEach((item) => {
              if (item.value == this.search_condition.flavorId) {
                this.flavorText = item.text;
              }
            });
            this.types.batchNumberList.forEach((item) => {
              if (item.value == this.search_condition.batchId) {
                this.batchText = item.text;
              }
            });
            this.tableData = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
            this.tableData = [];
          }
        }
      );
    },
    init() {
      this.$api.Dropdown.batchNumberList({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          res.data.forEach((item) => {
            this.types.batchNumberList.push({
              text: item.batchName,
              value: item.id,
            });
          });
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });

      this.$api.Dropdown.selectBatchFlavor().then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.flavorOptions.push({
              text: res.data[i].flavorName,
              value: res.data[i].id,
            });
          }
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });

      this.$api.Dropdown.selectModelBySupplierId({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          for (let i = 0; i < res.data.length; i++) {
            this.types.ModelBySupplierId.push({
              text: res.data[i].modelName,
              value: res.data[i].id,
              flavor: res.data[i].flavor,
            });
          }
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    changeModel(a, b) {
      this.types.flavorOptions = [{ text: "Please select", value: "" }];
      if (a) {
        b.forEach((item) => {
          if (item.value == a) {
            this.modelText = item.text;
            let o = JSON.parse(item.flavor);
            for (let i = 0; i < o.length; i++) {
              this.types.flavorOptions.push({
                text: o[i].flavorName,
                value: o[i].id,
              });
            }
          }
        });
      }
    },
    reset() {
      this.search_condition = {
        status: "",
        startTime: "",
        endTime: "",
        type: "",
        smokeBombModelId: "",
        flavorId: "",
        batchId: "",
        // supplierId: localStorage.supplierId,
      };
    },
  },
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul v-if="menu" class="metismenu list-unstyled" id="side-menu">
          <li v-for="item in menu" :key="item.id">
            <router-link
              v-if="!item.children"
              tag="a"
              :to="item.href"
              class="side-nav-link"
            >
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </router-link>
            <a
              v-if="item.children"
              href="javascript: void(0);"
              class="has-arrow"
            >
              <i :class="item.icon"></i>
              <span>{{ item.title }}</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li v-for="child in item.children" :key="child.id">
                <router-link :to="child.href" class="side-nav-link">{{
                  child.title
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>

        <ul v-else class="metismenu list-unstyled" id="side-menu">
          <li>
            <router-link tag="a" to="/" class="side-nav-link">
              <i class="ti-home"></i>
              <!-- <span class="badge badge-pill badge-primary float-right">2</span> -->
              <span>Home</span>
            </router-link>
          </li>

          <!-- 系统管理 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-settings"></i>
              <span>System Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 账号管理 -->
              <li>
                <router-link to="/systemManage/account" class="side-nav-link"
                  >Account Manage</router-link
                >
              </li>
              <!-- 角色管理 -->
              <li>
                <router-link to="/systemManage/role" class="side-nav-link"
                  >Role Manage</router-link
                >
              </li>
              <!-- 菜单管理 -->
              <!-- <li>
                <router-link
                  to="/userManage/UserAuthorization"
                  class="side-nav-link"
                  >Menu Manage</router-link
                >
              </li> -->
            </ul>
          </li>

          <!-- 用户管理 User Manage -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-user"></i>
              <span>User Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 用户列表 User -->
              <li>
                <router-link to="/userManage/user" class="side-nav-link"
                  >User List</router-link
                >
              </li>
              <!-- 用户使用数据 -->
              <li>
                <router-link to="/userManage/UserUseage" class="side-nav-link"
                  >User Usage</router-link
                >
              </li>
              <!-- 用户调研记录 -->
              <li>
                <router-link
                  to="/userManage/userResearchRecords"
                  class="side-nav-link"
                  >User Research Records</router-link
                >
              </li>
              <!-- 用户使用日志 -->
              <!-- <li>
                <router-link
                  to="/userManage/UserAuthorization"
                  class="side-nav-link"
                  >User Authorization</router-link
                >
              </li> -->
            </ul>
          </li>

          <!-- 生产管理 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-hummer"></i>
              <span>Production Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 烟弹批次 Cartridge Batch-->
              <li>
                <router-link
                  to="/productionManage/cartridgeBatch"
                  class="side-nav-link"
                >
                  Cartridge Batch</router-link
                >
              </li>
              <!-- 空烟弹生产订单 -->
              <li>
                <router-link
                  to="/productionManage/emptyCartridgeProductionOrders"
                  class="side-nav-link"
                >
                  Empty Carts Production Orders</router-link
                >
              </li>
              <!-- 设备生产订单 -->
              <li>
                <router-link
                  to="/productionManage/deviceProductionOrders"
                  class="side-nav-link"
                >
                  Device Production Orders</router-link
                >
              </li>
              <!-- 烟油型号 Flavor-->
              <li>
                <router-link
                  to="/productionManage/contentModel"
                  class="side-nav-link"
                >
                  Flavor</router-link
                >
              </li>

              <!-- 膏类型 -->
              <li>
                <router-link
                  to="/productionManage/pasteType"
                  class="side-nav-link"
                >
                  Paste Type</router-link
                >
              </li>

              <!-- 预热模式 -->
              <li>
                <router-link
                  to="/productionManage/preheatMode"
                  class="side-nav-link"
                >
                  Preheat Mode</router-link
                >
              </li>
              <!-- 加热模式 -->
              <li>
                <router-link
                  to="/productionManage/heatingMode"
                  class="side-nav-link"
                >
                  Heating Mode</router-link
                >
              </li>
            </ul>
          </li>

          <!-- 设备管理 Device Manage-->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-spray"></i>
              <span>Device Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 空烟弹申请 -->
              <li>
                <router-link
                  to="/deviceManage/emptyCartridges"
                  class="side-nav-link"
                  >Empty Cartridges</router-link
                >
              </li>
              <!-- 空烟弹统计 -->
              <li>
                <router-link
                  to="/deviceManage/emptyCartridgesStatistics"
                  class="side-nav-link"
                  >Empty Cartridges Statistics</router-link
                >
              </li>
              <!-- 设备列表 Device List -->
              <li>
                <router-link to="/deviceManage/device" class="side-nav-link"
                  >Device</router-link
                >
              </li>
              <!-- 设备型号 -->
              <li>
                <router-link
                  to="/deviceManage/deviceModel"
                  class="side-nav-link"
                  >Device Model</router-link
                >
              </li>
              <!-- 烟弹 Cartridge-->
              <li>
                <router-link to="/deviceManage/cartridge" class="side-nav-link">
                  Cartridge</router-link
                >
              </li>
              <!-- 烟弹型号 -->
              <li>
                <router-link
                  to="/deviceManage/cartridgeModel"
                  class="side-nav-link"
                >
                  Cartridge Model</router-link
                >
              </li>
              <!-- 烟弹使用记录 Cartridge Usage-->
              <li>
                <router-link
                  to="/deviceManage/cartridgeUsage"
                  class="side-nav-link"
                >
                  Cartridge Usage</router-link
                >
              </li>
            </ul>
          </li>

          <!-- 开发者管理 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-light-bulb"></i>
              <span>Developer Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 开发者烟弹管理 -->
              <li>
                <router-link
                  to="/developerManage/developerCartridge"
                  class="side-nav-link"
                >
                  Developer Cartridge</router-link
                >
              </li>
              <!-- 注册码管理 -->
              <!-- 开发者测试记录 -->
            </ul>
          </li>

          <!-- 统计分析  statistic analysis-->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-pie-chart"></i>
              <span>Statistic analysis</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 用户统计分析 -->
              <li>
                <router-link
                  to="/statistic/userStatistic"
                  class="side-nav-link"
                >
                  User Statistic</router-link
                >
              </li>
              <!-- 烟弹统计分析 -->
              <li>
                <router-link
                  to="/statistic/cartridgeStatistic"
                  class="side-nav-link"
                >
                  Cartridge Statistic</router-link
                >
              </li>
            </ul>
          </li>

          <!-- 客户管理  customer manage-->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-id-badge"></i>
              <span>Customer Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 客户管理 -->
              <li>
                <router-link
                  to="/customer/customerManage"
                  class="side-nav-link"
                >
                  Customer Manage</router-link
                >
              </li>
              <!-- 客户角色 -->
              <li>
                <router-link to="/customer/customerRole" class="side-nav-link">
                  Customer Role</router-link
                >
              </li>
            </ul>
          </li>

          <!--  Medical Manage 医疗管理 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-support"></i>
              <span>Medical Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li>
                <router-link to="/medicalManage/faq" class="side-nav-link"
                  >FAQ</router-link
                >
              </li>

              <!-- 调研问题管理 -->
              <li>
                <router-link to="/medicalManage/researchQuestions" class="side-nav-link"
                  >Research Questions</router-link
                >
              </li>

              <li>
                <router-link to="/medicalManage/userGuide" class="side-nav-link"
                  >Use guide</router-link
                >
              </li>

              <li>
                <router-link
                  to="/medicalManage/RecommenderCode"
                  class="side-nav-link"
                  >Recommender code</router-link
                >
              </li>
            </ul>
          </li>

          <!-- App Manage -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-mobile"></i>
              <span>App Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 用户反馈 -->
              <li>
                <router-link to="/appManage/feedback" class="side-nav-link"
                  >User Feedback</router-link
                >
              </li>
              <!-- FAQ -->
              <li>
                <router-link to="/appManage/application" class="side-nav-link"
                  >Application Manage</router-link
                >
              </li>

              <!-- 系统设置 -->
              <!-- <li>
                <router-link to="/appManage/settings" class="side-nav-link"
                  >Settings</router-link
                >
              </li> -->
            </ul>
          </li>

          <!-- 数据字典 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-book"></i>
              <span>Data Dictionary</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 烟弹成分 -->
              <li>
                <router-link
                  to="/dataDictionary/ingredient"
                  class="side-nav-link"
                >
                  Ingredient</router-link
                >
              </li>
              <!-- 烟弹口味 -->
              <li>
                <router-link to="/dataDictionary/flavor" class="side-nav-link">
                  Flavor</router-link
                >
              </li>
              <!-- 烟弹厂家 -->
              <li>
                <router-link
                  to="/dataDictionary/manufacturer"
                  class="side-nav-link"
                >
                  Manufacturer</router-link
                >
              </li>
              <!-- 品牌管理 -->
              <li>
                <router-link
                  to="/dataDictionary/brandManage"
                  class="side-nav-link"
                >
                  Brand Manage</router-link
                >
              </li>
              <!-- 雾化丝种类 -->
              <li>
                <router-link
                  to="/dataDictionary/atomizingWire"
                  class="side-nav-link"
                >
                  Atomizing Wire</router-link
                >
              </li>
              <!-- 溶剂类型 -->
              <li>
                <router-link to="/dataDictionary/solvent" class="side-nav-link">
                  Solvent</router-link
                >
              </li>
              <!-- 烟弹类型 -->
              <li>
                <router-link
                  to="/dataDictionary/cartridgeType"
                  class="side-nav-link"
                >
                  Cartridge Type</router-link
                >
              </li>
              <!-- 地区管理 -->
              <li>
                <router-link
                  to="/dataDictionary/regionManage"
                  class="side-nav-link"
                >
                  Region Manage</router-link
                >
              </li>
            </ul>
          </li>
          <!-- 系统维护 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-desktop"></i>
              <span>System Maintenance</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 系统日志 -->
              <li>
                <router-link
                  to="/systemMaintenance/systemLog"
                  class="side-nav-link"
                >
                  System Log</router-link
                >
              </li>
              <!-- 服务器日志 -->
              <li>
                <router-link
                  to="/systemMaintenance/serverLog"
                  class="side-nav-link"
                >
                  Server Log</router-link
                >
              </li>
            </ul>
          </li>

          <!-- 营销管理 -->
          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-medall-alt"></i>
              <span>Marketing Manage</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <!-- 系统日志 -->
              <li>
                <router-link
                  to="/marketingManage/messagePushed"
                  class="side-nav-link"
                >
                  Message Pushed</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>

<style></style>

<script>
import simplebar from "simplebar-vue";
import MetisMenu from "metismenujs/dist/metismenujs";

import { layoutMethods } from "@/state/helpers";
import store from "@/state/store";
import { mapState } from 'vuex'

/**
 * Sidebar component
 */
export default {
  data() {
    return {
      // menu: JSON.parse(sessionStorage.getItem("menu"))
      //   ? JSON.parse(sessionStorage.getItem("menu"))
      //   : [],
      // menu: [],
      // menu: JSON.parse(sessionStorage.getItem("menu")),
    };
  },
  components: {
    simplebar,
  },
  computed: {
    menu() {
      // 获取store中的状态
      return store.state.menu;
    },
  },
  // computed: mapState([
  //   // 映射 this.count 为 store.state.count
  //   "menu",
  // ]),
  mounted() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;

    if (window.location.pathname == "/") {
    } else {
      for (var i = 0; i < links.length; i++) {
        var m = window.location.pathname.split("/")[1];
        var m1 = links[i].pathname.split("/")[1];
        if (m == m1) {
          matchingMenuItem = links[i];
          break;
        }
      }
    }

    for (var i = 0; i < links.length; i++) {
      if (
        window.location.pathname.replace(/add|edit|info/i, "") ===
        links[i].pathname
      ) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");
            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("active");
          }
        } else {
          parent.classList.add("mm-active");
        }
      }
    }
  },
  updated() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;

    if (window.location.pathname == "/") {
    } else {
      for (var i = 0; i < links.length; i++) {
        var m = window.location.pathname.split("/")[1];
        var m1 = links[i].pathname.split("/")[1];
        if (m == m1) {
          matchingMenuItem = links[i];
          break;
        }
      }
    }

    for (var i = 0; i < links.length; i++) {
      if (
        window.location.pathname.replace(/add|edit|info/i, "") ===
        links[i].pathname
      ) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");
            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("active");
          }
        } else {
          parent.classList.add("mm-active");
        }
      }
    }
  },
  methods: {
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({
        layoutType: layout,
      });
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
  },
};
</script>

<style lang="scss">
.requiredRow {
  &:before {
    content: "*";
    color: red;
    padding-right: 2px;
  }
}
body[data-sidebar="dark"] .mm-active .active {
  color: #b4c9de !important;
  background-color: #707070;
}
.sub-menu .mm-active {
  background-color: #707070;
}
</style>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("User Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Avatar:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm.info.picture
                        ? _c("img", {
                            staticClass: "avatar-xs rounded-circle mr-2",
                            attrs: { src: _vm.info.picture, alt: "" }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("User Nickname:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.nickName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Question Group:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.problemGroupName))
                    ])
                  ]),
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Feedback Time:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.getLocalTime(_vm.info.createTime)))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Feedback Questions")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            _vm._l(_vm.info.problemVo, function(item, i) {
              return _c(
                "b-row",
                {
                  key: i,
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { cols: "1" }
                },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(_vm._s("Question" + (i + 1) + ":"))
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(item.problemName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(_vm._s("Answer" + (i + 1) + ":"))
                    ]),
                    _c(
                      "span",
                      { staticClass: "p-3" },
                      [
                        _c("b-form-radio-group", {
                          attrs: {
                            id: "btn-radios-2",
                            options: item.answerList,
                            buttons: "",
                            "button-variant": "outline-primary",
                            name: "radio-btn-outline"
                          },
                          model: {
                            value: item.answer,
                            callback: function($$v) {
                              _vm.$set(item, "answer", $$v)
                            },
                            expression: "item.answer"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: { size: "lg", type: "reset", variant: "outline-secondary" },
          on: { click: _vm.returnPage }
        },
        [_vm._v("Go Back")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
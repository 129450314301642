<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="typeForm" @reset="reset_form">
              <!-- 口味名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Brand Name"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="brand-name"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="brand-name"
                      v-model="typeform.brandName"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 品牌logo -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Brand Logo"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="brand-logo"
                    label-class="requiredRow"
                  >
                    <!-- <div class="mb-1">
                      <vue-dropzone
                        id="dropzone"
                        ref="myVueDropzone"
                        :options="dropzoneOptions"
                        @vdropzone-file-added="upload"
                        @removedfile="upload"
                        @vdropzone-error="upload"
                        @sending="upload"
                        @complete="upload"
                        @vdropzone-canceled="upload"
                        @vdropzone-max-files-exceeded="upload"
                        @totaluploadprogress="upload"
                        @vdropzone-success="add_img"
                        @vdropzone-success-multiple="add_img"
                      ></vue-dropzone>
                    </div> -->
                    <input
                      type="file"
                      class="filestyle"
                      data-input="false"
                      id="filestyle-1"
                      style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                      tabindex="-1"
                      @change="upload($event)"
                      :required="!typeform.file"
                      oninvalid="setCustomValidity('Please upload');"
                      oninput="setCustomValidity('');"
                    />

                    <div class="bootstrap-filestyle input-group">
                      <div
                        name="filedrag"
                        style="
                          position: absolute;
                          width: 100%;
                          height: 35px;
                          z-index: -1;
                        "
                      ></div>
                      <span class="group-span-filestyle" tabindex="0">
                        <div v-if="typeform.file" style="margin-bottom: 10px">
                          <img
                            :src="typeform.file"
                            style="
                              width: 100px;
                              height: 100px;
                              border: 2px dashed #ccc;
                            "
                          />
                          <span
                            style="margin-left: 10px; vertical-align: bottom"
                            v-if="photoName"
                          >
                            <span style="font-weight: 500">Picture name : </span
                            >{{ photoName }}</span
                          >
                        </div>
                        <label
                          for="filestyle-1"
                          style="margin-bottom: 0"
                          class="btn btn-secondary"
                        >
                          <span class="buttonText"> upload picture </span>
                        </label>
                        <span>
                          Recommended size of picture: 120 pixels * 120 pixels,
                          supporting JPG and PNG formats.
                        </span>
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 首页展示logo -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Homepage Display Logo"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="homepage-logo"
                    label-class="requiredRow"
                  >
                    <input
                      type="file"
                      class="filestyle"
                      data-input="false"
                      id="homepage-logo"
                      style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                      tabindex="-1"
                      @change="uploadHomePicture($event)"
                      :required="!typeform.homePicture"
                      oninvalid="setCustomValidity('Please upload');"
                      oninput="setCustomValidity('');"
                    />

                    <div class="bootstrap-filestyle input-group">
                      <div
                        name="filedrag"
                        style="
                          position: absolute;
                          width: 100%;
                          height: 35px;
                          z-index: -1;
                        "
                      ></div>
                      <span class="group-span-filestyle" tabindex="0">
                        <div
                          v-if="typeform.homePicture"
                          style="margin-bottom: 10px"
                        >
                          <img
                            :src="typeform.homePicture"
                            style="
                              width: 100px;
                              height: 100px;
                              border: 2px dashed #ccc;
                            "
                          />
                          <span
                            style="margin-left: 10px; vertical-align: bottom"
                            v-if="homePictureName"
                          >
                            <span style="font-weight: 500">Picture name : </span
                            >{{ homePictureName }}</span
                          >
                        </div>
                        <label
                          for="homepage-logo"
                          style="margin-bottom: 0"
                          class="btn btn-secondary"
                        >
                          <span class="buttonText"> upload picture </span>
                        </label>
                        <span>
                          Recommended size of picture: 523 pixels * 275 pixels,
                          supporting JPG and PNG formats.
                        </span>
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 所属客户 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Affiliated Customer"
                    label-cols-lg="4"
                    label-align-lg="right"
                    label-for="customer"
                    label-class="requiredRow"
                  >
                    <b-form-select
                      id="customer"
                      v-model="typeform.supplierId"
                      :options="types.allSupplier"
                      value-field="id"
                      text-field="brandName"
                      required
                      oninvalid="setCustomValidity('Please select');"
                      oninput="setCustomValidity('');"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 口味备注 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Brand Notes"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="brand-remark"
                  >
                    <b-form-textarea
                      id="remark"
                      v-model="typeform.remarks"
                      placeholder="Enter Notes"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Confirm</button>
                  <button type="reset" class="btn btn-secondary m-l-5 ml-1">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions, upload_img } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";

import {
  alpha,
  alphaNum,
  numeric,
  between,
  email,
  ipAddress,
  macAddress,
  maxLength,
  minLength,
  required,
  requiredIf,
  requiredUnless,
  sameAs,
  url,
  or,
  and,
  not,
  minValue,
  maxValue,
  integer,
  decimal,
  helpers,
} from "vuelidate/lib/validators";

export default {
  name: "brand",
  page: {
    title: "brand",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: /add/i.test(location.href)
        ? "Consumable Brands Create"
        : "Consumable Brands Modify",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Data Dictionary",
          // href: "/"
        },
        {
          text: /add/i.test(location.href)
            ? "Consumable Brands Create"
            : "Consumable Brands Modify",
          active: true,
        },
      ],
      typeform: {
        //"id":3,
        brandName: "",
        remarks: "",
        file: "",
        homePicture: "",
        supplierId: "",
      },
      types: {
        allSupplier: [{ brandName: "Please select", id: "" }],
      },
      photoName: "",
      homePictureName: "",
      submit: false,
      typesubmit: false,
    };
  },
  validations: {
    typeform: {
      file: { required, minLength: minLength(1) },
    },
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      this.typeform = {
        id: this.$route.params.id,
        brandName: this.$route.params.brandName,
        remarks: this.$route.params.remarks,
        file: this.$route.params.brandPicture,
        homePicture: this.$route.params.homePicture,
        supplierId: this.$route.params.supplierId,
      };
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.typeform.file = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    uploadHomePicture(e) {
      let file = e.target.files[0];
      this.homePictureName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.typeform.homePicture = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    add() {
      this.$api.Flavor.brandAdd(this.typeform).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    update() {
      this.$api.Flavor.brandUpdate(this.typeform).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    rangeform(e) {
      this.submit = true;
      this.$v.$touch();
    },
    submit_form() {
      this.typesubmit = true;
      this.$v.$touch();
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    typeForm(e) {
      if (this.$v.typeform.$invalid) {
        this.submitStatus = "ERROR";
        this.$bvToast.toast("Please upload pictures");
      } else {
        this.submit_form();
      }
    },
    reset_form() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.dropzone {
  width: 150px;
  height: 150px;
  border: 1px dashed #ced4da !important;
}
.dropzone .dz-message {
  margin: 1em 0;
}
.dropzone .dz-message {
  font-size: 16px !important;
}
.dropzone .dz-message {
  color: #ced4da;
  margin: 27px 0 !important;
}
.dropzone .dz-preview {
  margin: -6px;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Flavor info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">id：</label>
            <span class="p-3">{{ info.id }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">modelName：</label>
            <span class="p-3">{{ info.modelName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">modelEncoding：</label>
            <span class="p-3">{{ info.modelEncoding }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">brandName：</label>
            <span class="p-3">{{ info.brandName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">formula:</label>
            <span class="p-3">{{ info.formula }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">flavor：</label>
            <span class="p-3">{{ info.flavor }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Cartridge Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Flavor Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Flavor Details",
          active: true,
        },
      ],
      info: {
        id: 1,
        modelName: "a",
        modelEncoding: "1111",
        formula: "[]",
        flavor: "",
        brandName: "",
        type: 4,
        remarks: null,
        createTime: "",
        dose: 1,
        calculation: 0,
        consistence: "",
        solvent: "[]",
        atomizingWireType: "",
        reportLink: null,
        tobaccoTar: 0,
        reportEnclosure: null,
        explains: null,
        medicalCare: 1,
        maxFrequency: 3,
        supplierId: null,
        temperature: null,
        power: null,
        daySeconds: null,
      },
    };
  },
  created() {
    // 获取 烟弹信息
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.CartridgeModel.find(this.$route.query).then((res) => {
          console.log(res);
          if (res.success) {
            this.info = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("Steps", { attrs: { Steps: _vm.registSpets, SetData: _vm.SetData } }),
      !_vm.isComplete
        ? _c(
            "b-card",
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c(
                  "p",
                  {
                    staticClass: "title",
                    staticStyle: {
                      "font-size": "16px",
                      "font-weight": "bolder"
                    }
                  },
                  [
                    _c("span", {
                      staticStyle: {
                        display: "inline-block",
                        width: "10px",
                        height: "20px",
                        "vertical-align": "text-top",
                        "background-color": "#626ed4"
                      }
                    }),
                    _vm._v(" Batch Info ")
                  ]
                )
              ]),
              _c(
                "b-container",
                { staticStyle: { "margin-bottom": "30px" } },
                [
                  _c(
                    "b-row",
                    { attrs: { cols: "3" } },
                    [
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Batch Name：")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.batchName))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Batch ID：")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.number))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Flavor：")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.modelName))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Cartridge Model：")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.smokeModel))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Batch Quantity:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.batchQuantity))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Capacity:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.capacity))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Max Frequency:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.maxFrequency))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Brand Owner:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.brandName))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Production Factory:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.info.manufacturerName))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-col", { attrs: { lg: "12" } }, [
                _c(
                  "p",
                  {
                    staticClass: "title",
                    staticStyle: {
                      "font-size": "16px",
                      "font-weight": "bolder"
                    }
                  },
                  [
                    _c("span", {
                      staticStyle: {
                        display: "inline-block",
                        width: "10px",
                        height: "20px",
                        "vertical-align": "text-top",
                        "background-color": "#626ed4"
                      }
                    }),
                    _vm._v(" Import Device ")
                  ]
                )
              ]),
              _c(
                "b-form",
                {
                  ref: "firstTabForm",
                  on: { submit: _vm.onSubmit, reset: _vm.onReset }
                },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: { "label-cols-lg": "2", "label-align-lg": "right" }
                    },
                    [
                      _c("input", {
                        staticClass: "filestyle",
                        staticStyle: {
                          position: "absolute",
                          clip: "rect(0px, 0px, 0px, 0px)"
                        },
                        attrs: {
                          type: "file",
                          "data-input": "false",
                          id: "filestyle-1",
                          tabindex: "-1",
                          required: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.upload($event)
                          }
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0", "b-col": "8" }
                        },
                        [
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v("upload Excel file")
                              ])
                            ]
                          ),
                          _vm.form.file
                            ? _c("span", [_vm._v(_vm._s(_vm.form.file.name))])
                            : _c("span", [
                                _vm._v("only support xls、xlxs file")
                              ])
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticStyle: { "margin-top": "50px" },
                      attrs: { "label-cols-lg": "2", "label-align-lg": "right" }
                    },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticStyle: { "margin-left": "30px" },
                          attrs: { type: "reset" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.isComplete
        ? _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { lg: "12" }
                    },
                    [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c(
                          "div",
                          [
                            _c("b-icon", {
                              attrs: {
                                icon: "check-circle",
                                variant: "success",
                                "font-scale": "5"
                              }
                            })
                          ],
                          1
                        ),
                        _c("div", [
                          _c("p", [_vm._v("Device import completed")]),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.completeData.total) +
                                " total, " +
                                _vm._s(_vm.completeData.notActive) +
                                " inactive, exception " +
                                _vm._s(_vm.completeData.abnormal)
                            )
                          ])
                        ])
                      ]),
                      _c(
                        "b-button",
                        { attrs: { size: "lg" }, on: { click: _vm.onReset } },
                        [_vm._v("return")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                },
                reset: _vm.onReset
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Guide the title:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "title"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title",
                      placeholder: "Please enter",
                      oninput:
                        "if(value.length > 200)value = value.slice(0, 200)"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  }),
                  _c("span", [_vm._v("Title is maximum 200 characters")])
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Status:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "status"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticClass: "pt-2",
                    staticStyle: { "padding-top": "5px" },
                    attrs: {
                      options: _vm.types.status,
                      name: "cartridge-status"
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Picture:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "picture"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.pictureList.length
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _vm._l(_vm.pictureList, function(item, i) {
                                    return _c("span", { key: i }, [
                                      /png|jpg/.test(item.type)
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                              border: "2px dashed #ccc",
                                              "vertical-align": "top"
                                            },
                                            attrs: { src: item.url }
                                          })
                                        : _c("video", {
                                            staticStyle: {
                                              width: "100px",
                                              height: "100px",
                                              border: "2px dashed #ccc",
                                              "vertical-align": "top"
                                            },
                                            attrs: { src: item.url }
                                          })
                                    ])
                                  }),
                                  _vm.pictureName.length
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: { "margin-left": "10px" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v("File name :")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.pictureName.join(",")
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                2
                              )
                            : _vm._e(),
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v("upload picture/video")
                              ])
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " Suggested size: 1280 pixels * 1280 pixels, JPG, PNG format, up to 3 pieces;Video size is not more than 20M, only in MP4 format. "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Sort:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "sort"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "sort",
                      type: "number",
                      placeholder: "Please enter"
                    },
                    model: {
                      value: _vm.form.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Content:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "content"
                  }
                },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor },
                    on: { ready: _vm.onReady },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Device Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Device NO.:</label>
            <span class="p-3">{{ form.deviceNumber }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Device Coding:</label>
            <span class="p-3">{{ form.deviceCoding }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Device Status:</label>
            <span class="p-3">{{
              form.status == 0 ? "Unused" : 1 ? "Used" : "Unactivated"
            }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Device Location:</label>
            <span class="p-3">{{ form.deviceLocation }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Device usage info</h4>
          </div>
        </b-col>
      </b-row>

      <!-- table -->
      <div class="table-responsive mb-0">
        <b-table
          id="table"
          head-variant="light"
          show-empty
          empty-text="no records"
          :striped="true"
          :items="device_usage"
          :fields="fields"
          :per-page="search_condition.pageSize"
          :current-page="search_condition.pageNum"
        >
          <template v-slot:cell(index)="data">
            {{
              data.index +
              (search_condition.pageNum - 1) * search_condition.pageSize +
              1
            }}
          </template>
        </b-table>
      </div>
      <!-- end table -->
      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="rows != 0">
              <b-pagination
                v-model="search_condition.pageNum"
                :per-page="10"
                :total-rows="rows"
                aria-controls="table"
              ></b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>

    <b-card>
      <b-button
        size="lg"
        type="reset"
        variant="outline-secondary"
        @click="onReset"
        >Go Back</b-button
      >
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Device Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Device Details",
          active: true,
        },
      ],
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "deviceNumber", label: "Device NO.", class: "text-center" },
        { key: "deviceCoding", label: "Device Coding", class: "text-center" },
        {
          key: "createTime",
          label: "Device Time",
          formatter: (value) => {
            return timeZoneConversion.getLocalTimes(value);
          },
          class: "text-center",
        },
        { key: "address", label: "Device Location", class: "text-center" },
        {
          key: "totalDuration",
          label: "Duration of use(s)",
          class: "text-center",
        },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        id: "",
      },
      totalRows: 0,
      form: {
        activationTime: "",
        brandName: "",
        createTime: "",
        deviceCoding: "",
        deviceLocation: "",
        deviceName: "",
        deviceNumber: "",
        id: 0,
        isDelete: 0,
        smokeBombInfo: {
          atomizingWireType: "",
          brandName: "",
          calculation: 0,
          city: "",
          companyIcon: "",
          consistence: "",
          daySeconds: 0,
          dose: 0,
          explains: "",
          flavor: "",
          formulaList: [
            {
              component: "",
              id: 0,
              proportion: 0,
              smokeRecordId: 0,
              status: 0,
            },
          ],
          frequency: 0,
          id: 0,
          isDelete: 0,
          isPreheat: 0,
          madeinName: "",
          maxFrequency: 0,
          normalTemperature: "",
          picture: "",
          placeOfOrigin: "",
          power: "",
          preheatTime: "",
          productionBatch: "",
          proportion: "",
          proportion1: "",
          proportion2: "",
          reportEnclosure: "",
          reportLink: "",
          reportName: "",
          seconds: "",
          softTemperature: "",
          solvent: "",
          solvent1: "",
          solvent2: "",
          supplierId: 0,
          temperature4: "",
          temperature5: "",
          temperature6: "",
          tobaccoTar: 0,
          type: "",
          wildTemperature: "",
          worktemp: "",
        },
        status: 0,
        supplierMenu: 0,
      },
      device_usage: [],
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },

  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.Device.find(this.$route.query).then((res) => {
          if (res.success && res.data) {
            this.form = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
        this.search_condition.id = this.$route.query.id;
        this.$api.Device.find_usage(this.search_condition).then((res) => {
          if (res.success && res.data) {
            this.device_usage = res.data;
            this.totalRows = res.total;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReset() {
      this.$router.push("deviceList");
    },
  },
};
</script>
<style>
.blockquote {
  padding: 10px 10px;
  border-left: 5px solid #aaaaff;
}
</style>

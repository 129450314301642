<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="submit_form" @reset="reset_form">
              <!-- 雾化丝名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Country"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="country-name"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="country-name"
                      v-model="typeform.countryName"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <!-- 雾化丝名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="City"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="city-name"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="city-name"
                      v-model="typeform.cityName"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 所属客户 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Affiliated Customer"
                    label-cols-lg="4"
                    label-align-lg="right"
                    label-for="customer"
                    label-class="requiredRow"
                  >
                    <b-form-select
                      id="customer"
                      v-model="typeform.supplierId"
                      :options="types.allSupplier"
                      value-field="id"
                      text-field="brandName"
                      required
                      oninvalid="setCustomValidity('Please select');"
                      oninput="setCustomValidity('');"
                    >
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 备注 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Notes"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="remark"
                  >
                    <b-form-textarea
                      id="remark"
                      v-model="typeform.remarks"
                      placeholder="Enter Notes"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Confirm</button>
                  <button type="reset" class="btn btn-secondary m-l-5 ml-1">
                    Cancel </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "Region",
  page: {
    title: "Region",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Region Manage",
      items: [
        {
          text: "Data Dictionary",
          // href: "/"
        },
        {
          text: "Region Manage",
          // href: "/"
        },
        {
          text: /add/i.test(location.href) ? "Region Create" : "Region Modify",
          active: true,
        },
      ],
      typeform: {
        //"id":3,
        countryName: "",
        cityName: "",
        remarks: "",
        supplierId: ""
      },
      types: {
        allSupplier: [{ brandName: "Please select", id: "" }],
      },
      submit: false,
      typesubmit: false,
    };
  },
  computed: {
    is_add: function() {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      this.typeform = {
        id: this.$route.params.id,
        countryName: this.$route.params.countryName,
        cityName: this.$route.params.cityName,
        remarks: this.$route.params.remarks,
        supplierId: this.$route.params.supplierId
      };
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
    },
    add() {
      this.$api.Flavor.areaAdd(this.typeform).then((res) => {
        if(res.message){
              this.$bvToast.toast(res.message);
            }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    update() {
      this.$api.Flavor.areaUpdate(this.typeform).then((res) => {
        if(res.message){
              this.$bvToast.toast(res.message);
            }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    submit_form() {
      this.typesubmit = true;
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
    reset_form() {
      this.$router.go(-1);
    }
  },
};
</script>

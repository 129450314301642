<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  created() {
    this.$api.systemManage.findUserMenu().then((res) => {
      if (res.success && res.data) {
        this.$store.commit("setMenu", res.data);
      } else {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
      }
    });
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>

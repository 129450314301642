var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  attrs: { action: "#" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.typeForm($event)
                    }
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "flavor Name",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "flavor-name"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.typeform.name,
                                    expression: "typeform.name"
                                  }
                                ],
                                staticClass: "form-control col-md-9",
                                class: {
                                  "is-invalid":
                                    _vm.typesubmit &&
                                    _vm.$v.typeform.name.$error
                                },
                                attrs: {
                                  type: "text",
                                  placeholder: "Type something",
                                  name: "name"
                                },
                                domProps: { value: _vm.typeform.name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.typeform,
                                      "name",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _vm.typesubmit && _vm.$v.typeform.name.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      !_vm.$v.typeform.name.required
                                        ? _c("span", [
                                            _vm._v("This value is required.")
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "flavor Image",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "flavor-name"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "mb-1" },
                                [
                                  _c("vue-dropzone", {
                                    ref: "myVueDropzone",
                                    attrs: {
                                      id: "dropzone",
                                      options: _vm.dropzoneOptions
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Flavor Notes",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "flavor-remark"
                              }
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.flavorRemark,
                                    expression: "flavorRemark"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  maxlength: 225,
                                  rows: "3",
                                  placeholder: ""
                                },
                                domProps: { value: _vm.flavorRemark },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.flavorRemark = $event.target.value
                                  }
                                }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" },
                          on: { click: _vm.addFlavor }
                        },
                        [_vm._v("Submit")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary m-l-5 ml-1",
                          attrs: { type: "reset" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vertical-menu" },
    [
      _c("simplebar", { staticClass: "h-100" }, [
        _c("div", { attrs: { id: "sidebar-menu" } }, [
          _vm.menu
            ? _c(
                "ul",
                {
                  staticClass: "metismenu list-unstyled",
                  attrs: { id: "side-menu" }
                },
                _vm._l(_vm.menu, function(item) {
                  return _c(
                    "li",
                    { key: item.id },
                    [
                      !item.children
                        ? _c(
                            "router-link",
                            {
                              staticClass: "side-nav-link",
                              attrs: { tag: "a", to: item.href }
                            },
                            [
                              _c("i", { class: item.icon }),
                              _c("span", [_vm._v(_vm._s(item.title))])
                            ]
                          )
                        : _vm._e(),
                      item.children
                        ? _c(
                            "a",
                            {
                              staticClass: "has-arrow",
                              attrs: { href: "javascript: void(0);" }
                            },
                            [
                              _c("i", { class: item.icon }),
                              _c("span", [_vm._v(_vm._s(item.title))])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "ul",
                        {
                          staticClass: "sub-menu",
                          attrs: { "aria-expanded": "false" }
                        },
                        _vm._l(item.children, function(child) {
                          return _c(
                            "li",
                            { key: child.id },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "side-nav-link",
                                  attrs: { to: child.href }
                                },
                                [_vm._v(_vm._s(child.title))]
                              )
                            ],
                            1
                          )
                        }),
                        0
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "ul",
                {
                  staticClass: "metismenu list-unstyled",
                  attrs: { id: "side-menu" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "side-nav-link",
                          attrs: { tag: "a", to: "/" }
                        },
                        [
                          _c("i", { staticClass: "ti-home" }),
                          _c("span", [_vm._v("Home")])
                        ]
                      )
                    ],
                    1
                  ),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-settings" }),
                        _c("span", [_vm._v("System Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/systemManage/account" }
                              },
                              [_vm._v("Account Manage")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/systemManage/role" }
                              },
                              [_vm._v("Role Manage")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-user" }),
                        _c("span", [_vm._v("User Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/userManage/user" }
                              },
                              [_vm._v("User List")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/userManage/UserUseage" }
                              },
                              [_vm._v("User Usage")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/userManage/userResearchRecords" }
                              },
                              [_vm._v("User Research Records")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-hummer" }),
                        _c("span", [_vm._v("Production Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: {
                                  to: "/productionManage/cartridgeBatch"
                                }
                              },
                              [_vm._v(" Cartridge Batch")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: {
                                  to:
                                    "/productionManage/emptyCartridgeProductionOrders"
                                }
                              },
                              [_vm._v(" Empty Carts Production Orders")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: {
                                  to: "/productionManage/deviceProductionOrders"
                                }
                              },
                              [_vm._v(" Device Production Orders")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/productionManage/contentModel" }
                              },
                              [_vm._v(" Flavor")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/productionManage/pasteType" }
                              },
                              [_vm._v(" Paste Type")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/productionManage/preheatMode" }
                              },
                              [_vm._v(" Preheat Mode")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/productionManage/heatingMode" }
                              },
                              [_vm._v(" Heating Mode")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-spray" }),
                        _c("span", [_vm._v("Device Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/deviceManage/emptyCartridges" }
                              },
                              [_vm._v("Empty Cartridges")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: {
                                  to: "/deviceManage/emptyCartridgesStatistics"
                                }
                              },
                              [_vm._v("Empty Cartridges Statistics")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/deviceManage/device" }
                              },
                              [_vm._v("Device")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/deviceManage/deviceModel" }
                              },
                              [_vm._v("Device Model")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/deviceManage/cartridge" }
                              },
                              [_vm._v(" Cartridge")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/deviceManage/cartridgeModel" }
                              },
                              [_vm._v(" Cartridge Model")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/deviceManage/cartridgeUsage" }
                              },
                              [_vm._v(" Cartridge Usage")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-light-bulb" }),
                        _c("span", [_vm._v("Developer Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: {
                                  to: "/developerManage/developerCartridge"
                                }
                              },
                              [_vm._v(" Developer Cartridge")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-pie-chart" }),
                        _c("span", [_vm._v("Statistic analysis")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/statistic/userStatistic" }
                              },
                              [_vm._v(" User Statistic")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/statistic/cartridgeStatistic" }
                              },
                              [_vm._v(" Cartridge Statistic")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-id-badge" }),
                        _c("span", [_vm._v("Customer Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/customer/customerManage" }
                              },
                              [_vm._v(" Customer Manage")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/customer/customerRole" }
                              },
                              [_vm._v(" Customer Role")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-support" }),
                        _c("span", [_vm._v("Medical Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/medicalManage/faq" }
                              },
                              [_vm._v("FAQ")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: {
                                  to: "/medicalManage/researchQuestions"
                                }
                              },
                              [_vm._v("Research Questions")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/medicalManage/userGuide" }
                              },
                              [_vm._v("Use guide")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/medicalManage/RecommenderCode" }
                              },
                              [_vm._v("Recommender code")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-mobile" }),
                        _c("span", [_vm._v("App Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/appManage/feedback" }
                              },
                              [_vm._v("User Feedback")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/appManage/application" }
                              },
                              [_vm._v("Application Manage")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-book" }),
                        _c("span", [_vm._v("Data Dictionary")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/ingredient" }
                              },
                              [_vm._v(" Ingredient")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/flavor" }
                              },
                              [_vm._v(" Flavor")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/manufacturer" }
                              },
                              [_vm._v(" Manufacturer")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/brandManage" }
                              },
                              [_vm._v(" Brand Manage")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/atomizingWire" }
                              },
                              [_vm._v(" Atomizing Wire")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/solvent" }
                              },
                              [_vm._v(" Solvent")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/cartridgeType" }
                              },
                              [_vm._v(" Cartridge Type")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/dataDictionary/regionManage" }
                              },
                              [_vm._v(" Region Manage")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-desktop" }),
                        _c("span", [_vm._v("System Maintenance")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/systemMaintenance/systemLog" }
                              },
                              [_vm._v(" System Log")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/systemMaintenance/serverLog" }
                              },
                              [_vm._v(" Server Log")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "has-arrow",
                        attrs: { href: "javascript: void(0);" }
                      },
                      [
                        _c("i", { staticClass: "ti-medall-alt" }),
                        _c("span", [_vm._v("Marketing Manage")])
                      ]
                    ),
                    _c(
                      "ul",
                      {
                        staticClass: "sub-menu",
                        attrs: { "aria-expanded": "false" }
                      },
                      [
                        _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "side-nav-link",
                                attrs: { to: "/marketingManage/messagePushed" }
                              },
                              [_vm._v(" Message Pushed")]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]
              )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
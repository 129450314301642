import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"


async function list(params,ctx){

    // let d=JSON.parse(JSON.stringify(params))

    return axios.fetchGet(baseUrl + '/recommendCode/list',params)
}

async function get(params={}){
    axios.fetchGet(baseUrl + '/RecommenderCode/get',params)
    return {
        success:true,
        data:params.code+"123456",
    }
}

function add(params){
    return axios.fetchPost(baseUrl + '/recommendCode/addRecommendCode', params);
}

function find(params){
   return axios.fetchGet(baseUrl + '/RecommenderCode/findById',params)
}

function update(params){
    return axios.fetchPost(baseUrl + '/recommendCode/updateRecommendCode', params);
}

function del(params){
    return axios.fetchPost(baseUrl + '/RecommenderCode/deleteById', params);
}
function enableOrDisable (params={}){
    return axios.fetchPost(baseUrl + '/recommendCode/enableOrDisable', params);
}

export default {
    list,
    find,
    add,
    update,
    del,
    enableOrDisable,
    get,
}

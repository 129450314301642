<template>
  <Layout>
    <!-- end page title -->
    <div class="row">
      <div class="col-xl-3 col-md-6" v-for="stat of statData" :key="stat.icon">
        <Stat
          :title="stat.title"
          :image="stat.image"
          :subText="stat.subText"
          :value="stat.value"
          :color="stat.color"
          :popoverText="stat.popoverText"
        />
      </div>
    </div>
    <!-- end row -->

    <b-row>
      <b-col xl="12">
        <b-card title="Inventory data">
          <i
            class="ti-help-alt"
            style="cursor: pointer;font-size: 20px;position: absolute;top: 20px;left: 140px;"
            v-b-popover.hover.bottom="popoverInfo"
          ></i>
          <!-- <b-card title="Inventory data (Unit:Thousand)"> -->
          <b-row>
            <b-col lg="8">
              <!-- <Chart /> -->
              <apexchart
                type="area"
                height="320"
                ref="selectInactiveIcon"
                :options="selectInactiveIcon.chartOptions"
                :series="selectInactiveIcon.series"
              ></apexchart>
            </b-col>
            <b-col lg="4">
              <b-row>
                <b-col md="4">
                  <div class="text-center">
                    <p class="text-muted mb-4">Unused Cartridges</p>
                    <h3>{{ cartridges.noActivated }}</h3>
                    <!-- Radial bar chart  -->
                    <apexchart
                      class="apex-charts"
                      height="150"
                      type="radialBar"
                      :series="[percent.noActivated]"
                      :options="radialBarChart.chartOptions"
                    ></apexchart>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="text-center">
                    <p class="text-muted mb-4">Activated Cartridges</p>
                    <h3>{{ cartridges.activated }}</h3>
                    <apexchart
                      class="apex-charts"
                      height="150"
                      type="radialBar"
                      :series="[percent.activated]"
                      :options="radialBarChart.chartOptions"
                    ></apexchart>
                  </div>
                </b-col>
                <b-col md="4">
                  <div class="text-center">
                    <p class="text-muted mb-4">Depleted Cartridges</p>
                    <h3>{{ cartridges.invalid }}</h3>
                    <!-- Radial bar chart  -->
                    <apexchart
                      class="apex-charts"
                      height="150"
                      type="radialBar"
                      :series="[percent.invalid]"
                      :options="radialBarChart.chartOptions"
                    ></apexchart>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- end row -->

    <b-row>
      <b-col xl="8">
        <!-- 最近活跃用户 -->
        <b-card title="Latest Active Users">
          <!-- <Transaction :transactions="transactions" /> -->

          <b-table
            show-empty
            empty-text="no records"
            head-variant="light"
            :striped="true"
            :items="user_provider"
            :fields="activeUsersFields"
            :busy="user_isBusy"
          >
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>

            <template v-slot:cell(nickName)="data">
              <img
                v-if="data.item.picture"
                :src="`${data.item.picture}`"
                alt
                class="avatar-xs rounded-circle mr-2"
              />
              <img
                v-else
                src="../../assets/face.jpg"
                alt
                class="avatar-xs rounded-circle mr-2"
              />
              {{ data.item.nickName }}
            </template>

            <template v-slot:cell(status)="data">
              <span>active</span>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <b-col xl="4">
        <b-row>
          <b-col md="12">
            <b-card title="Product Ranking">
              <b-table
                show-empty
                empty-text="no records"
                id="flavor-table"
                head-variant="light"
                :striped="true"
                :items="flavor_provider"
                :fields="fields"
                :busy="flavor_isBusy"
              >
                <template v-slot:table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(index)="data">
                  <span
                    class="badge"
                    :class="{
                      'badge-danger': data.index === 0,
                      'badge-warning': data.index === 1,
                      'badge-success': data.index === 2,
                    }"
                    >{{ data.index + 1 }}</span
                  >
                </template>
              </b-table>
            </b-card>
          </b-col>

          <b-col md="12">
            <b-card title="User Feedback">
              <div v-for="item in feedback" :key="item.index">
                <p class="text-muted" style="margin-bottom: 0;">
                  "{{ item.content }}"
                </p>
                <!-- <div class="float-right mt-2">
                  <a href="#" class="text-primary">
                    <i class="mdi mdi-arrow-right h5"></i>
                  </a>
                </div> -->
                <h6 class="mb-0">
                  <img
                    v-if="item.picture"
                    :src="item.picture"
                    alt
                    class="avatar-xs rounded-circle mr-2"
                  />
                  {{ item.nickName }}
                  <span style="margin-left: 20px;">{{
                    getLocalTime(item.createTime)
                  }}</span>
                </h6>
                <br />
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- end row -->
  </Layout>
</template>
<script>
import Layout from "../layouts/main";

import Stat from "@/components/widgets/widget-stat";
import Chart from "@/components/widgets/chart";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

import {
  sparklineChartData,
  salesDonutChart,
  radialBarChart,
  selectInactiveIcon,
} from "./data";

export default {
  components: {
    Layout,
    Stat,
    Chart,
  },
  data() {
    return {
      flavor_isBusy: false,
      user_isBusy: false,
      sparklineChartData: sparklineChartData,
      salesDonutChart: salesDonutChart,
      radialBarChart: radialBarChart,
      selectInactiveIcon: selectInactiveIcon,
      // 口味
      flavorData: [],
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "flavor", label: "Flavor", class: "text-center" },
        { key: "flavorTotal", label: "Flavor Total", class: "text-center" },
      ],
      activeUsersFields: [
        { key: "index", label: "#" },
        // { key: "id", label: "UserId"},
        { key: "nickName", label: "Nickname" },
        {
          key: "time",
          label: "Date",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
        },
        { key: "duration", label: "Amount(s)" },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          // formatter: value => {
          //   return value == 1 ? "active" : "inactive";
          // }
        },
      ],
      statData: [
        {
          title: "Active Carts",
          image: require("@/assets/images/services-icon/01.png"),
          value: 0,
          subText: 0,
          color: "success",
          popoverText:
            "When the user first time use the cartridge,change the status to active. The percent is statistics by Yesterday VS the day before Yesterday.",
        },
        {
          title: "Active User",
          image: require("@/assets/images/services-icon/02.png"),
          value: 0,
          subText: 0,
          color: "danger",
          popoverText:
            "The users have used devices.The percent is statistics by Yesterday VS the day before Yesterday.",
        },
        {
          title: "Used up",
          image: require("@/assets/images/services-icon/03.png"),
          value: 0,
          subText: 0,
          color: "info",
          popoverText:
            "Consumption statistics show that 75% of users have used up and no usage records are generated within 14 days, which are marked as exhausted.The percent is statistics by Yesterday VS the day before Yesterday.",
        },
        {
          title: "Total Carts",
          image: require("@/assets/images/services-icon/04.png"),
          value: 0,
          subText: 0,
          color: "warning",
          popoverText:
            "The total of cartridges produced, including  used up, to be activated, and expired.The percent is statistics by Yesterday VS the day before Yesterday.",
        },
      ],
      feedback: [],
      cartridges: {},
      percent: {
        activated: "",
        noActivated: "",
        invalid: "",
      },
      popoverInfo:
        "Statistics of the total number of cartridges that have been produced and not used by users.",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    getLocalTime(value) {
      return timeZoneConversion.getLocalTime(value);
    },
    user_provider() {
      this.user_isBusy = true;
      return this.$api.Statistics.user({ pageNum: 1, pageSize: 10 }).then(
        (res) => {
          this.user_isBusy = false;
          if (res.success) {
            if (res.data.length > 5) {
              return res.data.slice(0, 5);
            } else {
              return res.data;
            }
          } else {
            if(res.message){
              if(res.message){
              this.$bvToast.toast(res.message);
            }
            }
            return [];
          }
        }
      );
    },
    flavor_provider() {
      this.flavor_isBusy = true;
      return this.$api.Statistics.flavor({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        this.flavor_isBusy = false;
        if (res.success) {
          return res.data;
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          return [];
        }
      });
    },

    init() {
      this.$api.systemManage.findUserMenu().then((res) => {
        if (res.success && res.data) {
          this.$store.commit("setMenu", res.data);
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
      this.$api.Statistics.selectUserAndSmokeGrowthRate({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          this.statData[0].value = res.data.todayActivated;
          this.statData[1].value = res.data.todayActive;
          this.statData[2].value = res.data.todayConsume;
          this.statData[3].value = res.data.totalCartridges;
          this.statData[0].subText = res.data.activationGrowth;
          this.statData[1].subText = res.data.activeGrowth;
          this.statData[2].subText = res.data.consumeGrowth;
          this.statData[3].subText = res.data.cigaretteGrowth;
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
      this.$api.Statistics.selectNewFeedback({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          this.feedback = res.data;
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
      this.$api.Statistics.smokeBombStatistics({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          this.cartridges = res.data;
          this.percent.activated = (
            (this.cartridges.activated / this.cartridges.totalCartridges) *
            100
          ).toFixed(2);
          this.percent.noActivated = (
            (this.cartridges.noActivated / this.cartridges.totalCartridges) *
            100
          ).toFixed(2);
          this.percent.invalid = (
            (this.cartridges.invalid / this.cartridges.totalCartridges) *
            100
          ).toFixed(2);
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
      this.$api.Statistics.selectInactiveIcon({
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          this.selectInactiveIcon.chartOptions.xaxis.categories = [];
          this.selectInactiveIcon.series[0].data = [];
          res.data.forEach((item) => {
            this.selectInactiveIcon.chartOptions.xaxis.categories.push(
              item.timeGroup
            );
            this.selectInactiveIcon.series[0].data.push(item.count);
          });
          this.$refs.selectInactiveIcon.refresh();
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
      this.$api.systemManage.selectAllPlatformButton().then((res) => {
        if (res.success && res.data) {
          sessionStorage["buttenpremissions"] = JSON.stringify(res.data);
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.requiredRow {
  &:before {
    content: "*";
    color: red;
    padding-right: 2px;
  }
}
</style>

<script>
/**
 * Chartist line-area chart
 */
export default {
  data() {
    return {
      data: {
        labels: [1, 2, 3, 4, 5, 6, 7, 8,9,10,11,12],
        series: [[2, 4, 6, 5, 7, 8, 6, 7,9,8,10,7]]
      },
      options: {
        low: 0,
        showArea: true,
        height: 320
      }
    };
  }
};
</script>
<template>
  <chartist dir="ltr" ratio="ct-chart" :data="data" :options="options" type="Line"></chartist>
</template>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Steps :Steps="registSpets" :SetData="SetData" />
    <b-card>
      <b-col lg="12">
        <p class="title" style="font-size:16px;font-weight: bolder;">
          <span
            style="display: inline-block;
            width: 10px;
            height: 20px;
            vertical-align: text-top;
            background-color: #626ed4;"
          ></span>
          Batch Info
        </p>
      </b-col>
      <b-tabs pills style="margin:20px 0;">
        <b-tab title="Batch Details" active
          ><b-container>
            <b-row cols="3">
              <b-col>
                <label class="col-form-label">Batch Name：</label>
                <span class="p-3">{{ batchDetailsVo.batchName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Batch ID：</label>
                <span class="p-3">{{ batchDetailsVo.number }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Cartridge Model：</label>
                <span class="p-3">{{ batchDetailsVo.smokeModel }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Flavor：</label>
                <span class="p-3">{{ batchDetailsVo.modelName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Content Capacity:</label>
                <span class="p-3">{{ batchDetailsVo.capacity }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Max Frequency:</label>
                <span class="p-3">{{ batchDetailsVo.maxFrequency }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Batch Quantity:</label>
                <span class="p-3">{{ batchDetailsVo.batchQuantity }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Production Factory:</label>
                <span class="p-3">{{ batchDetailsVo.manufacturerName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Remarks:</label>
                <span class="p-3">{{ batchDetailsVo.remarks }}</span>
              </b-col>
            </b-row>
          </b-container></b-tab
        >
        <b-tab title="Content Details"
          ><b-container>
            <b-row cols="3">
              <b-col>
                <label class="col-form-label">Content Type: </label>
                <span class="p-3">{{ smokeOilDetailsVo.type }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Brand：</label>
                <span class="p-3">{{ smokeOilDetailsVo.brandName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Flavor：</label>
                <span class="p-3">{{ smokeOilDetailsVo.modelName }}</span>
              </b-col>
            </b-row>
          </b-container></b-tab
        >
        <b-tab title="Cartridge Details"
          ><b-container>
            <b-row cols="3">
              <b-col>
                <label class="col-form-label">Model Name: </label>
                <span class="p-3">{{ testSmokeBombModelVo.modelName }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Heating Wire Material: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.heatingWireMaterial
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Heating Wire Size: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.heatingWireSize
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Heating Wire Porosity: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.heatingWirePorosity
                }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Oil Hole: </label>
                <span class="p-3">{{ testSmokeBombModelVo.oilInlet }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Resistance: </label>
                <span class="p-3">{{ testSmokeBombModelVo.resistance }}</span>
              </b-col>
              <b-col>
                <label class="col-form-label">Cotton coil: </label>
                <span class="p-3">{{
                  testSmokeBombModelVo.cottonBag == 0
                    ? "Yes"
                    : testSmokeBombModelVo.cottonBag == 1
                    ? "No"
                    : ""
                }}</span>
              </b-col>
            </b-row>
          </b-container></b-tab
        >
        <b-tab title="Heating&Preheating Mode">
          <b-container>
            <span style="font-weight: 700;">Heating Mode</span>
            <b-table
              id="heating-table"
              head-variant="light"
              show-empty
              empty-text="no records"
              ref="table"
              :busy="heatingIsBusy"
              :striped="true"
              :items="heatingModeDetailsVo"
              :fields="heatingModefields"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
            <span style="font-weight: 700;">Preheating Mode</span>
            <b-table
              id="preheating-table"
              head-variant="light"
              show-empty
              empty-text="no records"
              ref="table"
              :busy="preheatingIsBusy"
              :striped="true"
              :items="warmUpModeDetailsVo"
              :fields="warmUpModefields"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-container></b-tab
        >
      </b-tabs>
    </b-card>
    <b-card>
      <b-col lg="12">
        <p class="title" style="font-size:16px;font-weight: bolder;">
          <span
            style="display: inline-block;
            width: 10px;
            height: 20px;
            vertical-align: text-top;
            background-color: #626ed4;"
          ></span>
          COA Info
        </p>
      </b-col>
      <b-container>
        <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
          <!-- 样本ID -->
          <b-form-group
            label="Sample ID:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="sample-id"
            label-class="requiredRow"
          >
            <b-form-input
              id="sample-id"
              v-model="form.sampleNumber"
              required
              oninvalid="setCustomValidity('Please enter');"
              oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
              placeholder="Please enter"
            ></b-form-input>
          </b-form-group>

          <!-- 样本名称 -->
          <b-form-group
            label="Sample Name:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="sample-name"
          >
            <b-form-input
              id="sample-name"
              v-model="form.sampleName"
              oninput="if(value.length > 50)value = value.slice(0, 50)"
              placeholder="Please enter"
            ></b-form-input>
          </b-form-group>

          <!-- 样本批次ID -->
          <b-form-group
            label="Sample Batch ID:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="sample-batch"
            label-class="requiredRow"
          >
            <b-form-input
              id="sample-batch"
              v-model="form.sampleBatchNumber"
              required
              oninvalid="setCustomValidity('Please enter');"
              oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
              placeholder="Please enter"
            ></b-form-input>
          </b-form-group>

          <!-- Formula  烟油成分-->
          <b-form-group
            class="inner"
            label="Content Formula:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="cartridge-formula"
            label-class="requiredRow"
          >
            <div
              v-for="(data, index) in form.formula"
              :key="index"
              class="inner mb-3 row"
            >
              <div class="col-md-3 col-4">
                <b-form-select
                  id="cartridge-type"
                  v-model="data.id"
                  :options="types.cartridgeComponentData"
                  value-field="id"
                  text-field="component"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </div>

              <div class="col-md-3 col-4">
                <b-form-input
                  class="inner form-control"
                  v-model="data.proportion"
                  required
                  type="number"
                  min="0"
                  max="100"
                  step="0.0001"
                  placeholder="Please enter proportion(%)"
                  oninvalid="setCustomValidity('Please enter')"
                  oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');"
                ></b-form-input>
              </div>

              <div class="col-md-2 col-4" style="padding-top: 7px;">
                <b-form-checkbox
                  v-model="data.status"
                  value="0"
                  unchecked-value="1"
                  :disabled="statusDisabled && data.status == 1"
                  @input="change_status($event, data, index)"
                  >Main</b-form-checkbox
                >
              </div>
              <div class="col-md-2 col-4" style="padding-top: 7px;">
                <b-form-checkbox
                  v-model="data.display"
                  value="0"
                  unchecked-value="1"
                  :disabled="displayDisabled && data.display == 1"
                  @input="change_display($event, data, index)"
                  >Show at ModelX</b-form-checkbox
                >
              </div>
              <div class="col-md-2 col-2" v-if="form.formula.length > 1">
                <b-button variant="danger" @click="deleteFormula(index)"
                  >Delete</b-button
                >
              </div>
            </div>
            <b-button variant="primary" @click="addFormula">Add</b-button>
          </b-form-group>

          <!-- 认证类型 -->
          <b-form-group
            class="inner"
            label="Test Items:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="certification"
            label-class="requiredRow"
          >
            <div
              v-for="(ele, e) in form.addReport"
              :key="e"
              class="inner mb-3 row"
            >
              <div class="col-md-3 col-4">
                <b-form-select
                  id="certification"
                  v-model="ele.id"
                  :options="types.selectReportParameters"
                  value-field="id"
                  text-field="reportName"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </div>

              <div class="col-md-3 col-4">
                <b-form-select
                  v-model="ele.passOrNot"
                  :options="types.passOrNots"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </div>

              <div class="col-md-3 col-2" style="padding-top: 7px;">
                <span
                  @click="modalShow = !modalShow"
                  style="cursor:pointer;color:Rgba( 68, 82, 204);"
                  >Create Custom Options</span
                >
              </div>
              <div class="col-md-2 col-2" v-if="form.addReport.length > 1">
                <b-button variant="danger" @click="deleteCertification(e)"
                  >Delete</b-button
                >
              </div>
            </div>
            <b-button variant="primary" @click="addCertification">Add</b-button>
          </b-form-group>
          <!-- 溶剂 -->
          <b-form-group
            class="inner"
            label="Solvent:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="Solvent"
            label-class="requiredRow"
          >
            <div
              v-for="(item, i) in form.solvent"
              :key="i"
              class="inner mb-3 row"
            >
              <div class="col-md-4 col-4">
                <b-form-select
                  id="cartridge-type"
                  v-model="item.id"
                  :options="types.SolventEncoding"
                  value-field="id"
                  text-field="solventName"
                  required
                  oninvalid="setCustomValidity('Please select');"
                  oninput="setCustomValidity('')"
                ></b-form-select>
              </div>

              <div class="col-md-4 col-4">
                <b-form-input
                  class="inner form-control"
                  v-model="item.proportion"
                  required
                  type="number"
                  min="0"
                  max="100"
                  step="0.0001"
                  placeholder="Please Enter proportion(%)"
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/, '$1$2.$3');"
                ></b-form-input>
              </div>

              <div class="col-md-2 col-2" v-if="form.solvent.length > 1">
                <b-button variant="danger" @click="deleteSolvent(i)"
                  >Delete</b-button
                >
              </div>
            </div>
            <b-button variant="primary" @click="addSolvent">Add</b-button>
          </b-form-group>

          <!--Report Type  测试报告类型 -->
          <b-form-group
            label="Report Type:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="report"
            label-class="requiredRow"
          >
            <b-form-radio-group
              v-model="form.reportType"
              id="reportType"
              class="pt-2"
              required
            >
              <b-form-radio value = 1 @change="changeLink">Link</b-form-radio>
              <b-form-radio value = 2 @change="changeEnclosure"
                >Enclosure</b-form-radio
              >
            </b-form-radio-group>
          </b-form-group>

          <!--Report Link 链接报告 -->
          <b-form-group
            v-if="showReportLink"
            label="Report Link:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="reportLink"
            label-class="requiredRow"
          >
            <b-form-input
              id="reportLink"
              v-model="form.reportLink"
              required
              oninvalid="setCustomValidity('Please enter');"
              oninput="setCustomValidity('')"
              placeholder="Enter Report Link"
            ></b-form-input>
          </b-form-group>

          <!-- 报告文件 -->
          <b-form-group
            v-if="showReportEnclosure"
            label="Report File:"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="reportEnclosure"
            label-class="requiredRow"
          >
            <input
              type="file"
              class="filestyle"
              data-input="false"
              id="filestyle-1"
              style="position: absolute; clip: rect(0px, 0px, 0px, 0px);"
              tabindex="-1"
              @change="upload($event)"
              required
              oninvalid="setCustomValidity('Please upload');"
              oninput="setCustomValidity('');"
            />
            <span class="group-span-filestyle" tabindex="0" b-col="8">
              <label
                for="filestyle-1"
                style="margin-bottom: 0;"
                class="btn btn-secondary"
              >
                <span class="buttonText">Upload PDF file</span>
              </label>
              <span v-if="fileName">{{ fileName }}</span>
              <span v-else>only support PDF file</span>
            </span>
          </b-form-group>

          <!-- 备注 -->
          <b-form-group
            label="Notes"
            label-cols-lg="2"
            label-align-lg="right"
            label-for="remarks"
          >
            <b-form-textarea
              id="remarks"
              v-model="form.remarks"
              placeholder="Enter Notes"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>

          <b-form-group
            label-cols-lg="4"
            label-align-lg="right"
            style="margin-top: 50px;"
          >
            <b-button type="submit" variant="primary">Confirm</b-button>
            <b-button type="reset" style="margin-left: 30px;">Cancel</b-button>
          </b-form-group>
        </b-form>
      </b-container>
    </b-card>
    <!-- 添加认证类型 -->
    <b-modal
      ref="modal"
      v-model="modalShow"
      title="Create Custom Options"
      @hidden="resetModal"
      hide-footer
    >
      <form ref="modelform" @submit="onSubmitModal" @reset="onResetModal">
        <b-form-group
          label="Certification Type:"
          label-cols-lg="4"
          label-align-lg="right"
          label-for="certification-type"
          label-class="requiredRow"
        >
          <b-form-input
            id="certification-type"
            v-model="reportName"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
        </b-form-group>
      </form>
    </b-modal>
  </Layout>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Steps from "./cartridge-batch-step";
import { onReady, upload_img } from "@/api/upload";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    Steps,
  },
  data() {
    return {
      title: "Perfect Flavor Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Perfect Flavor Details",
          active: true,
        },
      ],
      registSpets: 2,
      SetData: [
        "Create Batch",
        "In Production",
        "Perfect Flavor Details",
        "Has been completed",
      ],
      isComplete: false,
      infoId: window.location.search,
      batchDetailsVo: {},
      smokeOilDetailsVo: {},
      testSmokeBombModelVo: {},
      heatingModeDetailsVo: [],
      warmUpModeDetailsVo: [],
      batchNumberCoaDetailsVo: {},
      produceStatus: "",
      heatingModefields: [
        { key: "modeName", label: "Mode Name", class: "text-center" },
        { key: "heatingType", label: "Mode Type", class: "text-center" },
        {
          key: "temperature",
          label: "Heating Temperature(℉)",
          class: "text-center",
        },
      ],
      warmUpModefields: [
        { key: "warmUpName", label: "Mode Name", class: "text-center" },
        {
          key: "warmUpTimes",
          label: "Preheating Time(S)",
          class: "text-center",
        },
        {
          key: "temperature",
          label: "Preheating Temperature(℉)",
          class: "text-center",
        },
      ],
      heatingIsBusy: false,
      preheatingIsBusy: false,
      form: {
        id: "",
        sampleNumber: "",
        sampleName: "",
        sampleBatchNumber: "",
        formula: [
          {
            id: "",
            component: "",
            proportion: "",
            status: 1,
            display: 1,
            encoding: "",
          },
        ],
        addReport: [
          {
            id: "",
            passOrNot: "",
            reportName: "",
          },
        ],
        solvent: [
          {
            encoding: "",
            id: "",
            proportion: "",
            solventName: "",
          },
        ],
        reportType: "",
        reportLink: "",
        remarks: "",
      },
      fileName: "",
      showReportLink: false,
      showReportEnclosure: false,
      types: {
        cartridgeComponentData: [{ component: "Please select", id: "" }],
        SolventEncoding: [{ solventName: "Please select", id: "" }],
        selectReportParameters: [{ reportName: "Please select", id: "" }],
        passOrNots: [
          { text: "Please select", value: "" },
          { text: "Pass", value: 0 },
          { text: "Not pass", value: 1 },
        ],
      },
      modalShow: false,
      reportName: "",
      statusDisabled: false,
      displayDisabled: false,
      modelXs: []
    };
  },
  beforeCreate() {
    this.infoId = window.location.search;
  },
  created() {
    if (this.infoId) {
      let id = this.infoId.split("=")[1];
      this.form.id = Number(id);
      this.getBatchInfo(id);
    }
    this.init();
  },
  // watch: {
  //   produceStatus: function(val) {
  //     this.registSpets = val;
  //   },
  // },
  methods: {
    init() {
      this.$api.Dropdown.componentEncodingList().then((res) => {
        if (res.success) {
          this.types.cartridgeComponentData = this.types.cartridgeComponentData.concat(
            res.data
          );
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });

      this.$api.Dropdown.selectAllSolventEncoding().then((res) => {
        if (res.success) {
          this.types.SolventEncoding = this.types.SolventEncoding.concat(
            res.data
          );
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });

      this.$api.Dropdown.selectReportParameters().then((res) => {
        if (res.success) {
          this.types.selectReportParameters = this.types.selectReportParameters.concat(
            res.data
          );
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
    },
    getBatchInfo(id) {
      this.$api.CartridgeBatch.selectReportInformation({ id: id }).then(
        (res) => {
          if (res.success) {
            this.produceStatus = res.data.produceStatus;
            this.batchDetailsVo = res.data.batchDetailsVo;
            this.smokeOilDetailsVo = res.data.smokeOilDetailsVo;
            this.testSmokeBombModelVo = res.data.testSmokeBombModelVo;
            this.heatingModeDetailsVo = res.data.heatingModeDetailsVo;
            this.warmUpModeDetailsVo = res.data.warmUpModeDetailsVo;
            if (res.data.produceStatus == 3) {
              this.form = res.data.batchNumberCoaDetailsVo;
              if(this.form.reportType==1){
                this.showReportLink = true;
                this.showReportEnclosure = false;
              }else if(this.form.reportType==2){
                this.showReportLink = false;
                this.showReportEnclosure = true;
                if (this.form.reportLink) {
                  let arr = this.form.reportLink.split("/");
                  this.fileName = arr[arr.length - 1];
                }
              }
            }
            this.batchNumberCoaDetailsVo = res.data.batchNumberCoaDetailsVo;
            if (!this.batchNumberCoaDetailsVo.addReport) {
              this.batchNumberCoaDetailsVo.addReport = [
                {
                  id: "",
                  passOrNot: "",
                  reportName: "",
                },
              ];
            }
            if (!this.batchNumberCoaDetailsVo.solvent) {
              this.batchNumberCoaDetailsVo.solvent = [
                {
                  encoding: "",
                  id: "",
                  proportion: "",
                  solventName: "",
                },
              ];
            }
          } else {
            if(res.message){
              this.$bvToast.toast(res.message);
            }
          }
        }
      );
    },
    // 是否主要成分
    change_status(e, data, index) {
      let s = 0;
      this.form.formula.forEach((item) => {
        if (item.status == 0) {
          s = s + 1;
        }
      });
      if (s >= 5) {
        this.$bvToast.toast("No more than 5 main ingredients");
        this.statusDisabled = true;
      } else {
        this.statusDisabled = false;
      }
    },
    // 是否显示在ModelX
    change_display(e, data, index) {
      this.modelXs = [];
      this.form.formula.forEach((item) => {
        if (item.display == 0) {
          this.modelXs.push(item);
        }
      });
      if (this.modelXs.length >= 3) {
        this.$bvToast.toast("The number of displays in ModelX cannot exceed 3");
        this.displayDisabled = true;
      } else {
        this.displayDisabled = false;
      }
      
    },
    // 添加成分
    addFormula() {
      if (this.form.formula.length >= 10) {
        this.$bvToast.toast("formula.length must be  <10");
        return;
      } else {
        let d = {
          id: "",
          component: "",
          proportion: "",
          status: 1,
          display: 1,
          encoding: "",
        };
        this.form.formula.push(d);
      }
    },
    // 删除成分
    deleteFormula(index) {
      if (this.form.formula.length > 1) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.form.formula.splice(index, 1);
        }
      } else {
        this.$bvToast.toast(">1");
      }
    },
    // 添加认证类型
    addCertification() {
      if (this.form.addReport.length >= 10) {
        this.$bvToast.toast("addReport.length must be  <=10");
        return;
      } else {
        let d = {
          id: "",
          passOrNot: "",
          reportName: "",
        };
        this.form.addReport.push(d);
      }
    },
    // 删除认证类型
    deleteCertification(index) {
      if (this.form.addReport.length > 1) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.form.addReport.splice(index, 1);
        }
      } else {
        this.$bvToast.toast(">1");
      }
    },
    // 添加溶剂
    addSolvent() {
      if (this.form.solvent.length >= 10) {
        this.$bvToast.toast("solvent.length must be  <=10");
        return;
      } else {
        let d = {
          encoding: "",
          id: "",
          proportion: "",
          solventName: "",
        };
        this.form.solvent.push(d);
      }
    },
    // 删除溶剂
    deleteSolvent(index) {
      if (this.form.solvent.length > 1) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.form.solvent.splice(index, 1);
        }
      } else {
        this.$bvToast.toast(">1");
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      let a = 0;
      this.modelXs = [];
      this.form.formula.forEach((item) => {
        if (item.display == 0) {
          this.modelXs.push(item);
        }
      });
      this.modelXs.forEach((ele) => {
        if (ele.status == 0) {
          a = a + 1;
        }
      });
      if (this.modelXs.length && a == 0) {
        this.$bvToast.toast(
          "The display in the Model X must select a major component"
        );
        return;
      }
      this.$api.CartridgeBatch.perfectBatchInformation(this.form).then(
        (res) => {
          if (res.success) {
            if(res.message){
              this.$bvToast.toast(res.message);
            }
            this.$router.push("CartridgeBatchInfo?id=" + this.form.id);
          } else {
            if(res.message){
              this.$bvToast.toast(res.message);
            }
          }
        }
      );
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    // 测试报告
    changeLink() {
      this.showReportLink = true;
      this.showReportEnclosure = false;
      this.form.reportLink = "";
    },
    changeEnclosure() {
      this.showReportLink = false;
      this.showReportEnclosure = true;
      this.form.reportLink = "";
    },
    upload(e) {
      let file = e.target.files[0];
      if (/pdf/.test(file.type)) {
        // this.fileName = file.name;
        upload_img(file).then((res) => {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          if (res.success) {
            this.form.reportLink = res.data;
            let arr = res.data.split("/")
            this.fileName = arr[arr.length-1]
            console.log("uploading", this.form.reportLink);
          }
        });
      } else {
        // e.target.files = [];
        this.$bvToast.toast("only support PDF file");
      }
    },
    resetModal() {
      this.reportName = "";
    },
    onResetModal(evt) {
      evt.preventDefault();
      this.reportName = "";
      this.modalShow = !this.modalShow;
    },
    onSubmitModal(evt) {
      evt.preventDefault();
      this.$api.CartridgeBatch.addReportParameters({
        reportName: this.reportName,
        // supplierId: localStorage.supplierId,
      }).then((res) => {
        if (res.success) {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          this.modalShow = !this.modalShow;
          this.types.selectReportParameters = [
            { reportName: "Please select", id: "" },
          ];
          this.$api.Dropdown.selectReportParameters().then((res) => {
            if (res.success) {
              this.types.selectReportParameters = this.types.selectReportParameters.concat(
                res.data
              );
            } else {
              if(res.message){
              this.$bvToast.toast(res.message);
            }
            }
          });
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <b-card> -->
    <Steps :Steps="registSpets" :SetData="SetData" />
    <!-- </b-card> -->
    <b-card>
      <b-col lg="12">
        <p class="title" style="font-size: 16px; font-weight: bolder">
          <span
            style="
              display: inline-block;
              width: 10px;
              height: 20px;
              vertical-align: text-top;
              background-color: #626ed4;
            "
          ></span>
          Batch Info
        </p>
      </b-col>
      <!-- <form-wizard
        @on-complete="onComplete"
        @on-change="handleChange"
        :start-index.sync="activeIndex"
        color="#626ed4"
        error-color="#a94442"
      > -->
      <!-- <tab-content
          icon="mdi mdi-account-circle"
          :before-change="validateFirstTab"
        > -->
      <b-row>
        <b-col lg="12">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- Batch Name 批次名称 -->
            <b-form-group
              label="Batch Name:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="batch-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-name"
                v-model="form.batchName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- Batch Number 批次号 -->
            <!-- <b-form-group
              label="Batch Number:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="batch-number"
            >
              <b-form-input
                id="batch-number"
                v-model="form.batchNumber"
                placeholder="Batch Number"
                disabled
              >
              </b-form-input>
            </b-form-group> -->

            <!-- 烟弹型号 -->
            <b-form-group
              label="Cartridge Model:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="cartridge-model"
                v-model="form.testSmokeBombModeId"
                :options="types.selectAllTestSmokeBombModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
                @change="changeModelId(form.testSmokeBombModeId)"
              ></b-form-select>
            </b-form-group>

            <!-- 烟油型号 -->
            <b-form-group
              label="Flavor:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="content-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="content-model"
                v-model="form.smokeBombModelId"
                :options="types.ModelBySupplierId"
                @change="
                  changeModel(form.smokeBombModelId, types.ModelBySupplierId)
                "
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <!-- 烟弹口味 -->
            <b-form-group
              v-if="types.flavorOptions.length"
              :label="flavorLabel"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-taste"
              label-class="requiredRow"
            >
              <b-form-radio-group
                class="pt-2"
                v-model="form.flavorId"
                :options="types.flavorOptions"
                required
              ></b-form-radio-group>
            </b-form-group>

            <!-- 烟油容量 -->
            <b-form-group
              label="Content Capacity:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="capacity"
              label-class="requiredRow"
            >
              <b-form-input
                id="capacity"
                v-model="form.capacity"
                type="number"
                min="0"
                step="0.01"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 最大吸入上限 -->
            <b-form-group
              label="Max Frequency:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="maxFrequency"
              label-class="requiredRow"
            >
              <b-form-input
                id="maxFrequency"
                v-model="form.maxFrequency"
                type="number"
                min="0"
                step="0.01"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- Batch Quantity 批次数量 -->
            <b-form-group
              label="Batch Quantity:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="cartridge-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="batch-quantity"
                v-model="form.batchQuantity"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- Manufacturer Name 生产厂家 -->
            <b-form-group
              label="Production Factory:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="manufacturer"
            >
              <b-form-select
                id="manufacturer"
                v-model="form.manufacturerEncodingId"
                :options="types.manufacturerEncodingList"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Remarks:"
              label-cols-lg="2"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Cancel</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>

      <!-- end row -->
      <!-- </tab-content> -->
      <!-- </form-wizard> -->
    </b-card>
  </Layout>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Steps from "./cartridge-batch-step";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    Steps,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
  },
  beforeCreate() {
    // this.init();
  },
  mounted() {
    this.init();
  },
  data() {
    return {
      title: "Cartridge Batch Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Cartridge Batch Create",
          active: true,
        },
      ],
      registSpets: 0,
      SetData: ["Create Batch", "Import Production Details", "Complete"],
      types: {
        flavorOptions: [],
        ModelBySupplierId: [{ text: "Please select", value: "", flavor: "" }],
        manufacturerEncodingList: [{ name: "Please select", id: "" }],
        selectAllTestSmokeBombModel: [{ modelName: "Please select", id: "" }],
      },
      form: {
        batchName: "",
        smokeBombModelId: "",
        flavorId: "",
        testSmokeBombModeId: "",
        capacity: "",
        maxFrequency: "",
        batchQuantity: 100,
        manufacturerEncodingId: "",
        remarks: "",
      },
      flavorLabel: "Flavor:",
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.manufacturerEncodingList().then((res) => {
        if (res.success && res.data) {
          this.types.manufacturerEncodingList =
            this.types.manufacturerEncodingList.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.selectAllTestSmokeBombModel().then((res) => {
        if (res.success && res.data) {
          this.types.selectAllTestSmokeBombModel =
            this.types.selectAllTestSmokeBombModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });

      // this.$api.Dropdown.selectModelBySupplierId({
      //    //supplierId: localStorage.supplierId,
      // }).then((res) => {
      //   if (res.success) {
      //     // this.types.ModelBySupplierId = res.data;
      //     for (let i = 0; i < res.data.length; i++) {
      //       this.types.ModelBySupplierId.push({
      //         text: res.data[i].modelName,
      //         value: res.data[i].id,
      //         flavor: res.data[i].flavor,
      //         type: res.data[i].type,
      //       });
      //     }
      //   } else {
      //     if(res.message){
      //         this.$bvToast.toast(res.message);
      //       }
      //   }
      // });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.CartridgeBatch.add(this.form).then((res) => {
        if (res.success) {
          this.$router.go(-1);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    changeModel(a, b) {
      this.types.flavorOptions = [];
      if (a) {
        b.forEach((item) => {
          if (item.value == a) {
            if (item.flavor) {
              let o = JSON.parse(item.flavor);
              for (let i = 0; i < o.length; i++) {
                this.types.flavorOptions.push({
                  text: o[i].flavorName,
                  value: o[i].id,
                });
              }
            }
            let u = JSON.parse(item.type);
            if (u.id == 1) {
              this.flavorLabel = "Flavor:";
            } else if (u.id == 4) {
              this.flavorLabel = "Graphic Name:";
            } else {
              this.flavorLabel = "Flavor:";
            }
          }
        });
      }
    },
    changeModelId(modelId) {
      if (modelId) {
        this.$api.Dropdown.selectCartridgeModelById({
          id: modelId,
        }).then((res) => {
          if (res.success) {
            // this.types.ModelBySupplierId = res.data;
            this.types.ModelBySupplierId = [];
            this.types.ModelBySupplierId.push({
              text: "Please select",
              value: "",
              flavor: "",
            });
            for (let i = 0; i < res.data.length; i++) {
              this.types.ModelBySupplierId.push({
                text: res.data[i].modelName,
                value: res.data[i].id,
                flavor: res.data[i].flavor,
                type: res.data[i].type,
              });
            }
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      }
    },
  },
};
</script>

import store from '@/state/store'

export default [
	{
		path: '/',
		meta: {
			authRequired: true,
		},
		name: 'home',
		component: () => import('./views/home'),
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('./views/account/login'),
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters['auth/loggedIn']) {
					// Redirect to the home page instead
					next({
						name: 'home',
					})
				} else {
					// Continue to the login page
					next()
				}
			},
		},
	},
	{
		path: '/logout',
		name: 'logout',
		meta: {
			authRequired: false,
			beforeResolve(routeTo, routeFrom, next) {
				store.dispatch('auth/logOut')
				next({
					name: 'login',
				})
			},
		},
	},
	// 统计分析
	{
		path: '/consumptionReport/userAnalysis',
		name: 'userAnalysis',
		component: () => import('./views/consumption-report/user-analysis'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/consumptionReport/consumptionAnalysis',
		name: 'consumptionAnalysis',
		component: () =>
			import('./views/consumption-report/consumption-analysis'),
		meta: {
			authRequired: false,
		},
	},
	// 设备管理 route
	{
		path: '/deviceMgmt/deviceList',
		name: 'deviceList',
		component: () => import('./views/device-manage/device-list/device'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceListInfo',
		name: 'deviceListInfo',
		component: () =>
			import('./views/device-manage/device-list/device-info'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceListAdd',
		name: 'deviceListAdd',
		component: () => import('./views/device-manage/device-list/device-add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceListEdit',
		name: 'deviceListEdit',
		component: () =>
			import('./views/device-manage/device-list/device-edit'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeList',
		name: 'cartridgeList',
		component: () =>
			import('./views/device-manage/cartridge-list/cartridge'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeListInfo',
		name: 'cartridgeListInfo',
		component: () =>
			import('./views/device-manage/cartridge-list/cartridge-info'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeListAdd',
		name: 'cartridgeListAdd',
		component: () =>
			import('./views/device-manage/cartridge-list/cartridge-add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeListEdit',
		name: 'cartridgeListEdit',
		component: () =>
			import('./views/device-manage/cartridge-list/cartridge-edit'),
		meta: {
			authRequired: false,
		},
	},

	// 生产批次
	{
		path: '/productionMgmt/cartridgeBatch',
		name: 'CartridgeBatch',
		component: () =>
			import(
				'./views/production-manage/cartridge-batch/cartridge-batch.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/cartridgeBatchInfo',
		name: 'CartridgeBatchInfo',
		component: () =>
			import(
				'./views/production-manage/cartridge-batch/cartridge-batch-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/cartridgeBatchAdd',
		name: 'CartridgeBatchAdd',
		component: () =>
			import(
				'./views/production-manage/cartridge-batch/cartridge-batch-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/cartridgeBatchEdit',
		name: 'CartridgeBatchEdit',
		component: () =>
			import(
				'./views/production-manage/cartridge-batch/cartridge-batch-edit.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeImport',
		name: 'CartridgeImport',
		component: () =>
			import(
				'./views/production-manage/cartridge-batch/cartridge-batch-complete.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// {
	//   path: "/deviceMgmt/cartridgeImport",
	//   name: "CartridgeImport",
	//   component: () => import("./views/production-manage/cartridge-batch/cartridge-batch-complete.vue"),
	//   meta: {
	//     authRequired: false,
	//   },
	// },

	// 空烟弹生产订单
	{
		path: '/productionMgmt/emptyCartridgeOrders',
		name: 'emptyCartridgeOrders',
		component: () =>
			import(
				'./views/production-manage/empty-cartridge-production-orders/index.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/emptyCartridgeOrdersAdd',
		name: 'emptyCartridgeOrdersAdd',
		component: () =>
			import(
				'./views/production-manage/empty-cartridge-production-orders/add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/emptyCartridgeOrdersInfo',
		name: 'emptyCartridgeOrdersInfo',
		component: () =>
			import(
				'./views/production-manage/empty-cartridge-production-orders/info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//贴片生产订单
	{
		path: '/productionMgmt/patchOrders',
		name: 'patchOrders',
		component: () =>
			import(
				'./views/production-manage/patch-production-order/index.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/patchOrdersAdd',
		name: 'patchOrdersAdd',
		component: () =>
			import(
				'./views/production-manage/patch-production-order/add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/patchOrdersInfo',
		name: 'patchOrdersInfo',
		component: () =>
			import(
				'./views/production-manage/patch-production-order/info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//包装箱订单生产
	{
		path: '/productionMgmt/packingBoxOrders',
		name: 'packingBoxOrder',
		component: () =>
			import(
				'./views/production-manage/packing-box-order/index.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/packingBoxOrdersAdd',
		name: 'patchOrdersAdd',
		component: () =>
			import(
				'./views/production-manage/packing-box-order/add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/packingBoxOrdersInfo',
		name: 'patchOrdersInfo',
		component: () =>
			import(
				'./views/production-manage/packing-box-order/info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// 设备生产订单
	{
		path: '/productionMgmt/deviceOrders',
		name: 'deviceOrders',
		component: () =>
			import(
				'./views/production-manage/device-production-orders/index.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/deviceOrdersAdd',
		name: 'deviceOrdersAdd',
		component: () =>
			import(
				'./views/production-manage/device-production-orders/add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/deviceOrdersInfo',
		name: 'deviceOrdersInfo',
		component: () =>
			import(
				'./views/production-manage/device-production-orders/info.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/productionMgmt/flavor',
		name: 'flavor',
		component: () => import('./views/production-manage/flavor/flavor.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/flavorAdd',
		name: 'flavorAdd',
		component: () =>
			import('./views/production-manage/flavor/flavor-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/flavorInfo',
		name: 'flavorInfo',
		component: () =>
			import('./views/production-manage/flavor/flavor-info.vue'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/productionMgmt/flavorEdit',
		name: 'flavorEdit',
		component: () => import('./views/production-manage/flavor/flavor-add'),
		meta: {
			authRequired: false,
		},
	},
	// 膏类型
	{
		path: '/productionMgmt/waxType',
		name: 'waxType',
		component: () =>
			import('./views/production-manage/wax-type/wax-type.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxTypeAdd',
		name: 'waxTypeAdd',
		component: () =>
			import('./views/production-manage/wax-type/wax-type-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxTypeEdit',
		name: 'waxTypeEdit',
		component: () =>
			import('./views/production-manage/wax-type/wax-type-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 膏弹包装订单
	{
		path: '/productionMgmt/waxCartsPkg',
		name: 'waxCartsPkg',
		component: () =>
			import('./views/production-manage/wax-carts-pkg/wax-carts-pkg.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxCartsPkgAdd',
		name: 'waxCartsPkgAdd',
		component: () =>
			import(
				'./views/production-manage/wax-carts-pkg/wax-carts-pkg-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/waxCartsPkgInfo',
		name: 'waxCartsPkgInfo',
		component: () =>
			import(
				'./views/production-manage/wax-carts-pkg/wax-carts-pkg-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 烟弹型号
	{
		path: '/deviceMgmt/cartridgeModel',
		name: 'cartridgeModel',
		component: () =>
			import('./views/device-manage/cartridge-model/cartridge-model.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeModelAdd',
		name: 'cartridgeModelAdd',
		component: () =>
			import(
				'./views/device-manage/cartridge-model/cartridge-model-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/cartridgeModelEdit',
		name: 'cartridgeModelEdit',
		component: () =>
			import(
				'./views/device-manage/cartridge-model/cartridge-model-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/deviceMgmt/usageRecord',
		name: 'deviceUsageRecord',
		component: () =>
			import('./views/device-manage/usage-record/usage-record'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/deviceMgmt/cartridgeUsageInfo',
		name: 'CartridgeUsageInfo',
		component: () =>
			import('./views/device-manage/usage-record/usage-record-info'),
		meta: {
			authRequired: false,
		},
	},
	// 预热模式
	{
		path: '/dataDictionary/preheatMode',
		name: 'preheatMode',
		component: () =>
			import(
				'./views/data-dictionary/preheating-mode/preheating-mode.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/preheatModeAdd',
		name: 'preheatModeAdd',
		component: () =>
			import(
				'./views/data-dictionary/preheating-mode/preheating-mode-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/preheatModeEdit',
		name: 'preheatModeEdit',
		component: () =>
			import(
				'./views/data-dictionary/preheating-mode/preheating-mode-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// 加热模式
	{
		path: '/dataDictionary/heatingMode',
		name: 'heatingMode',
		component: () =>
			import('./views/data-dictionary/heating-mode/heating-mode.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 加热模式添加
	{
		path: '/dataDictionary/heatingModeAdd',
		name: 'heatingModeAdd',
		component: () =>
			import('./views/data-dictionary/heating-mode/heating-mode-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/dataDictionary/heatingModeEdit',
		name: 'heatingModeEdit',
		component: () =>
			import('./views/data-dictionary/heating-mode/heating-mode-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//开发者测试记录
	{
		path: '/productionMgmt/testRecord',
		name: 'testRecord',
		component: () =>
			import('./views/production-manage/test-record/test-record.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/testRecordInfo',
		name: 'testRecordInfo',
		component: () =>
			import(
				'./views/production-manage/test-record/test-record-info.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//生成二维码
	{
		path: '/productionMgmt/qrCode',
		name: 'qrCode',
		component: () =>
			import('./views/production-manage/qr-code/qr-code.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/productionMgmt/qrCodeAdd',
		name: 'qrCodeAdd',
		component: () =>
			import('./views/production-manage/qr-code/qr-code-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 设备型号
	{
		path: '/deviceMgmt/deviceModel',
		name: 'deviceModel',
		component: () =>
			import('./views/device-manage/device-model/device-model.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceModelAdd',
		name: 'deviceModelAdd',
		component: () =>
			import('./views/device-manage/device-model/device-model-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/deviceModelEdit',
		name: 'deviceModelEdit',
		component: () =>
			import('./views/device-manage/device-model/device-model-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 空烟弹申请
	{
		path: '/deviceMgmt/orderStatus',
		name: 'orderStatus',
		component: () =>
			import('./views/device-manage/order-status/order-status.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusAdd',
		name: 'orderStatusAdd',
		component: () =>
			import('./views/device-manage/order-status/order-status-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusEdit',
		name: 'orderStatusEdit',
		component: () =>
			import('./views/device-manage/order-status/order-status-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusInfo',
		name: 'orderStatusInfo',
		component: () =>
			import('./views/device-manage/order-status/order-status-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/orderStatusReview',
		name: 'orderStatusReview',
		component: () =>
			import(
				'./views/device-manage/order-status/order-status-review.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/deviceMgmt/emptyCartridgesInventory',
		name: 'emptyCartridgesInventory',
		component: () =>
			import(
				'./views/device-manage/empty-cartridges-inventory/empty-cartridges-inventory.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// User Manage
	{
		path: '/userMgmt/userAccounts',
		name: 'userAccounts',
		component: () => import('./views/user-manage/user-accounts/user.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/userAccountsInfo',
		name: 'userAccountsInfo',
		component: () =>
			import('./views/user-manage/user-accounts/user-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/usageRecord',
		name: 'usageRecord',
		component: () => import('./views/user-manage/usage-record/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/usageRecordInfo',
		name: 'usageRecordInfo',
		component: () => import('./views/user-manage/usage-record/info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/UserAuthorization',
		name: 'UserAuthorization',
		component: () =>
			import('./views/user-manage/user-authorization/index.vue'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/userMgmt/UserAuthorizationInfo',
		name: 'UserAuthorizationInfo',
		component: () =>
			import('./views/user-manage/user-authorization/info.vue'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/userMgmt/survey',
		name: 'survey',
		component: () => import('./views/user-manage/survey/survey.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/userMgmt/surveyInfo',
		name: 'surveyInfo',
		component: () => import('./views/user-manage/survey/survey-info.vue'),
		meta: {
			authRequired: false,
		},
	},

	// Content Manage
	{
		path: '/contentManage/article',
		name: 'Article',
		component: () => import('./views/contents-manage/article'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentManage/articleAdd',
		name: 'ArticleAdd',
		component: () => import('./views/contents-manage/article-add'),
		meta: {
			authRequired: false,
		},
	},
	/*
	{
		path: '/contentManage/articleEdit',
		name: 'ArticleEdit',
		component: () => import('./views/content-manage/article-edit'),
		meta: {
			authRequired: false
		}
	},

	{
		path: '/contentManage/articleInfo',
		name: 'ArticleInfo',
		component: () => import('./views/content-manage/article-info'),
		meta: {
			authRequired: false
		}
	},
*/

	// App Manage
	{
		path: '/appManage/application',
		name: 'application',
		component: () =>
			import('./views/app-manage/application-manage/application.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/appManage/applicationAdd',
		name: 'applicationAdd',
		component: () =>
			import('./views/app-manage/application-manage/application-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/appManage/applicationEdit',
		name: 'applicationEdit',
		component: () =>
			import('./views/app-manage/application-manage/application-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// {
	// 	path: '/appManage/communityInfo',
	// 	name: 'FAQInfo',
	// 	component: () => import('./views/app-manage/community-info'),
	// 	meta: {
	// 		authRequired: false
	// 	}
	// },

	{
		path: '/appManage/settings',
		name: 'Settings',
		component: () => import('./views/app-manage/settings'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/appManage/feedback',
		name: 'feedback',
		component: () => import('./views/app-manage/feedback'),
		meta: {
			authRequired: false,
		},
	},

	// 授权解锁列表
	{
		path: '/contentMgmt/questions',
		name: 'questions',
		component: () =>
			import('./views/medical-manage/health-manage/questions.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/questionsInfo',
		name: 'questionsInfo',
		component: () =>
			import('./views/medical-manage/health-manage/questions-info.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/questionsAdd',
		name: 'questionsAdd',
		component: () =>
			import('./views/medical-manage/health-manage/questions-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/questionsEdit',
		name: 'questionsEdit',
		component: () =>
			import('./views/medical-manage/health-manage/questions-edit.vue'),
		meta: {
			authRequired: false,
		},
	},
	//烟弹评论管理
	{
		path: '/contentMgmt/comment',
		name: 'comment',
		component: () => import('./views/content-manage/comment/comment.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/community',
		name: 'FAQ1',
		component: () => import('./views/content-manage/community/index'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/communityAdd',
		name: 'communityAdd',
		component: () => import('./views/content-manage/community/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/communityEdit',
		name: 'communityEdit',
		component: () => import('./views/content-manage/community/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/communityInfo',
		name: 'communityInfo',
		component: () => import('./views/content-manage/community/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/userGuide',
		name: 'healthManage/userGuide',
		component: () => import('./views/content-manage/user-guide/index'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/userGuideAdd',
		name: 'healthManage/userGuideAdd',
		component: () => import('./views/content-manage/user-guide/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/userGuideEdit',
		name: 'healthManage/userGuideEdit',
		component: () => import('./views/content-manage/user-guide/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/userGuideInfo',
		name: 'healthManage/userGuideInfo',
		component: () => import('./views/content-manage/user-guide/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/recommenderCode',
		name: 'healthManage/recommenderCode',
		component: () =>
			import('./views/content-manage/recommender-code/index'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/recommenderCodeAdd',
		name: 'healthManage/recommenderCodeAdd',
		component: () => import('./views/content-manage/recommender-code/add'),
		meta: {
			authRequired: false,
		},
	},

	{
		path: '/contentMgmt/recommenderCodeEdit',
		name: 'healthManage/recommenderCodeEdit',
		component: () => import('./views/content-manage/recommender-code/add'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/keywordBlacklist',
		name: 'keywordBlacklist',
		component: () =>
			import(
				'./views/content-manage/keyword-blacklist/keyword-blacklist'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/keywordBlacklistAdd',
		name: 'keywordBlacklistAdd',
		component: () =>
			import(
				'./views/content-manage/keyword-blacklist/keyword-blacklist-add'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/keywordBlacklistEdit',
		name: 'keywordBlacklistEdit',
		component: () =>
			import(
				'./views/content-manage/keyword-blacklist/keyword-blacklist-add'
			),
		meta: {
			authRequired: false,
		},
	},
	// 调研问题
	{
		path: '/contentMgmt/researchQuestions',
		name: 'researchQuestions',
		component: () =>
			import(
				'./views/content-manage/research-questions/research-questions.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/researchQuestionsAdd',
		name: 'researchQuestionsAdd',
		component: () =>
			import(
				'./views/content-manage/research-questions/research-questions-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/contentMgmt/researchQuestionsEdit',
		name: 'researchQuestionsEdit',
		component: () =>
			import(
				'./views/content-manage/research-questions/research-questions-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// 系统管理--账号管理
	{
		path: '/systemMgmt/accounts',
		name: 'systemAccounts',
		component: () =>
			import('./views/system-manage/account-manage/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemMgmt/systemAccountsAdd',
		name: 'systemAccountsAdd',
		component: () => import('./views/system-manage/account-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemMgmt/systemAccountsEdit',
		name: 'systemAccountsEdit',
		component: () => import('./views/system-manage/account-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 系统管理--角色管理
	{
		path: '/systemMgmt/role',
		name: 'role',
		component: () => import('./views/system-manage/role-manage/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemMgmt/roleAdd',
		name: 'roleAdd',
		component: () => import('./views/system-manage/role-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	{
		path: '/systemMgmt/roleEdit',
		name: 'roleEdit',
		component: () => import('./views/system-manage/role-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 系统管理-菜单管理
	{
		path: '/systemMgmt/menu',
		name: 'menu',
		component: () => import('./views/system-manage/menu-manage/menu.vue'),
		meta: {
			authRequired: false,
		},
	},

	//data-dictionary 数据字典

	// 成分列表
	{
		path: '/dataDictionary/ingredient',
		name: 'ingredient',
		component: () =>
			import(
				'./views/data-dictionary/cartridge-ingredient/ingredient.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 成分添加
	{
		path: '/dataDictionary/ingredientAdd',
		name: 'ingredientAdd',
		component: () =>
			import(
				'./views/data-dictionary/cartridge-ingredient/ingredient-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 成分编辑
	{
		path: '/dataDictionary/ingredientEdit',
		name: 'ingredientEdit',
		component: () =>
			import(
				'./views/data-dictionary/cartridge-ingredient/ingredient-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// 厂家列表
	{
		path: '/dataDictionary/manufacturer',
		name: 'manufacturer',
		component: () =>
			import('./views/data-dictionary/manufacturer/manufacturer.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 厂家添加
	{
		path: '/dataDictionary/manufacturerAdd',
		name: 'manufacturerAdd',
		component: () =>
			import('./views/data-dictionary/manufacturer/manufacturer-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 厂家编辑
	{
		path: '/dataDictionary/manufacturerEdit',
		name: 'manufacturerEdit',
		component: () =>
			import('./views/data-dictionary/manufacturer/manufacturer-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//品牌管理列表
	{
		path: '/dataDictionary/consumableBrands',
		name: 'consumableBrands',
		component: () =>
			import('./views/data-dictionary/consumable-brands/brand.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 品牌添加
	{
		path: '/dataDictionary/consumableBrandsAdd',
		name: 'consumableBrandsAdd',
		component: () =>
			import('./views/data-dictionary/consumable-brands/brand-add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 品牌编辑
	{
		path: '/dataDictionary/consumableBrandsEdit',
		name: 'consumableBrandsEdit',
		component: () =>
			import('./views/data-dictionary/consumable-brands/brand-add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//雾化丝种类列表
	{
		path: '/dataDictionary/atomizingWire',
		name: 'atomizingWire',
		component: () =>
			import('./views/data-dictionary/atomizing-wire/atomizingWire.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 雾化丝种类添加
	{
		path: '/dataDictionary/atomizingWireAdd',
		name: 'atomizingWireAdd',
		component: () =>
			import(
				'./views/data-dictionary/atomizing-wire/atomizingWireAdd.vue'
			),
		meta: {
			authRequired: false,
		},
	},

	// 雾化丝种类编辑
	{
		path: '/dataDictionary/atomizingWireEdit',
		name: 'atomizingWireEdit',
		component: () =>
			import(
				'./views/data-dictionary/atomizing-wire/atomizingWireAdd.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	//溶剂列表
	{
		path: '/dataDictionary/solvent',
		name: 'solvent',
		component: () => import('./views/data-dictionary/solvent/solvent.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 溶剂添加
	{
		path: '/dataDictionary/solventAdd',
		name: 'solventAdd',
		component: () =>
			import('./views/data-dictionary/solvent/solventAdd.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 溶剂编辑
	{
		path: '/dataDictionary/solventEdit',
		name: 'solventEdit',
		component: () =>
			import('./views/data-dictionary/solvent/solventAdd.vue'),
		meta: {
			authRequired: false,
		},
	},
	//烟弹类型
	{
		path: '/dataDictionary/cartridgeType',
		name: 'cartridgeType',
		component: () =>
			import('./views/data-dictionary/cartridge-type/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 烟弹类型添加
	{
		path: '/dataDictionary/cartridgeTypeAdd',
		name: 'cartridgeTypeAdd',
		component: () =>
			import('./views/data-dictionary/cartridge-type/add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 烟弹类型编辑
	{
		path: '/dataDictionary/cartridgeTypeEdit',
		name: 'cartridgeTypeEdit',
		component: () =>
			import('./views/data-dictionary/cartridge-type/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	//地区管理列表
	{
		path: '/dataDictionary/regionManage',
		name: 'regionManage',
		component: () =>
			import('./views/data-dictionary/region-manage/region.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 地区添加
	{
		path: '/dataDictionary/regionAdd',
		name: 'regionAdd',
		component: () =>
			import('./views/data-dictionary/region-manage/regionAdd.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 地区编辑
	{
		path: '/dataDictionary/regionEdit',
		name: 'regionEdit',
		component: () =>
			import('./views/data-dictionary/region-manage/regionAdd.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 系统维护
	// 系统日志
	{
		path: '/systemMaintenance/systemLog',
		name: 'systemLog',
		component: () =>
			import('./views/system-maintenance/system-log/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 服务器日志
	{
		path: '/systemMaintenance/serverLog',
		name: 'serverLog',
		component: () =>
			import('./views/system-maintenance/server-log/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	//客户管理列表
	{
		path: '/accountMgmt/accounts',
		name: 'accounts',
		component: () => import('./views/account-manage/accounts/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 客户添加
	{
		path: '/accountMgmt/accountsAdd',
		name: 'accountsAdd',
		component: () => import('./views/account-manage/accounts/add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 客户编辑
	{
		path: '/accountMgmt/accountsEdit',
		name: 'accountsEdit',
		component: () => import('./views/account-manage/accounts/add.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 客户详情
	{
		path: '/accountMgmt/accountsInfo',
		name: 'accountsInfo',
		component: () => import('./views/account-manage/accounts/info.vue'),
		meta: {
			authRequired: false,
		},
	},
	//客户角色列表
	{
		path: '/accountMgmt/roleMgmt',
		name: 'roleMgmt',
		component: () => import('./views/account-manage/role-manage/index.vue'),
		meta: {
			authRequired: false,
		},
	},
	// 客户角色添加
	{
		path: '/accountMgmt/roleMgmtAdd',
		name: 'roleMgmtAdd',
		component: () => import('./views/account-manage/role-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},

	//客户角色编辑
	{
		path: '/accountMgmt/customerRoleEdit',
		name: 'customerRoleEdit',
		component: () => import('./views/account-manage/role-manage/add.vue'),
		meta: {
			authRequired: false,
		},
	},

	// 营销管理
	// 消息推送
	{
		path: '/marketingManage/messagePushed',
		name: 'messagePushed',
		component: () =>
			import(
				'./views/marketing-manage/message-pushed/message-pushed.vue'
			),
		meta: {
			authRequired: false,
		},
	},
	// 消息推送添加
	{
		path: '/marketingManage/messagePushedAdd',
		name: 'messagePushedAdd',
		component: () =>
			import(
				'./views/marketing-manage/message-pushed/message-pushed-add.vue'
			),
		meta: {
			authRequired: false,
		},
	},
]

<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <b-row class="align-items-center" v-if="!editId">
          <b-col lg="12">
            <div class="blockquote">
              <h4>Basic Info</h4>
            </div>
          </b-col>
        </b-row>
        <!-- 客户名称 -->
        <b-form-group
          label="Customer Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="customer-name"
            v-model="form.brandName"
            placeholder="Please enter"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>

        <!-- 客户昵称 -->
        <b-form-group
          label="Customer Nickname:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="Nickname"
          label-class="requiredRow"
        >
          <b-form-input
            id="Nickname"
            v-model="form.nickName"
            placeholder="Please enter"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>

        <!-- 客户区域 -->
        <b-form-group
          label="Customer Area:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer-area"
          label-class="requiredRow"
        >
          <b-form-select
            v-model="form.areaEncodingId"
            :options="types.allCountry"
            value-field="id"
            text-field="cityName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 客户类型 -->
        <b-form-group
          label="Customer Type:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer-type"
          label-class="requiredRow"
        >
          <b-form-select
            v-model="form.customerType"
            :options="types.customerTypes"
            value-field="value"
            text-field="text"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 客户角色 -->
        <b-form-group
          label="Customer Role:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer-role"
          label-class="requiredRow"
        >
          <b-form-select
            id="customer-role"
            v-model="form.supplierRoleId"
            :options="types.supplierRoleList"
            value-field="id"
            text-field="roleName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 客户头像 -->
        <b-form-group
          label="Customer Photo:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="logo"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
          />
          <!-- required 
          oninvalid="setCustomValidity('Please upload');"
          oninput="setCustomValidity('');" -->

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.picture" style="margin-bottom: 10px">
                <img
                  :src="form.picture"
                  style="width: 100px; height: 100px; border: 2px dashed #ccc"
                />
                <span
                  style="margin-left: 10px; vertical-align: bottom"
                  v-if="!editId"
                >
                  <span style="font-weight: 500">Picture name : </span
                  >{{ photoName }}</span
                >
              </div>
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span>
                Recommended size of picture: 120 pixels * 120 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- 联系人 -->
        <b-form-group
          label="Contacts:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="contacts"
        >
          <b-form-input
            id="contacts"
            v-model="form.contactName"
            trim
            placeholder="Please enter"
            oninput="if(value.length > 20)value = value.slice(0, 20)"
          ></b-form-input>
        </b-form-group>

        <!-- 邮箱 -->
        <b-form-group
          label="Email:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="form.email"
            type="email"
            trim
            placeholder="Please enter"
            oninput="if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>

        <!-- 联系电话 -->
        <b-form-group
          label="Contact Number:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="contact-number"
        >
          <b-form-input
            id="contact-number"
            v-model="form.phone"
            trim
            placeholder="Please enter"
            oninput="if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>

        <!-- 联系地址 -->
        <b-form-group
          label="Contact Address:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="contact-address"
        >
          <b-form-input
            id="contact-address"
            v-model="form.address"
            trim
            placeholder="Please enter"
            oninput="if(value.length > 100)value = value.slice(0, 100)"
          ></b-form-input>
        </b-form-group>

        <!-- 备注 -->
        <b-form-group
          label="Remark:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="remark"
        >
          <b-form-textarea
            id="remark"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-row class="align-items-center" v-if="!editId">
          <b-col lg="12">
            <div class="blockquote">
              <h4>Account Info</h4>
            </div>
          </b-col>
        </b-row>

        <!-- 登录账号 -->
        <b-form-group
          label="Login Account:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="login-account"
          v-if="!editId"
          label-class="requiredRow"
        >
          <b-form-input
            id="login-account"
            v-model="form.username"
            placeholder="Please enter"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>
        <!-- 登录密码 -->
        <b-form-group
          label="Login Password:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="login-password"
          v-if="!editId"
          label-class="requiredRow"
        >
          <b-form-input
            id="login-password"
            v-model="form.password"
            placeholder="Please enter"
            type="password"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>
        <!-- 重复密码 -->
        <b-form-group
          label="Confirm Password:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="login-password"
          v-if="!editId"
          label-class="requiredRow"
        >
          <b-form-input
            id="login-password"
            v-model="confirmPassword"
            placeholder="Please enter"
            type="password"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";
import { onReady, upload_img } from "@/api/upload";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Accounts Modify" : "Accounts Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Account Mgmt",
            // href: "/"
          },
          {
            text: this.editId ? "Accounts Modify" : "Accounts Create",
            active: true,
          },
        ],
      },
      form: {
        brandName: "",
        nickName: "",
        areaEncodingId: "",
        customerType: "",
        supplierRoleId: "",
        picture: "",
        contactName: "",
        email: "",
        phone: "",
        address: "",
        remarks: "",
        username: "",
        password: "",
      },
      photoName: "",
      confirmPassword: "",
      types: {
        customerTypes: [
          { text: "Please select", value: "", disabled: false },
          { text: "Channel", value: 1, disabled: false },
          { text: "Brands", value: 2, disabled: false },
          { text: "Smoke oil", value: 3, disabled: false },
        ],
        supplierRoleList: [{ id: "", roleName: "Please select" }],
        allCountry: [{ id: "", cityName: "Please select" }],
      },
      editor: ClassicEditor,
      editId: window.location.search,
      photoName: "",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$api.Dropdown.supplierRoleList().then((res) => {
        if (res.success) {
          this.types.supplierRoleList = this.types.supplierRoleList.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.allCountry().then((res) => {
        if (res.success) {
          this.types.allCountry = this.types.allCountry.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.form.id) {
        this.$api.customerManage.updateById(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        if (this.form.password != this.confirmPassword) {
          this.$bvToast.toast(
            "The two passwords are inconsistent. Please reconfirm the password"
          );
        } else {
          this.$api.customerManage.add(this.form).then((res) => {
            if (res.success) {
              this.$router.go(-1);
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        brandName: "",
        nickName: "",
        areaEncodingId: "",
        customerType: "",
        supplierRoleId: "",
        picture: "",
        contactName: "",
        email: "",
        phone: "",
        address: "",
        remarks: "",
        username: "",
        password: "",
      };
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.customerManage.editSupplier({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.form.picture = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
  },
};
</script>

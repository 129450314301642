/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:06
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-30 14:43:42
 * @Description:
 */
import { format } from './time'
import axios from '../config/http.js'
import axios1 from '../config/http-json.js'
import baseUrl from '../config/env.js'

// customer manage
async function list(params, ctx) {
	return axios.fetchGet(baseUrl + '/supplier/list', params)
}

function add(params) {
	return axios.fetchPost(baseUrl + '/supplier/addSupplier', params)
}

function editSupplier(params) {
	return axios.fetchGet(baseUrl + '/supplier/editSupplier', params)
}

function updateById(params) {
	return axios.fetchPost(baseUrl + '/supplier/updateSupplier', params)
}
function findById(params) {
	return axios.fetchGet(baseUrl + '/supplier/findById', params)
}

// role manage
async function roleList(params, ctx) {
	return axios.fetchGet(
		baseUrl + '/supplierRole/selectSupplierRoleList',
		params
	)
}

function roleAdd(params) {
	return axios1.fetchPost(baseUrl + '/supplierRole/saveSupplierRole', params)
}

function roleFindById(params) {
	return axios.fetchGet(baseUrl + '/supplierRole/selectById', params)
}

function roleUpdateById(params) {
	return axios1.fetchPost(
		baseUrl + '/supplierRole/updateSupplierRole',
		params
	)
}

function findAllMenu(params) {
	return axios.fetchGet(baseUrl + '/supplierRole/findAllMenu', params)
}
function selectAllDataPermission(params) {
	return axios.fetchGet(
		baseUrl + '/supplierRole/selectAllDataPermission',
		params
	)
}

//查询客户的App菜单权限
function selectDeveloperMenu(params = {}) {
	return axios.fetchGet(baseUrl + '/supplierRole/selectDeveloperMenu', params)
}

export default {
	list,
	add,
	editSupplier,
	updateById,
	findById,
	roleList,
	roleAdd,
	roleFindById,
	roleUpdateById,
	findAllMenu,
	selectAllDataPermission,
	selectDeveloperMenu,
}

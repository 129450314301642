<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>User Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Avatar:</label>
            <span class="p-3"
              ><img
                v-if="info.picture"
                :src="info.picture"
                alt
                class="avatar-xs rounded-circle mr-2"
            /></span>
          </b-col>
          <b-col>
            <label class="col-form-label">User Nickname:</label>
            <span class="p-3">{{ info.nickName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Question Group:</label>
            <span class="p-3">{{ info.problemGroupName }}</span>
          </b-col>
          <!-- <b-col>
            <label class="col-form-label">Used Atomizer?:</label>
            <span class="p-3">{{
              info.isAtomization == 1 ? "Yes" : "No"
            }}</span>
          </b-col> -->
          <b-col lg="12">
            <label class="col-form-label">Feedback Time:</label>
            <span class="p-3">{{ getLocalTime(info.createTime) }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- 反馈问题 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Feedback Questions</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row
          cols="1"
          v-for="(item, i) in info.problemVo"
          :key="i"
          style="margin-bottom: 20px"
        >
          <b-col>
            <label class="col-form-label">{{
              "Question" + (i + 1) + ":"
            }}</label>
            <span class="p-3">{{ item.problemName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">{{ "Answer" + (i + 1) + ":" }}</label>
            <span class="p-3">
              <b-form-radio-group
                id="btn-radios-2"
                v-model="item.answer"
                :options="item.answerList"
                buttons
                button-variant="outline-primary"
                name="radio-btn-outline"
              ></b-form-radio-group>
            </span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- <b-card> -->
    <b-button
      size="lg"
      type="reset"
      variant="outline-secondary"
      @click="returnPage"
      >Go Back</b-button
    >
    <!-- </b-card> -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Survey Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "Survey Details",
          active: true,
        },
      ],
      info: {
        binding: 0,
        startTime: "",
        id: 0,
        nickName: "",
        picture: "",
        problemVo: [],
      },
      answerList: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
      ],
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    getLocalTime(date) {
      return timeZoneConversion.getLocalTime(date);
    },
    init() {
      if (this.$route.query.id) {
        this.$api.userManage
          .selectUserReplyDetails(this.$route.query)
          .then((res) => {
            if (res.success) {
              res.data.problemVo.forEach((item) => {
                if (item.answer == 1) {
                  item.answerList = [
                    { text: "1", value: "1" },
                    { text: "2", value: "2", disabled: true },
                    { text: "3", value: "3", disabled: true },
                    { text: "4", value: "4", disabled: true },
                    { text: "5", value: "5", disabled: true },
                  ];
                } else if (item.answer == 2) {
                  item.answerList = [
                    { text: "1", value: "1", disabled: true },
                    { text: "2", value: "2" },
                    { text: "3", value: "3", disabled: true },
                    { text: "4", value: "4", disabled: true },
                    { text: "5", value: "5", disabled: true },
                  ];
                } else if (item.answer == 3) {
                  item.answerList = [
                    { text: "1", value: "1", disabled: true },
                    { text: "2", value: "2", disabled: true },
                    { text: "3", value: "3" },
                    { text: "4", value: "4", disabled: true },
                    { text: "5", value: "5", disabled: true },
                  ];
                } else if (item.answer == 4) {
                  item.answerList = [
                    { text: "1", value: "1", disabled: true },
                    { text: "2", value: "2", disabled: true },
                    { text: "3", value: "3", disabled: true },
                    { text: "4", value: "4" },
                    { text: "5", value: "5", disabled: true },
                  ];
                } else if (item.answer == 5) {
                  item.answerList = [
                    { text: "1", value: "1", disabled: true },
                    { text: "2", value: "2", disabled: true },
                    { text: "3", value: "3", disabled: true },
                    { text: "4", value: "4", disabled: true },
                    { text: "5", value: "5" },
                  ];
                }
              });

              this.info = res.data;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    returnPage() {
      this.$router.go(-1);
    },
  },
};
</script>

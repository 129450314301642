var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Blog Title:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "title",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title",
                      placeholder: "Please enter",
                      required3: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  }),
                  _c("span", [_vm._v("Title is up to 100 characters long")])
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Blog Category:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "categoryId",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "categoryId",
                      options: _vm.types.questionCategorys,
                      "value-field": "id",
                      "text-field": "name",
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    model: {
                      value: _vm.form.categoryId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "categoryId", $$v)
                      },
                      expression: "form.categoryId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Product/Batch Association:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "modelId",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("multiselect", {
                    attrs: {
                      options: _vm.types.modelIds,
                      "track-by": "id",
                      label: "modelName",
                      placeholder: "Please select",
                      searchable: false,
                      "close-on-select": false,
                      multiple: true,
                      required: "",
                      oninvalid: "setCustomValidity('Please select');",
                      oninput: "setCustomValidity('')"
                    },
                    on: { input: _vm.selectModel },
                    model: {
                      value: _vm.selectedModel,
                      callback: function($$v) {
                        _vm.selectedModel = $$v
                      },
                      expression: "selectedModel"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Display Priority:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "sort",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "sort",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('')",
                      placeholder: "Please enter",
                      type: "number"
                    },
                    model: {
                      value: _vm.form.sort,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "sort", $$v)
                      },
                      expression: "form.sort"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Cover Picture:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "frontCover",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1",
                      required: !_vm.form.frontCover,
                      oninvalid: "setCustomValidity('Please upload');",
                      oninput: "setCustomValidity('')"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.frontCover
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "2px dashed #ccc"
                                    },
                                    attrs: { src: _vm.form.frontCover }
                                  }),
                                  !_vm.editId
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "vertical-align": "bottom"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v("Picture name : ")]
                                          ),
                                          _vm._v(_vm._s(_vm.photoName))
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v(" upload picture ")
                              ])
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " Recommended size of picture: 950 pixels * 450 pixels, supporting JPG and PNG formats, within 2m. "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Blog Type:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "answer",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "b-form-radio-group",
                    {
                      staticClass: "pt-2",
                      attrs: { id: "contentType" },
                      model: {
                        value: _vm.form.contentType,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "contentType", $$v)
                        },
                        expression: "form.contentType"
                      }
                    },
                    [
                      _c(
                        "b-form-radio",
                        {
                          attrs: { value: "0" },
                          on: { change: _vm.changeCustom }
                        },
                        [_vm._v("The custom")]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          attrs: { value: "1" },
                          on: { change: _vm.changeLink }
                        },
                        [_vm._v("External links")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showCustom,
                      expression: "showCustom"
                    }
                  ],
                  attrs: {
                    label: "Blog Content:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "answerContent",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("div", {
                    staticStyle: { "max-width": "1260px" },
                    attrs: { id: "wangeEditor" }
                  })
                ]
              ),
              _vm.showLink
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Link Address:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "Link",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "Link",
                          placeholder: "Please Enter",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.contentUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "contentUrl", $$v)
                          },
                          expression: "form.contentUrl"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showLink
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Abstract Remarks",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "remarks"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "remarks",
                          placeholder: "Enter Notes",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.abstractRemarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "abstractRemarks", $$v)
                          },
                          expression: "form.abstractRemarks"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  !_vm.is_add
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "ID",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "model-name1"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "model-name1",
                              disabled: "",
                              placeholder: ""
                            },
                            model: {
                              value: _vm.form.id,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "id", $$v)
                              },
                              expression: "form.id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "type",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name2"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: "model-name2", placeholder: "Enter type" },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "unusedTimes",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name3"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name3",
                          placeholder: "Enter unusedTimes"
                        },
                        model: {
                          value: _vm.form.unusedTimes,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "unusedTimes", $$v)
                          },
                          expression: "form.unusedTimes"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "madeinName",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name4"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name4",
                          placeholder: "Enter madeinName"
                        },
                        model: {
                          value: _vm.form.madeinName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "madeinName", $$v)
                          },
                          expression: "form.madeinName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "worktemp",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name5"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name5",
                          placeholder: "Enter worktemp"
                        },
                        model: {
                          value: _vm.form.worktemp,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "worktemp", $$v)
                          },
                          expression: "form.worktemp"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "normalTemperature",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name6"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name6",
                          placeholder: "Enter normalTemperature"
                        },
                        model: {
                          value: _vm.form.normalTemperature,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "normalTemperature", $$v)
                          },
                          expression: "form.normalTemperature"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "wildTemperature",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name7"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name7",
                          placeholder: "Enter wildTemperature"
                        },
                        model: {
                          value: _vm.form.wildTemperature,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "wildTemperature", $$v)
                          },
                          expression: "form.wildTemperature"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "softTemperature",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter softTemperature"
                        },
                        model: {
                          value: _vm.form.softTemperature,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "softTemperature", $$v)
                          },
                          expression: "form.softTemperature"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "temperature4",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name8"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name8",
                          placeholder: "Enter temperature4"
                        },
                        model: {
                          value: _vm.form.temperature4,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "temperature4", $$v)
                          },
                          expression: "form.temperature4"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "temperature5",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name9"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name9",
                          placeholder: "Enter temperature5"
                        },
                        model: {
                          value: _vm.form.temperature5,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "temperature5", $$v)
                          },
                          expression: "form.temperature5"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "temperature6",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name10"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name10",
                          placeholder: "Enter temperature6"
                        },
                        model: {
                          value: _vm.form.temperature6,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "temperature6", $$v)
                          },
                          expression: "form.temperature6"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "placeOfOrigin",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name11"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name11",
                          placeholder: "Enter placeOfOrigin"
                        },
                        model: {
                          value: _vm.form.placeOfOrigin,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "placeOfOrigin", $$v)
                          },
                          expression: "form.placeOfOrigin"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "City",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name12"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name12",
                          placeholder: "Enter City"
                        },
                        model: {
                          value: _vm.form.city,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "city", $$v)
                          },
                          expression: "form.city"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Address",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name13"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name13",
                          required: "",
                          placeholder: "Enter Address"
                        },
                        model: {
                          value: _vm.form.address,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "address", $$v)
                          },
                          expression: "form.address"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "picture:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "picture"
                      }
                    },
                    [
                      _c("img", {
                        staticClass:
                          "img-fluid img-thumbnail rounded-circle avatar-lg",
                        attrs: { src: _vm.form.picture, alt: "" }
                      }),
                      _c("input", {
                        staticClass: "filestyle",
                        staticStyle: {
                          position: "absolute",
                          clip: "rect(0px, 0px, 0px, 0px)"
                        },
                        attrs: {
                          type: "file",
                          "data-input": "false",
                          id: "filestyle-1",
                          tabindex: "-1"
                        },
                        on: {
                          change: function($event) {
                            return _vm.upload($event, "picture")
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "bootstrap-filestyle input-group" },
                        [
                          _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "35px",
                              "z-index": "-1"
                            },
                            attrs: { name: "filedrag" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0" }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-1" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v(" upload picture ")
                                  ])
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  " Recommended size of picture: 512 pixels * 512 pixels, supporting JPG and PNG formats, within 2m. "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "companyIcon:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "companyIcon"
                      }
                    },
                    [
                      _c("img", {
                        staticClass:
                          "img-fluid img-thumbnail rounded-circle avatar-lg",
                        attrs: { src: _vm.form.companyIcon, alt: "" }
                      }),
                      _c("input", {
                        staticClass: "filestyle",
                        staticStyle: {
                          position: "absolute",
                          clip: "rect(0px, 0px, 0px, 0px)"
                        },
                        attrs: {
                          type: "file",
                          "data-input": "false",
                          id: "filestyle-1",
                          tabindex: "-1"
                        },
                        on: {
                          change: function($event) {
                            return _vm.upload($event, "companyIcon")
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "bootstrap-filestyle input-group" },
                        [
                          _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "35px",
                              "z-index": "-1"
                            },
                            attrs: { name: "filedrag" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0" }
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "btn btn-secondary",
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { for: "filestyle-1" }
                                },
                                [
                                  _c("span", { staticClass: "buttonText" }, [
                                    _vm._v(" upload companyIcon ")
                                  ])
                                ]
                              ),
                              _c("span", [
                                _vm._v(
                                  " Recommended size of picture: 512 pixels * 512 pixels, supporting JPG and PNG formats, within 2m. "
                                )
                              ])
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "daySeconds",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter daySeconds"
                        },
                        model: {
                          value: _vm.form.daySeconds,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "daySeconds", $$v)
                          },
                          expression: "form.daySeconds"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "seconds",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter Seconds"
                        },
                        model: {
                          value: _vm.form.seconds,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "seconds", $$v)
                          },
                          expression: "form.seconds"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "deviceCoding",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter deviceCoding"
                        },
                        model: {
                          value: _vm.form.deviceCoding,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceCoding", $$v)
                          },
                          expression: "form.deviceCoding"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "deviceNumber",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter  deviceNumber"
                        },
                        model: {
                          value: _vm.form.deviceNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceNumber", $$v)
                          },
                          expression: "form.deviceNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "deviceStatus",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter deviceStatus "
                        },
                        model: {
                          value: _vm.form.deviceStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceStatus", $$v)
                          },
                          expression: "form.deviceStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "explains",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter explains"
                        },
                        model: {
                          value: _vm.form.explains,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "explains", $$v)
                          },
                          expression: "form.explains"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "power",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter power"
                        },
                        model: {
                          value: _vm.form.power,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "power", $$v)
                          },
                          expression: "form.power"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "preheatTime",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter preheatTime"
                        },
                        model: {
                          value: _vm.form.preheatTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "preheatTime", $$v)
                          },
                          expression: "form.preheatTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "productionBatch",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter productionBatch"
                        },
                        model: {
                          value: _vm.form.productionBatch,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "productionBatch", $$v)
                          },
                          expression: "form.productionBatch"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "proportion",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter proportion"
                        },
                        model: {
                          value: _vm.form.proportion,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "proportion", $$v)
                          },
                          expression: "form.proportion"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "proportion1",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter proportion1"
                        },
                        model: {
                          value: _vm.form.proportion1,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "proportion1", $$v)
                          },
                          expression: "form.proportion1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "proportion2",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter proportion2"
                        },
                        model: {
                          value: _vm.form.proportion2,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "proportion2", $$v)
                          },
                          expression: "form.proportion2"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "smokeRecordModelId",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter smokeRecordModelId"
                        },
                        model: {
                          value: _vm.form.smokeRecordModelId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "smokeRecordModelId", $$v)
                          },
                          expression: "form.smokeRecordModelId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "smokeRecordNumber",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter smokeRecordNumber"
                        },
                        model: {
                          value: _vm.form.smokeRecordNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "smokeRecordNumber", $$v)
                          },
                          expression: "form.smokeRecordNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Model Encoding:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-encoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-encoding",
                          required: "",
                          placeholder: "Enter Model Encoding"
                        },
                        model: {
                          value: _vm.form.modelEncoding,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "modelEncoding", $$v)
                          },
                          expression: "form.modelEncoding"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Brand Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "brand-name"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: { options: _vm.types.brandNames },
                        model: {
                          value: _vm.form.brandName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "brandName", $$v)
                          },
                          expression: "form.brandName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Cartridge Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "cartridge-type"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: { options: _vm.types.cartridgeTypes },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "inner",
                      attrs: {
                        label: "Cartridge Formula:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "cartridge-formula",
                        description:
                          "The first two cigarette oil formulas will be displayed on the APP homepage"
                      }
                    },
                    [
                      _vm._l(_vm.form.formulaList, function(data, index) {
                        return _c(
                          "div",
                          {
                            key: "formulaList_" + index,
                            staticClass: "inner mb-3 row"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-5 col-5" },
                              [
                                _c("multiselect", {
                                  staticClass: "inner",
                                  attrs: {
                                    options: _vm.types.cartridgeComponentData
                                  },
                                  model: {
                                    value: data.component,
                                    callback: function($$v) {
                                      _vm.$set(data, "component", $$v)
                                    },
                                    expression: "data.component"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-5 col-5" },
                              [
                                _c("b-form-input", {
                                  staticClass: "inner form-control",
                                  attrs: {
                                    required: "",
                                    placeholder: "Enter Capacity"
                                  },
                                  model: {
                                    value: data.capacity,
                                    callback: function($$v) {
                                      _vm.$set(data, "capacity", $$v)
                                    },
                                    expression: "data.capacity"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-2 col-2" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFormula(index)
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.addFormula }
                        },
                        [_vm._v("Create")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Flavor:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "flavor"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          options: _vm.types.flavorOptions,
                          multiple: true
                        },
                        model: {
                          value: _vm.form.flavor,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "flavor", $$v)
                          },
                          expression: "form.flavor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Dose:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "dose"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "dose",
                          required: "",
                          placeholder: "Enter Cartridge Dose(ml)"
                        },
                        model: {
                          value: _vm.form.dose,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "dose", $$v)
                          },
                          expression: "form.dose"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Calculation:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "calculation"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "calculation",
                          required: "",
                          placeholder: "Enter Inhalation dose per second(mg)"
                        },
                        model: {
                          value: _vm.form.calculation,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "calculation", $$v)
                          },
                          expression: "form.calculation"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Frequency:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "frequency"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "frequency",
                          required: "",
                          placeholder: "Enter frequency"
                        },
                        model: {
                          value: _vm.form.frequency,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "frequency", $$v)
                          },
                          expression: "form.frequency"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Max Frequency:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "max-frequency"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "max-frequency",
                          required: "",
                          placeholder: "Enter Maximum number of Cartridge"
                        },
                        model: {
                          value: _vm.form.maxFrequency,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "maxFrequency", $$v)
                          },
                          expression: "form.maxFrequency"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Content Of Tobacco:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "tobaccoTar"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "tobaccoTar",
                          required: "",
                          placeholder: "Enter Content Of Tobacco(ml)"
                        },
                        model: {
                          value: _vm.form.tobaccoTar,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "tobaccoTar", $$v)
                          },
                          expression: "form.tobaccoTar"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Consistence:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "consistence"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "consistence",
                          required: "",
                          placeholder: "Enter Consistence"
                        },
                        model: {
                          value: _vm.form.consistence,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "consistence", $$v)
                          },
                          expression: "form.consistence"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "IsPreheat:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right"
                      }
                    },
                    [
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: { name: "isPreheat", value: "YES" },
                          model: {
                            value: _vm.form.isPreheat,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isPreheat", $$v)
                            },
                            expression: "form.isPreheat"
                          }
                        },
                        [_vm._v("YES")]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: { name: "isPreheat", value: "NO" },
                          model: {
                            value: _vm.form.isPreheat,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isPreheat", $$v)
                            },
                            expression: "form.isPreheat"
                          }
                        },
                        [_vm._v("NO")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Medical Care:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right"
                      }
                    },
                    [
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: { name: "medicalCare", value: "YES" },
                          model: {
                            value: _vm.form.medicalCare,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "medicalCare", $$v)
                            },
                            expression: "form.medicalCare"
                          }
                        },
                        [_vm._v("YES")]
                      ),
                      _c(
                        "b-form-radio",
                        {
                          staticClass: "custom-control-inline pt-2",
                          attrs: { name: "medicalCare", value: "NO" },
                          model: {
                            value: _vm.form.medicalCare,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "medicalCare", $$v)
                            },
                            expression: "form.medicalCare"
                          }
                        },
                        [_vm._v("NO")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Solvent:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "solvent"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "solvent",
                          required: "",
                          placeholder: "Enter Solvent"
                        },
                        model: {
                          value: _vm.form.solvent,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "solvent", $$v)
                          },
                          expression: "form.solvent"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Solvent1:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "solvent1"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "solvent1",
                          placeholder: "Enter Solvent1"
                        },
                        model: {
                          value: _vm.form.solvent1,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "solvent1", $$v)
                          },
                          expression: "form.solvent1"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Solvent2:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "solvent1"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "solvent2",
                          placeholder: "Enter Solvent 2"
                        },
                        model: {
                          value: _vm.form.solvent2,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "solvent2", $$v)
                          },
                          expression: "form.solvent2"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Atomizing Wire Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "atomizingWireType"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "atomizingWireType",
                          required: "",
                          placeholder: "Enter Atomizing Wire Type"
                        },
                        model: {
                          value: _vm.form.atomizingWireType,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "atomizingWireType", $$v)
                          },
                          expression: "form.atomizingWireType"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Report Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right"
                      }
                    },
                    [
                      _c(
                        "b-form-checkbox-group",
                        {
                          staticClass: "pt-2",
                          attrs: { id: "reportType" },
                          model: {
                            value: _vm.form.reportType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "reportType", $$v)
                            },
                            expression: "form.reportType"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: "link" },
                              on: { change: _vm.changeLink }
                            },
                            [_vm._v("Link")]
                          ),
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: "enclosure" },
                              on: { change: _vm.changeEnclosure }
                            },
                            [_vm._v("Enclosure")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.showReportLink
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Report Link:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "reportLink"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "reportLink",
                              required: "",
                              placeholder: "Enter Report Link"
                            },
                            model: {
                              value: _vm.form.reportLink,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "reportLink", $$v)
                              },
                              expression: "form.reportLink"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.showReportEnclosure
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Report Enclosure:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right"
                          }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { id: "editor" } },
                            [
                              _c("ckeditor", {
                                attrs: { editor: _vm.editor },
                                on: { ready: _vm.onReady },
                                model: {
                                  value: _vm.form.reportEnclosure,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "reportEnclosure", $$v)
                                  },
                                  expression: "form.reportEnclosure"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Submit")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "m-l-5 ml-1",
                          attrs: { type: "reset", variant: "danger" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Manufacturer Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="manufacturer-name"
              >
                <b-form-input
                  id="manufacturer-name"
                  v-model="search_condition.name"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- <b-col lg="3">
              <b-form-group
                label="Manufacturer Region"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="manufacturer-area"
              >
                <b-form-input
                  id="manufacturer-area"
                  v-model="search_condition.cityName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col> -->
            <b-col lg="3">
              <b-form-group
                label="Contacts"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="contacts"
              >
                <b-form-input
                  id="contacts"
                  v-model="search_condition.contacts"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                label="Contact Number"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="contact-number"
              >
                <b-form-input
                  id="contact-number"
                  v-model="search_condition.phone"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                label="Email"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="contact-email"
              >
                <b-form-input
                  id="contact-email"
                  v-model="search_condition.email"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'manufacturer::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="device-table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(contact)="data">
                <span style="display: block" v-if="data.item.contacts">{{
                  data.item.contacts + "[" + data.item.phone + "]"
                }}</span>
                <span style="display: block" v-if="data.item.address">{{
                  data.item.address
                }}</span>
              </template>

              <template v-slot:cell(actions)="row">
                <b-button
                  v-has="'manufacturer::Modify'"
                  size="sm"
                  variant="info"
                  @click="edit(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Modify</b-button
                >
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Flavor List component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Manufacturer",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Data Dictionary",
          // href: "/"
        },
        {
          text: "Manufacturer",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "name", label: "Manufacturer Name", class: "text-center" },
        //{ key: "areaName", label: "Manufacturer Region", class: "text-center" },
        { key: "contact", label: "Contact", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        //cityName: "",
        contacts: "",
        phone: "",
        email: "",
      },
      types: {
        TypeEncoding: [{ text: "Please select", value: "" }],
      },
      isBusy: false,
      has_data: false,
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // this.$api.Dropdown.selectTypeEncoding().then((res) => {
      //   if (res.success) {
      //     for (let i = 0; i < res.data.length; i++) {
      //       this.types.TypeEncoding.push({
      //         text: res.data[i].typeName,
      //         value: res.data[i].id,
      //       });
      //     }
      //   } else {
      //     if (res.message) {
      //       this.$bvToast.toast(res.message);
      //     }
      //   }
      // });
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Flavor.manufacturerList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
      //this.$bvToast.toast(JSON.stringify(this.search_condition));
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("manufacturerAdd");
    },
    edit(item, index, button) {
      this.$router.push("manufacturerEdit?id=" + item.id);
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Cartridge Status",
                        "label-cols-lg": "2",
                        "label-align-lg": "right"
                      }
                    },
                    [
                      _c("b-form-radio-group", {
                        attrs: {
                          id: "btn-radios-2",
                          options: _vm.types.statusList,
                          buttons: "",
                          "button-variant": "outline-primary",
                          name: "radio-btn-outline"
                        },
                        model: {
                          value: _vm.search_condition.status,
                          callback: function($$v) {
                            _vm.$set(_vm.search_condition, "status", $$v)
                          },
                          expression: "search_condition.status"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Flavor",
                                "label-cols-md": "4",
                                "label-align-md": "right"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "cartridge-model",
                                  options: _vm.types.ModelBySupplierId
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.changeModel(
                                      _vm.search_condition.smokeBombModelId,
                                      _vm.types.ModelBySupplierId
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.search_condition.smokeBombModelId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.search_condition,
                                      "smokeBombModelId",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "search_condition.smokeBombModelId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Cartridge Batch",
                                "label-cols-md": "4",
                                "label-align-lg": "right"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "cartridge-batch",
                                  options: _vm.types.batchNumberList
                                },
                                model: {
                                  value: _vm.search_condition.batchId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.search_condition,
                                      "batchId",
                                      $$v
                                    )
                                  },
                                  expression: "search_condition.batchId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Query Time",
                                "label-cols-md": "4",
                                "label-align-md": "right"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "btn-radios-2",
                                  options: _vm.types.typeList,
                                  buttons: "",
                                  "button-variant": "outline-primary",
                                  name: "radio-btn-outline"
                                },
                                model: {
                                  value: _vm.search_condition.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.search_condition, "type", $$v)
                                  },
                                  expression: "search_condition.type"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { md: "6" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Select Time",
                                "label-cols-md": "4",
                                "label-align-md": "right",
                                "label-for": "select-time"
                              }
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  format: "MM/DD/YYYY",
                                  range: "",
                                  "append-to-body": "",
                                  lang: "en",
                                  placeholder: "Select date"
                                },
                                model: {
                                  value: _vm.selectTime,
                                  callback: function($$v) {
                                    _vm.selectTime = $$v
                                  },
                                  expression: "selectTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.provider()
                                }
                              }
                            },
                            [_vm._v("Search")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              on: {
                                click: function($event) {
                                  return _vm.reset()
                                }
                              }
                            },
                            [_vm._v("Cancel")]
                          ),
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "has",
                                  rawName: "v-has",
                                  value:
                                    "consumptionAnalysis::Download the report",
                                  expression:
                                    "'consumptionAnalysis::Download the report'"
                                },
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.modal-1",
                                  modifiers: { "modal-1": true }
                                }
                              ],
                              staticStyle: { float: "right" },
                              attrs: { variant: "outline-primary" }
                            },
                            [_vm._v("Download the report")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { lg: "12" } },
            [
              _c(
                "b-card",
                [
                  _c("apexchart", {
                    ref: "apexChart",
                    staticClass: "apex-charts",
                    attrs: {
                      dir: "ltr",
                      height: "380",
                      type: "bar",
                      series: _vm.basicColumChart.series,
                      options: _vm.basicColumChart.chartOptions
                    }
                  }),
                  _c("br"),
                  _c("b-table", {
                    ref: "table",
                    attrs: {
                      "show-empty": "",
                      "empty-text": "no records",
                      id: "table",
                      "head-variant": "light",
                      items: _vm.tableData,
                      busy: _vm.isBusy,
                      striped: true,
                      fields: _vm.fields
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-danger my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-1",
            title: "Download the report",
            "hide-footer": "",
            size: "xl"
          }
        },
        [
          _c(
            "b-button",
            {
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.getPdf("#pdfDom")
                }
              }
            },
            [_vm._v("Download to PDF")]
          ),
          _c(
            "div",
            { attrs: { id: "pdfDom" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { lg: "12" } },
                    [
                      _c(
                        "b-card",
                        [
                          _c("p", [
                            _vm._v(
                              " Cartridge Model: " +
                                _vm._s(
                                  _vm.modelText != "Please select"
                                    ? _vm.modelText
                                    : ""
                                ) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " Cartridge Batch: " +
                                _vm._s(
                                  _vm.batchText != "Please select"
                                    ? _vm.batchText
                                    : ""
                                ) +
                                " "
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              " Cartridge Flavor: " +
                                _vm._s(
                                  _vm.flavorText != "Please select"
                                    ? _vm.flavorText
                                    : ""
                                ) +
                                " "
                            )
                          ]),
                          _c("p", { staticStyle: { display: "flex" } }, [
                            _c("span", { staticStyle: { flex: "1" } }, [
                              _vm._v(
                                "A total of " +
                                  _vm._s(_vm.total) +
                                  " , " +
                                  _vm._s(_vm.brandLength) +
                                  " batches"
                              )
                            ]),
                            _c("span", { staticStyle: { flex: "2" } }, [
                              _vm._v("Select Time: " + _vm._s(_vm.timeText))
                            ])
                          ]),
                          _c("apexchart", {
                            staticClass: "apex-charts",
                            attrs: {
                              dir: "ltr",
                              height: "380",
                              type: "bar",
                              series: _vm.basicColumChart.series,
                              options: _vm.basicColumChart.chartOptions
                            }
                          }),
                          _c("br"),
                          _c("b-table", {
                            attrs: {
                              "show-empty": "",
                              "empty-text": "no records",
                              id: "table",
                              "head-variant": "light",
                              items: _vm.tableData,
                              busy: _vm.isBusy,
                              striped: true,
                              fields: _vm.fields
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "table-busy",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "text-center text-danger my-2"
                                      },
                                      [
                                        _c("b-spinner", {
                                          staticClass: "align-middle"
                                        }),
                                        _c("strong", [_vm._v("Loading...")])
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
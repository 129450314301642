<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Steps :Steps="registSpets" :SetData="SetData" />
    <b-card v-if="!isComplete">
      <b-col lg="12">
        <p class="title" style="font-size:16px;font-weight: bolder;">
          <span
            style="display: inline-block;
            width: 10px;
            height: 20px;
            vertical-align: text-top;
            background-color: #626ed4;"
          ></span>
          Batch Info
        </p>
      </b-col>
      <b-container style="margin-bottom:30px;">
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Batch Name：</label>
            <span class="p-3">{{ info.batchName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Batch ID：</label>
            <span class="p-3">{{ info.number }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Flavor：</label>
            <span class="p-3">{{ info.modelName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Cartridge Model：</label>
            <span class="p-3">{{ info.smokeModel }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Batch Quantity:</label>
            <span class="p-3">{{ info.batchQuantity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Capacity:</label>
            <span class="p-3">{{ info.capacity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Max Frequency:</label>
            <span class="p-3">{{ info.maxFrequency }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Brand Owner:</label>
            <span class="p-3">{{ info.brandName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Factory:</label>
            <span class="p-3">{{ info.manufacturerName }}</span>
          </b-col>
          <!-- <b-col>
            <label class="col-form-label">Remarks:</label>
            <span class="p-3">{{ info.remarks }}</span>
          </b-col> -->
        </b-row>
      </b-container>
      <b-col lg="12">
        <p class="title" style="font-size:16px;font-weight: bolder;">
          <span
            style="display: inline-block;
            width: 10px;
            height: 20px;
            vertical-align: text-top;
            background-color: #626ed4;"
          ></span>
          Import Device
        </p>
      </b-col>
      <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
        <!-- <b-form-group
          label-cols-lg="2"
          label-align-lg="right"
          label="Import Way:"
        >
          <b-form-radio-group
            class="pt-2"
            required
            :options="['upload Excel']"
          ></b-form-radio-group>
        </b-form-group> -->

        <b-form-group label-cols-lg="2" label-align-lg="right">
            <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px);"
            tabindex="-1"
            @change="upload($event)"
            required
          />
            <span class="group-span-filestyle" tabindex="0" b-col="8">
              <label
                for="filestyle-1"
                style="margin-bottom: 0;"
                class="btn btn-secondary"
              >
                <span class="buttonText">upload Excel file</span>
              </label>
              <span v-if="form.file">{{form.file.name}}</span>
              <span v-else>only support xls、xlxs file</span>
            </span>
          <!-- </b-form-group> -->
        </b-form-group>

        <b-form-group label-cols-lg="2" label-align-lg="right" style="margin-top: 50px;">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" style="margin-left: 30px;">Cancel</b-button>
        </b-form-group>
      </b-form>
    </b-card>

    <b-card v-if="isComplete">
      <b-row>
        <b-col lg="12" style="text-align:center;">
          <div style="text-align:center;">
            <div>
              <b-icon
                icon="check-circle"
                variant="success"
                font-scale="5"
              ></b-icon>
            </div>
            <div>
              <p>Device import completed</p>
              <p>{{completeData.total}} total, {{completeData.notActive}} inactive, exception {{completeData.abnormal}}</p>
            </div>
          </div>
          <b-button size="lg" @click="onReset">return</b-button>
        </b-col>
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Steps from "./cartridge-batch-step";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    FormWizard,
    TabContent,
    Multiselect,
    Steps,
  },
  data() {
    return {
      title: "Cartridge Batch Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Cartridge Batch Create",
          active: true,
        },
      ],
      registSpets: this.isComplete ? 2 : 1,
      SetData: ["Create Batch", "Import Production Details", "Complete"],
      isComplete: false,
      infoId: window.location.search,
      info: {},
      form: {
          id:"",
          file:""
      },
      completeData:{}
    };
  },
  beforeCreate() {
    this.infoId = window.location.search;
  },
  created() {
    if (this.infoId) {
      let id = this.infoId.split("=")[1];
      this.form.id = id
      this.getBatchInfo(id);
    }
  },
  watch: {
    isComplete: function(val) {
      this.registSpets = val ? 2 : 1;
    },
  },
  methods: {
    getBatchInfo(id) {
      this.$api.CartridgeBatch.find({ id: id }).then((res) => {
        if (res.success) {
          this.info = res.data;
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      let formData = new FormData();
      formData.append("id",this.form.id);
      formData.append("file",this.form.file);
      let that = this;
      this.$api.CartridgeBatch.addBatch(formData).then((res) => {
        // that.$bvToast.toast(res.data.message);
        if (res.data.success) {
          that.completeData = res.data.data
          that.isComplete = true
        }else{
          that.$bvToast.toast(res.data.message);
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    upload(e) {
      let file = e.target.files[0];
      console.log(file);
      if (/sheet/.test(file.type)) {
        this.form.file = file;
        console.log("uploading", this.form.file);
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support xls、xlxs file");
      }
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("User Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Avatar:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm.info.picture
                        ? _c("img", {
                            staticClass: "avatar-xs rounded-circle mr-2",
                            attrs: { src: _vm.info.picture, alt: "" }
                          })
                        : _vm._e()
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Nickname:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.nickName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Registered Devices:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.binding))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Used Atomizer?:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.isAtomization == 1 ? "Yes" : "No"))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Smoked Vape?:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(_vm.info.isElectricSmoke == 1 ? "Yes" : "No")
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Total Registered Carts:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.smokeRecordTotal))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v(" Used For:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.info.useTime))
                    ])
                  ]),
                  _c(
                    "b-col",
                    { attrs: { lg: "12" } },
                    [
                      _c("label", { staticClass: "col-form-label" }, [
                        _vm._v("Likes:")
                      ]),
                      _vm._l(_vm.info.flavor, function(item, i) {
                        return _c(
                          "b-badge",
                          {
                            key: i,
                            staticStyle: { "margin-left": "10px" },
                            attrs: { variant: "primary" }
                          },
                          [_vm._v(_vm._s(item))]
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Device Control")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Dosage Control:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("-")])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Disable Time:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("-")])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Temp Control:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("-")])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Is Authorized:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("-")])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Referral Code:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [_vm._v("-")])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Registered Devices")])
                ])
              ])
            ],
            1
          ),
          _c("br"),
          _c("b-table", {
            ref: "device_table",
            attrs: {
              id: "device_table",
              "head-variant": "light",
              striped: "",
              fields: _vm.deviceFields,
              items: _vm.device_provider,
              "per-page": _vm.device.pageSize,
              "current-page": _vm.device.pageNum,
              busy: _vm.device_isBusy,
              "show-empty": "",
              "empty-text": "No records"
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(index)",
                fn: function(data) {
                  return [
                    _vm._v(
                      _vm._s(
                        data.index +
                          (_vm.device.pageNum - 1) * _vm.device.pageSize +
                          1
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    _vm.device_totalRows
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.device_totalRows,
                                "aria-controls": "device_table"
                              },
                              model: {
                                value: _vm.device.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.device, "pageNum", $$v)
                                },
                                expression: "device.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Cartridge Info")])
                ])
              ])
            ],
            1
          ),
          _c("br"),
          _c("b-table", {
            ref: "cartridge_table",
            attrs: {
              id: "cartridge_table",
              "head-variant": "light",
              striped: "",
              fields: _vm.cartridgeFields,
              items: _vm.cartridge_provider,
              "per-page": _vm.cartridge.pageSize,
              "current-page": _vm.cartridge.pageNum,
              busy: _vm.cartridge_isBusy,
              "show-empty": "",
              "empty-text": "No records"
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(index)",
                fn: function(data) {
                  return [
                    _vm._v(
                      _vm._s(
                        data.index +
                          (_vm.cartridge.pageNum - 1) * _vm.cartridge.pageSize +
                          1
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    _vm.cartridge_totalRows
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.cartridge_totalRows,
                                "aria-controls": "cartridge_table"
                              },
                              model: {
                                value: _vm.cartridge.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.cartridge, "pageNum", $$v)
                                },
                                expression: "cartridge.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Use History")])
                ])
              ])
            ],
            1
          ),
          _c("b-table", {
            ref: "SmokeBomb_table",
            attrs: {
              "head-variant": "light",
              striped: "",
              fields: _vm.usageRecordFields,
              id: "SmokeBomb_table",
              items: _vm.SmokeBomb_provider,
              "per-page": _vm.SmokeBomb.pageSize,
              "current-page": _vm.SmokeBomb.pageNum,
              busy: _vm.SmokeBom_isBusy,
              "show-empty": "",
              "empty-text": "No records"
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center text-danger my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _c("strong", [_vm._v("Loading...")])
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "cell(index)",
                fn: function(data) {
                  return [
                    _vm._v(
                      _vm._s(
                        data.index +
                          (_vm.SmokeBomb.pageNum - 1) * _vm.SmokeBomb.pageSize +
                          1
                      )
                    )
                  ]
                }
              }
            ])
          }),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    _vm.SmokeBomb_totalRows
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.SmokeBomb_totalRows,
                                "aria-controls": "SmokeBomb_table"
                              },
                              model: {
                                value: _vm.SmokeBomb.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.SmokeBomb, "pageNum", $$v)
                                },
                                expression: "SmokeBomb.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-button",
            {
              attrs: {
                size: "lg",
                type: "reset",
                variant: "outline-secondary"
              },
              on: { click: _vm.returnPage }
            },
            [_vm._v("Go Back")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
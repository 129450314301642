<template>
  <section>
  <b-row class="text-center col-lg-12">
        <b-col lg="12">
            <b-form-group  >
                <b-button 
                @click="goback()"  
                variant="primary"
            >Back</b-button> 
            </b-form-group>
        </b-col>
  </b-row>
  </section>
</template>


<script>
export default {
    name: 'goback',
    data () {
      return {
        //editor2: {},
      }
    },
    props: ['value','config'],
    model: {
        prop: 'value',
        event: 'write',
    },
    mounted () {
    },
    watch: {
    },
    methods: {
          goback(){
               this.$router.go(-1);
          },
    }
}
</script>


<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Customer Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Customer Name:</label>
            <span class="p-3">{{ info.brandName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Customer Area:</label>
            <span class="p-3">{{ info.areaEncodingName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Customer Type:</label>
            <span class="p-3">{{
              info.customerType == 1
                ? "Channel"
                : info.customerType == 2
                ? "Brands"
                : "Smoke oil"
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Customer Role:</label>
            <span class="p-3">{{ info.roleName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Email:</label>
            <span class="p-3">{{ info.email }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Contact Number:</label>
            <span class="p-3">{{ info.phone }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Contacts:</label>
            <span class="p-3">{{ info.contactName }}</span>
          </b-col>
        </b-row>
        <b-row cols="1">
          <b-col>
            <label class="col-form-label">Contact Address:</label>
            <span class="p-3">{{ info.address }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Remark:</label>
            <span class="p-3">{{ info.remarks }}</span>
          </b-col>
        </b-row>
        <b-button @click="toReturn">Go Back</b-button>
      </b-container>
    </b-card>
    <!-- end card -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Accounts Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Account Mgmt",
          // href: "/"
        },
        {
          text: "Accounts Details",
          active: true,
        },
      ],
      info: {
        brandName: "",
        areaEncodingName: "",
        customerType: "",
        roleName: "",
        contactName: "",
        email: "",
        phone: "",
        address: "",
        remarks: "",
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.customerManage.findById(this.$route.query).then((res) => {
          if (res.success) {
            this.info = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    toReturn() {
      this.$router.go(-1);
    },
  },
};
</script>

/**
 * 用户管理 接口模块
 */
// import base from "./base"

import { format } from "./time";
import axios from "../config/http.js";
import baseUrl from "../config/env.js";
import qs from "qs";

const userManage = {
  userList(params) {
    // params.createTime=format(params.createTime)
    // params.endTime=format(params.endTime)
    return axios.fetchGet(baseUrl + "/user/list", params);
  },
  add(params = {}) {
    return axios.fetchPost(baseUrl + "/user/addUser", params);
  },
  // 删除 post
  deleteUser(params) {
    return axios.fetchPost(baseUrl + "/user/deleteById", params);
  },

  // 查看详情 get
  getUser(params) {
    return axios.fetchGet(baseUrl + "/user/findById", params);
  },

  // 获取用户绑定的设备信息 get
  getUserDevice(params) {
    return axios.fetchGet(baseUrl + "/user/findDeviceById", params);
  },
  // 获取用户绑定的烟弹信息 get
  selectBindSmokeRecord(params) {
    return axios.fetchGet(baseUrl + "/user/selectBindSmokeRecord", params);
  },

  // 修改 post
  updateUser(params) {
    // {email:"409493@gmail.com",
    // nickName:wu,
    // gender:0,
    // id:56,}
    return axios.fetchPost(baseUrl + "/user/updateById", params);
  },

  findSmokeBombList(params = {}) {
    return axios.fetchGet(baseUrl + "/user/findSmokeBombList", params);
  },

  resetPassword(params = {}) {
    return axios.fetchPost(baseUrl + "/user/resetPassword", params);
  },

  disableOrEnable(params = {}) {
    return axios.fetchPost(baseUrl + "/user/disableOrEnable", params);
  },
  // enable(params={}){
  // 	return axios.fetchPost(baseUrl + '/user/enable', params);
  // },
  export_excel(params = {}) {
    let d = JSON.parse(JSON.stringify(params));
    // d.createTime=format(params.createTime)
    // d.endTime=format(params.endTime)
    d["platform-token"] = localStorage["auth-token"];
    // d['supplier-token']=localStorage['auth-token']
    location.href = baseUrl + "/user/exportUser?" + qs.stringify(d);
  },
  // 查看用户调研记录列表
  selectAllUserReply(params = {}) {
    return axios.fetchGet(baseUrl + "/problemGroup/selectAllUserReply", params);
  },
  // 查看用户反馈详情
  selectUserReplyDetails(params = {}) {
    return axios.fetchGet(
      baseUrl + "/problemGroup/selectUserReplyDetails",
      params
    );
  },
  // 查询所有问题组下拉框
  selectProblemGroupDropDown(params = {}) {
    return axios.fetchGet(
      baseUrl + "/problemGroup/selectProblemGroupDropDown",
      params
    );
  },
};

export default userManage;

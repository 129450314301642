import { format } from "./time";
import axios from "../config/http.js";
import baseUrl from "../config/env.js";
import axiosJson from "../config/http-json.js";

function list(params, ctx) {
  //    params.createTime=format(params.createTime)
  //    params.endTime=format(params.endTime)
  return axios.fetchGet(baseUrl + "/consultation/list", params);
}

function enableOrDisable(params = {}) {
  return axios.fetchPost(baseUrl + "/consultation/enableOrDisable", params);
}

function add(params) {
  return axiosJson.fetchPost(
    baseUrl + "/consultation/saveConsultation",
    params
  );
}

function findById(params) {
  return axios.fetchPost(baseUrl + "/consultation/findById", params);
}

function updateById(params) {
  return axiosJson.fetchPost(baseUrl + "/consultation/updateById", params);
}

function del(params) {
  return axios.fetchPost(baseUrl + "/consultation/removeById", params);
}

export default {
  list,
  findById,
  add,
  updateById,
  del,
  enableOrDisable,
};

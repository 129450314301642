<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Feedback Content"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="feedback-content"
              >
                <b-form-input
                  id="feedback-content"
                  trim
                  v-model="search_condition.content"
                  oninput="if(value.length > 100)value = value.slice(0, 100)"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label="Feedback Email"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="email"
              >
                <b-form-input
                  v-model="search_condition.email"
                  id="email"
                  trim
                  oninput="if(value.length > 50)value = value.slice(0, 50)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Feedback Ascription"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="feedback-ascription"
              >
                <b-form-select
                  v-model="search_condition.ascription"
                  :options="types.ascriptionList"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col lg="4">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="status"
              >
                <b-form-select
                  v-model="search_condition.state"
                  :options="types.stateList"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label-align-sm="right">
                <b-button variant="primary" @click="search()">Search</b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="device-table"
              head-variant="light"
              :striped="true"
              ref="table"
              :busy="isBusy"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
              }}</template>

              <template v-slot:cell(picture)="data">
                <img
                  v-if="data.item.picture"
                  :src="`${data.item.picture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
                <!-- {{ data.item.nickName }} -->
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group v-if="row.item.state == 0">
                  <b-button
                    v-has="'feedback::Process'"
                    size="sm"
                    variant="info"
                    @click="process(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Process
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { download } from "@/api/excel";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * User component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "User Feedback",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "App Manage",
          // href: "/"
        },
        {
          text: "User Feedback",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "content", label: "Feedback Content", class: "text-center" },
        { key: "picture", label: "Feedback Picture", class: "text-center" },
        {
          key: "ascription",
          label: "Feedback Ascription",
          class: "text-center",
          formatter: (value) => {
            return value == 1
              ? "Nicotine Salt"
              : value == 2
              ? "CBD"
              : value == 3
              ? "Supplement Android"
              : value == 4
              ? "Vaporizer Android"
              : "DIME Android";
          },
        },
        { key: "nickName", label: "Feedback Customer", class: "text-center" },
        { key: "email", label: "Feedback Email", class: "text-center" },
        {
          key: "state",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Resolved" : "Not solved";
          },
        },
        {
          key: "createTime",
          label: "Feedback Time",
          class: "text-center",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
        },
        { key: "actions", label: "Action", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        content: "",
        email: "",
        ascription: "",
        state: "",
      },
      types: {
        ascriptionList: [
          { text: "Please select", value: "", disabled: false },
          { text: "Supplement Android", value: 3, disabled: false },
          { text: "Vaporizer Android", value: 4, disabled: false },
          { text: "DIME Android", value: 5, disabled: false },
        ],
        stateList: [
          { text: "Please select", value: "", disabled: false },
          { text: "Resolved", value: 1, disabled: false },
          { text: "Not solved", value: 0, disabled: false },
        ],
      },
      isBusy: false,
      has_data: false,
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.appManage
        .feedbackList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            this.tableData = x.data;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // 停用启用
    process(a, b, c) {
      this.$api.appManage
        .process({
          id: a.id,
        })
        .then((res) => {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          if (res.success) {
            this.$refs.table.refresh();
          }
        });
    },
  },
};
</script>

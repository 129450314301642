<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 登录账号 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Account:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="account"
              label-class="requiredRow"
            >
              <b-form-input
                id="account"
                v-model="form.username"
                placeholder="Please enter"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
                :disabled="editType"
              ></b-form-input>
              <span>6-20 Letters/number/character components</span>
            </b-form-group>
          </b-col>
        </div>

        <!-- 昵称 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Nickname:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="nickname"
              label-class="requiredRow"
            >
              <b-form-input
                id="nickname"
                v-model="form.nickName"
                placeholder="Please enter"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
              ></b-form-input>
              <span>50 Letters/number/character components</span>
            </b-form-group>
          </b-col>
        </div>

        <!-- 选择角色 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Role:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="role"
              label-class="requiredRow"
            >
              <b-form-select
                id="role"
                v-model="form.roleId"
                :options="types.roleList"
                value-field="id"
                text-field="roleName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('');"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>

        <!-- 密码 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Password:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="password"
              label-class="requiredRow"
            >
              <b-form-input
                id="password"
                v-model="form.password"
                trim
                required
                placeholder="Please enter"
                type="password"
                :disabled="editType"
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
              ></b-form-input>
              <b-button
                v-if="editType"
                variant="outline-secondary"
                @click="modalShow = !modalShow"
                >Change</b-button
              >
              <span>6-20 Letters/number/character components</span>
            </b-form-group>
          </b-col>
        </div>

        <!-- 确认密码 -->
        <div class="form-group" v-if="!editType">
          <b-col lg="10">
            <b-form-group
              label="Confirm password:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="password"
              label-class="requiredRow"
            >
              <b-form-input
                id="password"
                v-model="confirmPassword"
                trim
                required
                placeholder="Please enter"
                type="password"
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </div>

        <!-- 姓名 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.fullName"
                trim
                placeholder="Please enter"
                oninput="if(value.length > 20)value = value.slice(0, 20)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </div>

        <!-- 联系电话 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Phone:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="form.phone"
                trim
                placeholder="Please enter"
                oninput="if(value.length > 50)value = value.slice(0, 50)"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </div>

        <!-- 头像 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Photo:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="Photo"
            >
              <input
                type="file"
                class="filestyle"
                data-input="false"
                id="filestyle-1"
                style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
                tabindex="-1"
                @change="upload($event)"
              />

              <div class="bootstrap-filestyle input-group">
                <div
                  name="filedrag"
                  style="
                    position: absolute;
                    width: 100%;
                    height: 35px;
                    z-index: -1;
                  "
                ></div>
                <span class="group-span-filestyle" tabindex="0">
                  <div v-if="form.picture" style="margin-bottom: 10px">
                    <img
                      :src="form.picture"
                      style="
                        width: 100px;
                        height: 100px;
                        border: 2px dashed #ccc;
                      "
                    />
                    <span
                      style="margin-left: 10px; vertical-align: bottom"
                      v-if="!editId"
                    >
                      <span style="font-weight: 500">Picture name : </span
                      >{{ photoName }}</span
                    >
                  </div>
                  <label
                    for="filestyle-1"
                    style="margin-bottom: 0"
                    class="btn btn-secondary"
                  >
                    <span class="buttonText"> upload picture </span>
                  </label>
                  <span>
                    Recommended size of picture: 120 pixels * 120 pixels,
                    supporting JPG and PNG formats.
                  </span>
                </span>
              </div>
            </b-form-group>
          </b-col>
        </div>

        <!-- 状态 -->
        <div class="form-group">
          <b-col lg="10">
            <b-form-group
              label="Status:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="status"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="form.status"
                :options="types.status"
                name="status"
                style="padding-top: 5px"
              >
              </b-form-radio-group>
            </b-form-group>
          </b-col>
        </div>

        <div class="form-group">
          <b-col lg="10">
            <b-form-group label-cols-lg="3">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" variant="danger" class="m-l-5 ml-1"
                >Cancel</b-button
              >
            </b-form-group>
          </b-col>
        </div>
      </b-form>

      <!-- 修改密码弹框 -->
      <b-modal
        ref="modal"
        v-model="modalShow"
        title="Edit Password"
        @hidden="resetModal"
        hide-footer
      >
        <form ref="form" @submit="onSure" @reset="onClose">
          <b-form-group
            label="Password:"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="password"
            label-class="requiredRow"
          >
            <b-form-input
              id="password"
              v-model="modalData.password"
              trim
              required
              placeholder="Enter password"
              type="password"
              oninvalid="setCustomValidity('Please enter');"
              oninput="setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Confirm password:"
            label-cols-lg="4"
            label-align-lg="right"
            label-for="password"
            label-class="requiredRow"
          >
            <b-form-input
              id="password"
              v-model="modalData.confirmPassword"
              trim
              required
              placeholder="Enter password"
              type="password"
              oninvalid="setCustomValidity('Please enter');"
              oninput="setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
            ></b-form-input>
          </b-form-group>
          <b-form-group label-cols-lg="4">
            <b-button type="submit" variant="primary">Confirm</b-button>
            <b-button type="reset" variant="danger" class="m-l-5 ml-1"
              >Cancel</b-button
            >
          </b-form-group>
        </form>
      </b-modal>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Accounts Modify" : "Accounts Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "System Mgmt",
            // href: "/"
          },
          {
            text: this.editId ? "Accounts Modify" : "Accounts Create",
            active: true,
          },
        ],
      },
      form: {
        username: "",
        nickName: "",
        roleId: "",
        password: "",
        fullName: "",
        phone: "",
        picture: "",
        status: "",
      },
      types: {
        roleList: [{ id: "", roleName: "Please select" }],
        status: [
          { text: "Enabled", value: 0, disabled: false },
          { text: "Disabled", value: 1, disabled: false },
        ],
      },
      modalShow: false,
      modalData: {
        password: "",
        confirmPassword: "",
      },
      editor: ClassicEditor,
      editId: window.location.search,
      editType: false,
      photoName: "",
      confirmPassword: "",
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      this.editId = this.editId.split("=")[1];
      this.editType = true;
      this.formData(this.editId);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    onReady: onReady,
    init() {
      this.$api.Dropdown.selectEnableRole({}).then((res) => {
        if (res.success) {
          this.types.roleList = this.types.roleList.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.editId) {
        this.$api.systemManage.updateById(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        if (this.form.password != this.confirmPassword) {
          this.$bvToast.toast(
            "The two passwords are inconsistent. Please reconfirm the password"
          );
        } else {
          this.$api.systemManage.add(this.form).then((res) => {
            if (res.success) {
              this.$router.go(-1);
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
        }
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        username: "",
        nickName: "",
        roleId: "",
        password: "",
        fullName: "",
        phone: "",
        picture: "",
        status: "",
      };
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.form.picture = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    formData(id) {
      this.$api.systemManage.findById({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.form.password = "123456";
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSure(evt) {
      evt.preventDefault();
      if (this.modalData.password != this.modalData.confirmPassword) {
        this.$bvToast.toast(
          "The two passwords are inconsistent. Please reconfirm the password"
        );
      } else {
        this.$api.systemManage
          .changePasswordById({
            id: this.editId,
            password: this.modalData.password,
          })
          .then((res) => {
            if (res.success) {
              this.modalShow = !this.modalShow;
            }
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          });
      }
    },
    onClose(evt) {
      evt.preventDefault();
      this.modalData = {
        password: "",
        confirmPassword: "",
      };
      this.modalShow = !this.modalShow;
    },
    resetModal() {
      this.modalData = {
        password: "",
        confirmPassword: "",
      };
    },
  },
};
</script>

<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />

		<b-card>
			<b-form @submit="onSubmit" @reset="onReset">
				<!-- 推送范围 -->
				<b-form-group
					label="Scope Of Pushed:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="scope"
					label-class="requiredRow"
				>
					<b-form-select
						id="scope"
						v-model="form.state"
						:options="types.states"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('')"
					>
					</b-form-select>
				</b-form-group>

				<!-- 推送范围 -->
				<b-form-group
					v-if="form.state == 1"
					label="Specify User:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="specify"
					label-class="requiredRow"
				>
					<multiselect
						v-model="inputText"
						:options="types.retrievalUserList"
						:multiple="true"
						placeholder="Please select"
						@input="selectRetrievalUser"
						label="email"
						track-by="id"
						required
					></multiselect>

					<!-- <b-form-input
            v-model="inputText"
            @input="init(inputText)"
            placeholder="Please enter email address for retrieval"
            @blur="clearInputText"
            required
            oninvalid="setCustomValidity('Please enter and specify a user');"
            oninput="setCustomValidity('')"
          ></b-form-input>
          <ul
            style="max-height: 260px;
            overflow: scroll;
            border: 1px solid #000;
            padding:0;"
            v-if="showSelect"
          >
            <li class="retrievalUser" style="list-style: none;padding-left:10px;"
              v-for="(item, index) in types.retrievalUserList"
              :key="index"
              :value="item.id"
              v-text="item.email"
              @click="clickRetrievalUser(item)"
            ></li>
          </ul> -->
				</b-form-group>

				<!-- 推送标题 -->
				<b-form-group
					label="Push The Title:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="title"
					label-class="requiredRow"
				>
					<b-form-input
						id="title"
						v-model="form.title"
						placeholder="Please enter"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
					></b-form-input>
				</b-form-group>

				<!-- 推送内容  -->
				<b-form-group
					label="Push Content:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="pushContent"
					label-class="requiredRow"
				>
					<!-- <ckeditor
            v-model="form.content"
            :editor="editor"
            @ready="onReady"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('')"
          ></ckeditor> -->
					<div id="wangeEditor" style="max-width: 1260px"></div>
				</b-form-group>

				<!-- 备注 -->
				<b-form-group
					label="Notes:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="remarks"
				>
					<b-form-textarea
						id="remarks"
						v-model="form.remarks"
						placeholder="Enter Notes"
						rows="3"
						max-rows="6"
					></b-form-textarea>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" variant="danger" class="m-l-5 ml-1"
						>Cancel</b-button
					>
				</b-form-group>
			</b-form>
		</b-card>
	</Layout>
</template>
<script>
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import baseUrl from '@/config/env.js'

import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Multiselect from 'vue-multiselect'

import E from 'wangeditor'
const editor = new E('#wangeEditor')

import { onReady, upload_img } from '@/api/upload'
import { BIconEjectFill } from 'bootstrap-vue'
/**
 * FAQ Add component
 */
export default {
	components: {
		Layout,
		PageHeader,
		ckeditor: CKEditor.component,
		Multiselect,
	},
	data() {
		return {
			page: {
				title: 'Message Pushed',
				items: [
					{
						text: 'UNI-CORE',
						// href: "/"
					},
					{
						text: 'Marketing Manage',
						// href: "/"
					},
					{
						text: this.editId
							? 'Message Pushed Modify'
							: 'Message Pushed Create',
						active: true,
					},
				],
			},
			inputText: [],
			showSelect: false,
			form: {
				state: '',
				userId: [],
				title: '',
				content: '',
				remarks: '',
			},
			types: {
				states: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'All users', value: 0, disabled: false },
					{ text: 'Specify user', value: 1, disabled: false },
				],
				retrievalUserList: [],
			},
			editor: ClassicEditor,
			editId: window.location.search,
		}
	},
	// beforeCreate() {
	//   this.editId = window.location.search;
	// },
	created() {
		// if (this.editId) {
		//   let id = this.editId.split("=")[1];
		//   this.formData(id);
		// }
		this.init()
	},
	mounted() {
		// 选择语言
		editor.customConfig.lang = {
			设置标题: 'title',
			正文: 'p',
			链接文字: 'link text',
			链接: 'link',
			插入: 'insert ',
			视频: 'video',
			创建: 'init',
			图片链接: 'pictures linking',
			网络: 'internet',
			上传: 'upload ',
			图片: 'picture',
			默认: 'default',
			代码: 'code',
			格式如: 'format',
			新浪: 'sina',
			字号: 'Font size',
			字体: 'Font',
			设置列表: 'Settings list',
			有序列表: 'Ordered list',
			无序列表: 'Unordered list',
			插入: 'insert ',
			插入表格: 'insert table',
			编辑: 'edit ',
			最大宽度: 'maximum width',
			删除: 'delete ',
			行: 'row',
			列: 'column',
			的: ' ',
			表格: 'table',
			上传图片: 'Upload picture',
			网络图片: 'Network picture',
			插入表格: 'Insert table',
			插入视频: 'Insert video',
			插入代码: 'Insert code',
			文字颜色: 'Text color',
			背景色: 'Background color',
			对齐方式: 'Alignment',
			靠左: 'left',
			居中: 'Center',
			靠右: 'right',
			// 还可自定添加更多
		}

		editor.customConfig.uploadImgServer = baseUrl + '/platform/uploadFile'
		editor.customConfig.uploadImgHeaders = {
			'platform-token': window.localStorage.getItem('auth-token'),
		}
		editor.customConfig.uploadFileName = 'file'

		editor.customConfig.uploadImgHooks = {
			// 上传图片之前
			before: function (xhr) {
				console.log(xhr)

				// 可阻止图片上传
				// return {
				//   prevent: true,
				//   msg: "需要提示给用户的错误信息",
				// };
			},
			// 图片上传并返回了结果，图片插入已成功
			success: function (xhr) {
				console.log('success', xhr)
			},
			// 图片上传并返回了结果，但图片插入时出错了
			fail: function (xhr, editor, resData) {
				console.log('fail', resData)
			},
			// 上传图片出错，一般为 http 请求的错误
			error: function (xhr, editor, resData) {
				console.log('error', xhr, resData)
			},
			// 上传图片超时
			timeout: function (xhr) {
				console.log('timeout')
			},
			// 图片上传并返回了结果，想要自己把图片插入到编辑器中
			// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
			customInsert: function (insertImgFn, result) {
				// result 即服务端返回的接口
				console.log('customInsert', result)

				// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
				result.data.forEach((element) => {
					insertImgFn(element.url)
				})
			},
		}

		editor.create()
	},
	// mounted() {
	//   if (!this.editId) {
	//     this.init();
	//   }
	// },
	methods: {
		onReady: onReady,
		init() {
			this.$api.marketingManage.retrievalUser().then((res) => {
				if (res.success) {
					if (res.data.length > 0) {
						this.types.retrievalUserList = res.data
						this.showSelect = true
					} else {
						this.showSelect = false
					}
				} else {
					if (res.message) {
						this.$bvToast.toast(res.message)
					}
				}
			})
		},
		// clickRetrievalUser(item) {
		//   this.inputText = item.email;
		//   this.form.userId = item.id;
		//   this.showSelect = false;
		// },
		// clearInputText() {},
		selectRetrievalUser(val) {
			this.form.userId = val.map((item) => {
				return item.id
			})
		},
		// selectState(val){
		//   if(val==0){
		//     this.form.userId = null;
		//   }
		// },
		onSubmit(evt) {
			evt.preventDefault()
			// if (this.form.id) {
			//   this.$api.marketingManage.updateById(this.form).then((res) => {
			//     if (res.success) {
			//       this.$router.go(-1);
			//     } else {
			//       if(res.message){
			//         this.$bvToast.toast(res.message);
			//       }
			//     }
			//   });
			// } else {
			this.form.content = editor.txt.html()
			this.$api.marketingManage.add(this.form).then((res) => {
				if (res.success) {
					this.$router.go(-1)
				} else {
					if (res.message) {
						this.$bvToast.toast(res.message)
					}
				}
			})
			// }
		},
		onReset(evt) {
			evt.preventDefault()
			this.$nextTick(() => {
				this.$router.go(-1)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.retrievalUser {
	&:hover {
		background-color: rgb(30, 144, 255);
	}
}
</style>
<style>
.w-e-menu {
	z-index: 2 !important;
}
.w-e-text-container,
.w-e-toolbar {
	z-index: 1 !important;
}
</style>

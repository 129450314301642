const basicColumChart = {
  chartOptions: {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    // colors: ['#626ed4', '#02a499', '#dee2e6'],
    xaxis: {
      categories: [],
    },
  },
  series: [
    {
      name: "Inactivated",
      data: [],
    },
    {
      name: "Activated",
      data: [],
    },
    {
      name: "Expired",
      data: [],
    },
    {
      name: "Abnormal",
      data: [],
    },
  ],
};

export { basicColumChart };

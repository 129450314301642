var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "topnav" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c(
        "nav",
        { staticClass: "navbar navbar-light navbar-expand-lg topnav-menu" },
        [
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "topnav-menu-content" }
            },
            [
              _c("ul", { staticClass: "navbar-nav" }, [
                _c(
                  "li",
                  { staticClass: "nav-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "nav-link side-nav-link",
                        attrs: { tag: "a", to: "/" }
                      },
                      [
                        _c("i", { staticClass: "ti-home mr-2" }),
                        _vm._v("Dashboard ")
                      ]
                    )
                  ],
                  1
                ),
                _c("li", { staticClass: "nav-item dropdown mega-dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle arrow-none",
                      attrs: {
                        href: "javascript: void(0);",
                        id: "topnav-uielement",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      on: { click: _vm.onMenuClick }
                    },
                    [
                      _c("i", { staticClass: "ti-package mr-2" }),
                      _vm._v("UI Elements ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu mega-dropdown-menu dropdown-mega-menu-xl",
                      attrs: { "aria-labelledby": "topnav-uielement" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-4" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/alerts" }
                                },
                                [_vm._v("Alerts")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/buttons" }
                                },
                                [_vm._v("Buttons")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/cards" }
                                },
                                [_vm._v("Cards")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/carousel" }
                                },
                                [_vm._v("Carousel")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/dropdowns" }
                                },
                                [_vm._v("Dropdowns")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/grid" }
                                },
                                [_vm._v("Grid")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-4" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/images" }
                                },
                                [_vm._v("Images")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/modals" }
                                },
                                [_vm._v("Modals")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/rangeslider" }
                                },
                                [_vm._v("Range Slider")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/progressbar" }
                                },
                                [_vm._v("Progress Bars")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/sweetalert" }
                                },
                                [_vm._v("Sweet-Alert")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/tabs" }
                                },
                                [_vm._v("Tabs & Accordions")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-4" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/typography" }
                                },
                                [_vm._v("Typography")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/video" }
                                },
                                [_vm._v("Video")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/general" }
                                },
                                [_vm._v("General")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/colors" }
                                },
                                [_vm._v("Colors")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { to: "/ui/rating" }
                                },
                                [_vm._v("Rating")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ]),
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle arrow-none",
                      attrs: {
                        href: "javascript: void(0);",
                        id: "topnav-pages",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      on: { click: _vm.onMenuClick }
                    },
                    [
                      _c("i", { staticClass: "ti-harddrives mr-2" }),
                      _vm._v("Components ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { "aria-labelledby": "topnav-pages" }
                    },
                    [
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item dropdown-toggle arrow-none",
                            attrs: {
                              href: "javascript: void(0);",
                              id: "topnav-email",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: { click: _vm.onMenuClick }
                          },
                          [
                            _vm._v(" Email "),
                            _c("div", { staticClass: "arrow-down" })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "topnav-email" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { to: "/email/inbox" }
                              },
                              [_vm._v("Inbox")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { to: "/email/read-email" }
                              },
                              [_vm._v("Email Read")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { to: "/email/compose" }
                              },
                              [_vm._v("Email Compose")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { tag: "a", to: "/calendar" }
                        },
                        [_vm._v("Calendar")]
                      ),
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item dropdown-toggle arrow-none",
                            attrs: {
                              href: "javascript: void(0);",
                              id: "topnav-form",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: { click: _vm.onMenuClick }
                          },
                          [
                            _vm._v(" Forms "),
                            _c("div", { staticClass: "arrow-down" })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "topnav-form" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/elements" }
                              },
                              [_vm._v("Form Elements")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/validation" }
                              },
                              [_vm._v("Form Validation")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/advanced" }
                              },
                              [_vm._v("Form Advanced")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/editor" }
                              },
                              [_vm._v("Form Editors")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/uploads" }
                              },
                              [_vm._v("Form File Upload")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/repeater" }
                              },
                              [_vm._v("Form Repeater")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/wizard" }
                              },
                              [_vm._v("Form Wizard")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/form/mask" }
                              },
                              [_vm._v("Form Mask")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item dropdown-toggle arrow-none",
                            attrs: {
                              href: "javascript: void(0);",
                              id: "topnav-chart",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: { click: _vm.onMenuClick }
                          },
                          [
                            _vm._v(" Charts "),
                            _c("div", { staticClass: "arrow-down" })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "topnav-chart" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/charts/chartist" }
                              },
                              [_vm._v("Chartist Chart")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/charts/chartjs" }
                              },
                              [_vm._v("Chartjs Chart")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/charts/apex" }
                              },
                              [_vm._v("Apex Chart")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/charts/echart" }
                              },
                              [_vm._v("E - Chart")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item dropdown-toggle arrow-none",
                            attrs: {
                              href: "javascript: void(0);",
                              id: "topnav-table",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: { click: _vm.onMenuClick }
                          },
                          [
                            _vm._v(" Tables "),
                            _c("div", { staticClass: "arrow-down" })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "topnav-table" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/tables/basic" }
                              },
                              [_vm._v("Basic Tables")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/tables/advancedtable" }
                              },
                              [_vm._v("Advanced Table")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", { staticClass: "dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "dropdown-item dropdown-toggle arrow-none",
                            attrs: {
                              href: "javascript: void(0);",
                              id: "topnav-contact",
                              role: "button",
                              "data-toggle": "dropdown",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            },
                            on: { click: _vm.onMenuClick }
                          },
                          [
                            _vm._v(" icons "),
                            _c("div", { staticClass: "arrow-down" })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "dropdown-menu",
                            attrs: { "aria-labelledby": "topnav-contact" }
                          },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/icons/material" }
                              },
                              [_vm._v("Material Design")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/icons/fontawesome" }
                              },
                              [_vm._v("Font Awesome")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/icons/ion" }
                              },
                              [_vm._v("Ion Icons")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/icons/themify" }
                              },
                              [_vm._v("Themify Icons")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/icons/dripicons" }
                              },
                              [_vm._v("Dripicons")]
                            ),
                            _c(
                              "router-link",
                              {
                                staticClass: "dropdown-item side-nav-link",
                                attrs: { tag: "a", to: "/icons/typicons" }
                              },
                              [_vm._v("Typicons Icons")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { tag: "a", to: "/maps/google" }
                        },
                        [_vm._v("Google maps")]
                      )
                    ],
                    1
                  )
                ]),
                _c("li", { staticClass: "nav-item dropdown mega-dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle arrow-none",
                      attrs: {
                        href: "javascript: void(0);",
                        id: "topnav-chart",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      on: { click: _vm.onMenuClick }
                    },
                    [
                      _c("i", { staticClass: "ti-archive mr-2" }),
                      _vm._v(" Authentication ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu mega-dropdown-menu dropdown-mega-menu-lg",
                      attrs: { "aria-labelledby": "topnav-uielement" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-6" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/login-1" }
                                },
                                [_vm._v("Login 1")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/register-1" }
                                },
                                [_vm._v("Register 1")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/recoverpwd-1" }
                                },
                                [_vm._v("Recover Password 1")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/lock-screen1" }
                                },
                                [_vm._v("Lock Screen 1")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-6" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/login-2" }
                                },
                                [_vm._v("Login 2")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/register-2" }
                                },
                                [_vm._v("Register 2")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/recoverpwd-2" }
                                },
                                [_vm._v("Recover Password 2")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/lock-screen2" }
                                },
                                [_vm._v("Lock Screen 2")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ]),
                _c("li", { staticClass: "nav-item dropdown mega-dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle arrow-none",
                      attrs: {
                        href: "javascript: void(0);",
                        id: "topnav-chart",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      on: { click: _vm.onMenuClick }
                    },
                    [
                      _c("i", { staticClass: "ti-support mr-2" }),
                      _vm._v(" Extra Pages ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-menu mega-dropdown-menu dropdown-mega-menu-lg",
                      attrs: { "aria-labelledby": "topnav-uielement" }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-lg-6" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/timeline" }
                                },
                                [_vm._v("Timeline")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/invoice" }
                                },
                                [_vm._v("Invoice")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/directory" }
                                },
                                [_vm._v("Directory")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/blank-page" }
                                },
                                [_vm._v("Blank Page")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/404" }
                                },
                                [_vm._v("Error 404")]
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "col-lg-6" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/500" }
                                },
                                [_vm._v("Error 500")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/pricing" }
                                },
                                [_vm._v("Pricing")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/maintenance" }
                                },
                                [_vm._v("Maintenance")]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "dropdown-item side-nav-link",
                                  attrs: { tag: "a", to: "/pages/faqs" }
                                },
                                [_vm._v("Faq")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]
                  )
                ]),
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle arrow-none",
                      attrs: {
                        href: "javascript: void(0);",
                        id: "topnav-layout",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      on: { click: _vm.onMenuClick }
                    },
                    [
                      _c("i", { staticClass: "ti-bookmark-alt mr-2" }),
                      _vm._v("Email Templates ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { "aria-labelledby": "topnav-layout" }
                    },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { tag: "a", to: "/email-template/basic" }
                        },
                        [_vm._v("Basic Action Email")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { tag: "a", to: "/email-template/alert" }
                        },
                        [_vm._v("Alert Email")]
                      ),
                      _c(
                        "router-link",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { tag: "a", to: "/email-template/billing" }
                        },
                        [_vm._v("Billing Email")]
                      )
                    ],
                    1
                  )
                ]),
                _c("li", { staticClass: "nav-item dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link dropdown-toggle arrow-none",
                      attrs: {
                        href: "javascript: void(0);",
                        id: "topnav-layout",
                        role: "button",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false"
                      },
                      on: { click: _vm.onMenuClick }
                    },
                    [
                      _c("i", { staticClass: "ti-layout mr-2" }),
                      _vm._v(" Layouts ")
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { "aria-labelledby": "topnav-layout" }
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { href: "javascript: void(0);" },
                          on: {
                            click: function($event) {
                              return _vm.changeLayout("vertical")
                            }
                          }
                        },
                        [_vm._v("Vertical")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { href: "javascript: void(0);" },
                          on: { click: _vm.topbarLight }
                        },
                        [_vm._v("Topbar Light")]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item side-nav-link",
                          attrs: { href: "javascript: void(0);" },
                          on: { click: _vm.boxedWidth }
                        },
                        [_vm._v("Boxed Width")]
                      )
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
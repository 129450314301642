import {format} from "./time"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"


async function list(params,ctx){
    return axios.fetchGet(baseUrl + '/messages/list',params)
}

function add(params){
    return axios1.fetchPost(baseUrl + '/messages/add', params);
}

function retrievalUser(params){
    return axios.fetchGet(baseUrl + '/messages/retrievalUser', params);
}

export default {
    list,
    add,
    retrievalUser
}

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Wax Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.pasteTypeName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "pasteTypeName", $$v)
                          },
                          expression: "form.pasteTypeName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Affiliated Customer:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "customer",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "customer",
                          options: _vm.types.allSupplier,
                          "value-field": "id",
                          "text-field": "brandName",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('');"
                        },
                        model: {
                          value: _vm.form.supplierId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "supplierId", $$v)
                          },
                          expression: "form.supplierId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Type Description:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "brand-name"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "brand-name",
                          oninput:
                            "if(value.length > 100)value = value.slice(0, 100)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.typeDescription,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "typeDescription", $$v)
                          },
                          expression: "form.typeDescription"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Notes:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "remarks"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "remarks",
                          placeholder: "Enter Notes",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
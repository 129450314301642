import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=19f8877c&"
import script from "./sidebar.vue?vue&type=script&lang=js&"
export * from "./sidebar.vue?vue&type=script&lang=js&"
import style1 from "./sidebar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\公司文件\\web\\uni-platform\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('19f8877c')) {
      api.createRecord('19f8877c', component.options)
    } else {
      api.reload('19f8877c', component.options)
    }
    module.hot.accept("./sidebar.vue?vue&type=template&id=19f8877c&", function () {
      api.rerender('19f8877c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/sidebar.vue"
export default component.exports
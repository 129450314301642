var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm._v(" " + _vm._s(new Date().getFullYear()) + " © UNI-CORE "),
          _vm._m(0)
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "d-none d-sm-inline-block" }, [
      _vm._v(" - Crafted with "),
      _c("i", { staticClass: "mdi mdi-heart text-danger" }),
      _vm._v(" by UNI-CORE ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }
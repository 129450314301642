var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Production ID:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "instructions",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "instructions",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.applicationInstructions,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "applicationInstructions", $$v)
                          },
                          expression: "form.applicationInstructions"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Application Type:",
                        "label-cols-sm": "2",
                        "label-align-sm": "right",
                        "label-for": "type",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          options: _vm.types.typeList,
                          "value-field": "value",
                          "text-field": "text",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('');"
                        },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Affiliated Customer:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "customer",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "customer",
                          options: _vm.types.allSupplier,
                          "value-field": "id",
                          "text-field": "brandName",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('');"
                        },
                        model: {
                          value: _vm.form.supplierId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "supplierId", $$v)
                          },
                          expression: "form.supplierId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      staticClass: "inner",
                      attrs: {
                        label: "Cartridge model:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _vm._l(_vm.form.modelNumber, function(data, index) {
                        return _c(
                          "div",
                          { key: data.id, staticClass: "inner mb-3 row" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-4 col-4" },
                              [
                                _c("b-form-select", {
                                  attrs: {
                                    id: "cartridge-type",
                                    options: _vm.types.SmokeBombModel,
                                    "value-field": "id",
                                    "text-field": "modelName",
                                    required: "",
                                    oninvalid:
                                      "setCustomValidity('Please select');",
                                    oninput: "setCustomValidity('');"
                                  },
                                  model: {
                                    value: data.id,
                                    callback: function($$v) {
                                      _vm.$set(data, "id", $$v)
                                    },
                                    expression: "data.id"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-4 col-4" },
                              [
                                _c("b-form-input", {
                                  staticClass: "inner form-control",
                                  attrs: {
                                    type: "number",
                                    min: "0",
                                    placeholder: "Please enter the quantity",
                                    required: "",
                                    oninvalid:
                                      "setCustomValidity('Please enter');",
                                    oninput: "setCustomValidity('');"
                                  },
                                  model: {
                                    value: data.number,
                                    callback: function($$v) {
                                      _vm.$set(data, "number", $$v)
                                    },
                                    expression: "data.number"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm.form.modelNumber.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-md-2 col-2" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: { variant: "danger" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteModelNumber(index)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "primary" },
                          on: { click: _vm.addModelNumber }
                        },
                        [_vm._v("Add")]
                      )
                    ],
                    2
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Notes:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "remarks"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "remarks",
                          placeholder: "Enter Notes",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
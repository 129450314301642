<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Basic Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">User Name:</label>
            <span class="p-4">{{ form.userName }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Brand:</label>
            <span class="p-4">{{ form.brandName }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Device Model:</label>
            <span class="p-4">{{ form.deviceModel }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Device MAC:</label>
            <span class="p-4">{{ form.deviceNumber }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Firmware:</label>
            <span class="p-4">{{ form.firmwareVersion }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Cartridge Batch No:</label>
            <span class="p-4">{{ form.cartridgeNumber }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Cartridge Model:</label>
            <span class="p-4">{{ form.cartridgeModel }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Version Of App:</label>
            <span class="p-4">{{ form.softwareVersion }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
    </b-card>
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Test Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Pre-Time(S):</label>
            <span class="p-4">{{ form.warmUpTimes }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Pre-Temp(℉):</label>
            <span class="p-4">{{ form.warmUpTemperature }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Heating Duration(S):</label>
            <span class="p-4">{{ form.duration }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Use Time(S):</label>
            <span class="p-4">{{
              form.createTime ? getLocalTimes(form.createTime) : ""
            }}</span>
            <!-- <span class="p-4">{{ form.createTime }}</span> -->
          </b-col>

          <b-col md="12">
            <label class="col-form-label">Temp Curve(℉):</label>
            <span class="p-4">{{ form.heatingTemperature }}</span>
          </b-col>

          <b-col> </b-col>
          <b-col md="12">
            <label class="col-form-label">Resistance Curve:</label>
            <span class="p-4">{{ form.resistance }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card>
      <b-button
        size="lg"
        type="reset"
        variant="outline-secondary"
        @click="onReset"
        >Back To List</b-button
      >
    </b-card>
  </Layout>
</template>
<script>
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Test Record Info",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Manage",
          // href: "/"
        },
        {
          text: "Test Record Info",
          active: true,
        },
      ],
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "deviceNumber", label: "Device NO.", class: "text-center" },
        { key: "deviceCoding", label: "Device Coding", class: "text-center" },
        {
          key: "createTime",
          label: "Device Time",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
          class: "text-center",
        },
        { key: "address", label: "Device Location", class: "text-center" },
        {
          key: "totalDuration",
          label: "Duration of use(s)",
          class: "text-center",
        },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        id: "",
      },
      totalRows: 0,
      form: {
        id: "",
        userName: "",
        brandName: "",
        deviceNumber: "",
        firmwareVersion: "",
        batchNumber: "",
        cartridgeModel: "",
        cartridgeNumber: "",
        softwareVersion: "",
        deviceModel: "",
        warmUpTimes: "",
        warmUpTemperature: "",
        duration: "",
        createTime: "",
        heatingTemperature: "",
        resistance: "",
      },
      device_usage: [],
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },

  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.warmUpMode
          .selectByCartsDataId(this.$route.query)
          .then((res) => {
            if (res.success && res.data) {
              this.form = res.data;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReset() {
      this.$router.push("testRecord");
    },
    getLocalTimes(value) {
      return timeZoneConversion.getLocalTimes(value);
    },
  },
};
</script>
<style>
.blockquote {
  padding: 10px 10px;
  border-left: 5px solid #aaaaff;
}
</style>

import {format} from "./time"
import axios from "../config/http.js"
import baseUrl from "../config/env.js"
import qs from "qs"


async function list(params,ctx){
    let d=JSON.parse(JSON.stringify(params))
    d.startTime=format(params.startTime)
    d.endTime=format(params.endTime)
    return axios.fetchGet(baseUrl + '/user/usageRecordList',d)
    // return {
    //     success:true,
    //     data:[
    //         {
    //           id:1,
    //           nickName: 'ddd',
    //           smoke_bomb_ID: '123',
    //           flavor: "Honey Dew Ice",
    //           smokeBombType: 'Honey Dew Ice',
    //           "createTime":"2020-04-22 15:05:26",
    //           address: 'new york',
    //           totalDuration: 10,
    //         },
    //    ],
    //    total:1,
    // }
}

function add(params){
    return axios.fetchPost(baseUrl + '/UserUseage/add', params);
}

function find(params){
   return axios.fetchGet(baseUrl + '/UserUseage/findById',params)
}

function update(params){
    return axios.fetchPost(baseUrl + '/UserUseage/updateById', params);
}

function del(params){
    return axios.fetchPost(baseUrl + '/UserUseage/deleteById', params);
}

function  export_excel(params={}){
        let d=JSON.parse(JSON.stringify(params))
        d.startTime=format(params.startTime)
        d.endTime=format(params.endTime)
        d['platform-token']=localStorage['auth-token']
        // d['supplier-token']=localStorage['auth-token']
        location.href= baseUrl +"/user/exportUsageRecordList"+"?"+ qs.stringify(d)
}

export default {
    list,
    find,
    add,
    update,
    del,
    export_excel,
}

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Order Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Order Name:</label>
            <span class="p-3">{{ form.orderName }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Order No.:</label>
            <span class="p-3">{{ form.number }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Model Name:</label>
            <span class="p-3">{{ form.modelName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Quantity:</label>
            <span class="p-3">{{ form.batchQuantity }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Manufacturer:</label>
            <span class="p-3">{{ form.manufacturerName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Production Time:</label>
            <span class="p-3">{{
              form.productionTime ? getLocalTimes(form.productionTime) : ""
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Status:</label>
            <span class="p-3">{{ form.statusName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Remarks:</label>
            <span class="p-3">{{ form.remarks }}</span>
          </b-col>
        </b-row>
      </b-container>
      <br />
      <br />
      <b-row
        class="align-items-center"
        v-if="form.status == 5 || form.status == 14 || form.status == 24"
      >
        <b-col lg="12">
          <div class="blockquote">
            <h4>Production Result</h4>
          </div>
        </b-col>
      </b-row>
      <b-container
        v-if="form.status == 5 || form.status == 14 || form.status == 24"
      >
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Planned Packing Quantity:</label>
            <span class="p-3">{{ form.batchQuantity }}</span>
          </b-col>

          <b-col>
            <label class="col-form-label">Actual Quantity:</label>
            <span class="p-3">{{ form.normalQuantity }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <b-button variant="outline-secondary" @click="returnPage">Go Back</b-button>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Wax Carts Pkg Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Wax Carts Pkg Details",
          active: true,
        },
      ],
      form: {},
      types: {
        statuss: [
          {
            text: "Production information to be confirmed",
            value: 0,
            disabled: false,
          },
          {
            text: "Production information has been confirmed",
            value: 1,
            disabled: false,
          },
          { text: "Is burning", value: 2, disabled: false },
          { text: "Burn complete", value: 3, disabled: false },
          { text: "Information to clear", value: 4, disabled: false },
          { text: "End of the production", value: 5, disabled: false },
        ],
      },
    };
  },
  computed: {},

  created() {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.emptyCartridges
          .selectByOrderId(this.$route.query)
          .then((res) => {
            if (res.success && res.data) {
              this.form = res.data;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    formatterStatus(value) {
      let a = "";
      this.types.statuss.filter((item) => {
        if (value === item.value) {
          a = item.text;
        }
      });
      return a;
    },
    returnPage() {
      this.$router.go(-1);
    },
    getLocalTimes(value) {
      return timeZoneConversion.getLocalTimes(value);
    },
  },
};
</script>
<style>
.blockquote {
  padding: 10px 10px;
  border-left: 5px solid #aaaaff;
}
</style>

import { saveAs } from 'file-saver';
import XLSX from "xlsx";
import {now} from "./time"



export function download(
    data=[],
    name="workboox",
    ws_name = "SheetJS",
){
    let header=[""]
    if (data.length>0) {
        header=Object.keys(data[0])
    }else{

    }
    var ws = XLSX.utils.json_to_sheet(data, {header , skipHeader: false});
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, ws_name);
    var wopts = { bookType:'xlsx', bookSST:false, type:'array' };
    var wbout = XLSX.write(wb,wopts);
    saveAs(new Blob([wbout],{type:"application/octet-stream"}), name+"_"+now()+".xlsx");

}


export default {
    download,
}

<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 预热名称 -->
        <b-form-group
          label="Mode Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="application-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="application-name"
            v-model="form.warmUpName"
            placeholder="Please enter"
            trim
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>

        <!-- 所属客户 -->
        <b-form-group
          label="Affiliated Customer:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer"
        >
          <b-form-select
            id="customer"
            v-model="form.supplierId"
            :options="types.allSupplier"
            value-field="id"
            text-field="brandName"
          >
          </b-form-select>
        </b-form-group>

        <!-- 预热温度 -->
        <b-form-group
          label="Preheat Temperature(℉):"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="path"
          label-class="requiredRow"
        >
          <b-form-input
            id="application-name"
            v-model="form.temperature"
            placeholder="Please enter a number between 100 and 1000"
            trim
            type="number"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');"
            onblur="if(value<100||value>1000)value=''"
          ></b-form-input>
        </b-form-group>

        <!-- 预热时间 -->
        <b-form-group
          label="Preheat Time(s):"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="path"
          label-class="requiredRow"
        >
          <b-form-input
            id="application-name"
            v-model="form.warmUpTimes"
            placeholder="Please enter a number between 10 and 120.The unit is in seconds"
            trim
            type="number"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');"
            onblur="if(value<10||value>120)value=''"
          ></b-form-input>
        </b-form-group>

        <!-- 预热图标 -->
        <b-form-group
          label="Preheat Photo:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="logo"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
          />
          <!-- required 
          oninvalid="setCustomValidity('Please upload');"
          oninput="setCustomValidity('');" -->

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="form.warmUpPhoto" style="margin-bottom: 10px">
                <img
                  :src="form.warmUpPhoto"
                  style="width: 100px; height: 100px; border: 2px dashed #ccc"
                />
                <span
                  style="margin-left: 10px; vertical-align: bottom"
                  v-if="!editId"
                >
                  <span style="font-weight: 500">Picture name : </span
                  >{{ photoName }}</span
                >
              </div>
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText"> upload picture </span>
              </label>
              <span>
                Recommended size of picture: 120 pixels * 120 pixels, supporting
                JPG and PNG formats.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- 状态 -->
        <b-form-group
          label="Status:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="status"
          label-class="requiredRow"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="form.status"
            :options="types.statuss"
            name="radio-options"
            required
            style="margin-top: 5px"
          ></b-form-radio-group>
        </b-form-group>

        <!-- 备注 -->
        <b-form-group
          label="Notes"
          label-cols-lg="2"
          label-align-sm="right"
          label-for="remark"
        >
          <b-form-textarea
            id="remark"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
  },
  data() {
    return {
      page: {
        title: this.editId ? "Preheat Mode Modify" : "Preheat Mode Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Data Dictionary",
            // href: "/"
          },
          {
            text: this.editId ? "Preheat Mode Modify" : "Preheat Mode Create",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        warmUpName: "",
        temperature: "",
        warmUpTimes: "",
        supplierId: "",
        warmUpPhoto: "",
        status: "",
        remarks: "",
      },
      types: {
        allSupplier: [{ brandName: "Please select", id: "" }],
        statuss: [
          { text: "Enabled", value: 0 },
          { text: "Disabled", value: 1 },
        ],
      },
      photoName: "",
      editId: window.location.search,
      editor: ClassicEditor,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    this.init();
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.form.id) {
        this.$api.warmUpMode.updateByWarmUpModeId(this.form).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.$router.go(-1);
          }
        });
      } else {
        this.$api.warmUpMode.addWarmUpMode(this.form).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.$router.go(-1);
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: "",
        warmUpName: "",
        temperature: "",
        warmUpTimes: "",
        supplierId: "",
        warmUpPhoto: "",
        status: "",
        remarks: "",
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.form.warmUpPhoto = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    formData(id) {
      this.$api.warmUpMode.selectByWarmUpModeId({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.required-icon {
  ::before {
    content: "*";
  }
}
</style>

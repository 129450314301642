/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:06
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-16 11:06:41
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons, BTooltip } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue-apexcharts'
import router from './router/index'
import store from '@/state/store'
import vco from 'v-click-outside'
import '@/design/index.scss'
import Sparkline from 'vue-sparklines'
import axios from './config/http.js'
import api from './api/index'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '../public/static/ueditor/ueditor.config.js'
import '../public/static/ueditor/ueditor.all.min.js'
//import '../public/static/ueditor/lang/zh-cn/zh-cn.js'
import '../public/static/ueditor/lang/en/en.js'
import '../public/static/ueditor/ueditor.parse.min.js'
import '../public/static/ueditor/themes/default/css/ueditor.css'

// 自定义组件
import blockquote from './components/blockquote/blockquote.vue'
import all from "@/state/toast";

Vue.use(all);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.config.productionTip = false

import htmlToPdf from '@/components/widgets/htmlToPdf'
// 使用Vue.use()方法就会调用工具方法中的install方法
Vue.use(htmlToPdf)

Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(require('vue-chartist'))
Vue.use(vco)
Vue.use(Sparkline)
Vue.use(VueGoogleMaps, {
	load: {
		key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
		libraries: 'places',
	},
	installComponents: true,
})
Vue.use(ElementUI)
Vue.component('apexchart', VueApexCharts)

// 修改原型链（调用$axios 完成数据请求）
Vue.prototype.$axios = axios
Vue.prototype.$api = api

//  自定义组件
Vue.use(blockquote)

/**按钮权限指令**/
Vue.directive('has', {
	bind: function (el, binding) {
		if (!Vue.prototype.$_has(binding.value)) {
			if (el.parentNode) {
				el.parentNode.removeChild(el)
			} else {
				el.style.display = 'none'
			}
		}
	},
})

//按钮权限检查方法
Vue.prototype.$_has = function (value) {
	let isExist = false
	let buttonpermsStr = sessionStorage.getItem('buttenpremissions')
	if (buttonpermsStr == undefined || buttonpermsStr == null) {
		return false
	}
	let buttonperms = JSON.parse(buttonpermsStr)
	for (let i = 0; i < buttonperms.length; i++) {
		if (buttonperms[i].permission === value) {
			isExist = true
			break
		}
	}
	return isExist
}

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')

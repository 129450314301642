<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Brand Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="brand-name"
              >
                <b-form-input
                  id="brand-name"
                  v-model="search_condition.brandName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- <b-col lg="4">
              <b-form-group
                label="Brand No."
                label-cols-sm="4"
                label-align-sm="right"
                label-for="brand-number"
              >
                <b-form-input
                  id="brand-number"
                  v-model="search_condition.brandEncoding"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col> -->
            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'consumableBrands::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="device-table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(brandPicture)="data">
                <img
                  v-if="data.item.brandPicture"
                  :src="`${data.item.brandPicture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
              </template>

              <template v-slot:cell(actions)="row">
                <b-button
                  v-has="'consumableBrands::Modify'"
                  size="sm"
                  variant="info"
                  @click="edit(row.item, row.index, $event.target)"
                  class="mr-1"
                  >Modify</b-button
                >
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Flavor List component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Consumable Brands",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Data Dictionary",
          // href: "/"
        },
        {
          text: "Consumable Brands",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "brandName", label: "Brand Name", class: "text-center" },
        // { key: "brandEncoding", label: "Brand No.", class: "text-center" },
        { key: "brandPicture", label: "Brand Logo", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        brandName: "",
        brandEncoding: "",
      },
      isBusy: false,
      has_data: false,
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {},
  mounted() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Flavor.brandList(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
      //this.$bvToast.toast(JSON.stringify(this.search_condition));
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("consumableBrandsAdd");
    },
    edit(item, index, button) {
      this.$router.push({ name: "consumableBrandsEdit", params: item });
    },
  },
};
</script>

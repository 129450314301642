<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Order Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="order-name"
              >
                <b-form-input
                  id="order-name"
                  v-model="search_condition.batchName"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Order No."
                label-cols-sm="4"
                label-align-sm="right"
                label-for="order-number"
              >
                <b-form-input
                  id="order-number"
                  trim
                  v-model="search_condition.number"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Create Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="create-time"
              >
                <date-picker
                  v-model="createTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="order-status"
              >
                <b-form-select
                  id="order-status"
                  v-model="search_condition.status"
                  :options="types.statuss"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'patchOrders::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >
          <div class="table-responsive mb-0">
            <b-table
              id="device-table"
              head-variant="light"
              show-empty
              empty-text="no records"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>
              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'patchOrders::Details'"
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Details
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * Cartridge Batch component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Patch Orders",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Patch Orders",
          active: true,
        },
      ],
      readySelectItem: 0,
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "batchName", label: "Order Name", class: "text-center" },
        { key: "number", label: "Order No.", class: "text-center" },
        { key: "batchQuantity",label: "Pre-production Quantity",class: "text-center"},
        { key: "normalQuantity",label: "Actual Quantity",class: "text-center"},
        { key: "identity",label: "Brand Identity",class: "text-center"},
        { key: "brandName",label: "Brand Name",class: "text-center"},
        { key: "url",label: "URL",class: "text-center"},
        { key: "statusName", label: "Status", class: "text-center"},
        { key: "operationName", label: "Created By", class: "text-center"},
        { key: "createTime",label: "Create Time",class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTimes(value);
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        batchName: "",
        number: "",
        startTime: "",
        endTime: "",
        status: "",
      },
      createTime: ["", ""],
      types: {
        statuss: [
          { text: "Please select", value: "", disabled: false },
          {
            text: "To be production",
            value: 1,
            disabled: false,
          },
          { text: "In Production", value: 2, disabled: false },
          { text: "Completed", value: 3, disabled: false },
        ],
        cartridgeType: [{ name: "Please select", id: "" }],
      },
      isBusy: false,
      has_data: false,
      dowmDisable: false,
    };
  },
  watch: {
    createTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {},
    provider(ctx) {
      this.isBusy = true;
      return this.$api.patchOrder
        .selectAllPatchOrder(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
      //this.$bvToast.toast(JSON.stringify(this.search_condition));
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("packingBoxOrdersAdd");
    },
    show(item, index, button) {
      this.$router.push("packingBoxOrdersInfo?id=" + item.id);
    },
    selectItem(item, index) {
      this.readySelectItem = index;
    },
  },
};
</script>

<style lang="scss">
.modal-list {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0px;

  .modal-item {
    text-align: center;
    cursor: pointer;
  }
}
.modal-content {
  width: auto;
}
.selected {
  border: 1px solid;
}
</style>
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  attrs: { action: "#" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submit_form($event)
                    },
                    reset: _vm.reset_form
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Model Name:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "model-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "model-name",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.modelName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "modelName", $$v)
                                  },
                                  expression: "typeform.modelName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Model Tag:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "model-tag",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "model-tag",
                                  placeholder: "Please enter",
                                  trim: "",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                                },
                                model: {
                                  value: _vm.typeform.modelIdentification,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.typeform,
                                      "modelIdentification",
                                      $$v
                                    )
                                  },
                                  expression: "typeform.modelIdentification"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Device Status:",
                                "label-cols-sm": "4",
                                "label-align-lg": "right",
                                "label-for": "deviceStatus",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "margin-top": "5px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.statuss,
                                  name: "radio-options",
                                  required: ""
                                },
                                model: {
                                  value: _vm.typeform.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "status", $$v)
                                  },
                                  expression: "typeform.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Model Logo:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "model-logo"
                              }
                            },
                            [
                              _c("input", {
                                staticClass: "filestyle",
                                staticStyle: {
                                  position: "absolute",
                                  clip: "rect(0px, 0px, 0px, 0px)"
                                },
                                attrs: {
                                  type: "file",
                                  "data-input": "false",
                                  id: "filestyle-1",
                                  tabindex: "-1"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.upload($event)
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "bootstrap-filestyle input-group"
                                },
                                [
                                  _c("div", {
                                    staticStyle: {
                                      position: "absolute",
                                      width: "100%",
                                      height: "35px",
                                      "z-index": "-1"
                                    },
                                    attrs: { name: "filedrag" }
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "group-span-filestyle ",
                                      attrs: { tabindex: "0" }
                                    },
                                    [
                                      _vm.typeform.modelPicture
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px"
                                              }
                                            },
                                            [
                                              _c("img", {
                                                staticStyle: {
                                                  width: "100px",
                                                  height: "100px",
                                                  border: "2px dashed #ccc"
                                                },
                                                attrs: {
                                                  src: _vm.typeform.modelPicture
                                                }
                                              }),
                                              _vm.is_add
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "10px",
                                                        "vertical-align":
                                                          "bottom"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            "font-weight": "500"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "Picture name : "
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(_vm.photoName)
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "btn btn-secondary ",
                                          staticStyle: { "margin-bottom": "0" },
                                          attrs: { for: "filestyle-1" }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "buttonText" },
                                            [_vm._v(" upload picture ")]
                                          )
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Remark:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right",
                                "label-for": "remark"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "remark",
                                  placeholder: "Enter Notes",
                                  rows: "3",
                                  "max-rows": "6"
                                },
                                model: {
                                  value: _vm.typeform.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "remarks", $$v)
                                  },
                                  expression: "typeform.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary m-l-5 ml-1",
                          attrs: { type: "reset" }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
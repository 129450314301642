<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Cartridge NO."
                label-cols-sm="4"
                label-align-sm="right"
                label-for="cartridge-number"
              >
                <b-form-input
                  id="cartridge-number"
                  trim
                  v-model="search_condition.smokeRecordNumber"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="User"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="user"
              >
                <b-form-input
                  id="user"
                  trim
                  v-model="search_condition.nickName"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="table"
              head-variant="light"
              ref="table"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <!-- <template v-slot:cell(actions)="row">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="show(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  show
                </b-button>

                <b-button
                  size="sm"
                  variant="info"
                  @click="show(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  edit
                </b-button>

                <b-button
                  size="sm"
                  variant="danger"
                  @click="del(row.item, row.index, $event.target)"
                  class="mr-1"
                >
                  del
                </b-button>
              </template> -->
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row>
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Usage Record",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Usage Record",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "smokeRecordNumber",
          label: "Cartridge NO.",
          class: "text-center",
        },
        { key: "modelName", label: "Flavor", class: "text-center" },
        { key: "type", label: "Cartridge Type", class: "text-center" },
        { key: "sumDuration", label: "Duration(s)", class: "text-center" },
        { key: "nickName", label: "Used By", class: "text-center" },
        {
          key: "createTime",
          label: "Last Used On",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
      ],
      usedTime: "",
      isBusy: false,
      has_data: false,
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        nickName: "",
        smokeRecordNumber: "",
        // supplierId: localStorage.supplierId,
      },
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.CartridgeUsage.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    gotoAdd() {
      this.$router.push("cartridgeListAdd");
    },
    del(a, b, c) {
      this.$api.CartridgeUsage.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    show(item, index, button) {
      this.$router.push("CartridgeUsageInfo?id=" + item.id);
    },
    edit(item, index, button) {
      this.$router.push("CartridgeUsageEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Heating Mode Name:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "heating-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "heating-name",
                      placeholder: "Please enter 4 to 20 characters",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('');",
                      onblur: "if(value.length<4||value.length>20)value=''"
                    },
                    model: {
                      value: _vm.form.modeName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "modeName", $$v)
                      },
                      expression: "form.modeName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Affiliated Customer:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "customer"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "customer",
                      options: _vm.types.allSupplier,
                      "value-field": "id",
                      "text-field": "brandName"
                    },
                    model: {
                      value: _vm.form.supplierId,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "supplierId", $$v)
                      },
                      expression: "form.supplierId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  staticStyle: { display: "none" },
                  attrs: {
                    label: "Heating Mode Type:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "heatingType",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      id: "heatingType",
                      options: _vm.types.heatingTypes,
                      name: "heatingType",
                      required: ""
                    },
                    nativeOn: {
                      change: function($event) {
                        return _vm.changeHeatingType(_vm.form.heatingType)
                      }
                    },
                    model: {
                      value: _vm.form.heatingType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "heatingType", $$v)
                      },
                      expression: "form.heatingType"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Heating Mode Description:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "path",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "application-name",
                      placeholder: "Please enter",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput:
                        "setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                    },
                    model: {
                      value: _vm.form.heatingRemarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "heatingRemarks", $$v)
                      },
                      expression: "form.heatingRemarks"
                    }
                  })
                ],
                1
              ),
              _vm.form.heatingType == 2
                ? _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Heating Temperature Setting:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "temperature",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _vm._l(_vm.temperatureList, function(item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "col-md-2 col-2",
                            staticStyle: {
                              display: "inline-block",
                              "padding-left": "0"
                            }
                          },
                          [
                            _c("label", [
                              _vm._v(_vm._s(index + 1 + " " + "seconds"))
                            ]),
                            _c("b-form-input", {
                              attrs: {
                                type: "number",
                                min: "0",
                                step: "0.01",
                                placeholder: "Please enter",
                                title: "",
                                required: "",
                                oninvalid: "setCustomValidity('Please enter')",
                                oninput:
                                  "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')",
                                onblur: "if(value<200||value>9999)value=''"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changeTemperatureList(
                                    _vm.temperatureList
                                  )
                                }
                              },
                              model: {
                                value: item.value,
                                callback: function($$v) {
                                  _vm.$set(item, "value", $$v)
                                },
                                expression: "item.value"
                              }
                            })
                          ],
                          1
                        )
                      }),
                      _c("p", [
                        _vm._v(
                          " The heating temperature is between 200 ℉ and 1000℉. After the 6th second, the heating temperature is the same as the 6th second "
                        )
                      ])
                    ],
                    2
                  )
                : _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Heating Temperature:",
                        "label-cols-lg": "3",
                        "label-align-lg": "right",
                        "label-for": "temperature",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "temperature",
                          placeholder: "Please enter(℉)",
                          trim: "",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter')",
                          oninput:
                            "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')"
                        },
                        model: {
                          value: _vm.form.temperature,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "temperature", $$v)
                          },
                          expression: "form.temperature"
                        }
                      })
                    ],
                    1
                  ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Heating Mode Photo:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "logo"
                  }
                },
                [
                  _c("input", {
                    staticClass: "filestyle",
                    staticStyle: {
                      position: "absolute",
                      clip: "rect(0px, 0px, 0px, 0px)"
                    },
                    attrs: {
                      type: "file",
                      "data-input": "false",
                      id: "filestyle-1",
                      tabindex: "-1"
                    },
                    on: {
                      change: function($event) {
                        return _vm.upload($event)
                      }
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "bootstrap-filestyle input-group" },
                    [
                      _c("div", {
                        staticStyle: {
                          position: "absolute",
                          width: "100%",
                          height: "35px",
                          "z-index": "-1"
                        },
                        attrs: { name: "filedrag" }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "group-span-filestyle",
                          attrs: { tabindex: "0" }
                        },
                        [
                          _vm.form.heatingPhoto
                            ? _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      width: "100px",
                                      height: "100px",
                                      border: "2px dashed #ccc"
                                    },
                                    attrs: { src: _vm.form.heatingPhoto }
                                  }),
                                  !_vm.editId
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "vertical-align": "bottom"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-weight": "500"
                                              }
                                            },
                                            [_vm._v("Picture name : ")]
                                          ),
                                          _vm._v(_vm._s(_vm.photoName))
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "label",
                            {
                              staticClass: "btn btn-secondary",
                              staticStyle: { "margin-bottom": "0" },
                              attrs: { for: "filestyle-1" }
                            },
                            [
                              _c("span", { staticClass: "buttonText" }, [
                                _vm._v(" upload picture ")
                              ])
                            ]
                          ),
                          _c("span", [
                            _vm._v(
                              " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                            )
                          ])
                        ]
                      )
                    ]
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Ready for Production - Yes / No:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "status",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-radio-group", {
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      id: "radio-group-1",
                      options: _vm.types.statuss,
                      name: "radio-options",
                      required: ""
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "3",
                    "label-align-sm": "right",
                    "label-for": "remark"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remark",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "3" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form action="#" @submit.prevent="submit_form"  @reset="reset_form">
              <!-- 型号名称 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Model Name:"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="model-name"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="model-name"
                      v-model="typeform.modelName"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>
              <!-- 型号标识 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Model Tag:"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="model-tag"
                    label-class="requiredRow"
                  >
                    <b-form-input
                      id="model-tag"
                      v-model="typeform.modelIdentification"
                      placeholder="Please enter"
                      trim
                      required
                      oninvalid="setCustomValidity('Please enter');"
                      oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 设备状态 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Device Status:"
                    label-cols-sm="4"
                    label-align-lg="right"
                    label-for="deviceStatus"
                    label-class="requiredRow"
                  >
                    <b-form-radio-group
                      id="radio-group-1"
                      v-model="typeform.status"
                      :options="types.statuss"
                      name="radio-options"
                      required
                      style="margin-top:5px;"
                    ></b-form-radio-group>
                  </b-form-group>
                </b-col>
              </div>

            <!-- 型号图标 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Model Logo:"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="model-logo"
                  >
                    <input
                      type="file"
                      class="filestyle"
                      data-input="false"
                      id="filestyle-1"
                      style="position: absolute; clip: rect(0px, 0px, 0px, 0px);"
                      tabindex="-1"
                      @change="upload($event)"
                    />

                    <div class="bootstrap-filestyle input-group">
                      <div
                        name="filedrag"
                        style="position: absolute; width: 100%; height: 35px; z-index: -1;"
                      ></div>
                      <span class="group-span-filestyle " tabindex="0">
                        <div v-if="typeform.modelPicture" style="margin-bottom: 10px;">
                          <img
                            :src="typeform.modelPicture"
                            style="width: 100px;height: 100px;border: 2px dashed #ccc;"
                          />
                          <span
                            style="margin-left: 10px;vertical-align: bottom;"
                            v-if="is_add"
                          >
                            <span style="font-weight: 500;"
                              >Picture name : </span
                            >{{ photoName }}</span
                          >
                        </div>
                        <label
                          for="filestyle-1"
                          style="margin-bottom: 0;"
                          class="btn btn-secondary "
                        >
                          <span class="buttonText">
                            upload picture
                          </span>
                        </label>
                        <span>
                          Recommended size of picture: 120 pixels * 120 pixels,
                          supporting JPG and PNG formats.
                        </span>
                      </span>
                    </div>
                  </b-form-group>
                </b-col>
              </div>

              <!-- 备注 -->
              <div class="form-group">
                <b-col lg="10">
                  <b-form-group
                    label="Remark:"
                    label-cols-sm="4"
                    label-align-sm="right"
                    label-for="remark"
                  >
                    <b-form-textarea
                      id="remark"
                      v-model="typeform.remarks"
                      placeholder="Enter Notes"
                      rows="3"
                      max-rows="6"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </div>

              <div class="form-group mb-0">
                <div class="text-center">
                  <button type="submit" class="btn btn-primary">Confirm</button>
                  <button type="reset" class="btn btn-secondary m-l-5 ml-1">
                    Cancel </button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { dropzoneOptions, upload_img } from "@/api/upload";
import vue2Dropzone from "vue2-dropzone";

export default {
  name: "Region",
  page: {
    title: "Region",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },

  data() {
    return {
      dropzoneOptions: dropzoneOptions,
      title: "Device Model",
      items: [
        {
          text: "Device Manage",
          // href: "/"
        },
        {
          text: "Device Model",
          // href: "/"
        },
        {
          text: /add/i.test(location.href)
            ? "Device Model Create"
            : "Device Model Modify",
          active: true,
        },
      ],
      typeform: {
        //"id":3,
        modelName: "",
        modelIdentification: "",
        status: "",
        modelPicture: "",
        remarks: "",
      },
      types: {
        statuss: [
          { text: "Enabled", value: 1, disabled: false },
          { text: "Disabled", value: 0, disabled: false },
        ],
      },
      photoName: "",
      submit: false,
      typesubmit: false,
    };
  },
  computed: {
    is_add: function() {
      return /add/i.test(location.href);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    show1() {
      this.$api.Device.deviceModelFindById(this.$route.query).then((res) => {
        if (res.success) {
          this.typeform = res.data;
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
    },
    init() {
      if (this.is_add) {
      } else {
        this.show1();
      }
    },
    add() {
      this.$api.Device.deviceModelSave(this.typeform).then((res) => {
        if(res.message){
              this.$bvToast.toast(res.message);
            }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    update() {
      this.$api.Device.deviceModelUpdateById(this.typeform).then((res) => {
        if(res.message){
              this.$bvToast.toast(res.message);
            }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    submit_form() {
      this.typesubmit = true;
      if (this.is_add) {
        this.add();
      } else {
        this.update();
      }
    },
     reset_form() {
      this.$router.go(-1);
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          if (res.success) {
            this.typeform.modelPicture = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
  },
};
</script>

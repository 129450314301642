var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal",
        size: "lg",
        centered: "",
        title: "Upload Files",
        "ok-title": "Confirm",
        "no-close-on-esc": "",
        "no-close-on-backdrop": ""
      },
      on: { ok: _vm.onConfirm, cancel: _vm.onCancel, close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "w-200" },
                [
                  _c(
                    "b-button",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { variant: "primary" },
                      on: { click: _vm.onConfirm }
                    },
                    [_vm._v(" Confirm ")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "secondary" },
                      on: { click: _vm.onCancel }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.showUploadModal,
        callback: function($$v) {
          _vm.showUploadModal = $$v
        },
        expression: "showUploadModal"
      }
    },
    [
      _c("b-card", [
        _c(
          "div",
          { staticClass: "file-list" },
          [
            _vm._l(_vm.fileList, function(item, index) {
              return _c("div", { key: index, staticClass: "file-list-item" }, [
                _c("i", {
                  staticClass: "ion ion-md-close-circle",
                  staticStyle: {
                    position: "absolute",
                    right: "5px",
                    top: "5px",
                    "font-size": "25px",
                    "z-index": "99999"
                  },
                  on: {
                    click: function($event) {
                      return _vm.onDeleteCarouselMap(item, index)
                    }
                  }
                }),
                /mp4/.test(item)
                  ? _c("video", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: item }
                    })
                  : _c("img", {
                      staticStyle: { width: "100px", height: "100px" },
                      attrs: { src: item }
                    })
              ])
            }),
            _c("input", {
              staticStyle: {
                position: "absolute",
                clip: "rect(0px, 0px, 0px, 0px)"
              },
              attrs: {
                type: "file",
                "data-input": "false",
                id: "uploadFile",
                multiple: "multiple",
                accept: "image/jpeg,image/gif,image/png,audio/mp4,video/mp4",
                tabindex: "-2"
              },
              on: {
                change: function($event) {
                  return _vm.onChangeFile($event)
                }
              }
            }),
            _vm.fileList.length < _vm.maxNumber
              ? _c(
                  "label",
                  {
                    staticClass: "file-list-item",
                    staticStyle: { "margin-bottom": "0px" },
                    attrs: { for: "uploadFile" }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-plus file-list-add",
                      staticStyle: { "font-size": "95px" }
                    })
                  ]
                )
              : _vm._e()
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
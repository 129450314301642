var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            [
              _c(
                "b-tabs",
                {
                  staticClass: "navtab-bg",
                  attrs: { justified: "", pills: "", "content-class": "p-3" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                [
                  _c(
                    "b-tab",
                    {
                      attrs: { active: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-inline-block d-sm-none" },
                                [_c("i", { staticClass: "fas fa-home" })]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [_vm._v("About me")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("b-card-text", [_vm._v("About me")]),
                      _c("wang", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.form.aboutUs,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "aboutUs", $$v)
                          },
                          expression: "form.aboutUs"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-inline-block d-sm-none" },
                                [_c("i", { staticClass: "far fa-user" })]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [_vm._v("User agreement")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c("b-card-text", [_vm._v("User agreement")]),
                      _c("wang", {
                        on: { save: _vm.save },
                        model: {
                          value: _vm.form.agreementContent,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "agreementContent", $$v)
                          },
                          expression: "form.agreementContent"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-inline-block d-sm-none" },
                                [_c("i", { staticClass: "far fa-envelope" })]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [_vm._v(" Startup page settings ")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "b-container",
                        {
                          staticClass: "bv-example-row",
                          attrs: { fluid: "sm" }
                        },
                        [
                          _c("b-card-text", [_vm._v("startup Page")]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4", xs: "12" } },
                                [
                                  _c(
                                    "b-img-lazy",
                                    _vm._b(
                                      {
                                        attrs: {
                                          src: _vm.form.startupPage,
                                          alt: "startupPage",
                                          fluid: ""
                                        }
                                      },
                                      "b-img-lazy",
                                      _vm.mainProps,
                                      false
                                    )
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { lg: "12" } }, [
                                _c("div", { staticClass: "form-group mb-0" }, [
                                  _c("label"),
                                  _c("input", {
                                    staticClass: "filestyle",
                                    staticStyle: {
                                      position: "absolute",
                                      clip: "rect(0px, 0px, 0px, 0px)"
                                    },
                                    attrs: {
                                      type: "file",
                                      "data-input": "false",
                                      id: "filestyle-1",
                                      tabindex: "-1"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.upload($event)
                                      }
                                    }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "bootstrap-filestyle input-group"
                                    },
                                    [
                                      _c("div", {
                                        staticStyle: {
                                          position: "absolute",
                                          width: "100%",
                                          height: "35px",
                                          "z-index": "-1"
                                        },
                                        attrs: { name: "filedrag" }
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "group-span-filestyle ",
                                          attrs: { tabindex: "0" }
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "btn btn-secondary ",
                                              staticStyle: {
                                                "margin-bottom": "0"
                                              },
                                              attrs: { for: "filestyle-1" }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "buttonText" },
                                                [_vm._v(" upload ")]
                                              )
                                            ]
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              " only support jpg/png file "
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "span",
                                { staticClass: "d-inline-block d-sm-none" },
                                [_c("i", { staticClass: "fas fa-cog" })]
                              ),
                              _c(
                                "span",
                                { staticClass: "d-none d-sm-inline-block" },
                                [_vm._v("customer service")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "customer service phone",
                            "label-cols-lg": "3",
                            "label-align-lg": "right"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "model-name1", placeholder: "" },
                            model: {
                              value: _vm.form.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "text-center col-lg-12" },
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "12" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.save()
                                        }
                                      }
                                    },
                                    [_vm._v("Save")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "8" } },
                [
                  _c(
                    "b-form",
                    {
                      ref: "firstTabForm",
                      on: { submit: _vm.onSubmit, reset: _vm.onReset }
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order Name:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "order-name",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-name",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please enter"
                            },
                            model: {
                              value: _vm.form.orderName,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "orderName", $$v)
                              },
                              expression: "form.orderName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Order No.:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "order-number"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "order-number",
                              placeholder: "Automatic system generation",
                              disabled: ""
                            }
                          })
                        ],
                        1
                      ),
                      _vm.selectItem == 4
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Production Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "cottonBag",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "margin-top": "5px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.productionType,
                                  name: "radio-options",
                                  required: ""
                                },
                                model: {
                                  value: _vm.productionType,
                                  callback: function($$v) {
                                    _vm.productionType = $$v
                                  },
                                  expression: "productionType"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectItem == 3 ||
                      (_vm.selectItem == 4 && _vm.productionType == 1)
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Batch Type:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "batch-type",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(_vm.types.batchType, function(
                                  label,
                                  index
                                ) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      staticClass: "mr-2 mt-2",
                                      attrs: {
                                        required: true,
                                        value: label.id
                                      },
                                      model: {
                                        value: _vm.form.batchType,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "batchType", $$v)
                                        },
                                        expression: "form.batchType"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(label.typeName) + " ")]
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Cartridge Model:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "cartridge-model",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "cartridge-model",
                              options: _vm.types.selectAllTestSmokeBombModel,
                              "value-field": "id",
                              "text-field": "modelName",
                              required: "",
                              oninvalid: "setCustomValidity('Please select');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.testSmokeBombModeId,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "testSmokeBombModeId", $$v)
                              },
                              expression: "form.testSmokeBombModeId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.selectItem == 3
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Flavor:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "flavor-model"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "flavor-model",
                                  options: _vm.types.flavorList,
                                  "value-field": "id",
                                  "text-field": "modelName",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.smokeBombModelId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "smokeBombModelId", $$v)
                                  },
                                  expression: "form.smokeBombModelId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectItem == 4
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Flavor:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "flavor-model",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "flavor-model",
                                  options: _vm.types.flavorList,
                                  "value-field": "id",
                                  "text-field": "modelName",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.smokeBombModelId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "smokeBombModelId", $$v)
                                  },
                                  expression: "form.smokeBombModelId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectItem == 2
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Default Heating mode:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "heating-model",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "heating-model",
                                  options:
                                    _vm.types.selectAllDefaultHeatingMode,
                                  "value-field": "id",
                                  "text-field": "modeName",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.defaultWarmHeatingId,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "defaultWarmHeatingId",
                                      $$v
                                    )
                                  },
                                  expression: "form.defaultWarmHeatingId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.selectItem == 2
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Default Pre-heat Mode:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "warmup-model",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "warmup-model",
                                  options: _vm.types.selectAllDefaultWarmUpMode,
                                  "value-field": "id",
                                  "text-field": "warmUpName",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.defaultWarmUpId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "defaultWarmUpId", $$v)
                                  },
                                  expression: "form.defaultWarmUpId"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Production Quantity:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "production-quantity",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "production-quantity",
                              type: "number",
                              min: "0",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.batchQuantity,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "batchQuantity", $$v)
                              },
                              expression: "form.batchQuantity"
                            }
                          }),
                          _vm.selectItem != 1
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.quantity) +
                                    " box,The recommended quantity is a multiple of 50, one box per 50"
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.selectItem == 2
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label:
                                  "Upper Limit for Vaporization Time in Seconds:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "maxFrequency",
                                "label-class": "requiredRow",
                                description:
                                  "To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "maxFrequency",
                                  type: "number",
                                  min: "0",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3');if(value>60000)value=60000;if(value<1)value=1",
                                  placeholder: "Please Enter"
                                },
                                model: {
                                  value: _vm.form.maxFrequency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "maxFrequency", $$v)
                                  },
                                  expression: "form.maxFrequency"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "PBCA Supplier:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "pbca-supplier",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "pbca-supplier",
                              type: "text",
                              min: "0",
                              maxlength: "250",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('');",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.pcbaSupplier,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "pcbaSupplier", $$v)
                              },
                              expression: "form.pcbaSupplier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Charger of PCBA:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "charger-of-pcba",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "charger-of-pcba",
                              type: "text",
                              min: "0",
                              maxlength: "250",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.chargerOfPcba,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "chargerOfPcba", $$v)
                              },
                              expression: "form.chargerOfPcba"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Assembly Supplier:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "assembly-supplier",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "assembly-supplier",
                              type: "text",
                              min: "0",
                              maxlength: "250",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.assemblySupplier,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "assemblySupplier", $$v)
                              },
                              expression: "form.assemblySupplier"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Charger of Assembly:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "charger-of-assembly",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "charger-of-assembly",
                              type: "text",
                              min: "0",
                              maxlength: "250",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.chargerOfAssembly,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "chargerOfAssembly", $$v)
                              },
                              expression: "form.chargerOfAssembly"
                            }
                          })
                        ],
                        1
                      ),
                      false
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Max seconds:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "maxFrequency",
                                "label-class": "requiredRow",
                                description:
                                  "To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "maxFrequency",
                                  type: "number",
                                  min: "0",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput:
                                    "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3');if(value>60000)value=60000;",
                                  placeholder: "Please Enter"
                                },
                                model: {
                                  value: _vm.form.maxFrequency,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "maxFrequency", $$v)
                                  },
                                  expression: "form.maxFrequency"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Voltage:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "curveOne",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "voltage-one" },
                                _vm._l(_vm.outputList, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(item.value) + " v ")]
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "voltage-two",
                                  staticStyle: { width: "100%" }
                                },
                                _vm._l(_vm.outputList, function(item, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "voltage-list" },
                                    [
                                      _c("i", {
                                        staticClass:
                                          "el-icon-plus voltage-plus",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltagePlus(index)
                                          }
                                        }
                                      }),
                                      _c("el-slider", {
                                        attrs: {
                                          vertical: "",
                                          step: _vm.step,
                                          min: _vm.min,
                                          max: _vm.max,
                                          height: "150px"
                                        },
                                        model: {
                                          value: item.value,
                                          callback: function($$v) {
                                            _vm.$set(item, "value", $$v)
                                          },
                                          expression: "item.value"
                                        }
                                      }),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-minus voltage-reduce",
                                        on: {
                                          click: function($event) {
                                            return _vm.onVoltageReduce(index)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                "div",
                                { staticClass: "voltage-three" },
                                _vm._l(8, function(index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "voltage-number"
                                    },
                                    [_vm._v(" " + _vm._s(index) + "s ")]
                                  )
                                }),
                                0
                              ),
                              _c("p", [
                                _vm._v(
                                  " The Voltage curve is between 0.1v-4.2v, after the 8th second, the same as the 8th second "
                                )
                              ])
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Manufacturer:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "manufacturer"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "manufacturer",
                              placeholder: "Please Enter"
                            },
                            model: {
                              value: _vm.form.manufacturerEncodingName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "manufacturerEncodingName",
                                  $$v
                                )
                              },
                              expression: "form.manufacturerEncodingName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Production Time:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "production-time"
                          }
                        },
                        [
                          _c("date-picker", {
                            attrs: {
                              id: "production-time",
                              format: "MM/DD/YYYY",
                              "append-to-body": "",
                              lang: "en",
                              placeholder: "Select date"
                            },
                            model: {
                              value: _vm.productionTime,
                              callback: function($$v) {
                                _vm.productionTime = $$v
                              },
                              expression: "productionTime"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Remarks:",
                            "label-cols-lg": "3",
                            "label-align-lg": "right",
                            "label-for": "remarks"
                          }
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              id: "remarks",
                              placeholder: "Other Notes",
                              rows: "3",
                              "max-rows": "6"
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { lg: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            { attrs: { type: "submit", variant: "primary" } },
                            [_vm._v("Confirm")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticStyle: { "margin-left": "30px" },
                              attrs: { type: "reset" }
                            },
                            [_vm._v("Cancel")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: Code-HHX
 * @Date: 2021-12-22 16:11:33
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-24 15:16:29
 * @Description: 关键词黑名单列表页面
-->

<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />

		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="3">
							<!-- 关键词 -->
							<b-form-group
								label="Key Word"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="key-words"
							>
								<b-form-input
									id="key-words"
									v-model="search_condition.sensitiveWord"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<!-- 关键词分类 -->
						<b-col lg="3">
							<b-form-group
								label="Keyword Category"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="keyword-category"
							>
								<b-form-select
									id="keyword-category"
									v-model="
										search_condition.sensitiveWordCategoryId
									"
									:options="types.keywordCategoryList"
									value-field="value"
									text-field="text"
								></b-form-select>
							</b-form-group>
						</b-col>

						<!-- 风险级别 -->
						<b-col lg="3">
							<b-form-group
								label="Risk Level"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="risk-level"
							>
								<b-form-select
									id="risk-level"
									v-model="search_condition.riskLevel"
									:options="types.riskLevelList"
									value-field="value"
									text-field="text"
								></b-form-select>
							</b-form-group>
						</b-col>

						<!-- 状态 -->
						<b-col lg="3">
							<b-form-group
								label="Status"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="status"
							>
								<b-form-select
									v-model="search_condition.status"
									:options="types.status"
									value-field="value"
									text-field="text"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="3">
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<!-- table -->
					<b-button
						v-has="'keywordBlacklist::Create'"
						@click="gotoAdd"
						variant="primary"
						>Create</b-button
					>

					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="no records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
									(search_condition.pageNum - 1) *
										search_condition.pageSize +
									1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'keywordBlacklist::Modify'"
										size="sm"
										variant="primary"
										@click="
											rowModify(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Modify
									</b-button>
									<b-button
										v-if="row.item.status == 0"
										v-has="'keywordBlacklist::Enable'"
										size="sm"
										variant="success"
										@click="
											rowEnable(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Enable
									</b-button>
									<b-button
										v-if="row.item.status == 1"
										v-has="'keywordBlacklist::Disable'"
										size="sm"
										variant="secondary"
										@click="
											rowDisable(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Disable
									</b-button>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									>
									</b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * FAQ component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
	},
	data() {
		return {
			page: {
				title: 'Keyword Blacklist',
				items: [
					{
						text: 'UNI-CORE',
						// href: "/"
					},
					{
						text: 'Content Mgmt',
						// href: "/"
					},
					{
						text: 'Keyword Blacklist',
						active: true,
					},
				],
			},
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{
					key: 'sensitiveWord',
					label: 'Key Word',
					class: 'text-center',
				}, //关键词
				{
					key: 'categoryName',
					label: 'keyword Category',
					class: 'text-center',
				}, //关键词分类
				{ key: 'riskLevel', label: 'Risk Level', class: 'text-center' }, //风险级别
				{
					key: 'status',
					label: 'Status',
					class: 'text-center',
					formatter: (value) => {
						let a = ''
						this.types.status.filter((item) => {
							if (value === item.value) {
								a = item.text
							}
						})
						return a
					},
				}, //状态
				{
					key: 'createTime',
					label: 'Comment Time',
					class: 'text-center',
					formatter: (value) => {
						if (value) {
							return timeZoneConversion.getLocalTime(value)
						} else {
							return ''
						}
					},
				}, //添加时间
				{
					key: 'operatorName',
					label: 'Operator Name',
					class: 'text-center',
				}, // 操作人
				{ key: 'actions', label: 'Opreration', class: 'text-center' },
			],
			createTime: ['', ''],
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				supplierId: localStorage.supplierId,
				riskLevel: '', //风险级别
				sensitiveWord: '', //关键词
				sensitiveWordCategoryId: '', //关键词类别编号
				status: '', //0禁用 1启用
			},
			isBusy: false,
			has_data: false,
			types: {
				//关键词分类列表
				keywordCategoryList: [
					{
						text: 'Please select',
						value: '',
					},
				],
				//风险级别
				riskLevelList: [
					{ text: 'Please select', value: '' },
					{ text: 'LV1', value: 1 },
					{ text: 'LV2', value: 2 },
					{ text: 'LV3', value: 3 },
					{ text: 'LV4', value: 4 },
					{ text: 'LV5', value: 5 },
				],
				//状态
				status: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'Disabled', value: 0, disabled: false },
					{ text: 'Enable', value: 1, disabled: false },
				],
			},
		}
	},
	watch: {
		createTime: function (val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		rows() {
			// return this.totalRows + this.search_condition.pageSize;
			return this.totalRows
		},
	},
	mounted() {
		this.init()
	},
	created() {},
	methods: {
		init() {
			let that = this
			this.$api.Dropdown.selectAllSensitiveWordCategory()
				.then((res) => {
					that.types.keywordCategoryList = [
						{ text: 'Please select', value: '' },
					]
					res.data.forEach((element) => {
						that.types.keywordCategoryList.push({
							text: element.categoryName,
							value: element.id,
						})
					})
				})
				.catch((error) => {
					console.log(error)
				})
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		provider(ctx) {
			this.isBusy = true
			return this.$api.Content.blackList(this.search_condition, ctx)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		gotoAdd() {
			this.$router.push('keywordBlacklistAdd')
		},
		rowModify(row, index, event) {
			this.$router.push({
				name: 'keywordBlacklistEdit',
				params: {
					rowItem: row,
				},
			})
		},
		rowEnable(row, index, event) {
			this.$api.Content.enableOrDisableById({
				id: row.id,
				status: 1,
			})
				.then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.$refs.table.refresh()
					}
				})
				.catch((error) => {
					if (res.message) {
						this.$toast(res)
					}
				})
		},
		rowDisable(row, index, event) {
			this.$api.Content.enableOrDisableById({
				id: row.id,
				status: 0,
			})
				.then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.$refs.table.refresh()
					}
				})
				.catch((error) => {
					if (res.message) {
						this.$toast(res)
					}
				})
		},
	},
}
</script>

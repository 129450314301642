var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Device Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device NO.:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceNumber))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Coding:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceCoding))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Status:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.form.status == 0
                            ? "Unused"
                            : 1
                            ? "Used"
                            : "Unactivated"
                        )
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Location:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceLocation))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Device usage info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-responsive mb-0" },
            [
              _c("b-table", {
                attrs: {
                  id: "table",
                  "head-variant": "light",
                  "show-empty": "",
                  "empty-text": "no records",
                  striped: true,
                  items: _vm.device_usage,
                  fields: _vm.fields,
                  "per-page": _vm.search_condition.pageSize,
                  "current-page": _vm.search_condition.pageNum
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(index)",
                    fn: function(data) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              data.index +
                                (_vm.search_condition.pageNum - 1) *
                                  _vm.search_condition.pageSize +
                                1
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass:
                      "dataTables_paginate paging_simple_numbers float-right"
                  },
                  [
                    _vm.rows != 0
                      ? _c(
                          "ul",
                          { staticClass: "pagination mb-0" },
                          [
                            _c("b-pagination", {
                              attrs: {
                                "per-page": 10,
                                "total-rows": _vm.rows,
                                "aria-controls": "table"
                              },
                              model: {
                                value: _vm.search_condition.pageNum,
                                callback: function($$v) {
                                  _vm.$set(_vm.search_condition, "pageNum", $$v)
                                },
                                expression: "search_condition.pageNum"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        [
          _c(
            "b-button",
            {
              attrs: {
                size: "lg",
                type: "reset",
                variant: "outline-secondary"
              },
              on: { click: _vm.onReset }
            },
            [_vm._v("Go Back")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
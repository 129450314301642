var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c(
            "div",
            { staticClass: "card wrap1 p-3" },
            [
              _c(
                "div",
                { staticClass: "col-lg-12" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Authorization question name",
                        "label-cols-sm": "3",
                        "label-align-sm": "right"
                      }
                    },
                    [
                      _c("b-form-input", {
                        staticClass: "pr-0",
                        attrs: { required: "", placeholder: "Enter Question" },
                        model: {
                          value: _vm.form.title,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Authorization question",
                    "label-cols-sm": "3",
                    "label-align-sm": "right"
                  }
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "col-lg-1 mb-3 ml-5",
                      attrs: { variant: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.AddQuestion()
                        }
                      }
                    },
                    [_vm._v("Add")]
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "wrap2 col-lg-12" },
                [
                  _vm._l(_vm.form.content, function(item, ind) {
                    return _c("div", { key: ind, staticClass: "col-lg-12" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-body m-2",
                          staticStyle: { border: "1px solid #ddd" }
                        },
                        [
                          _c(
                            "form",
                            [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex num",
                                      attrs: { lg: "12" }
                                    },
                                    [
                                      _c("div", { staticClass: "num1" }, [
                                        _vm._v("Question " + _vm._s(ind + 1))
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-9 col-8" },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Authorization answer",
                                                "label-cols-sm": "3",
                                                "label-align-sm": "right"
                                              }
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "inner form-control",
                                                attrs: {
                                                  required: "",
                                                  placeholder: "Enter question"
                                                },
                                                model: {
                                                  value: item.title,
                                                  callback: function($$v) {
                                                    _vm.$set(item, "title", $$v)
                                                  },
                                                  expression: "item.title"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-md-1 col-1" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass:
                                                "btn-margin btn btn-primary btn-block inner",
                                              attrs: { variant: "danger" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.delQuestion(ind)
                                                }
                                              }
                                            },
                                            [_vm._v("Del")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { lg: "10" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "success" },
                                          on: {
                                            click: function($event) {
                                              return _vm.add_answer(item)
                                            }
                                          }
                                        },
                                        [_vm._v("Add Answer")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-col", { attrs: { lg: "12" } }, [
                                _c(
                                  "div",
                                  _vm._l(item.q, function(field, index) {
                                    return _c(
                                      "div",
                                      { key: ind + "_" + index },
                                      [
                                        _c(
                                          "b-col",
                                          { staticClass: "d-flex  " },
                                          [
                                            _c("b-form-input", {
                                              staticClass:
                                                "mr-5  mb-3 offset-3",
                                              attrs: {
                                                required: "",
                                                placeholder: "Enter answer"
                                              },
                                              model: {
                                                value: field.content,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    field,
                                                    "content",
                                                    $$v
                                                  )
                                                },
                                                expression: "field.content"
                                              }
                                            }),
                                            _c(
                                              "b-form-checkbox",
                                              {
                                                attrs: {
                                                  name: "checkbox-1",
                                                  value: "1",
                                                  "unchecked-value": "0"
                                                },
                                                model: {
                                                  value: field.status,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      field,
                                                      "status",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "field.status"
                                                }
                                              },
                                              [_vm._v(" unlock ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              ]),
                              item.q.length > 0
                                ? _c(
                                    "b-row",
                                    { staticClass: "col-lg-12" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: { variant: "success" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.del_answer(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Del")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ])
                  }),
                  _c(
                    "b-row",
                    { staticClass: "col-lg-12" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status",
                                "label-cols-lg": "2",
                                "label-align-lg": "right"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "padding-top": "5px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.status,
                                  name: "cartridge-status"
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "col-lg-12" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Category:",
                                "label-cols-lg": "2",
                                "label-align-lg": "right",
                                "label-for": "categoryId"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.types.questionCategorys,
                                  "value-field": "id",
                                  "text-field": "name"
                                },
                                model: {
                                  value: _vm.form.categoryId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "categoryId", $$v)
                                  },
                                  expression: "form.categoryId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "text-center col-lg-12" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "12" } },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "primary" },
                                  on: { click: _vm.onSubmit }
                                },
                                [_vm._v("Confirm")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "m-l-5 ml-1",
                                  attrs: { type: "reset", variant: "danger" },
                                  on: { click: _vm.onReset }
                                },
                                [_vm._v("Cancel")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
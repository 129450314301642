var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.config,
            expression: "config"
          }
        ],
        staticClass: "right-bar"
      },
      [
        _c("div", { staticClass: "h-100", attrs: { "data-simplebar": "" } }, [
          _c("div", { staticClass: "rightbar-title px-3 py-4" }, [
            _c(
              "a",
              {
                staticClass: "right-bar-toggle float-right",
                attrs: { href: "javascript:void(0);" },
                on: { click: _vm.hide }
              },
              [_c("i", { staticClass: "mdi mdi-close noti-icon" })]
            ),
            _c("h5", { staticClass: "m-0" }, [_vm._v("Settings")])
          ]),
          _c("hr", { staticClass: "mt-0" }),
          _c("h6", { staticClass: "text-center" }, [_vm._v("Choose Layouts")]),
          _c("div", { staticClass: "p-4" }, [
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: "//veltrix.vuejs-light.themesbrand.com/"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid img-thumbnail",
                      attrs: {
                        src: require("@/assets/images/layouts/layout-1.jpg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: "//veltrix.vuejs-dark.themesbrand.com/"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid img-thumbnail",
                      attrs: {
                        src: require("@/assets/images/layouts/layout-2.jpg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mb-2" },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      tag: "a",
                      target: "_blank",
                      to: "//veltrix.vuejs-rtl.themesbrand.com/"
                    }
                  },
                  [
                    _c("img", {
                      staticClass: "img-fluid img-thumbnail",
                      attrs: {
                        src: require("@/assets/images/layouts/layout-3.jpg"),
                        alt: ""
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _vm._m(0)
          ])
        ])
      ]
    ),
    _c("div", { staticClass: "rightbar-overlay" })
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-primary btn-block mt-3",
        attrs: { href: "https://1.envato.market/grNDB", target: "_blank" }
      },
      [_c("i", { staticClass: "mdi mdi-cart mr-1" }), _vm._v(" Purchase Now ")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }
/*
 * @Author: Code-HHX
 * @Date: 2021-12-10 11:07:06
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-23 11:09:56
 * @Description: api 接口的统一出口
 * @接口文档地址：http://47.115.181.84:8080/electronic-cigarette/doc.html
 */

// 账号模块 接口
import account from './account.js'

// 用户管理模块
import userManage from './user.js'
import UserUseage from './UserUseage'
import UserAuthorization from './UserAuthorization'

// app 模块
import appManage from './appManage.js'

import Cartridge from './Cartridge'
import CartridgeBatch from './CartridgeBatch'
import CartridgeModel from './CartridgeModel'
import CartridgeUsage from './CartridgeUsage'
import Device from './Device'
import Message from './Message'
import Flavor from './flavor'
import Consultation from './Consultation'
import Category from './Category'
import Dropdown from './Dropdown'
import Statistics from './statistics'
import RecommenderCode from './RecommenderCode'
import excel from './excel'
import Question from './Question'
import systemManage from './systemManage'
import systemMaintenance from './systemMaintenance'
import customerManage from './customerManage'
import warmUpMode from './warmUpMode'
import emptyCartridges from './emptyCartridges'
import marketingManage from './marketingManage'
import medicalManage from './medicalManage'
import Content from './Content.js'
import patchOrder from './patchOrder'

const api = {
	account,
	userManage,
	UserUseage,
	UserAuthorization,

	appManage,
	CartridgeBatch,
	CartridgeModel,
	Cartridge,
	Device,
	CartridgeUsage,
	Message,
	Flavor,
	Consultation,
	Category,
	Dropdown,
	Statistics,
	Content,
	Question,
	RecommenderCode,
	excel,
	systemManage,
	systemMaintenance,
	customerManage,
	warmUpMode,
	emptyCartridges,
	marketingManage,
	medicalManage,
	patchOrder,
}

// 导出接口
export default api

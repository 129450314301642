/**
 * account 接口模块
 */

import axios from "../config/http.js";
import axios1 from "axios";
import qs from "qs";

import baseUrl from "../config/env.js";

const account = {
  // 登录 post
  login(params) {
    return axios1
      .post(baseUrl + "/platform/doLogin", qs.stringify(params), {
      // .post(baseUrl + "/supplier/doLogin", qs.stringify(params), {
        withCredentials: true,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((x) => x.data);
  },
  // 退出 post
  logout() {
    return axios.fetchPost(baseUrl + "/platform/logout"); //boss
    // return axios.fetchPost(baseUrl + '/supplier/logout');
  },
  // 修改密码 post
  resetPassword(params) {
    return axios.fetchPost(baseUrl + "/platform/changePassword", params); //boss
    // return axios.fetchPost(baseUrl + '/supplier/changePassword', params);
  },
  findUserMenu(params = {}) {
    return axios.fetchGet(baseUrl + "/platform/findUserMenu", params); //boss
    // return axios.fetchGet(baseUrl+"/supplier/findUserMenu",params)
  },
  submitCurrentTimeZone(params = {}) {
    return axios.fetchPost(baseUrl + "/platform/submitCurrentTimeZone", params); //boss
  },
};

export default account;

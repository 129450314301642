var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "deviceCoding",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "deviceCoding"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "deviceCoding",
                          required: "",
                          placeholder: "Enter deviceCoding"
                        },
                        model: {
                          value: _vm.form.deviceCoding,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceCoding", $$v)
                          },
                          expression: "form.deviceCoding"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "deviceNumber",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "deviceNumber"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "deviceNumber",
                          required: "",
                          placeholder: "Enter deviceNumber"
                        },
                        model: {
                          value: _vm.form.deviceNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceNumber", $$v)
                          },
                          expression: "form.deviceNumber"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Submit")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "m-l-5 ml-1",
                          attrs: { type: "reset", variant: "danger" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { format } from "./time";
import axios from "../config/http.js";
import axios1 from "../config/http-json.js";
import baseUrl from "../config/env.js";

async function smokeRecordApplicationList(params, ctx) {
  return axios.fetchGet(baseUrl + "/smokeRecordApplication/list", params);
}

function smokeRecordApplicationAdd(params) {
  return axios1.fetchPost(baseUrl + "/smokeRecordApplication/add", params);
}

function smokeRecordApplicationInfo(params) {
  return axios.fetchGet(baseUrl + "/smokeRecordApplication/selectById", params);
}

function smokeRecordApplicationUpdate(params) {
  return axios1.fetchPost(baseUrl + "/smokeRecordApplication/update", params);
}

function inStockStatistics(params) {
  return axios.fetchGet(
    baseUrl + "/smokeRecordApplication/inStockStatistics",
    params
  );
}

function review(params) {
  return axios.fetchPost(baseUrl + "/smokeRecordApplication/review", params);
}

//  空烟弹生产订单
function selectAllOrder(params, ctx) {
  return axios.fetchGet(baseUrl + "/smokeRecordOrder/selectAllOrder", params);
}
function smokeRecordOrderSave(params, ctx) {
  return axios.fetchPost(baseUrl + "/smokeRecordOrder/save", params);
}
function selectByOrderId(params, ctx) {
  return axios.fetchGet(baseUrl + "/smokeRecordOrder/selectByOrderId", params);
}
function cancelEmptyCartridgeOrderById(params) {
  return axios.fetchPost(
    baseUrl + "/smokeRecordOrder/cancelEmptyCartridgeOrderById",
    params
  );
}

export default {
  smokeRecordApplicationList,
  smokeRecordApplicationAdd,
  smokeRecordApplicationInfo,
  smokeRecordApplicationUpdate,
  inStockStatistics,
  review,
  selectAllOrder,
  smokeRecordOrderSave,
  selectByOrderId,
  cancelEmptyCartridgeOrderById,
};

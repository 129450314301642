<!-- 上传文件(图片或者视频) -->
<template>
	<b-modal
		id="modal"
		size="lg"
		v-model="showUploadModal"
		centered
		title="Upload Files"
		ok-title="Confirm"
		@ok="onConfirm"
		@cancel="onCancel"
		@close="onClose"
		no-close-on-esc
		no-close-on-backdrop
	>
		<b-card>
			<div class="file-list">
				<div
					v-for="(item, index) of fileList"
					:key="index"
					class="file-list-item"
				>
					<i
						class="ion ion-md-close-circle"
						style="
							position: absolute;
							right: 5px;
							top: 5px;
							font-size: 25px;
							z-index: 99999;
						"
						@click="onDeleteCarouselMap(item, index)"
					/>
					<video
						v-if="/mp4/.test(item)"
						:src="item"
						style="width: 100px; height: 100px"
					></video>
					<img
						v-else
						:src="item"
						style="width: 100px; height: 100px"
					/>
				</div>
				<input
					type="file"
					data-input="false"
					id="uploadFile"
					multiple="multiple"
					accept="image/jpeg,image/gif,image/png,audio/mp4,video/mp4"
					style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
					tabindex="-2"
					@change="onChangeFile($event)"
				/>
				<label
					v-if="fileList.length < maxNumber"
					for="uploadFile"
					style="margin-bottom: 0px"
					class="file-list-item"
				>
					<i
						class="el-icon-plus file-list-add"
						style="font-size: 95px"
					>
					</i>
				</label>
			</div>
		</b-card>
		<template v-slot:modal-footer>
			<div class="w-200">
				<b-button
					variant="primary"
					style="margin-right: 10px"
					@click="onConfirm"
				>
					Confirm
				</b-button>
				<b-button variant="secondary" @click="onCancel"
					>Cancel</b-button
				>
			</div>
		</template>
	</b-modal>
</template>
<script>
import { onReady, upload_multiple_file } from '@/api/upload'

export default {
	name: 'UploadFileModal',
	components: {},
	data() {
		return {
			fileList: [], //显示用的图片地址列表
			confirmFileList: [], //新增的文件上传列表
			deleteFileList: [], //删除已上传的文件列表
		}
	},
	props: {
		//控制组件显示
		showUploadModal: {
			type: Boolean,
			default: false,
		},
		//上传到服务器的路径前缀
		path: {
			type: String,
			default: '',
		},
		//父组件传过来显示的文件列表
		oldFileList: {
			type: Array,
			default: () => [],
		},
		//最大可上传文件数量
		maxNumber: {
			type: Number,
			default: 1,
		},
		//文件类型
		fileType: {
			type: RegExp,
			default: /image|video|mp4/,
		},
	},
	watch: {
		showUploadModal(value) {
			if (value) {
				this.fileList = JSON.parse(JSON.stringify(this.oldFileList))
			}
		},
	},
	mounted() {},
	methods: {
		onReady: onReady,
		onReset(evt) {
			this.fileList = []
			this.confirmFileList = []
			this.deleteFileList = []
		},
		onConfirm(bvModalEvt) {
			bvModalEvt.preventDefault()
			let that = this
			if (
				this.confirmFileList.length == 0 &&
				this.deleteFileList.length == 0
			) {
				this.$emit('CancelEvent', false)
				this.onReset()
			} else if (
				this.confirmFileList.length == 0 &&
				this.deleteFileList.length > 0
			) {
				this.onFinishEvent(
					that.fileList,
					that.deleteFileList,
					that.path
				)
			} else {
				const loading = this.$loading({
					lock: true,
					text: 'Uploading...',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
				})
				upload_multiple_file(this.confirmFileList, this.path + '/')
					.then((res) => {
						if (res.errno > 0) {
							this.$$bvToast.toast('Upload failed!')
							return
						}
						let finishFileList = res.data
						//倒序删除
						for (let i = that.fileList.length - 1; i >= 0; i--) {
							const element = that.fileList[i]
							if (element.indexOf('static') == -1) {
								that.fileList.splice(i, 1)
							}
						}
						finishFileList.forEach((item) => {
							that.fileList.push(item.url)
						})
						this.onFinishEvent(
							that.fileList,
							that.deleteFileList,
							that.path
						)
						this.onReset()
					})
					.catch((error) => {
						this.$bvToast.toast(error.message)
					})
					.finally(() => {
						loading.close()
					})
			}
		},
		onCancel(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.$emit('CancelEvent', false)
			this.onReset()
		},
		onClose(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.$emit('CancelEvent', false)
			this.onReset()
		},
		onDeleteCarouselMap(item, index) {
			let that = this
			this.$bvModal
				.msgBoxConfirm('Are you sure?', {
					title: 'Operation tips',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'Yes',
					cancelTitle: 'No',
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						try {
							for (
								let i = 0;
								i < that.confirmFileList.length;
								i++
							) {
								//本地预览地址
								const element = this.confirmFileList[i]
								if (element.showUrl == item) {
									that.confirmFileList.splice(i, 1)
								}
							}
							//记录删除已上传的文件
							if (item.indexOf('static') != -1) {
								that.deleteFileList.push(item)
							}
							that.fileList.splice(index, 1)
						} catch (error) {
							this.$bvToast.toast(error.message)
						}
					}
				})
				.catch((err) => {
					// An error occurred
				})
		},
		onChangeFile(e) {
			if (e.target.files.length == undefined) return
			let files = e.target.files
			for (let i = 0; i < files.length; i++) {
				const file = files[i]
				if (this.fileList.length >= this.maxNumber) {
					e.target.files = null
					this.$bvToast.toast('Upload up to five pictures.')
					return
				}
				if (this.fileType.test(file.type)) {
					const showUrl = this.getObjectURL(file)
					file.showUrl = showUrl
					this.confirmFileList.push(file)
					this.fileList.push(showUrl)
				} else {
					e.target.files = null
					this.$bvToast.toast(
						'Only upload files of the specified type!'
					)
				}
			}
		},
		/**
		 * @description: 操作完成回调函数
		 * @param {*} fileList 修改完成后的文件列表
		 * @param {*} deleteFileList 删除已上传的文件列表
		 * @param {*} path 上传服务器路径
		 * @return {*}
		 */
		onFinishEvent(fileList, deleteFileList, path) {
			this.$emit('FinishEvent', fileList, deleteFileList, path)
		},
		//获取上传图片本地地址
		getObjectURL(file) {
			var url = null
			if (/image/.test(file.type)) {
				if (window.createObjectURL != undefined) {
					url = window.createObjectURL(file)
				} else if (window.URL != undefined) {
					url = window.URL.createObjectURL(file)
				} else if (window.webkitURL != undefined) {
					url = window.webkitURL.createObjectURL(file)
				}
			} else if (/video|audio/.test(file.type)) {
				url = URL.createObjectURL(file) + '#mp4' //加mp4是为了本地预览识别类型
			}
			return url
		},
	},
}
</script>

<style lang="scss" scoped>
// ::v-deep .modal-footer {
//   display: none;
// }
::v-deep .card-body {
	height: 400px;
	overflow: hidden;
	overflow-y: auto;
}

.file-list {
	width: 100%;
	height: auto;
	display: flex;
	display: -webkit-flex;
	flex-direction: row;
	flex-wrap: wrap;

	.file-list-item {
		width: 100px;
		position: relative;
		margin-right: 45px;
		margin-top: 15px;
		max-width: 100px;
		max-height: 100px;
	}

	.file-list-add {
		border: 2px dashed #ccc;
	}
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="User Nickname"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="nick-name"
              >
                <b-form-input
                  id="nick-name"
                  trim
                  v-model="search_condition.nickName"
                  oninput="if(value.length > 20)value = value.slice(0, 20)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Question Group"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="question-group"
              >
                <b-form-select
                  id="question-group"
                  v-model="search_condition.problemGroupId"
                  :options="questionGroupList"
                  text-field="problemGroupName"
                  value-field="id"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Answer Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="select-time"
              >
                <date-picker
                  v-model="selectTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="3">
            <!-- <b-form-group label-cols-sm="4" label-align-sm="right"> -->
              <b-button variant="primary" @click="search()">Search</b-button>
            <!-- </b-form-group> -->
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="No records"
              id="device-table"
              head-variant="light"
              :striped="true"
              ref="table"
              :busy="isBusy"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'survey::Details'"
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Details</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Survey",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "Survey",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "nickName", label: "User Nickname", class: "text-center" },
        {
          key: "problemGroupName",
          label: "Question Group Name",
          class: "text-center",
        },
        {
          key: "createTime",
          label: "Answer Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.getLocalTime(value);
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      selectTime: [],
      questionGroupList: [
        { problemGroupName: "Please select", id: "", disabled: false },
      ], //平台
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        endTime: "",
        problemGroupId: "",
        nickName: "",
        // supplierId: localStorage.supplierId,
      },
      isBusy: false,
      has_data: false,
    };
  },
  created() {
    this.init();
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    init() {
      this.$api.userManage.selectProblemGroupDropDown().then((res) => {
        if (res.success) {
          this.questionGroupList = this.questionGroupList.concat(res.data);
        }
      });
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.userManage
        .selectAllUserReply(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            this.tableData = x.data;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    show(item, index, button) {
      this.$router.push("surveyInfo?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

import { format } from './time'
import qs from 'qs'
import axios from '../config/http.js'
import axios1 from '../config/http-json.js'
import baseUrl from '../config/env.js'
import { param } from 'jquery'

function allCountry(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/allCountry', params)
}
function allCountryName(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/allCountryName', params)
}
function selectAllCountryName(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllCountryName', params)
}

function brandList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/brandList', params)
}
function batchNumberList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/batchNumberList', params)
}

function componentEncodingList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/componentEncodingList', params)
}

function manufacturerEncodingList(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/manufacturerEncodingList',
		params
	)
}

function selectAllAtomizingWire(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllAtomizingWire', params)
}

function selectAllFlavor(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllFlavor', params)
}
function selectBatchFlavor(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectBatchFlavor', params)
}

function selectAllSolventEncoding(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllSolventEncoding',
		params
	)
}

function selectModelBySupplierId(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectModelBySupplierId', params)
}

function selectTypeEncoding(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectTypeEncoding', params)
}

function selectTypeByOil(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectTypeByOil', params)
}

function supplierRoleList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/supplierRoleList', params)
}
function selectRoleAllList(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectRoleAllList', params)
}
function selectEnableRole(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectEnableRole', params)
}
function selectAllSupplier(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllSupplier', params)
}
function selectByWarmUpModeId(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectByWarmUpModeId', params)
}
function selectByHeatingModeId(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectByHeatingModeId', params)
}
function selectAllTestSmokeBombModel(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllTestSmokeBombModel',
		params
	)
}
function selectAllDeviceModel(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllDeviceModel', params)
}
function selectCartridgeModelById(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectCartridgeModelById',
		params
	)
}

//查询二维码列表
function qrCodeList(params = {}) {
	return axios.fetchGet(baseUrl + '/qrCodeGroup/list', params)
}
//下载二维码列表
// function exportQrCode(params = {}) {
//   return axios.fetchGet(baseUrl + "/qrCodeGroup/exportQrCode", params);
// }
//下载二维码列表
function exportQrCode(params = {}) {
	let d = JSON.parse(JSON.stringify(params))
	d.id = params.id
	d['platform-token'] = localStorage['auth-token']
	location.href =
		baseUrl + '/qrCodeGroup/exportQrCode' + '?' + qs.stringify(d)
}
function exportCartridgeQrCode(params = {}) {
	let d = JSON.parse(JSON.stringify(params))
	d.id = params.id
	d['platform-token'] = localStorage['auth-token']
	location.href =
		baseUrl + '/smokeRecordOrder/exportCartridgeQrCode' + '?' + qs.stringify(d)
}
//添加二维码
function addQrCode(params = {}) {
	return axios1.fetchPost(baseUrl + '/qrCodeGroup/addQrCode', params)
}

//查询默认的加热模式下拉框
function selectDefaultHeatingMode(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectDefaultHeatingMode',
		params
	)
}

//查询默认的预热模式下拉框
function selectDefaultWarmUpMode(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectDefaultWarmUpMode', params)
}

//查询所有烟弹类型
function selectAllCartridgeType(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllCartridgeType', params)
}

//FAQ中的查询烟油型号下拉框
function selectModelAndPasteType(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectModelAndPasteType', params)
}

//查询所有商品标签下拉框
function selectAllCommodityLabel(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllCommodityLabel', params)
}

//查询所有使用感受标签下拉框
function selectAllExperienceFeelingsLabel(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllExperienceFeelingsLabel',
		params
	)
}

//查询所有口感标签下拉框
function selectAllTasteLabel(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllTasteLabel', params)
}

//查询所有强度标签下拉框
function selectAllStrength(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllStrength', params)
}

//查询所有敏感类别下拉框
function selectAllSensitiveWordCategory(params = {}) {
	return axios.fetchGet(
		baseUrl + '/dropDown/selectAllSensitiveWordCategory',
		params
	)
}

//查询一级菜单所属的所有子菜单
function selectAllSubMenu(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllSubMenu', params)
}

async function selectReason(params = {}) {
	axios.fetchGet(baseUrl + '/dropDown/selectTypeEncoding', params)
	return {
		success: true,
		data: [
			{ id: 0, name: 'fda' },
			{ id: 1, name: 'recall' },
			{ id: 2, name: '...' },
		],
	}
}

function selectAllBrandIdentity(params = {}) {
	return axios.fetchGet(baseUrl + '/dropDown/selectAllBrandIdentity',params)
}

export default {
	allCountry,
	allCountryName,
	selectAllCountryName,
	brandList,
	componentEncodingList,
	manufacturerEncodingList,
	selectAllAtomizingWire,
	selectAllFlavor,
	selectBatchFlavor,
	selectAllSolventEncoding,
	selectModelBySupplierId,
	selectTypeEncoding,
	selectTypeByOil,
	selectReason,
	batchNumberList,
	supplierRoleList,
	selectRoleAllList,
	selectEnableRole,
	selectAllSupplier,
	selectByWarmUpModeId,
	selectByHeatingModeId,
	selectAllTestSmokeBombModel,
	selectAllDeviceModel,
	selectCartridgeModelById,
	qrCodeList,
	exportQrCode,
	addQrCode,
	selectDefaultHeatingMode,
	selectDefaultWarmUpMode,
	selectAllCartridgeType,
	selectModelAndPasteType,
	selectAllCommodityLabel,
	selectAllExperienceFeelingsLabel,
	selectAllTasteLabel,
	selectAllStrength,
	selectAllSensitiveWordCategory,
	selectAllSubMenu,
	exportCartridgeQrCode,
	selectAllBrandIdentity
}

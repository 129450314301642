var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  attrs: { action: "#" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.typeForm($event)
                    },
                    reset: _vm.onReset
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Code:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right"
                              }
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.typeform.code,
                                    expression: "typeform.code"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.typesubmit &&
                                    _vm.$v.typeform.code.$error
                                },
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "A combination of six letters and Numbers",
                                  name: "name",
                                  disabled: ""
                                },
                                domProps: { value: _vm.typeform.code },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.typeform,
                                      "code",
                                      $event.target.value
                                    )
                                  }
                                }
                              }),
                              _c("span", [_vm._v(" Automatically generate ")])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Effective time:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right"
                              }
                            },
                            [
                              _c("date-picker", {
                                attrs: {
                                  format: "MM/DD/YYYY",
                                  range: "",
                                  "append-to-body": "",
                                  lang: "en",
                                  disabled: _vm.typeform.failureState == 1,
                                  placeholder: "Select Effective time"
                                },
                                model: {
                                  value: _vm.selectTime,
                                  callback: function($$v) {
                                    _vm.selectTime = $$v
                                  },
                                  expression: "selectTime"
                                }
                              }),
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { value: "1", "unchecked-value": "0" },
                                  on: {
                                    input: function($event) {
                                      return _vm.changeFailureState(
                                        _vm.typeform.failureState
                                      )
                                    }
                                  },
                                  model: {
                                    value: _vm.typeform.failureState,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.typeform,
                                        "failureState",
                                        $$v
                                      )
                                    },
                                    expression: "typeform.failureState"
                                  }
                                },
                                [_vm._v("Permanent")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c(
                        "b-col",
                        { attrs: { lg: "10" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status:",
                                "label-cols-sm": "4",
                                "label-align-sm": "right"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "padding-top": "5px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.statuss,
                                  name: "status"
                                },
                                model: {
                                  value: _vm.typeform.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.typeform, "status", $$v)
                                  },
                                  expression: "typeform.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "form-group mb-0" }, [
                    _c("div", { staticClass: "text-center" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary m-l-5 ml-1",
                          attrs: { type: "reset" }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
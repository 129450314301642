var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Device Code Name:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "modelName",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "modelName",
                      placeholder: "Please enter",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.modelName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "modelName", $$v)
                      },
                      expression: "form.modelName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Device Name:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "deviceName",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "deviceName",
                      placeholder: "Please enter",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.deviceName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "deviceName", $$v)
                      },
                      expression: "form.deviceName"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Brand Identity:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "brand-identity",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap" },
                    _vm._l(_vm.types.selectAllBrandIdentity, function(
                      label,
                      index
                    ) {
                      return _c(
                        "b-form-checkbox",
                        {
                          key: index,
                          staticClass: "mr-2 mt-2",
                          attrs: { required: true, value: label.id },
                          model: {
                            value: _vm.form.brandIdentityId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "brandIdentityId", $$v)
                            },
                            expression: "form.brandIdentityId"
                          }
                        },
                        [_vm._v(" " + _vm._s(label.brandName) + " ")]
                      )
                    }),
                    1
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Curve Type:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "curve-type",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap" },
                    _vm._l(_vm.types.curveType, function(label, index) {
                      return _c(
                        "b-form-checkbox",
                        {
                          key: index,
                          staticClass: "mr-2 mt-2",
                          attrs: { required: true, value: label.id },
                          model: {
                            value: _vm.form.curveType,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "curveType", $$v)
                            },
                            expression: "form.curveType"
                          }
                        },
                        [_vm._v(" " + _vm._s(label.typeName) + " ")]
                      )
                    }),
                    1
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Applicable Device:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "applicable-device",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap" },
                    _vm._l(_vm.types.selectAllDeviceModel, function(
                      label,
                      index
                    ) {
                      return _c(
                        "b-form-checkbox",
                        {
                          key: index,
                          staticClass: "mr-2 mt-2",
                          attrs: { required: true, value: label.id },
                          model: {
                            value: _vm.form.deviceModelId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "deviceModelId", $$v)
                            },
                            expression: "form.deviceModelId"
                          }
                        },
                        [_vm._v(" " + _vm._s(label.modelName) + " ")]
                      )
                    }),
                    1
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Cartridge Type:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "cartridge-type",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-wrap" },
                    _vm._l(_vm.types.cartridgeType, function(label, index) {
                      return _c(
                        "b-form-checkbox",
                        {
                          key: index,
                          staticClass: "mr-2 mt-2",
                          attrs: { required: true, value: label.id },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        [_vm._v(" " + _vm._s(label.name) + " ")]
                      )
                    }),
                    1
                  )
                ]
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes",
                    "label-cols-lg": "3",
                    "label-align-sm": "right",
                    "label-for": "remark"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remark",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "3" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: Code-HHX
 * @Date: 2021-12-24 17:45:21
 * @LastEditors: Code-HHX
 * @LastEditTime: 2022-04-29 12:03:13
 * @Description: 菜单列表页面
-->
<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />

		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="3">
							<b-form-group
								label="系统"
								label-cols-sm="3"
								label-align-sm="right"
								label-for="menu-system"
							>
								<b-form-select
									id="menu-system"
									v-model="search_condition.menuType"
									:options="types.menuSystemList"
									value-field="value"
									text-field="text"
									@change="onChangeMenuSystem"
								></b-form-select>
							</b-form-group>
						</b-col>
						<b-col lg="3">
							<b-form-group
								label="父级菜单"
								label-cols-sm="3"
								label-align-sm="right"
								label-for="menu-parent"
							>
								<b-form-select
									id="menu-parent"
									v-model="search_condition.pid"
									:options="types.menuParentList"
									value-field="id"
									text-field="name"
								></b-form-select>
							</b-form-group>
						</b-col>
						<b-col lg="3">
							<b-form-group
								label="名称"
								label-cols-sm="3"
								label-align-sm="right"
								label-for="menu-name"
							>
								<b-form-input
									id="menu-name"
									v-model="search_condition.name"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<b-col lg="2">
							<b-button variant="primary" @click="search()"
								>查询</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<!-- table -->
					<b-button
						v-has="'menu::Create'"
						@click="gotoAdd"
						variant="primary"
						>添加</b-button
					>

					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="no records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
									(search_condition.pageNum - 1) *
										search_condition.pageSize +
									1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-has="'menu::Modify'"
										size="sm"
										variant="info"
										@click="
											edit(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										修改
									</b-button>
									<b-button
										v-if="row.item.type == 1"
										v-has="'menu::Modify'"
										size="sm"
										variant="warning"
										@click="
											editButton(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										按钮组
									</b-button>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									>
									</b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<!-- 新增/修改菜单详情弹窗 -->
		<b-modal
			id="modal"
			size="lg"
			v-model="showMenuDetailModal"
			centered
			:title="menuInfo.id ? '添加' : '修改'"
			ok-title="提交"
			cancel-title="取消"
			@close="onCloseDetailModal"
			no-close-on-esc
			no-close-on-backdrop
		>
			<b-card>
				<b-form
					id="menuInfoForm"
					@submit="onConfirmMenuInfo"
					@reset="onCancelDetailModal"
				>
					<!-- 平台 -->
					<b-form-group
						label="平台："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-system"
						label-class="requiredRow"
					>
						<b-form-select
							id="modal-menu-system"
							class="col-lg-10"
							v-model="menuInfo.menuType"
							:options="types.menuSystemList"
							required
							oninvalid="setCustomValidity('Please select');"
							oninput="setCustomValidity('')"
							@change="onChangeModalMenuSystem"
							:disabled="menuInfo.id != 0 ? true : false"
						></b-form-select>
					</b-form-group>
					<!-- 菜单级别 -->
					<b-form-group
						label="菜单级别："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-level"
						label-class="requiredRow"
					>
						<b-form-select
							id="modal-menu-level"
							class="col-lg-10"
							v-model="menuInfo.type"
							:options="types.menuLevelList"
							required
							oninvalid="setCustomValidity('Please select');"
							oninput="setCustomValidity('')"
							:disabled="menuInfo.id != 0 ? true : false"
						></b-form-select>
					</b-form-group>
					<!-- 父级菜单 -->
					<b-form-group
						v-if="menuInfo.type == 1"
						label="父级菜单："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-parent"
						label-class="requiredRow"
					>
						<b-form-select
							id="modal-menu-parent"
							class="col-lg-10"
							v-model="menuInfo.pid"
							:options="types.modalMenuParentList"
							required
							oninvalid="setCustomValidity('Please select');"
							oninput="setCustomValidity('')"
							text-field="name"
							value-field="id"
						></b-form-select>
					</b-form-group>
					<!-- 名称 -->
					<b-form-group
						label="名称："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-name"
						label-class="requiredRow"
					>
						<b-form-input
							id="modal-menu-name"
							class="col-lg-10"
							v-model="menuInfo.name"
							required
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 路径 -->
					<b-form-group
						v-if="menuInfo.type == 0"
						label="路径："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-href"
					>
						<b-form-input
							id="modal-menu-href"
							class="col-lg-10"
							v-model="menuInfo.href"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 路径（type=1时必填） -->
					<b-form-group
						v-if="menuInfo.type == 1"
						label="路径："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-href"
						label-class="requiredRow"
					>
						<b-form-input
							id="modal-menu-href"
							class="col-lg-10"
							v-model="menuInfo.href"
							required
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 图标 -->
					<b-form-group
						label="图标："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-icon"
					>
						<b-form-input
							id="modal-menu-icon"
							class="col-lg-10"
							v-model="menuInfo.icon"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 排序 -->
					<b-form-group
						label="排序："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-sort"
					>
						<b-form-input
							id="modal-menu-sort"
							class="col-lg-10"
							v-model="menuInfo.sort"
							type="number"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 快捷菜单名称 -->
					<b-form-group
						label="快捷菜单名称："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-quick-name"
					>
						<b-form-input
							id="modal-menu-quick-name"
							class="col-lg-10"
							v-model="menuInfo.quickName"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 快捷菜单图标 -->
					<b-form-group
						label="快捷菜单图标："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-quick-icon"
					>
						<b-form-input
							id="modal-menu-quick-icon"
							class="col-lg-10"
							v-model="menuInfo.quickIcon"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 菜单别名 -->
					<b-form-group
						v-if="menuInfo.menuType == 2 || menuInfo.menuType == 3"
						label="菜单别名："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-menu-alias"
					>
						<b-form-input
							id="modal-menu-alias"
							class="col-lg-10"
							v-model="menuInfo.menuAlias"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<b-form-group label-cols-lg="5">
						<b-button type="submit" variant="primary"
							>提交</b-button
						>
						<b-button
							type="reset"
							variant="secondary"
							class="m-l-5 ml-1"
							>取消</b-button
						>
					</b-form-group>
				</b-form>
			</b-card>
			<template v-slot:modal-footer>
				<div class="w-100"></div>
			</template>
		</b-modal>
		<!-- 新增/修改菜单按钮弹窗 -->
		<b-modal
			id="modal-button"
			size="lg"
			v-model="showMenuButtonModal"
			centered
			title="按钮组"
			ok-title="提交"
			cancel-title="取消"
			@close="onCloseButtonModal"
			no-close-on-esc
			no-close-on-backdrop
		>
			<b-card>
				<b-form
					id="menuButtonForm"
					@submit="onConfirmMenuButton"
					@reset="onCloseButtonModal"
				>
					<!-- pid -->
					<b-form-group
						label="PID："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-button-pid"
					>
						<b-form-text
							id="modal-button-pid"
							class="col-lg-10 pt-1"
						>
							<h6>{{ menuInfo.id }}</h6>
						</b-form-text>
					</b-form-group>
					<!-- 父级菜单 -->
					<b-form-group
						label="父级菜单："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-button-parent"
					>
						<b-form-text
							id="modal-button-parent"
							class="col-lg-10 pt-1"
						>
							<h6>{{ menuInfo.name }}</h6>
						</b-form-text>
					</b-form-group>
					<!-- 权限别名 -->
					<b-form-group
						label="权限别名："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-button-permission"
						label-class="requiredRow"
					>
						<b-form-input
							id="modal-button-permission"
							required
							class="col-lg-10"
							v-model="menuInfo.permission"
							oninvalid="setCustomValidity('Please enter');"
							oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
							placeholder="Please enter"
						></b-form-input>
					</b-form-group>
					<!-- 按钮组 -->
					<b-form-group
						label="按钮组："
						label-cols-lg="2"
						label-align-lg="right"
						label-for="modal-button-group"
					>
						<b-form-checkbox-group
							class="pt-2"
							form="menuButtonForm"
							id="modal-button-group"
							v-model="selectButtonList"
							:options="types.buttonGroup"
							switches
						></b-form-checkbox-group>
					</b-form-group>
					<b-form-group label-cols-lg="5">
						<b-button type="submit" variant="primary"
							>提交</b-button
						>
						<b-button
							type="reset"
							variant="secondary"
							class="m-l-5 ml-1"
							>取消</b-button
						>
					</b-form-group>
				</b-form>
			</b-card>
			<template v-slot:modal-footer>
				<div class="w-100"></div>
			</template>
		</b-modal>
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'

/**
 * Menu component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
	},
	data() {
		return {
			page: {
				title: 'Menu Mgmt',
				items: [
					{
						text: 'UNI-CORE',
						// href: "/"
					},
					{
						text: 'System Mgmt',
						// href: "/"
					},
					{
						text: 'Menu Mgmt',
						active: true,
					},
				],
			},
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,

			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{ key: 'id', label: 'ID', class: 'text-center' },
				{ key: 'pid', label: '父级ID', class: 'text-center' },
				{ key: 'name', label: '名称', class: 'text-center' },
				{
					key: 'type',
					label: '级别',
					class: 'text-center',
					formatter: (value) => {
						return value == 0 ? '一级菜单' : '二级菜单'
					},
				},
				{ key: 'href', label: '路径', class: 'text-center' },
				{ key: 'sort', label: '排序', class: 'text-center' },
				{ key: 'icon', label: '图标', class: 'text-center' },
				{
					key: 'quickName',
					label: '快捷菜单名称',
					class: 'text-center',
				},
				{
					key: 'quickIcon',
					label: '快捷菜单图标',
					class: 'text-center',
				},
				{ key: 'menuAlias', label: '菜单别名', class: 'text-center' },
				{ key: 'actions', label: '操作', class: 'text-center' },
			],
			types: {
				//菜单级别列表
				menuLevelList: [
					{ text: '一级菜单', value: 0 },
					{ text: '二级菜单', value: 1 },
				],
				//所属平台列表
				menuSystemList: [
					{ text: '平台', value: 0 },
					{ text: '供应商', value: 1 },
					{ text: '平台 Developer', value: 3 },
					{ text: '供应商 Developer ', value: 2 },
				],

				bossParentList: [], //BOSS父级菜单
				brandParentList: [], //品牌父级菜单
				bossAppParentList: [], //BOSS APP父级菜单
				brandAppParentList: [], //品牌 APP父级菜单

				//父级菜单列表
				menuParentList: [{ name: '请选择', id: '' }],
				//弹窗父级菜单列表
				modalMenuParentList: [{ name: '请选择', id: '' }],
				buttonGroup: [
					{ text: 'View', value: 'list' },
					{ text: 'Create', value: 'Create' },
					{ text: 'Modify', value: 'Modify' },
					{ text: 'Enable', value: 'Enable' },
					{ text: 'Disable', value: 'Disable' },
					{ text: 'Delete', value: 'Delete' },
					{ text: 'Details', value: 'Details' },
					{ text: 'Verify', value: 'Verify' },
					{ text: 'Close', value: 'Close' },
					{ text: 'Cancel', value: 'Cancel' },
					{ text: 'Review', value: 'Review' },
					{ text: 'Recovery', value: 'Recovery' },
					{ text: 'Process', value: 'Process' },
					{ text: 'Accept', value: 'Accept' },
					{ text: 'Refuse', value: 'Refuse' },
					{ text: 'Export', value: 'Export' },
					{ text: 'Down', value: 'Down' },
					{ text: 'Allocation', value: 'Allocation' },
					{ text: 'Stage Setting', value: 'Stage Setting' },
					{ text: 'Preheat Mode', value: 'Preheat Mode' },
					{ text: 'Heating Mode', value: 'Heating Mode' },
					{
						text: 'Download the report',
						value: 'Download the report',
					},
					{ text: 'Release Binding', value: 'Release Binding' },
					{ text: 'Batch deplete', value: 'Batch deplete' },
					{
						text: 'App content settings',
						value: 'App content settings',
					},
					{
						text: 'Perfect flavor info',
						value: 'Perfect flavor info',
					},
					{ text: 'Modify flavor info', value: 'Modify flavor info' },
					{
						text: 'Increased Validity Period',
						value: 'Increased Validity Period',
					},
				],
			},
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				name: '', //菜单名称
				menuType: 0, //所属平台
				pid: '', //父级菜单
			},
			isBusy: false,
			has_data: false,

			showMenuDetailModal: false,
			//菜单详情
			menuInfo: {
				id: 0,
				pid: '',
				type: 0, //0一级菜单 1二级菜单 2菜单按钮
				menuType: 0, //0平台 1供应商
				name: '', //名称
				href: '', //路径
				icon: '', //图标
				sort: '', //排序
				permission: '', //权限值
				quickName: '', //快捷菜单名称
				quickIcon: '', //快捷菜单图标
				menuAlias: '', //菜单别名
				button: [], //按钮组
			},

			showMenuButtonModal: false,
			oldButtonList: [], //原来选中的按钮组
			selectButtonList: [], //选中的按钮组合
		}
	},
	watch: {},
	computed: {
		rows() {
			return this.totalRows
		},
	},
	mounted() {
		this.init()
	},
	created() {},
	methods: {
		async selectOneLevelMenu(menuType) {
			await this.$api.Dropdown.selectAllSubMenu({
				menuType: menuType,
				pid: 0,
				type: 0,
			})
				.then((res) => {
					if (res.success && res.data) {
						if (menuType == 0) {
							this.types.bossParentList = res.data
						} else if (menuType == 1) {
							this.types.brandParentList = res.data
						} else if (menuType == 2) {
							this.types.brandAppParentList = res.data
						} else if (menuType == 3) {
							this.types.bossAppParentList = res.data
						}
					} else {
						if (res.message) {
							this.$toast(res)
						}
					}
				})
				.catch((error) => {
					this.$toast(error)
				})
		},
		async init() {
			await this.selectOneLevelMenu(0)
			await this.selectOneLevelMenu(1)
			await this.selectOneLevelMenu(2)
			await this.selectOneLevelMenu(3)
			this.types.menuParentList = this.types.menuParentList.concat(
				this.types.bossParentList
			)
			this.types.modalMenuParentList =
				this.types.modalMenuParentList.concat(this.types.bossParentList)
		},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		gotoAdd() {
			this.showMenuDetailModal = true
		},
		provider(ctx) {
			this.isBusy = true
			return this.$api.systemManage
				.selectMenuList(this.search_condition, ctx)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		edit(item, index, button) {
			this.menuInfo = item
			if (this.menuInfo.menuType == 0) {
				this.types.modalMenuParentList = [{ name: '请选择', id: '' }]
				this.types.modalMenuParentList =
					this.types.modalMenuParentList.concat(
						this.types.bossParentList
					)
			} else if (this.menuInfo.menuType == 1) {
				this.types.modalMenuParentList = [{ name: '请选择', id: '' }]
				this.types.modalMenuParentList =
					this.types.modalMenuParentList.concat(
						this.types.brandParentList
					)
			}
			this.showMenuDetailModal = true
		},
		editButton(item, index, button) {
			this.menuInfo = item
			this.menuInfo.button = []
			//获取菜单按钮组
			this.$api.systemManage
				.selectButtonGroup({
					menuType: this.menuInfo.menuType,
					pid: this.menuInfo.id,
				})
				.then((res) => {
					if (res.success && res.data.length > 0) {
						let str = res.data[0].permission
						if (str && str.indexOf(':') != -1) {
							this.menuInfo.permission = str.substring(
								0,
								str.indexOf(':')
							)
						}
						this.selectButtonList = []
						this.oldButtonList = res.data
						this.types.buttonGroup.forEach((element) => {
							res.data.forEach((menuButton) => {
								if (element.text == menuButton.name) {
									this.selectButtonList.push(element.value)
								}
							})
						})
					}
					if (!res.success) {
						this.$toast(res)
					}
				})
				.catch((error) => {
					this.$toast(error)
				})
			this.showMenuButtonModal = true
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		onChangeMenuSystem(value) {
			this.search_condition.pid = ''
			this.types.menuParentList = [{ name: '请选择', id: '' }]
			if (value == 0) {
				this.types.menuParentList = this.types.menuParentList.concat(
					this.types.bossParentList
				)
			} else if (value == 1) {
				this.types.menuParentList = this.types.menuParentList.concat(
					this.types.brandParentList
				)
			} else if (value == 2) {
				this.types.menuParentList = this.types.menuParentList.concat(
					this.types.brandAppParentList
				)
			} else if (value == 3) {
				this.types.menuParentList = this.types.menuParentList.concat(
					this.types.bossAppParentList
				)
			}
		},
		onChangeModalMenuSystem(value) {
			this.menuInfo.pid = ''
			this.types.modalMenuParentList = [{ name: '请选择', id: '' }]

			if (value == 0) {
				this.types.modalMenuParentList =
					this.types.modalMenuParentList.concat(
						this.types.bossParentList
					)
			} else if (value == 1) {
				this.types.modalMenuParentList =
					this.types.modalMenuParentList.concat(
						this.types.brandParentList
					)
			} else if (value == 2) {
				this.types.modalMenuParentList =
					this.types.modalMenuParentList.concat(
						this.types.brandAppParentList
					)
			} else if (value == 3) {
				this.types.modalMenuParentList =
					this.types.modalMenuParentList.concat(
						this.types.bossAppParentList
					)
			}
		},
		onConfirmMenuInfo(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.$api.systemManage
				.addMenuDetail(this.menuInfo)
				.then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.$refs.table.refresh()
					}
				})
				.catch((error) => {
					this.$toast(error)
				})
				.finally(() => {
					this.reloadData()
				})
			this.showMenuDetailModal = false
		},
		onCancelDetailModal() {
			this.reloadData()
			this.showMenuDetailModal = false
		},
		onCloseDetailModal() {
			this.reloadData()
			this.showMenuDetailModal = false
		},
		reloadData() {
			//初始化数据
			this.menuInfo = {
				id: 0,
				pid: '',
				type: 0,
				menuType: 0,
				name: '',
				href: '',
				icon: '',
				sort: '',
				permission: '',
				quickName: '',
				quickIcon: '',
				menuAlias: '',
				button: [],
			}
			this.selectButtonList = []
		},
		onConfirmMenuButton(bvModalEvt) {
			bvModalEvt.preventDefault()
			if (this.selectButtonList.length <= 0) {
				this.$bvToast.toast('Please select at least one button', {
					title: `Tips`,
					variant: 'warning',
					solid: true,
					toaster: 'b-toaster-bottom-right',
				})
				return
			}

			//组装数据
			let addMenuVo = this.menuInfo
			if (this.oldButtonList.length <= 0) {
				if (this.selectButtonList.length > 0) {
					for (let i = 0; i < this.selectButtonList.length; i++) {
						const element = this.selectButtonList[i]
						if (element == 'list') element = 'View'
						let newButton = {
							id: 0,
							pid: addMenuVo.id,
							name: element,
							permission: addMenuVo.permission + '::' + element,
							sort: i + 1,
						}
						addMenuVo.button.push(newButton)
					}
				}
			} else if (this.oldButtonList.length > 0) {
				for (let i = 0; i < this.selectButtonList.length; i++) {
					const element = this.selectButtonList[i]
					if (element == 'list') element = 'View'
					let newButton = {
						id: 0,
						pid: addMenuVo.id,
						name: element,
						permission: addMenuVo.permission + '::' + element,
						sort: i + 1,
					}
					for (let j = 0; j < this.oldButtonList.length; j++) {
						const item = this.oldButtonList[j]
						if (newButton.name == item.name) {
							newButton.id = item.id
						}
					}
					addMenuVo.button.push(newButton)
				}
			}
			console.log(addMenuVo)
			this.$api.systemManage
				.addMenuButton(addMenuVo)
				.then((res) => {
					if (res.success) {
						this.$toast(res)
						this.reloadData()
						this.showMenuButtonModal = false
					}
				})
				.catch((error) => {
					this.$toast(res)
					this.reloadData()
				})
		},
		onCloseButtonModal() {
			this.reloadData()
			this.showMenuButtonModal = false
		},
	},
}
</script>

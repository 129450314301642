<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Title"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  v-model="search_condition.title"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Category"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="category"
              >
                <b-form-select
                  v-model="search_condition.categoryId"
                  :options="types.questionCategorys"
                  value-field="id"
                  text-field="name"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="status"
              >
                <b-form-select
                  v-model="search_condition.isDelete"
                  :options="types.statuss"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
        </b-card>

        <b-card>
          <!-- table -->
          <b-button
            v-has="'community::Create'"
            @click="gotoAdd"
            variant="primary"
            >Create</b-button
          >

          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="table"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <!-- <b-button
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    show
                  </b-button> -->

                  <b-button
                    v-has="'community::Modify'"
                    size="sm"
                    variant="info"
                    @click="edit(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    Modify
                  </b-button>

                  <!-- <b-button
                    size="sm"
                    variant="danger"
                    @click="del(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    delete
                  </b-button> -->
                  <b-button
                    v-if="row.item.isDelete"
                    v-has="'community::Disable'"
                    size="sm"
                    variant="danger"
                    @click="enableOrDisable(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Disable</b-button
                  >

                  <b-button
                    v-else
                    v-has="'community::Enable'"
                    size="sm"
                    variant="info"
                    @click="enableOrDisable(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Enable</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * FAQ component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      page: {
        title: "Community",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Content Mgmt",
            // href: "/"
          },
          {
            text: "Community",
            active: true,
          },
        ],
      },
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "title", label: "Title", class: "text-center" },

        {
          key: "categoryId",
          label: "Category",
          class: "text-center",
          formatter: (value) => {
            let a = "";
            this.types.questionCategorys.forEach((item) => {
              if (item.id == value) {
                a = item.name;
              }
            });
            return a;
          },
        },
        { key: "sort", label: "Sort", class: "text-center" },
        {
          key: "isDelete",
          label: "Status",
          formatter: (value) => {
            return value == 1 ? "Enabled" : "Disabled";
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          class: "text-center",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        categoryId: "",
        isDelete: "",
        title: "",
        // supplierId: localStorage.supplierId,
      },
      isBusy: false,
      has_data: false,
      // selectTime: ["",""],
      types: {
        statuss: [
          { text: "Please select", value: "", disabled: false },
          { text: "Enabled", value: 1, disabled: false },
          { text: "Disabled", value: 0, disabled: false },
        ],
        questionCategorys: [{ id: "", name: "Please select" }],
      },
    };
  },
  watch: {
    //    selectTime:function(val){
    //         this.search_condition.createTime=val[0]
    //         this.search_condition.endTime=val[1]
    //    },
  },
  computed: {
    rows() {
      // return this.totalRows + this.search_condition.pageSize;
      return this.totalRows;
    },
  },
  mounted() {
    this.init();
  },
  created() {},
  methods: {
    init() {
      this.$api.Category.list().then((res) => {
        if (res.success) {
          this.types.questionCategorys = this.types.questionCategorys.concat(
            res.data
          );
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    gotoAdd() {
      this.$router.push("communityAdd");
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Consultation.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    show(item, index, button) {
      this.$router.push("communityInfo?id=" + item.id);
    },
    edit(item, index, button) {
      this.$router.push("communityEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    del(a, b, c) {
      this.$api.Consultation.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
    // 停用启用
    enableOrDisable(a, b, c) {
      this.$api.Consultation.enableOrDisable({
        id: a.id,
        isDelete: a.isDelete === 0 ? 1 : 0,
      }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <b-form-group
          label="deviceCoding"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="deviceCoding"
        >
          <b-form-input
            id="deviceCoding"
            v-model="form.deviceCoding"
            required
            placeholder="Enter deviceCoding"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="deviceNumber"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="deviceNumber"
        >
          <b-form-input
            id="deviceNumber"
            v-model="form.deviceNumber"
            required
            placeholder="Enter deviceNumber"
          ></b-form-input>
        </b-form-group>

        <!--
				<b-form-group
					label="SupplierId:" 
					label-cols-lg="2"
					label-align-lg="right" 
					label-for="supplierId">
                    <select class="form-control" v-model="form.SupplierId">
                          <option v-for="(a,b) in types.brandNames">{{a}}</option>
                    </select>
				</b-form-group>
               -->

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";

/**
 * Device Add
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: "Device Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Device Create",
          active: true,
        },
      ],
      form: {
        deviceCoding: "",
        deviceNumber: "",
        // supplierId:localStorage.supplierId,
      },
      types: {
        brandNames: [],
        flavorOptions: [],
        cartridgeTypes: [],
        cartridgeComponentData: [],
      },
      show: true, // 表单显示
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.Device.add(this.form).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        } else {
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        deviceCoding: "",
        deviceNumber: "",
        // supplierId:localStorage.supplierId,
      };
      this.show = false;
      this.$nextTick(() => {
        // this.show = true
        this.$router.go(-1);
      });
    },
  },
};
</script>

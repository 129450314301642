var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "align-items-center" },
    [
      _c("b-col", { attrs: { lg: "12" } }, [
        _c("div", { staticClass: "blockquote" }, [
          _c("h4", { staticClass: "font-size-18" }, [_vm._v(_vm._s(_vm.title))])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
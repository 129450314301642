import {format} from "./time"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"


async function list(params,ctx){
    axios.fetchGet(baseUrl + '/question/list',params)
    return {
        success:true,
        data:[
            {
              id:1,
              questionName: 'Have you been feeling better lately?',
              questionCategory: 'science',
              status: 1,
              activatedTimes: '2020-04-22 15:05:26',
            },
        ],
        total:1,
    }
}

function add(params){
    return axios1.fetchPost(baseUrl + '/question/add', params);
}

function find(params){
   return axios.fetchGet(baseUrl + '/question/findById',params)
}

function update(params){
    return axios1.fetchPost(baseUrl + '/question/updateById', params);
}

function del(params){
    return axios.fetchPost(baseUrl + '/question/deleteById', params);
}


export default {
    list,
    find,
    add,
    update,
    del,
}

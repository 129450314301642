// 时区转换
import moment from "moment";

export default {
  // 显示时间的时区处理
  getLocalTime(date) {
    if (!date) return "-";
    let local = new Date();
    local.getTimezoneOffset() * 60000;
    let san = new Date(date);
    let dateTime = new Date(san.getTime() - local.getTimezoneOffset() * 60000);
    let year = dateTime.getFullYear();
    let month = this.appendZero(dateTime.getMonth() + 1);
    let day = this.appendZero(dateTime.getDate());
    let hours = this.appendZero(dateTime.getHours());
    let minu = this.appendZero(dateTime.getMinutes());
    let sec = this.appendZero(dateTime.getSeconds());
    // return (
    //   year + "-" + month + "-" + day + " " + hours + ":" + minu + ":" + sec
    // );
    return moment(dateTime).format("MMMM Do YYYY, h:mm:ss a");
  },
  // 转换时间格式不减时区
  foramtTime(date) {
    //let dateTime =new Date(date);
    return moment(date).format("MMMM Do YYYY");
  },
  getLocalTimes(date) {
    if (!date) return "-";
    let local = new Date();
    local.getTimezoneOffset() * 60000;
    let san = new Date(date);
    let dateTime = new Date(san.getTime() - local.getTimezoneOffset() * 60000);
    let year = dateTime.getFullYear();
    let month = this.appendZero(dateTime.getMonth() + 1);
    let day = this.appendZero(dateTime.getDate());
    // return (
    //   year + "-" + month + "-" + day
    // );
    return moment(dateTime).format("MMMM Do YYYY");
  },

  // 查询时间的时区处理
  queryTime(date) {
    let dateee = new Date(date).toJSON();
    return new Date(+new Date(dateee))
      .toISOString()
      .replace(/T/g, " ")
      .replace(/\.[\d]{3}Z/, "");
  },
  appendZero(obj) {
    if (obj < 10) return "0" + "" + obj;
    else return obj;
  },
};

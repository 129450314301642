<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- 包装订单名称 -->
            <b-form-group
              label="Package Order Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="order-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="order-name"
                v-model="form.orderName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- 订单号 -->
            <b-form-group
              label="Order No.:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="order-number"
            >
              <b-form-input
                id="order-number"
                placeholder="Automatic system generation"
                disabled
              >
              </b-form-input>
            </b-form-group>

            <!-- 烟弹型号 -->
            <b-form-group
              label="Cartridge Model:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="cartridge-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="cartridge-model"
                v-model="form.testSmokeBombModeId"
                :options="types.selectAllTestSmokeBombModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>
            <!-- 生产数量 -->
            <b-form-group
              label="Production Quantity:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="production-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="production-quantity"
                v-model="form.batchQuantity"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
              <span>
                {{ quantity }} box,The recommended quantity is a multiple of 50,
                one box per 50</span
              >
            </b-form-group>

            <!-- 生产厂家 -->
            <b-form-group
              label="Manufacturer:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="manufacturer"
            >
              <b-form-input
                id="manufacturer"
                v-model="form.manufacturerEncodingName"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 生产时间 -->
            <b-form-group
              label="Production Time:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="production-time"
            >
              <date-picker
                id="production-time"
                v-model="productionTime"
                format="MM/DD/YYYY"
                append-to-body
                lang="en"
                placeholder="Select date"
              ></date-picker>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Remarks:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Enter Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Cancel</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
    //每50个一盒
    quantity() {
      var number = 0;
      if (this.form.batchQuantity > 0 && this.form.batchQuantity < 50) {
        return 1;
      } else {
        number = this.form.batchQuantity / 50;
        return Math.floor(number);
      }
    },
  },
  created() {
    this.selectItem = this.$route.params.selectItem;
    this.init();
  },
  watch: {
    productionTime(val) {
      this.form.productionTime = this.dateFormatter(val);
      console.log(this.form.productionTime);
    },
  },
  data() {
    return {
      selectItem: 0, //类型(1是油弹 2是膏弹 3是电池烟弹 4是电磁烟弹 5是NIC)
      title: "Wax Carts Pkg Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Wax Carts Pkg Create",
          active: true,
        },
      ],
      types: {
        // manufacturerEncodingList: [{ name: "Please select", id: "" }],
        selectAllTestSmokeBombModel: [{ modelName: "Please select", id: "" }],
        selectAllDefaultHeatingMode: [{ modeName: "Please select", id: "" }],
        selectAllDefaultWarmUpMode: [{ warmUpName: "Please select", id: "" }],
      },
      form: {
        orderName: "",
        testSmokeBombModeId: "",
        batchQuantity: "",
        manufacturerEncodingName: "",
        productionTime: "",
        remarks: "",
        defaultWarmHeatingId: "",
        defaultWarmUpId: "",
        type: "",
        orderType: 1, // 包装订单
      },
      productionTime: "",
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.selectAllTestSmokeBombModel({ type: 2 }).then(
        (res) => {
          if (res.success && res.data) {
            this.types.selectAllTestSmokeBombModel =
              this.types.selectAllTestSmokeBombModel.concat(res.data);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        }
      );
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.form.type = this.selectItem; //烟弹类型
      this.$api.emptyCartridges.smokeRecordOrderSave(this.form).then((res) => {
        if (res.success) {
          this.$router.go(-1);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    dateFormatter(val) {
      let dateTime = new Date(val);
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1;
      let day = dateTime.getDate();
      // let h = dateTime.getHours();
      // let mm = dateTime.getMinutes();
      // let s = dateTime.getSeconds();
      return (
        year + "-" + this.appendZero(month) + "-" + this.appendZero(day)
        // + " " +
        // this.appendZero(h) +
        // ":" +
        // this.appendZero(mm) +
        // ":" +
        // this.appendZero(s)
      );
    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },
  },
};
</script>

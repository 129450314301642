var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("Steps", { attrs: { Steps: _vm.registSpets, SetData: _vm.SetData } }),
      _c(
        "b-card",
        [
          _c("b-col", { attrs: { lg: "12" } }, [
            _c(
              "p",
              {
                staticClass: "title",
                staticStyle: { "font-size": "16px", "font-weight": "bolder" }
              },
              [
                _c("span", {
                  staticStyle: {
                    display: "inline-block",
                    width: "10px",
                    height: "20px",
                    "vertical-align": "text-top",
                    "background-color": "#626ed4"
                  }
                }),
                _vm._v(" Batch Info ")
              ]
            )
          ]),
          _c(
            "b-tabs",
            { staticStyle: { margin: "20px 0" }, attrs: { pills: "" } },
            [
              _c(
                "b-tab",
                { attrs: { title: "Batch Details", active: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch Name：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.batchName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch ID：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.number))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Cartridge Model：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.smokeModel))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Flavor：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.modelName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Content Capacity:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.capacity))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Max Frequency:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.maxFrequency))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Batch Quantity:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.batchQuantity))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Production Factory:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.batchDetailsVo.manufacturerName)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Remarks:")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.batchDetailsVo.remarks))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Content Details" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Content Type: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.smokeOilDetailsVo.type))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Brand：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.smokeOilDetailsVo.brandName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Flavor：")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.smokeOilDetailsVo.modelName))
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Cartridge Details" } },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        { attrs: { cols: "3" } },
                        [
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Model Name: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.testSmokeBombModelVo.modelName))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Material: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.heatingWireMaterial
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Size: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(_vm.testSmokeBombModelVo.heatingWireSize)
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Heating Wire Porosity: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.heatingWirePorosity
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Oil Hole: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(_vm._s(_vm.testSmokeBombModelVo.oilInlet))
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Resistance: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.resistance
                                    ? _vm.testSmokeBombModelVo.resistance + "Ω"
                                    : ""
                                )
                              )
                            ])
                          ]),
                          _c("b-col", [
                            _c("label", { staticClass: "col-form-label" }, [
                              _vm._v("Cotton coil: ")
                            ]),
                            _c("span", { staticClass: "p-3" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.testSmokeBombModelVo.cottonBag == 0
                                    ? "Yes"
                                    : _vm.testSmokeBombModelVo.cottonBag == 1
                                    ? "No"
                                    : ""
                                )
                              )
                            ])
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-tab",
                { attrs: { title: "Heating&Preheating Mode" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("span", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("Heating Mode")
                      ]),
                      _c("b-table", {
                        ref: "table",
                        attrs: {
                          id: "heating-table",
                          "head-variant": "light",
                          "show-empty": "",
                          "empty-text": "no records",
                          busy: _vm.heatingIsBusy,
                          striped: true,
                          items: _vm.heatingModeDetailsVo,
                          fields: _vm.heatingModefields
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-busy",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center text-danger my-2"
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle"
                                    }),
                                    _c("strong", [_vm._v("Loading...")])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      }),
                      _c("span", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("Preheating Mode")
                      ]),
                      _c("b-table", {
                        ref: "table",
                        attrs: {
                          id: "preheating-table",
                          "head-variant": "light",
                          "show-empty": "",
                          "empty-text": "no records",
                          busy: _vm.preheatingIsBusy,
                          striped: true,
                          items: _vm.warmUpModeDetailsVo,
                          fields: _vm.warmUpModefields
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-busy",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "text-center text-danger my-2"
                                  },
                                  [
                                    _c("b-spinner", {
                                      staticClass: "align-middle"
                                    }),
                                    _c("strong", [_vm._v("Loading...")])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.produceStatus == 3
        ? _c(
            "b-card",
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c(
                  "p",
                  {
                    staticClass: "title",
                    staticStyle: {
                      "font-size": "16px",
                      "font-weight": "bolder"
                    }
                  },
                  [
                    _c("span", {
                      staticStyle: {
                        display: "inline-block",
                        width: "10px",
                        height: "20px",
                        "vertical-align": "text-top",
                        "background-color": "#626ed4"
                      }
                    }),
                    _vm._v(" COA Info ")
                  ]
                )
              ]),
              _c(
                "b-container",
                { staticStyle: { width: "80%" } },
                [
                  _c(
                    "b-row",
                    { attrs: { cols: "1" } },
                    [
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Sample ID: ")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(
                            _vm._s(_vm.batchNumberCoaDetailsVo.sampleNumber)
                          )
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Sample Name: ")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.batchNumberCoaDetailsVo.sampleName))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Sample Batch ID: ")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(
                            _vm._s(
                              _vm.batchNumberCoaDetailsVo.sampleBatchNumber
                            )
                          )
                        ])
                      ]),
                      _c(
                        "b-col",
                        [
                          _c("label", { staticClass: "col-form-label" }, [
                            _vm._v("Content Formula: ")
                          ]),
                          _vm._l(_vm.batchNumberCoaDetailsVo.formula, function(
                            a
                          ) {
                            return _c(
                              "span",
                              {
                                key: a.id,
                                staticStyle: {
                                  "margin-left": "30px",
                                  display: "flex"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [_vm._v(_vm._s(a.component))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [_vm._v(_vm._s(a.proportion + "%"))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        a.status == 0
                                          ? "Main ingredients"
                                          : "The secondary components"
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        a.display == 0 ? "Show at Model X" : ""
                                      )
                                    )
                                  ]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        [
                          _c("label", { staticClass: "col-form-label" }, [
                            _vm._v("Test Items: ")
                          ]),
                          _vm._l(
                            _vm.batchNumberCoaDetailsVo.addReport,
                            function(item) {
                              return _c(
                                "span",
                                {
                                  key: item.id,
                                  staticStyle: {
                                    "margin-left": "30px",
                                    display: "flex"
                                  }
                                },
                                [_vm._v(_vm._s(item.reportName))]
                              )
                            }
                          )
                        ],
                        2
                      ),
                      _c(
                        "b-col",
                        [
                          _c("label", { staticClass: "col-form-label" }, [
                            _vm._v("Solvent: ")
                          ]),
                          _vm._l(_vm.batchNumberCoaDetailsVo.solvent, function(
                            b
                          ) {
                            return _c(
                              "span",
                              {
                                key: b.id,
                                staticStyle: {
                                  "margin-left": "30px",
                                  display: "flex"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [_vm._v(_vm._s(b.solventName))]
                                ),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-right": "20px" } },
                                  [_vm._v(_vm._s(b.proportion + "%"))]
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Test Report: ")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.batchNumberCoaDetailsVo.reportLink))
                        ])
                      ]),
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Remarks: ")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.batchNumberCoaDetailsVo.remarks))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        { staticStyle: { width: "70%" } },
        [
          _c(
            "b-button",
            { attrs: { size: "lg" }, on: { click: _vm.onReset } },
            [_vm._v("Go Back")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import {format} from "./time"
import axios from "../config/http.js"
import axios1 from "../config/http-json.js"
import baseUrl from "../config/env.js"

async function list(params,ctx){
    // params.createTime=format(params.createTime)
    console.log(params,ctx)
    return axios.fetchGet(baseUrl+"/smokeBombModel/list",params)
 }

function find(params){
   return axios.fetchGet(baseUrl + '/smokeBombModel/selectByModelId',params)
}

function format_add(params){
    let d=JSON.parse(JSON.stringify(params))

    d["dose"]=+d["dose"]
    d["maxFrequency"]=+d["maxFrequency"]
    d["medicalCare"]=+d["medicalCare"]
    //d["daySeconds"]=+d["daySeconds"]

    for (let i in d['solvent']){
     //   d['solvent'][i]["proportion"]=+d['solvent'][i]["proportion"]
    }

    for (let i in d['formula']){
      //  d['formula'][i]["proportion"]=+d['formula'][i]["proportion"]
        d['formula'][i]["status"]=+d['formula'][i]["status"]
    }




//    let f=""
//    for (let i in d["flavor"]){
//        f+=d["flavor"][i]["flavorName"] +","
//    }
//    d["flavor"]=f.substr(0,f.length-1)
//    let form=[]
//    for (let i in d['formula']){
//        let s={}
//        let ff=d['formula'][i]
//        s["id"]=ff["component"]["id"]
//        s["component"]=ff["component"]["componentName"]
//        s["proportion"]=parseInt(ff["proportion"])
//        s["status"]=parseInt(ff["status"])
//        form.push(s)
//    }
//    d["formula"]=JSON.stringify(form)
//    let solv=[]
//    for (let i in d['solvent']){
//        let s={}
//        let ff=d['solvent'][i]
//        s["id"]=ff["solvent"]["id"]
//        s["solvent"]=ff["solvent"]["solventName"]
//        s["encoding"]=ff["solvent"]["encoding"]
//        s["proportion"]=parseInt(ff["proportion"])
//        solv.push(s)
//    }
//    d["solvent"]=JSON.stringify(solv)
//    d["type"]=parseInt(d["type"]["id"])
//    d["brandName"]=d["brandName"]["brandName"]
//    d["atomizingWireType"]=d["atomizingWireType"]["name"]
    // delete d["reportType"]
    return d
}


function add(params){
    // let d=format_add(params)
	// return axios1.fetchPost(baseUrl + '/smokeBombModel/add', d);
    return axios1.fetchPost(baseUrl + '/smokeBombModel/add', params);
}

function update(params){
    // let d=format_add(params)
	// return axios.fetchPost(baseUrl + '/smokeBombModel/updateById', d);
    return axios1.fetchPost(baseUrl + '/smokeBombModel/updateById', params);
}

function equipmentControl(params){
    return axios.fetchPost(baseUrl + '/smokeBombModel/equipmentControl', params);
}

function absorbedDose(params){
    return axios.fetchGet(baseUrl + '/smokeBombModel/absorbedDose', params);
}
function associatedWarmUp(params){
    return axios1.fetchPost(baseUrl + '/smokeBombModel/associatedWarmUp', params);
}
function associatedHeatingMode(params){
    return axios1.fetchPost(baseUrl + '/smokeBombModel/associatedHeatingMode', params);
}
function testSmokeBombModelList(params){
    return axios.fetchGet(baseUrl + '/testSmokeBombModel/list', params);
}
function testSmokeBombModelAdd(params){
    return axios.fetchGet(baseUrl + '/testSmokeBombModel/add', params);
}
function selectTestSmokeBombModelId(params){
    return axios.fetchGet(baseUrl + '/testSmokeBombModel/selectTestSmokeBombModelId', params);
}
function testSmokeBombModelUpdate(params){
    return axios.fetchGet(baseUrl + '/testSmokeBombModel/updateById', params);
}


export default {
    list,
    find,
    add,
    update,
    equipmentControl,
    absorbedDose,
    associatedWarmUp,
    associatedHeatingMode,
    testSmokeBombModelList,
    testSmokeBombModelAdd,
    selectTestSmokeBombModelId,
    testSmokeBombModelUpdate
}

<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />
		
		<b-card>
			<b-form @submit.stop.prevent="onSubmit" @reset="onReset">
				<!-- 标题 -->
				<b-form-group
					label="Title:" 
					label-cols-lg="2"
					label-align-lg="right"
					label-for="title">
					<b-form-input id="title" 
						v-model="form.title" required placeholder="Enter Title"></b-form-input>
				</b-form-group>
				
				<!-- 发布用户 -->
				<b-form-group
					label="Publish user:" 
					label-cols-lg="2"
					label-align-lg="right"
					label-for="user">
					<b-form-input id="user" 
						v-model="form.user" required placeholder="Enter Publish user"></b-form-input>
				</b-form-group>
				
				<!-- 文章分类 -->
				<b-form-group
					label="Category:" 
					label-cols-lg="2"
					label-align-lg="right" 
					label-for="category">

                    <b-form-select
                        v-model="form.category"
                        :options="types.Categorys"
                        value-field="id"
                        text-field="name"
                    >
                    </b-form-select>

				</b-form-group>
				
				<!-- 文章内容  -->
				<b-form-group
					label="Content:" 
					label-cols-lg="2"
					label-align-lg="right"
					label-for="content">
					<ckeditor 
                           v-model="form.content" 
                           :editor="editor"
                           @ready="onReady"
                    >
                    </ckeditor>
				</b-form-group >
				
				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button> 
					<b-button type="reset" variant="danger" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</b-form>
		</b-card>
	</Layout>
</template>
<script>
	import Layout from "../../layouts/main";
	import PageHeader from "@/components/page-header";
	
	import CKEditor from "@ckeditor/ckeditor5-vue";
	import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
	import Multiselect from "vue-multiselect";

    import { onReady} from "@/api/upload"




	/**
	 * Article Add component
	 */
	export default {
		components: {
			Layout,
			PageHeader,
			ckeditor: CKEditor.component,
			Multiselect
		},
		data() {
			return {
				page: {
					title: "Article Create",
					items: [{
							text: "UNI-CORE",
							// href: "/"
						},
						{
							text: "Content Manage",
							// href: "/"
						},
						{
							text: "Article Create",
							active: true
						}
					]
				},
				form: {
                    title : '',
                    user:"",
                    category : '',
                    content : '',

//                  "id": 0,
//                  "title": "",
//                  "content": "",
//                  "createTime": "",
//                  "state": 0,
//                  "supplierId": localStorage.supplierId,
//                  "userName": ""
				},
				categorys: [
					'science', 'common sense'
				],
				editor: ClassicEditor,
                types:{
                    Categorys:[
                       {id:"",name:''},
                    ],
                    statuss:[
                       { text: '', value: 2, disabled: false },
                       { text: 'Added', value: 1, disabled: false },
                       { text: 'Removed', value:0, disabled: false },
                    ],
                },

				
			};
		},
		created() {

		},
        mounted(){
            this.init()
        },
		methods: {
            onReady:onReady,
            init(){
                this.$api.Category.list().then(res=>{
                    if (res.success) {
                        this.types.Categorys=this.types.Categorys.concat(res.data)
                    }
                })
            },

			onSubmit(evt) {
                    evt.preventDefault()
                    this.$api.Message.add(this.form).then(res=>{
                        if(res.message){
              this.$bvToast.toast(res.message);
            }
                        if (res.success){
                            this.$router.go(-1);
                        }
                    })
			},
			onReset(evt) {
				evt.preventDefault()
                this.form={
                    title : '',
                    user:"",
                    category : '',
                    content : '',
                }
				this.$nextTick(() => {
					this.show = true
				})
			},
		}
	};
</script>

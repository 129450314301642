var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row align-items-center" }, [
    _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "page-title-box" }, [
        _c("h4", { staticClass: "font-size-18" }, [_vm._v(_vm._s(_vm.title))]),
        _c(
          "div",
          { staticClass: "page-title-right" },
          [
            _c("b-breadcrumb", {
              staticClass: "m-0",
              attrs: { items: _vm.items }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 问题组名称 -->
        <b-form-group
          label="Question Group Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="title"
          label-class="requiredRow"
        >
          <b-form-input
            id="title"
            v-model="form.problemGroupName"
            placeholder="Please enter"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
          ></b-form-input>
          <span>Title is up to 100 characters long</span>
        </b-form-group>

        <!-- 问题分类 -->
        <b-form-group
          label="Question Category:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="categoryId"
          label-class="requiredRow"
        >
          <b-form-select
            id="categoryId"
            v-model="form.problemTypeId"
            :options="types.questionCategorys"
            value-field="id"
            text-field="typeName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('')"
          >
          </b-form-select>
        </b-form-group>

        <!-- 问题设置 -->
        <b-form-group
          label="Question Setting:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="setting"
          label-class="requiredRow"
        >
          <b-button
            variant="link"
            @click="addProblemName"
            style="margin-bottom: 10px; text-decoration: none"
            >Add a question</b-button
          >
          <div
            class="inner mb-3 row"
            v-for="(item, index) in form.problemName"
            :key="index"
          >
            <div class="col-md-8 col-8">
              <b-form-group
                :label="'Question' + (index + 1) + ':'"
                label-cols-lg="2"
                label-align-lg="right"
                label-class="requiredRow"
              >
                <b-form-input
                  v-model="item.problemName"
                  placeholder="Please enter"
                  required
                  oninvalid="setCustomValidity('Please enter');"
                  oninput="setCustomValidity('');if(value.length > 100)value = value.slice(0, 100)"
                ></b-form-input>
              </b-form-group>
            </div>

            <div class="col-md-8 col-8">
              <b-form-group
                :label="'Answer' + (index + 1) + ':'"
                label-cols-lg="2"
                label-align-lg="right"
                label-class="requiredRow"
              >
                <b-form-radio-group
                  id="btn-radios-2"
                  :options="answerList"
                  buttons
                  button-variant="outline-primary"
                  name="radio-btn-outline"
                  disabled
                ></b-form-radio-group>
              </b-form-group>
            </div>

            <div class="col-md-8 col-8" v-if="form.problemName.length > 1">
              <b-form-group label="" label-cols-lg="2" label-align-lg="right">
                <b-button
                  variant="link"
                  @click="deleteProblemName(index)"
                  style="text-decoration: none"
                  >Delete</b-button
                >
              </b-form-group>
            </div>
          </div>
        </b-form-group>

        <!-- 状态 -->
        <b-form-group
          label="Status:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="status"
          label-class="requiredRow"
        >
          <b-form-radio-group
            id="radio-group-1"
            v-model="form.status"
            :options="types.statuss"
            name="radio-options"
            required
            style="margin-top: 5px"
          ></b-form-radio-group>
        </b-form-group>

        <!-- 所属客户 -->
        <b-form-group
          label="Affiliated Customer:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer"
          label-class="requiredRow"
        >
          <b-form-select
            id="customer"
            v-model="form.supplierId"
            :options="types.allSupplier"
            value-field="id"
            text-field="brandName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady, upload_img } from "@/api/upload";
/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      page: {
        title: this.editId
          ? "Research Questions Modify"
          : "Research Questions Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Content Mgmt",
            // href: "/"
          },
          {
            text: this.editId
              ? "Research Questions Modify"
              : "Research Questions Create",
            active: true,
          },
        ],
      },
      form: {
        problemTypeId: "",
        problemGroupName: "",
        problemName: [{ problemName: "" }],
        status: 1,
        remarks: "",
        supplierId: "",
      },
      answerList: ["1", "2", "3", "4", "5"],
      types: {
        questionCategorys: [{ id: "", typeName: "Please select" }],
        statuss: [
          { text: "Enabled", value: 1, disabled: false },
          { text: "Disabled", value: 0, disabled: false },
        ],
        allSupplier: [{ brandName: "Please select", id: "" }],
      },
      editId: window.location.search,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {
    if (!this.editId) {
      this.init();
    }
  },
  methods: {
    onReady: onReady,
    init() {
      this.$api.medicalManage.selectAllProblemType().then((res) => {
        if (res.success) {
          this.types.questionCategorys = this.types.questionCategorys.concat(
            res.data
          );
        }
      });
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.form.id) {
        this.$api.medicalManage.modifiedProblemGroup(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$api.medicalManage.saveProblemGroup(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    formData(id) {
      this.$api.medicalManage
        .selectProblemGroupDetails({ id: id })
        .then((res) => {
          if (res.success) {
            this.form = res.data;
            this.init();
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
    },
    addProblemName() {
      if (this.form.problemName.length >= 15) {
        this.$bvToast.toast("problemName.length must be  <=15");
        return;
      } else {
        let d = {
          problemName: "",
        };
        this.form.problemName.push(d);
      }
    },
    deleteProblemName(index) {
      if (this.form.problemName.length > 1) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.form.problemName.splice(index, 1);
        }
      } else {
        this.$bvToast.toast(">1");
      }
    },
  },
};
</script>

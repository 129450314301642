<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form>
        <b-tabs
          v-model="tabIndex"
          justified
          pills
          class="navtab-bg"
          content-class="p-3"
        >
          <b-tab active>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-home"></i>
              </span>
              <span class="d-none d-sm-inline-block">About me</span>
            </template>
            <b-card-text>About me</b-card-text>
            <wang v-model="form.aboutUs" @save="save" />
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-user"></i>
              </span>
              <span class="d-none d-sm-inline-block">User agreement</span>
            </template>

            <b-card-text>User agreement</b-card-text>
            <wang v-model="form.agreementContent" @save="save" />
          </b-tab>

          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="far fa-envelope"></i>
              </span>
              <span class="d-none d-sm-inline-block">
                Startup page settings
              </span>
            </template>

            <b-container class="bv-example-row" fluid="sm">
              <b-card-text>startup Page</b-card-text>
              <b-row>
                <b-col cols="4" xs="12">
                  <b-img-lazy
                    v-bind="mainProps"
                    :src="form.startupPage"
                    alt="startupPage"
                    fluid
                  ></b-img-lazy>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12">
                  <div class="form-group mb-0">
                    <label></label>

                    <input
                      type="file"
                      class="filestyle"
                      data-input="false"
                      id="filestyle-1"
                      style="position: absolute; clip: rect(0px, 0px, 0px, 0px);"
                      tabindex="-1"
                      @change="upload($event)"
                    />

                    <div class="bootstrap-filestyle input-group">
                      <div
                        name="filedrag"
                        style="position: absolute; width: 100%; height: 35px; z-index: -1;"
                      ></div>
                      <span class="group-span-filestyle " tabindex="0">
                        <label
                          for="filestyle-1"
                          style="margin-bottom: 0;"
                          class="btn btn-secondary "
                        >
                          <span class="buttonText">
                            upload
                          </span>
                        </label>
                        <span>
                          only support jpg/png file
                        </span>
                      </span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span class="d-inline-block d-sm-none">
                <i class="fas fa-cog"></i>
              </span>
              <span class="d-none d-sm-inline-block">customer service</span>
            </template>

            <b-form-group
              label="customer service phone"
              label-cols-lg="3"
              label-align-lg="right"
            >
              <b-form-input
                id="model-name1"
                v-model="form.phone"
                placeholder=""
              ></b-form-input>
            </b-form-group>

            <b-row class="text-center col-lg-12">
              <b-col lg="12">
                <b-form-group>
                  <b-button @click="save()" variant="primary">Save</b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import { upload_app_img } from "@/api/upload";

import wang from "@/components/wang";

export default {
  components: {
    Layout,
    PageHeader,
    wang,
  },
  computed: {},
  mounted() {
    this.init();
  },
  created() {},
  methods: {
    save(t = "") {
      this.$api.appManage.updateSystemSetup(this.form).then((res) => {
        if(res.message){
              this.$bvToast.toast(res.message);
            }
        if (res.success) {
          this.form = res.data;
        } else {
        }
      });
    },
    init() {
      this.$api.appManage
        .selectSystemSetupById({ id: window.location.search.split("=")[1] })
        .then((res) => {
          if (res.success) {
            this.form = res.data;
            console.log("zzzzzz", res.data);
          } else {
          }
        });
    },
    upload(e) {
      let that = this;
      let file = e.target.files[0];
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_app_img(file).then((res) => {
          that.$bvToast.toast(res.message);
          if (res.success) {
            this.form.startupPage = res.data;
            this.save();
          } else {
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
  },
  data() {
    return {
      tabIndex: 0,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 400,
        class: "my-5",
      },
      page: {
        title: "Settings",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "App Manage",
            // href: "/"
          },
          {
            text: "Settings",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        phone: "",
        aboutUs: "",
        agreementContent: "",
        startupPage: "",
        skinSetting: "",
      },
    };
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Name:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "qr-name",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "qr-name",
                      placeholder: "Please enter Name",
                      trim: "",
                      required: "",
                      oninvalid: "setCustomValidity('Please enter');",
                      oninput: "setCustomValidity('');"
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Number:",
                    "label-cols-lg": "3",
                    "label-align-lg": "right",
                    "label-for": "qr-number",
                    "label-class": "requiredRow"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "qr-number",
                      placeholder: "Please enter Number 1 to 5000",
                      trim: "",
                      required: "",
                      type: "number",
                      oninvalid: "setCustomValidity('Please enter 1 to 5000');",
                      oninput: "setCustomValidity('');",
                      onblur: "if(value<0||value>5000)value=1"
                    },
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Notes:",
                    "label-cols-lg": "3",
                    "label-align-sm": "right",
                    "label-for": "remark"
                  }
                },
                [
                  _c("b-form-textarea", {
                    attrs: {
                      id: "remark",
                      placeholder: "Enter Notes",
                      rows: "3",
                      "max-rows": "6"
                    },
                    model: {
                      value: _vm.form.remarks,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remarks", $$v)
                      },
                      expression: "form.remarks"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "3" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 名称 -->
        <b-form-group
          label="Name:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="qr-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="qr-name"
            v-model="form.name"
            placeholder="Please enter Name"
            trim
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');"
          ></b-form-input>
        </b-form-group>

        <!-- 生成数量 -->
        <b-form-group
          label="Number:"
          label-cols-lg="3"
          label-align-lg="right"
          label-for="qr-number"
          label-class="requiredRow"
        >
          <b-form-input
            id="qr-number"
            v-model="form.number"
            placeholder="Please enter Number 1 to 5000"
            trim
            required
            type="number"
            oninvalid="setCustomValidity('Please enter 1 to 5000');"
            oninput="setCustomValidity('');"
            onblur="if(value<0||value>5000)value=1"
          ></b-form-input>
        </b-form-group>

        <!-- 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="3"
          label-align-sm="right"
          label-for="remark"
        >
          <b-form-textarea
            id="remark"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="3">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
  },
  data() {
    return {
      page: {
        title: "QR Code Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Production Mgmt",
            // href: "/"
          },
          {
            text: "QR Code Create",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        name: "",
        number: "",
        remarks: "",
      },
      temperatureList: [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
      types: {
        allSupplier: [{ brandName: "Please select", id: "" }],
        heatingTypes: [
          { text: "Consistent Temperature", value: 1 },
          { text: "High Wave", value: 2 },
        ],
        statuss: [
          { text: "Enabled", value: 0 },
          { text: "Disabled", value: 1 },
        ],
      },
      photoName: "",
      editId: window.location.search,
      editor: ClassicEditor,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    this.init();
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {},
  methods: {
    onReady: onReady,
    init() {
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.Dropdown.addQrCode(this.form).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$router.go(-1);
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: "",
        modeName: "",
        heatingType: "2",
        heatingRemarks: "",
        supplierId: "",
        heatingPhoto: "",
        temperature: "",
        remarks: "",
        status: "",
      };
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
    upload(e) {
      let file = e.target.files[0];
      this.photoName = file.name;
      console.log(file);
      if (/image/.test(file.type)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.form.heatingPhoto = res.data;
            console.log("zzzzzzzz", this.form);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
    formData(id) {
      this.$api.warmUpMode.selectByHeatingModeId({ id: id }).then((res) => {
        if (res.success) {
          if (res.data.temperature) {
            let arr = res.data.temperature.split(",");
            this.temperatureList = [];
            arr.forEach((i) => {
              this.temperatureList.push({ value: i });
            });
          }
          this.form = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    changeTemperatureList(data) {
      let arr = [];
      data.forEach((item) => {
        if (item.value) {
          arr.push(item.value);
        }
      });
      this.form.temperature = arr.join(",");
    },
    changeHeatingType(value) {
      if (value == 2) {
        this.temperatureList = [
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
          { value: "" },
        ];
      } else {
        this.form.temperature = "";
      }
    },
  },
};
</script>

<style lang="scss">
.required-icon {
  ::before {
    content: "*";
  }
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- 用户资料 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>User Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <b-col>
            <label class="col-form-label">Avatar:</label>
            <span class="p-3"
              ><img
                v-if="info.picture"
                :src="info.picture"
                alt
                class="avatar-xs rounded-circle mr-2"
            /></span>
          </b-col>
          <b-col>
            <label class="col-form-label">Nickname:</label>
            <span class="p-3">{{ info.nickName }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Registered Devices:</label>
            <span class="p-3">{{ info.binding }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Used Atomizer?:</label>
            <span class="p-3">{{
              info.isAtomization == 1 ? "Yes" : "No"
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Smoked Vape?:</label>
            <span class="p-3">{{
              info.isElectricSmoke == 1 ? "Yes" : "No"
            }}</span>
          </b-col>
          <b-col>
            <label class="col-form-label">Total Registered Carts:</label>
            <span class="p-3">{{ info.smokeRecordTotal }}</span>
          </b-col>
          <!-- <b-col>
            <label class="col-form-label">Gender:</label>
            <span class="p-3">{{
              info.gender == 1
                ? "Female"
                : info.gender == 0
                ? "Male"
                : "Secret"
            }}</span>
          </b-col> -->
          <b-col>
            <label class="col-form-label"> Used For:</label>
            <span class="p-3">{{ info.useTime }}</span>
          </b-col>
          <b-col lg="12">
            <label class="col-form-label">Likes:</label>
            <b-badge
              v-for="(item, i) in info.flavor"
              :key="i"
              variant="primary"
              style="margin-left: 10px"
              >{{ item }}</b-badge
            >
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- 设备控制 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Device Control</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row cols="3">
          <!-- 计量控制 -->
          <b-col>
            <label class="col-form-label">Dosage Control:</label>
            <span class="p-3">-</span>
            <!-- <span class="p-3">No limit</span> -->
            <!-- <span class="p-3">800 seconds/day </span> -->
          </b-col>
          <!-- 禁用时段 -->
          <b-col>
            <label class="col-form-label">Disable Time:</label>
            <span class="p-3">-</span>
            <!-- <span class="p-3">8:00-21:00</span> -->
          </b-col>
          <!-- 温度控制 -->
          <b-col>
            <label class="col-form-label">Temp Control:</label>
            <span class="p-3">-</span>
            <!-- <span class="p-3">580 ℉</span> -->
          </b-col>
          <!-- 是否授权 -->
          <b-col>
            <label class="col-form-label">Is Authorized:</label>
            <span class="p-3">-</span>
            <!-- <span class="p-3">Have authorized</span> -->
          </b-col>
          <!-- 推荐码 -->
          <b-col>
            <label class="col-form-label">Referral Code:</label>
            <span class="p-3">-</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <!-- end card -->

    <!-- 绑定的设备信息 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Registered Devices</h4>
          </div>
        </b-col>
      </b-row>
      <br />

      <b-table
        id="device_table"
        head-variant="light"
        striped
        :fields="deviceFields"
        ref="device_table"
        :items="device_provider"
        :per-page="device.pageSize"
        :current-page="device.pageNum"
        :busy="device_isBusy"
        show-empty
        empty-text="No records"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(index)="data">{{
          data.index + (device.pageNum - 1) * device.pageSize + 1
        }}</template>
      </b-table>

      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="device_totalRows">
              <b-pagination
                v-model="device.pageNum"
                :per-page="10"
                :total-rows="device_totalRows"
                aria-controls="device_table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <!-- end card -->

    <!-- 绑定的烟弹信息 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Cartridge Info</h4>
          </div>
        </b-col>
      </b-row>
      <br />

      <b-table
        id="cartridge_table"
        head-variant="light"
        striped
        :fields="cartridgeFields"
        ref="cartridge_table"
        :items="cartridge_provider"
        :per-page="cartridge.pageSize"
        :current-page="cartridge.pageNum"
        :busy="cartridge_isBusy"
        show-empty
        empty-text="No records"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:cell(index)="data">{{
          data.index + (cartridge.pageNum - 1) * cartridge.pageSize + 1
        }}</template>
      </b-table>

      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="cartridge_totalRows">
              <b-pagination
                v-model="cartridge.pageNum"
                :per-page="10"
                :total-rows="cartridge_totalRows"
                aria-controls="cartridge_table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>
    <!-- end card -->

    <!-- 使用记录 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Use History</h4>
          </div>
        </b-col>
      </b-row>
      <b-table
        head-variant="light"
        striped
        :fields="usageRecordFields"
        id="SmokeBomb_table"
        ref="SmokeBomb_table"
        :items="SmokeBomb_provider"
        :per-page="SmokeBomb.pageSize"
        :current-page="SmokeBomb.pageNum"
        :busy="SmokeBom_isBusy"
        show-empty
        empty-text="No records"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(index)="data">{{
          data.index + (SmokeBomb.pageNum - 1) * SmokeBomb.pageSize + 1
        }}</template>
      </b-table>

      <!-- pagination -->
      <b-row>
        <b-col>
          <div class="dataTables_paginate paging_simple_numbers float-right">
            <ul class="pagination mb-0" v-if="SmokeBomb_totalRows">
              <b-pagination
                v-model="SmokeBomb.pageNum"
                :per-page="10"
                :total-rows="SmokeBomb_totalRows"
                aria-controls="SmokeBomb_table"
              >
              </b-pagination>
            </ul>
          </div>
        </b-col>
      </b-row>
      <!-- end pagination -->
    </b-card>

    <b-card>
      <b-button
        size="lg"
        type="reset"
        variant="outline-secondary"
        @click="returnPage"
        >Go Back</b-button
      >
    </b-card>
    <!-- end card -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * User Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "User Accounts Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "User Accounts Details",
          active: true,
        },
      ],
      // 绑定的设备信息
      deviceFields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "deviceNumber", label: "Device No.", class: "text-center" },
        {
          key: "deviceLocation",
          label: "Active Location",
          class: "text-center",
        },
        {
          key: "address",
          label: "Lastest Loaction",
          class: "text-center",
        },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 0 ? "Active" : "Activate";
          },
        },
        { key: "versionNo", label: "Firmware version", class: "text-center" },
        {
          key: "activationTime",
          label: "Activation time",
          class: "text-center",
        },
      ],
      // 绑定的烟弹信息
      cartridgeFields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "smokeRecordNumber",
          label: "Cartridge ID",
          class: "text-center",
        },
        {
          key: "smokeBombModelName",
          label: "Cartridge model",
          class: "text-center",
        },
        {
          key: "smokeOilModelName",
          label: "Content model",
          class: "text-center",
        },
        // { key: "flavor", label: "Content model", class: "text-center" },
        { key: "remaining", label: "Remaining", class: "text-center" },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1
              ? "Activated"
              : value == 2
              ? "Unactivated"
              : value == 3
              ? "Depleted"
              : value == 4
              ? "Abnormal"
              : value == 9
              ? "Empty"
              : "";
          },
        },
        {
          key: "activationTime",
          label: "Activation time",
          class: "text-center",
        },
        { key: "lastUseTime", label: "Last use time", class: "text-center" },
      ],
      // 使用记录
      usageRecordFields: [
        { key: "index", label: "#", class: "text-center" },
        {
          key: "smokeRecordNumber",
          label: "Cartridge ID",
          class: "text-center",
        },
        { key: "deviceNumber", label: "Device ID", class: "text-center" },
        { key: "flavor", label: "Flavor", class: "text-center" },
        {
          key: "createTime",
          label: "Use Time",
          class: "text-center",
          formatter: (value) => {
            return timeZoneConversion.foramtTime(value);
          },
        },
        {
          key: "deviceLocation",
          label: "Active Location",
          class: "text-center",
        },
        { key: "address", label: "Lastest Loaction", class: "text-center" },
        { key: "totalDuration", label: "Duration(s)", class: "text-center" },
      ],
      info: {
        binding: 0,
        createTime: "",
        dateOfBirth: "",
        email: "",
        frequency: 0,
        gender: 0,
        id: 0,
        isElectricSmoke: 0,
        isSmoke: 0,
        nickName: "",
        picture: "",
        source: "",
      },
      device: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      cartridge: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
      },
      device_isBusy: false,
      cartridge_isBusy: false,
      device_totalRows: 0,
      cartridge_totalRows: 0,
      SmokeBomb: {
        pageNum: 1,
        pageSize: 10,
        id: this.$route.query.id,
        // supplierId: localStorage.supplierId,
      },
      SmokeBom_isBusy: false,
      SmokeBomb_totalRows: 0,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    device_provider(ctx) {
      this.device_isBusy = true;
      return this.$api.userManage.getUserDevice(this.device, ctx).then((x) => {
        this.device_totalRows = x.total;
        this.device_isBusy = false;
        return x.data;
      });
    },
    cartridge_provider(ctx) {
      this.cartridge_isBusy = true;
      return this.$api.userManage
        .selectBindSmokeRecord(this.cartridge, ctx)
        .then((x) => {
          this.cartridge_totalRows = x.total;
          this.cartridge_isBusy = false;
          return x.data;
        });
    },
    SmokeBomb_provider(ctx) {
      this.SmokeBom_isBusy = true;
      return this.$api.userManage
        .findSmokeBombList(this.SmokeBomb, ctx)
        .then((x) => {
          this.SmokeBomb_totalRows = x.total;
          this.SmokeBom_isBusy = false;
          return x.data;
        });
    },
    init() {
      if (this.$route.query.id) {
        this.$api.userManage.getUser(this.$route.query).then((res) => {
          if (res.success) {
            this.info = res.data;
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    returnPage() {
      this.$router.go(-1);
    },
  },
};
</script>

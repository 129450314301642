/*
 * @Author: Code-HHX
 * @Date: 2021-12-23 11:03:35
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-24 10:43:53
 * @Description: Content Mgmt 内容管理接口模块
 */

import axios from '../config/http-json.js'
import baseUrl from '../config/env.js'

//评论管理列表
function commentList(params = {}) {
	return axios.fetchGet(baseUrl + '/comment/list', params)
}

//审核评论 0通过 1不通过
function reviewById(params = {}) {
	return axios.fetchPost(baseUrl + '/comment/reviewById', params)
}

//删除或恢复评论  0恢复 1删除
function updateCommentById(params = {}) {
	return axios.fetchPost(baseUrl + '/comment/updateCommentById', params)
}

//敏感词类别列表
function blackList(params = {}) {
	return axios.fetchGet(baseUrl + '/blackList/list', params)
}

//启用或禁用敏感词
function enableOrDisableById(params = {}) {
	return axios.fetchPost(baseUrl + '/blackList/enableOrDisableById', params)
}

//保存或修改敏感词
function saveOrUpdateBlackList(params = {}) {
	return axios.fetchPost(baseUrl + '/blackList/saveOrUpdateBlackList', params)
}

export default {
	commentList,
	reviewById,
	updateCommentById,
	blackList,
	enableOrDisableById,
	saveOrUpdateBlackList,
}

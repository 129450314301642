<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit.stop.prevent="onSubmit" @reset="onReset">
        <!-- 标题 -->
        <b-form-group
          label="Guide the title:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="form.title"
            placeholder="Please enter"
            oninput="if(value.length > 200)value = value.slice(0, 200)"
          ></b-form-input>
          <span>Title is maximum 200 characters</span>
        </b-form-group>

        <!-- 状态 -->
        <b-form-group
          label="Status:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="status"
        >
          <b-form-radio-group
            class="pt-2"
            v-model="form.status"
            :options="types.status"
            name="cartridge-status"
            style="padding-top: 5px"
          ></b-form-radio-group>
        </b-form-group>

        <!-- 背景图片 -->
        <b-form-group
          label="Picture:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="picture"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px)"
            tabindex="-1"
            @change="upload($event)"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1"
            ></div>
            <span class="group-span-filestyle" tabindex="0">
              <div v-if="pictureList.length" style="margin-bottom: 10px">
                <span v-for="(item, i) in pictureList" :key="i">
                  <img
                    v-if="/png|jpg/.test(item.type)"
                    :src="item.url"
                    style="
                      width: 100px;
                      height: 100px;
                      border: 2px dashed #ccc;
                      vertical-align: top;
                    "
                  />
                  <video
                    v-else
                    :src="item.url"
                    style="
                      width: 100px;
                      height: 100px;
                      border: 2px dashed #ccc;
                      vertical-align: top;
                    "
                  ></video>
                </span>
                <div style="margin-left: 10px" v-if="pictureName.length">
                  <span style="font-weight: 500">File name :</span>
                  {{ pictureName.join(",") }}
                </div>
              </div>
              <label
                for="filestyle-1"
                style="margin-bottom: 0"
                class="btn btn-secondary"
              >
                <span class="buttonText">upload picture/video</span>
              </label>
              <span>
                Suggested size: 1280 pixels * 1280 pixels, JPG, PNG format, up
                to 3 pieces;Video size is not more than 20M, only in MP4 format.
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- 排序 -->
        <b-form-group
          label="Sort:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="sort"
        >
          <b-form-input
            id="sort"
            v-model="form.sort"
            type="number"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- 文章内容  -->
        <b-form-group
          label="Content:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="content"
        >
          <ckeditor
            v-model="form.content"
            :editor="editor"
            @ready="onReady"
          ></ckeditor>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";

/**
 * Article Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
  },
  data() {
    return {
      page: {
        title: "User Guide Create",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "Content Mgmt",
            // href: "/"
          },
          {
            text: "User Guide Create",
            active: true,
          },
        ],
      },
      form: {
        title: "",
        status: 1,
        picture: "",
        sort: "",
        content: "",
        // supplierId: localStorage.supplierId,
      },
      types: {
        status: [
          { text: "enable", value: 1, disabled: false },
          { text: "disable", value: 0, disabled: false },
        ],
      },
      editor: ClassicEditor,
      picture: [],
      pictureList: [],
      pictureName: [],
    };
  },
  created() {
    if (/edit/i.test(window.location.href)) {
      this.form = {
        id: this.$route.params.id,
        title: this.$route.params.title,
        status: this.$route.params.status,
        picture: this.$route.params.picture,
        sort: this.$route.params.sort,
        content: this.$route.params.content,
      };
      if (this.form.picture) {
        this.picture = this.$route.params.picture.split(",");
        this.picture.forEach((element) => {
          let arr = element.split(".");
          let type = arr[arr.length - 1];
          this.pictureList.push({ url: element, type: type });
          console.log(this.pictureList);
        });
      }
    }
  },
  mounted() {},
  methods: {
    onReady: onReady,
    onSubmit(evt) {
      evt.preventDefault();
      this.form.picture = this.picture.join(",");
      console.log(this.form);
      for (const key in this.form) {
        if (!this.form[key]) {
          return this.$bvToast.toast("The " + key + " cannot be empty");
        }
      }
      if (/edit/i.test(window.location.href)) {
        this.$api.Message.update(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      } else {
        this.$api.Message.add(this.form).then((res) => {
          if (res.success) {
            this.$router.go(-1);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // this.form = {
      //   title: "",
      //   status: 1,
      //   picture: [],
      //   sort: "",
      //   content: "",
      //   supplierId: localStorage.supplierId
      // };
      // this.$nextTick(() => {
      //   this.show = true;
      // });
      this.$router.go(-1);
    },
    upload(e) {
      let file = e.target.files[0];
      console.log("uploading", file);
      if (/image|video/.test(file.type)) {
        if (file.size > 20 * 1024 * 1024) {
          this.$bvToast.toast("Video size is not more than 20M");
          return;
        }
        upload_img(file).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          if (res.success) {
            this.picture.push(res.data);
            this.pictureList.push({ url: res.data, type: file.type });
            this.pictureName.push(file.name);
            console.log("zzzzzzzz", this.picture);
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("only support jpg/png file");
      }
    },
  },
};
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c("b-form", { on: { submit: _vm.onSubmit, reset: _vm.onReset } }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Account:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "account",
                          "label-class": "requiredRow"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "account",
                            placeholder: "Please enter",
                            required: "",
                            oninvalid: "setCustomValidity('Please enter');",
                            oninput:
                              "setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)",
                            disabled: _vm.editType
                          },
                          model: {
                            value: _vm.form.username,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "username", $$v)
                            },
                            expression: "form.username"
                          }
                        }),
                        _c("span", [
                          _vm._v("6-20 Letters/number/character components")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Nickname:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "nickname",
                          "label-class": "requiredRow"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "nickname",
                            placeholder: "Please enter",
                            required: "",
                            oninvalid: "setCustomValidity('Please enter');",
                            oninput:
                              "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
                          },
                          model: {
                            value: _vm.form.nickName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "nickName", $$v)
                            },
                            expression: "form.nickName"
                          }
                        }),
                        _c("span", [
                          _vm._v("50 Letters/number/character components")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Role:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "role",
                          "label-class": "requiredRow"
                        }
                      },
                      [
                        _c("b-form-select", {
                          attrs: {
                            id: "role",
                            options: _vm.types.roleList,
                            "value-field": "id",
                            "text-field": "roleName",
                            required: "",
                            oninvalid: "setCustomValidity('Please select');",
                            oninput: "setCustomValidity('');"
                          },
                          model: {
                            value: _vm.form.roleId,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "roleId", $$v)
                            },
                            expression: "form.roleId"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Password:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "password",
                          "label-class": "requiredRow"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "password",
                            trim: "",
                            required: "",
                            placeholder: "Please enter",
                            type: "password",
                            disabled: _vm.editType,
                            oninvalid: "setCustomValidity('Please enter');",
                            oninput:
                              "setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
                          },
                          model: {
                            value: _vm.form.password,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "password", $$v)
                            },
                            expression: "form.password"
                          }
                        }),
                        _vm.editType
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-secondary" },
                                on: {
                                  click: function($event) {
                                    _vm.modalShow = !_vm.modalShow
                                  }
                                }
                              },
                              [_vm._v("Change")]
                            )
                          : _vm._e(),
                        _c("span", [
                          _vm._v("6-20 Letters/number/character components")
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            !_vm.editType
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c(
                      "b-col",
                      { attrs: { lg: "10" } },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Confirm password:",
                              "label-cols-lg": "3",
                              "label-align-lg": "right",
                              "label-for": "password",
                              "label-class": "requiredRow"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "password",
                                trim: "",
                                required: "",
                                placeholder: "Please enter",
                                type: "password",
                                oninvalid: "setCustomValidity('Please enter');",
                                oninput:
                                  "setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
                              },
                              model: {
                                value: _vm.confirmPassword,
                                callback: function($$v) {
                                  _vm.confirmPassword = $$v
                                },
                                expression: "confirmPassword"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Name:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "name"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "name",
                            trim: "",
                            placeholder: "Please enter",
                            oninput:
                              "if(value.length > 20)value = value.slice(0, 20)"
                          },
                          model: {
                            value: _vm.form.fullName,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "fullName", $$v)
                            },
                            expression: "form.fullName"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Phone:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "phone"
                        }
                      },
                      [
                        _c("b-form-input", {
                          attrs: {
                            id: "phone",
                            trim: "",
                            placeholder: "Please enter",
                            oninput:
                              "if(value.length > 50)value = value.slice(0, 50)"
                          },
                          model: {
                            value: _vm.form.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "phone", $$v)
                            },
                            expression: "form.phone"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Photo:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "Photo"
                        }
                      },
                      [
                        _c("input", {
                          staticClass: "filestyle",
                          staticStyle: {
                            position: "absolute",
                            clip: "rect(0px, 0px, 0px, 0px)"
                          },
                          attrs: {
                            type: "file",
                            "data-input": "false",
                            id: "filestyle-1",
                            tabindex: "-1"
                          },
                          on: {
                            change: function($event) {
                              return _vm.upload($event)
                            }
                          }
                        }),
                        _c(
                          "div",
                          { staticClass: "bootstrap-filestyle input-group" },
                          [
                            _c("div", {
                              staticStyle: {
                                position: "absolute",
                                width: "100%",
                                height: "35px",
                                "z-index": "-1"
                              },
                              attrs: { name: "filedrag" }
                            }),
                            _c(
                              "span",
                              {
                                staticClass: "group-span-filestyle",
                                attrs: { tabindex: "0" }
                              },
                              [
                                _vm.form.picture
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-bottom": "10px" }
                                      },
                                      [
                                        _c("img", {
                                          staticStyle: {
                                            width: "100px",
                                            height: "100px",
                                            border: "2px dashed #ccc"
                                          },
                                          attrs: { src: _vm.form.picture }
                                        }),
                                        !_vm.editId
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  "vertical-align": "bottom"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-weight": "500"
                                                    }
                                                  },
                                                  [_vm._v("Picture name : ")]
                                                ),
                                                _vm._v(_vm._s(_vm.photoName))
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "label",
                                  {
                                    staticClass: "btn btn-secondary",
                                    staticStyle: { "margin-bottom": "0" },
                                    attrs: { for: "filestyle-1" }
                                  },
                                  [
                                    _c("span", { staticClass: "buttonText" }, [
                                      _vm._v(" upload picture ")
                                    ])
                                  ]
                                ),
                                _c("span", [
                                  _vm._v(
                                    " Recommended size of picture: 120 pixels * 120 pixels, supporting JPG and PNG formats. "
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      {
                        attrs: {
                          label: "Status:",
                          "label-cols-lg": "3",
                          "label-align-lg": "right",
                          "label-for": "status"
                        }
                      },
                      [
                        _c("b-form-radio-group", {
                          staticStyle: { "padding-top": "5px" },
                          attrs: {
                            id: "radio-group-1",
                            options: _vm.types.status,
                            name: "status"
                          },
                          model: {
                            value: _vm.form.status,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "b-col",
                  { attrs: { lg: "10" } },
                  [
                    _c(
                      "b-form-group",
                      { attrs: { "label-cols-lg": "3" } },
                      [
                        _c(
                          "b-button",
                          { attrs: { type: "submit", variant: "primary" } },
                          [_vm._v("Confirm")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "m-l-5 ml-1",
                            attrs: { type: "reset", variant: "danger" }
                          },
                          [_vm._v("Cancel")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "b-modal",
            {
              ref: "modal",
              attrs: { title: "Edit Password", "hide-footer": "" },
              on: { hidden: _vm.resetModal },
              model: {
                value: _vm.modalShow,
                callback: function($$v) {
                  _vm.modalShow = $$v
                },
                expression: "modalShow"
              }
            },
            [
              _c(
                "form",
                { ref: "form", on: { submit: _vm.onSure, reset: _vm.onClose } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Password:",
                        "label-cols-lg": "4",
                        "label-align-lg": "right",
                        "label-for": "password",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "password",
                          trim: "",
                          required: "",
                          placeholder: "Enter password",
                          type: "password",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
                        },
                        model: {
                          value: _vm.modalData.password,
                          callback: function($$v) {
                            _vm.$set(_vm.modalData, "password", $$v)
                          },
                          expression: "modalData.password"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Confirm password:",
                        "label-cols-lg": "4",
                        "label-align-lg": "right",
                        "label-for": "password",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "password",
                          trim: "",
                          required: "",
                          placeholder: "Enter password",
                          type: "password",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 20)value = value.slice(0, 20)"
                        },
                        model: {
                          value: _vm.modalData.confirmPassword,
                          callback: function($$v) {
                            _vm.$set(_vm.modalData, "confirmPassword", $$v)
                          },
                          expression: "modalData.confirmPassword"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "4" } },
                    [
                      _c(
                        "b-button",
                        { attrs: { type: "submit", variant: "primary" } },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "m-l-5 ml-1",
                          attrs: { type: "reset", variant: "danger" }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "align-items-center" },
            [
              _c("b-col", { attrs: { lg: "12" } }, [
                _c("div", { staticClass: "blockquote" }, [
                  _c("h4", [_vm._v("Order Info")])
                ])
              ])
            ],
            1
          ),
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { cols: "3" } },
                [
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Order Name:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.orderName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Order No.:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.orderNumber))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Device Model:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.deviceModelName))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Production Quantity:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.batchQuantity))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Production Time:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.productionTime))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Clear Data:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.form.isClearData == 0
                            ? "Yes"
                            : _vm.form.isClearData == 1
                            ? "No"
                            : ""
                        )
                      )
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Status:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.formatterStatus(_vm.form.status)))
                    ])
                  ]),
                  _c("b-col", [
                    _c("label", { staticClass: "col-form-label" }, [
                      _vm._v("Remarks:")
                    ]),
                    _c("span", { staticClass: "p-3" }, [
                      _vm._v(_vm._s(_vm.form.remarks))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _vm.form.status == 1
            ? _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { lg: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [_vm._v("Production Result")])
                    ])
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.form.status == 1
            ? _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { attrs: { cols: "3" } },
                    [
                      _c("b-col", [
                        _c("label", { staticClass: "col-form-label" }, [
                          _vm._v("Actual Quantity:")
                        ]),
                        _c("span", { staticClass: "p-3" }, [
                          _vm._v(_vm._s(_vm.form.actualQuantity))
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-button",
        {
          attrs: { variant: "outline-secondary" },
          on: { click: _vm.returnPage }
        },
        [_vm._v("Go Back")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Cartridge Id"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="cartridge-number"
              >
                <b-form-input
                  v-model="search_condition.smokeRecordNumber"
                  id="cartridge-number"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Flavor Type"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="cartridge-type"
              >
                <!-- <b-form-select
                  id="cartridge-type"
                  v-model="search_condition.cartridge_type"
                  :options="types.cartridge_type"
                  value-field="id"
                  text-field="name"
                  disabled-field="disabled"
                ></b-form-select>-->
                <b-form-input
                  v-model="search_condition.type"
                  id="cartridge-type"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                label="Brand Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="brand-name"
              >
                <b-form-input
                  id="brand-name"
                  trim
                  v-model="search_condition.brandName"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <!-- <b-form-group
                label="Activation Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="active-time"
              >
                <date-picker
                  id="active-time"
                  v-model="selectTime"
                  :first-day-of-week="1"
                  format="MM/DD/YYYY"
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group> -->
              <b-form-group
                label="Activation Time"
                label-cols-md="4"
                label-align-md="right"
                label-for="active-time"
              >
                <date-picker
                  v-model="selectTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                label="Status"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="cartridge-status"
              >
                <b-form-select
                  id="cartridge-status"
                  value-field="value"
                  text-field="text"
                  disabled-field="disabled"
                  :options="types.statuss"
                  v-model="search_condition.status"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Batch Number"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="batch-number"
              >
                <b-form-input
                  v-model="search_condition.batchNumber"
                  id="batch-number"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group
                label="Batch Name"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="batch-name"
              >
                <b-form-input
                  v-model="search_condition.batchNumberName"
                  id="batch-name"
                  trim
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-button variant="primary" @click="search()">Search</b-button>
            </b-col>
          </b-row>
          <!-- end search -->
        </b-card>

        <b-modal
          ref="modal"
          v-model="modalShow"
          title="disable cartridges"
          @show="resetModal"
          @ok="handleOk"
          @hidden="resetModal"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              label="Reason"
              label-cols-sm="4"
              label-align-sm="right"
              label-for="reason"
            >
              <b-form-select
                id="reason"
                v-model="form.reason"
                :options="types.reasons"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              label="Cartridge ID"
              label-cols-sm="4"
              label-align-sm="right"
              label-for="ids"
            >
              <textarea
                id="ids"
                v-model="form.ids"
                placeholder="ID line by line, up to 10000"
                class="form-control"
                minlength="1"
              ></textarea>
            </b-form-group>

            <b-form-group
              label="Notes"
              label-cols-sm="4"
              label-align-sm="right"
              label-for="remark"
            >
              <b-form-input
                id="remark"
                v-model="form.remark"
                placeholder="Limited to 20 words"
                maxlength="20"
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
        <b-card>
          <b-button
            v-has="'cartridgeList::Batch Disable'"
            @click="modalShow = !modalShow"
            variant="info"
            >Batch deplete</b-button
          >

          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              id="table"
              show-empty
              empty-text="no records"
              head-variant="light"
              ref="table"
              :busy="isBusy"
              :striped="true"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    v-has="'cartridgeList::Details'"
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                    >Details</b-button
                  >
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <br />
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * cartridge component
 */
export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
  },
  data() {
    return {
      tableData: [],
      title: "Cartridge List",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Cartridge List",
          active: true,
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "smokeRecordNumber",
      sortDesc: false,
      fields: [
        { key: "index", label: "#" },
        { key: "smokeRecordNumber", label: "Cartridge Id" },
        { key: "batchName", label: "Batch Name" },
        { key: "type", label: "Flavor Type" },
        // { key: "deviceNumber", label: "Device NO." },
        // {
        //   key: "deviceStatus",
        //   label: "Device Status",
        //   formatter: value => {
        //     return value == 0 ? "Activate" : "Not Activate";
        //   }
        // },
        {
          key: "status",
          label: "Cartridge Status",
          formatter: (value) => {
            let a = "";
            this.types.statuss.filter((item) => {
              if (value === item.value) {
                a = item.text;
              }
            });
            return a;
          },
        },
        {
          key: "activationTime",
          label: "Activated Time",
          formatter: (value) => {
            if (value) {
              return timeZoneConversion.getLocalTime(value);
            } else {
              return "";
            }
          },
        },
        { key: "brandName", label: "Brand Owner" },
        { key: "dose", label: "Capacity(ml)" },
        { key: "flavor", label: "Flavor" },
        { key: "placeOfOrigin", label: "Place Of Origin" },
        // { key: "frequency", label: "Frequency" },
        { key: "actions", label: "Opreration" },
      ],
      search_condition: {
        pageNum: 1,
        pageSize: 10,

        // supplierId: localStorage.supplierId,
        // cartridge_type: "",
        brandName: "",
        smokeRecordNumber: "",
        status: "",
        type: "",
        startTime: "",
        endTime: "",
        batchNumber: "",
        batchNumberName: "",
      },
      selectTime: ["", ""],
      isBusy: false,
      modalShow: false,
      form: {
        ids: [],
        reason: "fda",
        remark: "",
      },
      types: {
        // 使用状态(0是未连接 1是连接中 2是未激活)
        statuss: [
          { text: "Please select", value: "", disabled: false },
          { text: "Activated", value: 1, disabled: false },
          { text: "Unactivated", value: 2, disabled: false },
          { text: "Disabled", value: 3, disabled: false },
          { text: "Abnormal", value: 4, disabled: false },
          { text: "Empty", value: 9, disabled: false },
        ],
        suppliers: ["Platform", "Supplier"],
        // cartridge_type: [
        //   { id: 0, name: "" },
        //   { id: 1, name: "CBD" },
        //   { id: 2, name: "NIC" },
        //   { id: 3, name: "THC" }
        // ],
        reasons: [],
      },
      has_data: false,
    };
  },
  created() {},
  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$api.Dropdown.selectReason().then((res) => {
        if (res.success) {
          this.types.reasons = res.data;
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    provider(ctx) {
      this.isBusy = true;
      return this.$api.Cartridge.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    gotoAdd() {
      this.$router.push("cartridgeListAdd");
    },
    del(a, b, c) {
      this.$api.Cartridge.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    show(item, index, button) {
      this.$router.push("cartridgeListInfo?id=" + item.id);
    },
    edit(item, index, button) {
      this.$router.push("cartridgeListEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    resetModal() {
      this.form = {
        ids: [],
        reason: "fda",
        remark: "",
      };
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (this.$refs.form.checkValidity()) {
        this.$api.Cartridge.disable(this.form).then((res) => {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
          this.modalShow = false;
          if (res.success) {
            this.$refs.table.refresh();
          }
        });
      } else {
        this.$bvToast.toast("check your input");
        return;
      }
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
  },
};
</script>

<style></style>

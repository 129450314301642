<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="4">
              <b-form-group
                label="Nickname"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="nick-name"
              >
                <b-form-input
                  id="nick-name"
                  trim
                  v-model="search_condition.nickName"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Smokebomb Type"
                label-cols-sm="6"
                label-align-sm="right"
              >
                <b-form-input
                  trim
                  v-model="search_condition.smoke_bomb_ID"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Status"
                label-cols-sm="6"
                label-align-sm="right"
              >
                <b-form-select
                  v-model="search_condition.status"
                  id="cartridge-type"
                  :options="types.statuss"
                  value-field="value"
                  text-field="text"
                >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group
                label="Select Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="select-time"
              >
                <date-picker
                  v-model="selectTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="4">
              <b-form-group label="" label-cols-sm="4" label-align-sm="right">
                <b-button variant="primary" @click="search()">Search</b-button>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col lg="12"> </b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-button-group>
            <b-button
              v-has="'UserAuthorization::Export'"
              @click="export_checkout"
              variant="info"
              >Export</b-button
            >
          </b-button-group>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="device-table"
              head-variant="light"
              :striped="true"
              ref="table"
              :busy="isBusy"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">
                {{
                  data.index +
                  (search_condition.pageNum - 1) * search_condition.pageSize +
                  1
                }}
              </template>

              <template v-slot:cell(nickName)="data">
                <img
                  v-if="data.item.picture"
                  :src="`${data.item.picture}`"
                  alt
                  class="avatar-xs rounded-circle mr-2"
                />
                {{ data.item.nickName }}
              </template>

              <template v-slot:cell(actions)="row">
                <b-button-group>
                  <b-button
                    size="sm"
                    variant="primary"
                    @click="show(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    show
                  </b-button>

                  <b-button
                    size="sm"
                    variant="danger"
                    @click="del(row.item, row.index, $event.target)"
                    class="mr-1"
                  >
                    del
                  </b-button>
                </b-button-group>
              </template>
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  >
                  </b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->

    <!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
		</div> -->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * User component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "User",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Manage",
          // href: "/"
        },
        {
          text: "Authorization",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      //"用户昵称", "烟弹ID", "烟弹口味", "烟弹类型", "最后使用时间", "使用位置", "总使用时长(s)"
      //"用户昵称", "烟弹类型", "解锁问题组", "状态", "授权天数", "提交时间"
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "nickName", label: "Nickname", class: "text-center" },
        { key: "smokeBombType", label: "Cartridge Type" },
        { key: "smoke_bomb_ID", label: "smoke_bomb_ID", class: "text-center" },
        { key: "flavor", label: "Question Group" },
        {
          key: "status",
          label: "Status",
          class: "text-center",
          formatter: (value) => {
            return value == 1 ? "Disabled" : "Ok";
          },
        },
        {
          key: "totalDuration",
          label: "Authorization Days",
          class: "text-center",
        },
        // { key: "actions", label: "Opreration" },
      ],
      selectTime: ["", ""],
      source: ["", "Platform", "Supplier"], //平台
      search_condition: {
        pageNum: 1,
        pageSize: 10,

        binding: "",
        createTime: "",
        dateOfBirth: "",
        email: "",
        frequency: "",
        gender: "",
        id: "",
        isElectricSmoke: "",
        isSmoke: "",
        nickName: "",
        picture: "",
        source: "",
        endTime: "",
        status: "",
      },
      isBusy: false,
      has_data: false,
      types: {
        statuss: [
          { text: "All", value: "", disabled: false },
          { text: "Authorized", value: 1, disabled: false },
          { text: "unAuthorized", value: 0, disabled: false },
        ],
      },
    };
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.createTime = val[0];
      if (val[1]) {
        this.search_condition.endTime = val[1].getTime() + 24 * 3600 * 1000 - 1;
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.UserAuthorization.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    gotoAdd() {
      this.$router.push("cartridgeListAdd");
    },
    del(a, b, c) {
      this.$api.UserAuthorization.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },

    export_excel() {
      this.$api.UserAuthorization.export_excel(this.search_condition);
    },
    export_checkout() {
      //校验是否存在搜索条件
      if (
        !this.search_condition.status &&
        !this.search_condition.nickName &&
        !this.search_condition.startTime &&
        !this.search_condition.endTime
      ) {
        this.$bvToast.toast("Please select the search criteria first");
        return;
      }
      //时间范围三个月内
      if (this.search_condition.createTime && this.search_condition.endTime) {
        let three = 90 * 24 * 3600 * 1000;
        let date = new Date(this.search_condition.endTime).getTime();
        let threeMonths = date - three;
        if (
          threeMonths > new Date(this.search_condition.createTime).getTime()
        ) {
          this.$bvToast.toast("Please select the time frame within 3 months");
          return;
        }
      }
      this.export_excel();
    },
    show(item, index, button) {
      this.$router.push("UserAuthorizationInfo?id=" + item.id);
    },
    edit(item, index, button) {
      this.$router.push("UserAuthorizationEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

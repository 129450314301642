<template>
  <Layout>
    <PageHeader :title="page.title" :items="page.items" />

    <b-card>
      <b-form @submit="onSubmit" @reset="onReset">
        <!-- 应用名称 -->
        <b-form-group
          label="Application Name:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="application-name"
          label-class="requiredRow"
        >
          <b-form-input
            id="application-name"
            v-model="form.applicationName"
            trim
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
          ></b-form-input>
        </b-form-group>

        <!-- 上架版本 -->
        <b-form-group
          label="Putaway Version:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="putaway-version"
          label-class="requiredRow"
        >
          <b-form-input
            id="putaway-version"
            v-model="form.version"
            trim
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 20)"
          ></b-form-input>
        </b-form-group>

        <!-- 推送路径 -->
        <b-form-group
          label="Push The Path:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="path"
          label-class="requiredRow"
        >
          <b-form-select
            id="path"
            v-model="form.path"
            :options="types.pathList"
            value-field="id"
            text-field="name"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 所属客户 -->
        <b-form-group
          label="Affiliated Customer:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer"
        >
          <!-- <b-form-select
            id="customer"
            v-model="form.supplierName"
            :options="types.pathList"
            value-field="id"
            text-field="name"
          >
          </b-form-select> -->
          <b-form-input
            id="customer"
            v-model="form.supplierName"
            trim
          ></b-form-input>
        </b-form-group>

        <!-- 安装包 -->
        <b-form-group
          label="Installation Package:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="package"
        >
          <input
            type="file"
            class="filestyle"
            data-input="false"
            id="filestyle-1"
            style="position: absolute; clip: rect(0px, 0px, 0px, 0px);"
            tabindex="-1"
            @change="upload($event)"
          />

          <div class="bootstrap-filestyle input-group">
            <div
              name="filedrag"
              style="position: absolute; width: 100%; height: 35px; z-index: -1;"
            ></div>
            <span class="group-span-filestyle " tabindex="0">
              <label
                for="filestyle-1"
                style="margin-bottom: 0;"
                class="btn btn-secondary "
              >
                <span class="buttonText">
                  upload
                </span>
              </label>
              <span v-if="form.route">
                {{ routeName }}
              </span>
            </span>
          </div>
        </b-form-group>

        <!-- 更新内容  -->
        <b-form-group
          label="Content"
          label-cols-lg="2"
          label-align-sm="right"
          label-for="content"
        >
          <b-form-textarea
            id="content"
            v-model="form.content"
            placeholder="Enter content"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <!-- 状态 -->
        <b-form-group
          label="Status:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="status"
        >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.status"
            name="status"
            value="1"
            >putaway</b-form-radio
          >
          <b-form-radio
            class="custom-control-inline pt-2"
            v-model="form.status"
            name="status"
            value="0"
            >sold out</b-form-radio
          >
        </b-form-group>

        <!-- 备注 -->
        <b-form-group
          label="Notes"
          label-cols-lg="2"
          label-align-sm="right"
          label-for="remark"
        >
          <b-form-textarea
            id="remark"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" variant="danger" class="m-l-5 ml-1"
            >Cancel</b-button
          >
        </b-form-group>
      </b-form>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import { onReady, upload_img } from "@/api/upload";
import goback from "@/components/goback";

/**
 * FAQ Add component
 */
export default {
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
    Multiselect,
    goback,
  },
  data() {
    return {
      page: {
        title: "Application Manage",
        items: [
          {
            text: "UNI-CORE",
            // href: "/"
          },
          {
            text: "App Manage",
            // href: "/"
          },
          {
            text: this.editId ? "Application Modify" : "Application Create",
            active: true,
          },
        ],
      },
      form: {
        id: "",
        applicationName: "",
        version: "",
        path: "",
        supplierName: "",
        route: "",
        content: "",
        status: "",
      },
      types: {
        pathList: [{ id: "", name: "Please select" }],
      },
      routeName: "",
      editId: window.location.search,
      editor: ClassicEditor,
    };
  },
  beforeCreate() {
    this.editId = window.location.search;
  },
  created() {
    if (this.editId) {
      let id = this.editId.split("=")[1];
      this.formData(id);
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    onReady: onReady,
    init() {
      //   this.$api.Category.list().then((res) => {
      //     if (res.success) {
      //       this.types.questionCategorys = this.types.questionCategorys.concat(
      //         res.data
      //       );
      //     }
      //   });
      this.types.pathList = [
        { id: 1, name: "Supplement" },
        { id: 2, name: "Dime" },
        { id: 3, name: "Dime Test" },
        { id: 4, name: "Vaporizer" },
        { id: 5, name: "Vaporizer Test" },
      ];
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (this.form.id) {
        this.$api.appManage.update(this.form).then((res) => {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          if (res.success) {
            this.$router.go(-1);
          }
        });
      } else {
        this.$api.appManage.update(this.form).then((res) => {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          if (res.success) {
            this.$router.go(-1);
          }
        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        applicationName: "",
        version: "",
        path: "",
        supplierName: "",
        route: "",
        content: "",
        status: "",
      };
      this.$nextTick(() => {
        this.$router.go(-1);
        // this.show = true;
      });
    },
    upload(e) {
      let files = document.getElementById("filestyle-1").files;
      let file = files[0];
      if (/apk/.test(file.name)) {
        console.log("uploading", file);
        upload_img(file).then((res) => {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
          if (res.success) {
            this.form.route = res.data;
            this.routeName = file.name;
          }
        });
      } else {
        e.target.files = [];
        this.$bvToast.toast("Please upload the installation package formate");
      }
    },
    formData(id) {
      this.$api.appManage.findById({ id: id }).then((res) => {
        if (res.success) {
          this.form = res.data;
          this.routeName = res.data.route.split("/").pop();
        } else {
          if(res.message){
              this.$bvToast.toast(res.message);
            }
        }
      });
    },
  },
};
</script>

<!--
 * @Author: Code-HHX
 * @Date: 2021-12-16 17:10:20
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-24 11:09:31
 * @Description: 烟弹评论管理
-->

<template>
	<Layout>
		<PageHeader :title="page.title" :items="page.items" />

		<!-- main -->
		<b-row>
			<b-col lg="12">
				<!-- 查询条件 -->
				<b-card>
					<b-row>
						<b-col lg="3">
							<!-- 评论内容 -->
							<b-form-group
								label="Comments"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="comments"
							>
								<b-form-input
									id="comments"
									v-model="search_condition.content"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<!-- 用户 -->
						<b-col lg="3">
							<b-form-group
								label="Nick Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="nickname"
							>
								<b-form-input
									id="nickname"
									v-model="search_condition.commentNickName"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<!-- 烟弹口味 -->
						<b-col lg="3">
							<b-form-group
								label="Flavor Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="flavor-name"
							>
								<b-form-input
									id="flavor-name"
									v-model="search_condition.flavorName"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<!-- 所属品牌 -->
						<b-col lg="3">
							<b-form-group
								label="Brand Name"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="brand-name"
							>
								<b-form-input
									id="brand-name"
									v-model="search_condition.brandName"
									trim
								></b-form-input>
							</b-form-group>
						</b-col>

						<!-- 评论时间 -->
						<b-col lg="3">
							<b-form-group
								label="Comment Time"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="comment-time"
							>
								<date-picker
									v-model="createTime"
									format="MM/DD/YYYY"
									range
									append-to-body
									lang="en"
									placeholder="Select date"
								></date-picker>
							</b-form-group>
						</b-col>

						<!-- 状态 -->
						<b-col lg="3">
							<b-form-group
								label="Status"
								label-cols-sm="4"
								label-align-sm="right"
								label-for="status"
							>
								<b-form-select
									v-model="search_condition.isShield"
									:options="types.status"
									value-field="value"
									text-field="text"
								></b-form-select>
							</b-form-group>
						</b-col>

						<b-col lg="3">
							<b-button variant="primary" @click="search()"
								>Search</b-button
							>
						</b-col>
					</b-row>
				</b-card>

				<b-card>
					<!-- table -->
					<!-- <b-button
						v-has="'community::Create'"
						@click="gotoAdd"
						variant="primary"
						>Create</b-button
					> -->

					<div class="table-responsive mb-0">
						<b-table
							show-empty
							empty-text="no records"
							id="table"
							head-variant="light"
							ref="table"
							:busy="isBusy"
							:striped="true"
							:items="provider"
							:fields="fields"
							:per-page="search_condition.pageSize"
							:current-page="search_condition.pageNum"
							:sort-by.sync="sortBy"
							:sort-desc.sync="sortDesc"
							:filter="filter"
							:filter-includedfields="filterOn"
							@filtered="onFiltered"
						>
							<template v-slot:table-busy>
								<div class="text-center text-danger my-2">
									<b-spinner class="align-middle"></b-spinner>
									<strong>Loading...</strong>
								</div>
							</template>

							<template v-slot:cell(index)="data">
								{{
									data.index +
									(search_condition.pageNum - 1) *
										search_condition.pageSize +
									1
								}}
							</template>

							<template v-slot:cell(actions)="row">
								<b-button-group>
									<b-button
										v-if="row.item.isShield == 0"
										v-has="'comment::Delete'"
										size="sm"
										variant="danger"
										@click="
											rowDelete(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Delete
									</b-button>
									<b-button
										v-if="row.item.isShield == 1"
										v-has="'comment::Recovery'"
										size="sm"
										variant="primary"
										@click="
											recovery(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Recovery
									</b-button>
									<b-button
										v-if="row.item.isShield == 2"
										v-has="'comment::Accept'"
										size="sm"
										variant="success"
										@click="
											accept(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Accept
									</b-button>
									<b-button
										v-if="row.item.isShield == 2"
										v-has="'comment::Refuse'"
										size="sm"
										variant="secondary"
										@click="
											refuse(
												row.item,
												row.index,
												$event.target
											)
										"
										class="mr-1"
									>
										Refuse
									</b-button>
								</b-button-group>
							</template>
						</b-table>
					</div>
					<!-- end table -->
					<!-- pagination -->
					<b-row v-if="has_data">
						<b-col>
							<div
								class="dataTables_paginate paging_simple_numbers float-right"
							>
								<ul class="pagination mb-0" v-if="rows != 0">
									<b-pagination
										v-model="search_condition.pageNum"
										:per-page="10"
										:total-rows="rows"
										aria-controls="table"
									>
									</b-pagination>
								</ul>
							</div>
						</b-col>
					</b-row>
					<!-- end pagination -->
				</b-card>
			</b-col>
		</b-row>
		<b-modal
			id="modal"
			size="lg"
			v-model="showRejectModal"
			centered
			title="Reasons For Refusal"
			ok-title="Confirm"
			@ok="onConfirm"
			@cancel="onCancel"
			@close="onClose"
			no-close-on-esc
			no-close-on-backdrop
		>
			<b-card>
				<div class="content">
					<b-input
						id="rejectContent"
						v-model="rejectContent"
						trim
						placeholder="Please enter the rejection reason"
					></b-input>
				</div>
			</b-card>
			<template v-slot:modal-footer>
				<div class="w-200">
					<b-button
						variant="primary"
						style="margin-right: 10px"
						@click="onConfirm"
					>
						Confirm
					</b-button>
					<b-button variant="secondary" @click="onCancel"
						>Cancel</b-button
					>
				</div>
			</template>
		</b-modal>
	</Layout>
</template>
<script>
import DatePicker from 'vue2-datepicker'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import timeZoneConversion from '@/components/widgets/timeZoneConversion'

/**
 * FAQ component
 */
export default {
	components: {
		Layout,
		PageHeader,
		DatePicker,
	},
	data() {
		return {
			page: {
				title: 'Comment',
				items: [
					{
						text: 'UNI-CORE',
						// href: "/"
					},
					{
						text: 'Content Mgmt',
						// href: "/"
					},
					{
						text: 'Comment',
						active: true,
					},
				],
			},
			totalRows: 0,
			currentPage: 1,
			perPage: 10,
			filter: null,
			filterOn: [],
			sortBy: 'id',
			sortDesc: false,
			fields: [
				{ key: 'index', label: '#', class: 'text-center' },
				{ key: 'content', label: 'Comments', class: 'text-center' }, //评论
				{
					key: 'commentNickName',
					label: 'Nick Name',
					class: 'text-center',
				}, //用户
				{
					key: 'flavorName',
					label: 'Flavor Name',
					class: 'text-center',
				}, //烟弹口味
				{ key: 'brandName', label: 'Brand Name', class: 'text-center' }, //所属品牌商
				{
					key: 'createTime',
					label: 'Create Time',
					class: 'text-center',
					formatter: (value) => {
						if (value) {
							return timeZoneConversion.getLocalTime(value)
						} else {
							return ''
						}
					},
				}, //评论时间
				{ key: 'actions', label: 'Opreration', class: 'text-center' }, //状态
			],
			createTime: ['', ''],
			search_condition: {
				pageNum: 1,
				pageSize: 10,
				brandName: '', //所属品牌
				flavorName: '', //烟弹口味
				commentNickName: '', //评论用户
				content: '', //评论内容
				isShield: '', //状态(0是不屏蔽 1是屏蔽 2是待审核)
				startTime: '',
				endTime: '',
			},
			isBusy: false,
			has_data: false,
			// selectTime: ["",""],
			types: {
				status: [
					{ text: 'Please select', value: '', disabled: false },
					{ text: 'Shielded', value: 1, disabled: false }, //屏蔽
					{ text: 'Unshielded', value: 0, disabled: false }, //不屏蔽
					{ text: 'Pending review', value: 2, disabled: false }, //待审核
				],
			},

			showRejectModal: false,
			selectRow: null, //当前选中的行
			rejectContent: '', //拒绝理由
		}
	},
	watch: {
		createTime: function (val) {
			this.search_condition.startTime = val[0]
				? timeZoneConversion.queryTime(val[0])
				: val[0]
			if (val[1]) {
				this.search_condition.endTime = timeZoneConversion.queryTime(
					val[1].getTime() + 24 * 3600 * 1000 - 1
				)
			} else {
				this.search_condition.endTime = val[1]
			}
		},
	},
	computed: {
		rows() {
			// return this.totalRows + this.search_condition.pageSize;
			return this.totalRows
		},
	},
	mounted() {
		this.init()
	},
	created() {},
	methods: {
		init() {},
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		provider(ctx) {
			this.isBusy = true
			return this.$api.Content.commentList(this.search_condition, ctx)
				.then((x) => {
					this.isBusy = false
					if (x.total) {
						this.has_data = true
						this.totalRows = x.total
						return x.data
					} else {
						this.has_data = false
						return []
					}
				})
				.catch((e) => {
					this.isBusy = false
					this.has_data = false
					return []
				})
		},
		search() {
			this.search_condition.pageNum = 1
			this.$refs.table.refresh()
		},
		//删除
		rowDelete(row, index, event) {
			this.$api.Content.updateCommentById({
				id: row.id,
				status: 1,
			})
				.then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.$refs.table.refresh()
					}
				})
				.catch((error) => {
					if (res.message) {
						this.$toast(res)
					}
				})
		},
		//恢复
		recovery(row, index, event) {
			this.$api.Content.updateCommentById({
				id: row.id,
				status: 0,
			})
				.then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.$refs.table.refresh()
					}
				})
				.catch((error) => {
					if (res.message) {
						this.$toast(res)
					}
				})
		},
		//审核同意
		accept(row, index, event) {
			this.$querybox('Are you sure to pass the audit?')
				.then((res) => {
					if (res) {
						this.$api.Content.reviewById({
							id: row.id,
							status: 0,
						})
							.then((res) => {
								if (res.message) {
									this.$toast(res)
								}
								if (res.success) {
									this.$refs.table.refresh()
								}
							})
							.catch((error) => {
								if (res.message) {
									this.$toast(res)
								}
							})
					}
				})
				.catch((error) => {
					console.log(error)
				})
		},
		//审核拒绝
		refuse(row, index, event) {
			this.rejectContent = ''
			this.selectRow = row
			this.showRejectModal = true
		},
		onConfirm() {
			this.$api.Content.reviewById({
				id: this.selectRow.id,
				remarks: this.rejectContent,
				status: 1,
			})
				.then((res) => {
					if (res.message) {
						this.$toast(res)
					}
					if (res.success) {
						this.rejectContent = ''
						this.selectRow = null
						this.showRejectModal = false
						this.$refs.table.refresh()
					}
				})
				.catch((error) => {
					if (res.message) {
						this.$toast(res)
					}
				})
		},
		onCancel() {
			this.rejectContent = ''
			this.selectRow = null
			this.showRejectModal = false
		},
		onClose() {
			this.rejectContent = ''
			this.selectRow = null
			this.showRejectModal = false
		},
	},
}
</script>

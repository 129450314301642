var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-form",
            {
              on: {
                submit: function($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                },
                reset: _vm.onReset
              }
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Title:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "title"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "title",
                      required: "",
                      placeholder: "Enter Title"
                    },
                    model: {
                      value: _vm.form.title,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "title", $$v)
                      },
                      expression: "form.title"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Publish user:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "user"
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      id: "user",
                      required: "",
                      placeholder: "Enter Publish user"
                    },
                    model: {
                      value: _vm.form.user,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "user", $$v)
                      },
                      expression: "form.user"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Category:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "category"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.types.Categorys,
                      "value-field": "id",
                      "text-field": "name"
                    },
                    model: {
                      value: _vm.form.category,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "category", $$v)
                      },
                      expression: "form.category"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Content:",
                    "label-cols-lg": "2",
                    "label-align-lg": "right",
                    "label-for": "content"
                  }
                },
                [
                  _c("ckeditor", {
                    attrs: { editor: _vm.editor },
                    on: { ready: _vm.onReady },
                    model: {
                      value: _vm.form.content,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-form-group",
                { attrs: { "label-cols-lg": "2" } },
                [
                  _c(
                    "b-button",
                    { attrs: { type: "submit", variant: "primary" } },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "m-l-5 ml-1",
                      attrs: { type: "reset", variant: "danger" }
                    },
                    [_vm._v("Cancel")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c(
        "b-card",
        [
          _vm.show
            ? _c(
                "b-form",
                { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Flavor Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "model-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "model-name",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 128)value = value.slice(0, 128)",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.modelName,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "modelName", $$v)
                          },
                          expression: "form.modelName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Flavor Type:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "cartridge-type",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "cartridge-type",
                          options: _vm.types.TypeEncoding,
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeType(_vm.form.type)
                          }
                        },
                        model: {
                          value: _vm.form.type,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Device Model:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "device-model",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "device-model",
                          options: _vm.types.deviceList,
                          "value-field": "id",
                          "text-field": "modelName",
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.deviceModelId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "deviceModelId", $$v)
                          },
                          expression: "form.deviceModelId"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.deviceModelId != 8
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Custom Temp:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "custom-temp",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: {
                                id: "custom-temp",
                                name: "customTemperature",
                                value: 0,
                                "unchecked-value": 1,
                                switch: "",
                                size: "lg"
                              },
                              model: {
                                value: _vm.form.customTemperature,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customTemperature", $$v)
                                },
                                expression: "form.customTemperature"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.form.customTemperature == 0
                                      ? "Yes"
                                      : "No"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Suggested Price:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "suggested-price",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "suggested-price",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          required: "",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.suggestRetailPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "suggestRetailPrice", $$v)
                          },
                          expression: "form.suggestRetailPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Actual Selling Price:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "actual-selling-price",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "actual-selling-price",
                          required: "",
                          type: "number",
                          min: "0",
                          step: "0.01",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(parseFloat(value) > 100000)value = 100000;value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/, '$1$2.$3')",
                          placeholder: "Please enter"
                        },
                        model: {
                          value: _vm.form.retailPrice,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "retailPrice", $$v)
                          },
                          expression: "form.retailPrice"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Brand Name:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "brand-name",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "brand-name",
                          options: _vm.types.brandNames,
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeBrand(_vm.form.brand)
                          }
                        },
                        model: {
                          value: _vm.form.brand,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "brand", $$v)
                          },
                          expression: "form.brand"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Product Genre Tags:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "product-genre-tags",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          id: "product-genre-tags",
                          options: _vm.types.productGenreList,
                          "track-by": "id",
                          label: "labelName",
                          placeholder: "Please select",
                          searchable: false,
                          "close-on-select": false,
                          multiple: true,
                          max: _vm.maxFive,
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: { input: _vm.selectGenre },
                        model: {
                          value: _vm.selectProductGenre,
                          callback: function($$v) {
                            _vm.selectProductGenre = $$v
                          },
                          expression: "selectProductGenre"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Usage Effects:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "usage-effects",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          id: "usage-effects",
                          options: _vm.types.usageEffectsList,
                          "track-by": "id",
                          label: "labelName",
                          placeholder: "Please select",
                          searchable: false,
                          "close-on-select": false,
                          multiple: true,
                          max: _vm.maxThree,
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: { input: _vm.selectEffects },
                        model: {
                          value: _vm.selectUsageEffects,
                          callback: function($$v) {
                            _vm.selectUsageEffects = $$v
                          },
                          expression: "selectUsageEffects"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Flavor Profiles:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "flavor-profiles",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("multiselect", {
                        attrs: {
                          options: _vm.types.flavorProfilesList,
                          "track-by": "id",
                          label: "labelName",
                          placeholder: "Please select",
                          searchable: false,
                          "close-on-select": false,
                          multiple: true,
                          max: _vm.maxThree,
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: { input: _vm.selectProfiles },
                        model: {
                          value: _vm.selectFlavorProfiles,
                          callback: function($$v) {
                            _vm.selectFlavorProfiles = $$v
                          },
                          expression: "selectFlavorProfiles"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Intensity:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "intensity",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-select", {
                        attrs: {
                          id: "intensity",
                          options: _vm.types.IntensityList,
                          required: "",
                          oninvalid: "setCustomValidity('Please select');",
                          oninput: "setCustomValidity('')"
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeType(_vm.form.type)
                          }
                        },
                        model: {
                          value: _vm.form.strengthId,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "strengthId", $$v)
                          },
                          expression: "form.strengthId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Flavor Picture:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "logo",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("input", {
                        staticClass: "filestyle",
                        staticStyle: {
                          position: "absolute",
                          clip: "rect(0px, 0px, 0px, 0px)"
                        },
                        attrs: {
                          type: "file",
                          "data-input": "false",
                          id: "filestyle",
                          tabindex: "-1",
                          required: !_vm.editId,
                          oninvalid: "setCustomValidity('Please upload');",
                          oninput: "setCustomValidity('');"
                        },
                        on: {
                          change: function($event) {
                            return _vm.upload($event)
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "bootstrap-filestyle input-group" },
                        [
                          _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "35px",
                              "z-index": "-1"
                            },
                            attrs: { name: "filedrag" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0" }
                            },
                            [
                              _vm.form.flavorPicture
                                ? _c(
                                    "div",
                                    { staticStyle: { position: "relative" } },
                                    [
                                      _c("i", {
                                        staticClass: "ion ion-md-close-circle",
                                        staticStyle: {
                                          position: "absolute",
                                          left: "70px",
                                          top: "5px",
                                          "font-size": "25px"
                                        },
                                        on: { click: _vm.onDeleteFlavorPicture }
                                      }),
                                      _c("img", {
                                        staticStyle: {
                                          width: "100px",
                                          height: "100px",
                                          border: "2px dashed #ccc"
                                        },
                                        attrs: { src: _vm.form.flavorPicture }
                                      }),
                                      _vm.photoName
                                        ? _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "10px",
                                                "vertical-align": "bottom"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "500"
                                                  }
                                                },
                                                [_vm._v("Picture name : ")]
                                              ),
                                              _vm._v(_vm._s(_vm.photoName))
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.form.flavorPicture
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "btn btn-secondary",
                                      staticStyle: { "margin-bottom": "0" },
                                      attrs: { for: "filestyle" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "buttonText" },
                                        [_vm._v(" upload picture ")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.form.flavorPicture
                                ? _c("span", [
                                    _vm._v(
                                      " Recommended size of picture: 512 pixels * 512 pixels, supporting JPG and PNG formats. "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "buttonText",
                          staticStyle: { position: "relative", top: "10px" }
                        },
                        [
                          _vm._v(
                            ' Tips：Product Icon for "My Product History" '
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Carousel:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "carousel",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bootstrap-filestyle input-group" },
                        [
                          _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "35px",
                              "z-index": "-1"
                            },
                            attrs: { name: "filedrag" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0" }
                            },
                            [
                              _vm.form.commodityCarouselMap
                                ? _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    _vm._l(
                                      _vm.form.commodityCarouselMap,
                                      function(item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              width: "100px",
                                              position: "relative",
                                              "margin-right": "10px"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ion ion-md-close-circle",
                                              staticStyle: {
                                                position: "absolute",
                                                right: "5px",
                                                top: "5px",
                                                "font-size": "25px",
                                                "z-index": "999"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onDeleteCarouselMap(
                                                    item,
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            /mp4/.test(item)
                                              ? _c("video", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px",
                                                    border: "2px dashed #ccc"
                                                  },
                                                  attrs: { src: item }
                                                })
                                              : _c("img", {
                                                  staticStyle: {
                                                    width: "100px",
                                                    height: "100px",
                                                    border: "2px dashed #ccc"
                                                  },
                                                  attrs: { src: item }
                                                })
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.form.commodityCarouselMap.length < 10
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "btn btn-secondary",
                                      staticStyle: { "margin-bottom": "0" },
                                      on: { click: _vm.onClickUploadCarousel }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "buttonText" },
                                        [_vm._v(" upload picture ")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.commodityCarouselMap.length < 10
                                ? _c("span", [
                                    _vm._v(
                                      " Recommended size of picture: 750 pixels * 750 pixels, supporting JPG and PNG formats. "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "buttonText",
                          staticStyle: { position: "relative", top: "10px" }
                        },
                        [_vm._v(" Tips：Picture and video for 1st section ")]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Creators Notes:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "creators-notes",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "creators-notes",
                          placeholder: "Enter Notes",
                          rows: "3",
                          required: "",
                          "max-rows": "15"
                        },
                        model: {
                          value: _vm.form.commodityCreationStory,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "commodityCreationStory", $$v)
                          },
                          expression: "form.commodityCreationStory"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Details:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "details",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "bootstrap-filestyle input-group" },
                        [
                          _c("div", {
                            staticStyle: {
                              position: "absolute",
                              width: "100%",
                              height: "35px",
                              "z-index": "-1"
                            },
                            attrs: { name: "filedrag" }
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "group-span-filestyle",
                              attrs: { tabindex: "0" }
                            },
                            [
                              _vm.form.commodityDetailsMap
                                ? _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    _vm._l(
                                      _vm.form.commodityDetailsMap,
                                      function(item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticStyle: {
                                              width: "100px",
                                              position: "relative",
                                              "margin-right": "10px"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "ion ion-md-close-circle",
                                              staticStyle: {
                                                position: "absolute",
                                                right: "5px",
                                                top: "5px",
                                                "font-size": "25px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.onDeleteDetailsMap(
                                                    item,
                                                    index
                                                  )
                                                }
                                              }
                                            }),
                                            _c("img", {
                                              staticStyle: {
                                                width: "100px",
                                                height: "100px",
                                                border: "2px dashed #ccc"
                                              },
                                              attrs: { src: item }
                                            })
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                              _vm.form.commodityDetailsMap.length < 10
                                ? _c(
                                    "label",
                                    {
                                      staticClass: "btn btn-secondary",
                                      staticStyle: { "margin-bottom": "0" },
                                      attrs: { for: "filestyle-3" },
                                      on: { click: _vm.onClickUploadDetails }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "buttonText" },
                                        [_vm._v(" upload picture ")]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.form.commodityDetailsMap.length < 10
                                ? _c("span", [
                                    _vm._v(
                                      " Recommended image width 750px, supporting JPG and PNG formats. "
                                    )
                                  ])
                                : _vm._e()
                            ]
                          )
                        ]
                      )
                    ]
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Public Display:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "on-sale"
                      }
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: {
                            id: "on-sale",
                            name: "onSale",
                            value: 0,
                            "unchecked-value": 1,
                            switch: "",
                            size: "lg"
                          },
                          model: {
                            value: _vm.form.onSale,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "onSale", $$v)
                            },
                            expression: "form.onSale"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.form.onSale == 0 ? "Yes" : "No") +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Find it link:",
                        "label-cols-sm": "2",
                        "label-align-sm": "right",
                        "label-for": "cart-url"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "cart-url",
                          placeholder: "Please enter",
                          maxlength: "100",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput: "setCustomValidity('')"
                        },
                        model: {
                          value: _vm.form.cartUrl,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "cartUrl", $$v)
                          },
                          expression: "form.cartUrl"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Product listing priority:",
                        "label-cols-sm": "2",
                        "label-align-sm": "right",
                        "label-for": "flavor-sort",
                        "label-class": "requiredRow"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "flavor-sort",
                          required: "",
                          placeholder: "Please enter",
                          oninvalid: "setCustomValidity('Please enter');",
                          oninput:
                            "setCustomValidity('');if(value.length > 50)value = value.slice(0, 50);value=value.replace(/[^\\d]/g,'')"
                        },
                        model: {
                          value: _vm.form.flavorSort,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "flavorSort", $$v)
                          },
                          expression: "form.flavorSort"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.form.type == 1 || _vm.form.type == 5
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Consistence:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "consistence",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "consistence",
                              required: "",
                              type: "number",
                              min: "0",
                              step: "0.0001",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput:
                                "setCustomValidity('');value = value.replace(/[^\\d.]/g, '').replace(/\\.{2,}/g,'.').replace(/^\\./g, '').replace('.', '$#$').replace(/\\./g, '').replace('$#$', '.').replace(/^(\\-)*(\\d+)\\.(\\d\\d\\d\\d).*$/, '$1$2.$3')"
                            },
                            model: {
                              value: _vm.form.consistence,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "consistence", $$v)
                              },
                              expression: "form.consistence"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.type == 5
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Medical Care:",
                            "label-cols-lg": "2",
                            "label-align-lg": "right",
                            "label-for": "medical",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c(
                            "b-form-radio",
                            {
                              staticClass: "custom-control-inline pt-2",
                              attrs: {
                                name: "medicalCare",
                                value: "0",
                                required: ""
                              },
                              model: {
                                value: _vm.form.medicalCare,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "medicalCare", $$v)
                                },
                                expression: "form.medicalCare"
                              }
                            },
                            [_vm._v("YES")]
                          ),
                          _c(
                            "b-form-radio",
                            {
                              staticClass: "custom-control-inline pt-2",
                              attrs: {
                                name: "medicalCare",
                                value: "1",
                                required: ""
                              },
                              model: {
                                value: _vm.form.medicalCare,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "medicalCare", $$v)
                                },
                                expression: "form.medicalCare"
                              }
                            },
                            [_vm._v("NO")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.type == 5
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Seconds/Day:",
                            "label-cols-sm": "2",
                            "label-align-sm": "right",
                            "label-for": "daySeconds",
                            "label-class": "requiredRow"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "remark",
                              placeholder: "Please enter",
                              maxlength: "20",
                              required: "",
                              oninvalid: "setCustomValidity('Please enter');",
                              oninput: "setCustomValidity('')"
                            },
                            model: {
                              value: _vm.form.daySeconds,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "daySeconds", $$v)
                              },
                              expression: "form.daySeconds"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Notes:",
                        "label-cols-lg": "2",
                        "label-align-lg": "right",
                        "label-for": "remarks"
                      }
                    },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "remarks",
                          placeholder: "Enter Notes",
                          rows: "3",
                          "max-rows": "6"
                        },
                        model: {
                          value: _vm.form.remarks,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "remarks", $$v)
                          },
                          expression: "form.remarks"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    { attrs: { "label-cols-lg": "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            type: "submit",
                            variant: "primary",
                            disabled: _vm.confirmDisabled
                          }
                        },
                        [_vm._v("Confirm")]
                      ),
                      _c(
                        "b-button",
                        { staticClass: "m-l-5 ml-1", attrs: { type: "reset" } },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("UploadFile", {
        attrs: {
          showUploadModal: _vm.showUploadModal,
          oldFileList: _vm.editUploadList,
          path: _vm.uploadPath,
          fileType: _vm.fileType,
          maxNumber: _vm.maxNumber
        },
        on: { FinishEvent: _vm.finishEvent, CancelEvent: _vm.cancelEvent }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Author: Code-HHX
 * @Date: 2021-12-24 11:33:50
 * @LastEditors: Code-HHX
 * @LastEditTime: 2021-12-24 15:30:08
 * @Description: 关键词黑名单添加修改页面
-->

<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<b-card>
			<b-form @submit="onSubmit" @reset="onReset" v-if="show">
				<!-- Key Word 关键词-->
				<b-form-group
					label="Key Word:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="key-word"
					label-class="requiredRow"
				>
					<b-form-input
						id="key-word"
						v-model="form.sensitiveWord"
						required
						oninvalid="setCustomValidity('Please enter');"
						oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
						placeholder="Please enter"
					></b-form-input>
				</b-form-group>

				<!-- Risk Level 关键词风险级别 -->
				<b-form-group
					label="Risk Level:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="risk-level"
					label-class="requiredRow"
				>
					<b-form-select
						id="risk-level"
						v-model="form.riskLevel"
						:options="types.riskLevelList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('');"
					>
					</b-form-select>
				</b-form-group>

				<!-- Keyword Category 关键词类别-->
				<b-form-group
					label="Keyword Category:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="keyword-category"
					label-class="requiredRow"
				>
					<b-form-select
						id="keyword-category"
						v-model="form.sensitiveWordCategoryId"
						:options="types.keywordCategoryList"
						value-field="value"
						text-field="text"
						required
						oninvalid="setCustomValidity('Please select');"
						oninput="setCustomValidity('');"
					>
					</b-form-select>
				</b-form-group>

				<!-- Status 状态 -->
				<b-form-group
					label="Status:"
					label-cols-lg="2"
					label-align-lg="right"
					label-for="status"
					label-class="requiredRow"
				>
					<b-form-checkbox
						v-model="form.status"
						name="check-button"
						switch
						size="lg"
						value="1"
						unchecked-value="0"
					>
						<b> {{ form.status == 0 ? 'Disable' : 'Enable' }}</b>
					</b-form-checkbox>
				</b-form-group>

				<b-form-group label-cols-lg="2">
					<b-button type="submit" variant="primary">Confirm</b-button>
					<b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
				</b-form-group>
			</b-form>
			<!-- <p>{{form}}</p> -->
		</b-card>
	</Layout>
</template>
<script>
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'

import { onReady, upload_img } from '@/api/upload'

/**
 * Cartridge Model Add
 */
export default {
	components: {
		ckeditor: CKEditor.component,
		Layout,
		PageHeader,
	},
	data() {
		return {
			title: /edit/i.test(window.location.href)
				? 'Keyword Blacklist Modify'
				: 'Keyword Blacklist Create',
			items: [
				{
					text: 'UNI-CORE',
					// href: "/"
				},
				{
					text: 'Content Mgmt',
					// href: "/"
				},
				{
					text: /edit/i.test(window.location.href)
						? 'Keyword Blacklist Modify'
						: 'Keyword Blacklist Create',
					active: true,
				},
			],
			rowItem: null,
			form: {
				supplierId: '',

				id: '',
				riskLevel: '', //风险级别
				sensitiveWordL: '', //敏感词
				sensitiveWordCategoryId: '', //敏感词类别
				status: 0,
			},
			types: {
				//风险级别
				riskLevelList: [
					{ text: 'Please select', value: '' },
					{ text: 'LV1', value: 1 },
					{ text: 'LV2', value: 2 },
					{ text: 'LV3', value: 3 },
					{ text: 'LV4', value: 4 },
					{ text: 'LV5', value: 5 },
				],
				//关键词分类列表
				keywordCategoryList: [
					{
						text: 'Please select',
						value: '',
					},
				],
			},
			show: true, // 表单显示
			editor: ClassicEditor,
		}
	},
	beforeCreate() {},
	created() {
		this.init()
	},
	mounted() {
		if (/edit/i.test(window.location.href)) {
			this.rowItem = this.$route.params.rowItem
			this.form = {
				supplierId: this.rowItem.id,

				id: this.rowItem.id,
				riskLevel: this.rowItem.riskLevel, //风险级别
				sensitiveWord: this.rowItem.sensitiveWord, //敏感词
				sensitiveWordCategoryId: this.rowItem.sensitiveWordCategoryId, //敏感词类别
				status: this.rowItem.status,
			}
		}
	},
	methods: {
		onReady: onReady,
		init() {
			let that = this
			this.$api.Dropdown.selectAllSensitiveWordCategory()
				.then((res) => {
					that.types.keywordCategoryList = [
						{ text: 'Please select', value: '' },
					]
					res.data.forEach((element) => {
						that.types.keywordCategoryList.push({
							text: element.categoryName,
							value: element.id,
						})
					})
				})
				.catch((error) => {
					console.log(error)
				})
		},
		onSubmit(evt) {
			evt.preventDefault()
			this.$api.Content.saveOrUpdateBlackList(this.form)
				.then((res) => {
					if (res.success) {
						this.$toastBack(res)
					} else {
						if (res.message) this.$toast(res)
					}
				})
				.catch((error) => {
					if (error.message) this.$toast(error)
				})
		},
		onReset(evt) {
			evt.preventDefault()
			this.form = {
				supplierId: '',

				id: '',
				riskLevel: '',
				sensitiveWordL: '',
				sensitiveWordCategoryId: '',
				status: 0,
			}
			this.rowItem = null
			this.show = false
			this.$nextTick(() => {
				// this.show = true;
				this.$router.go(-1)
			})
		},
	},
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <!-- 申请说明 -->
        <b-form-group
          label="Production ID:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="instructions"
          label-class="requiredRow"
        >
          <b-form-input
            id="instructions"
            v-model="form.applicationInstructions"
            required
            oninvalid="setCustomValidity('Please enter');"
            oninput="setCustomValidity('');if(value.length > 50)value = value.slice(0, 50)"
            placeholder="Please enter"
          ></b-form-input>
        </b-form-group>

        <!-- 申请类型 -->
        <b-form-group
          label="Application Type:"
          label-cols-sm="2"
          label-align-sm="right"
          label-for="type"
          label-class="requiredRow"
        >
          <b-form-select
            v-model="form.type"
            :options="types.typeList"
            value-field="value"
            text-field="text"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 所属客户 -->
        <b-form-group
          label="Affiliated Customer:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="customer"
          label-class="requiredRow"
        >
          <b-form-select
            id="customer"
            v-model="form.supplierId"
            :options="types.allSupplier"
            value-field="id"
            text-field="brandName"
            required
            oninvalid="setCustomValidity('Please select');"
            oninput="setCustomValidity('');"
          >
          </b-form-select>
        </b-form-group>

        <!-- 申请型号 -->
        <b-form-group
          class="inner"
          label="Cartridge model:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="model"
          label-class="requiredRow"
        >
          <div
            v-for="(data, index) in form.modelNumber"
            :key="data.id"
            class="inner mb-3 row"
          >
            <div class="col-md-4 col-4">
              <b-form-select
                id="cartridge-type"
                v-model="data.id"
                :options="types.SmokeBombModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('');"
              ></b-form-select>
            </div>

            <div class="col-md-4 col-4">
              <b-form-input
                class="inner form-control"
                v-model="data.number"
                type="number"
                min="0"
                placeholder="Please enter the quantity"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');"
              ></b-form-input>
            </div>

            <div class="col-md-2 col-2" v-if="form.modelNumber.length > 1">
              <b-button variant="danger" @click="deleteModelNumber(index)"
                >Delete</b-button
              >
            </div>
          </div>
          <b-button variant="primary" @click="addModelNumber">Add</b-button>
        </b-form-group>

        <!-- 备注 -->
        <b-form-group
          label="Notes:"
          label-cols-lg="2"
          label-align-lg="right"
          label-for="remarks"
        >
          <b-form-textarea
            id="remarks"
            v-model="form.remarks"
            placeholder="Enter Notes"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-cols-lg="2">
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button type="reset" class="m-l-5 ml-1">Cancel</b-button>
        </b-form-group>
      </b-form>
      <!-- <p>{{form}}</p> -->
    </b-card>
  </Layout>
</template>
<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Multiselect from "vue-multiselect";

import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import { onReady } from "@/api/upload";

/**
 * Cartridge Model Add
 */
export default {
  components: {
    ckeditor: CKEditor.component,
    Layout,
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      title: /edit/i.test(window.location.href)
        ? "Order Modify"
        : "Order Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: /edit/i.test(window.location.href)
            ? "Order Modify"
            : "Order Create",
          active: true,
        },
      ],
      form: {
        type: "",
        supplierId: "",
        applicationInstructions: "",
        modelNumber: [
          {
            id: "",
            number: 0,
            modelName: "",
          },
        ],
        remarks: "",
      },
      types: {
        SmokeBombModel: [{ modelName: "Please select", id: "" }],
        typeList: [
          { text: "Please select", value: "", disabled: false },
          { text: "The customer purchase", value: 1, disabled: false },
          { text: "Abnormal compensation", value: 2, disabled: false },
          { text: "Other", value: 3, disabled: false },
        ],
        allSupplier: [{ brandName: "Please select", id: "" }],
      },
      show: true, // 表单显示
      editor: ClassicEditor,
    };
  },
  created() {},
  mounted() {
    this.init();
    if (/edit/i.test(window.location.href)) {
      this.show1();
    }
  },
  methods: {
    init() {
      this.$api.Dropdown.selectAllTestSmokeBombModel().then((res) => {
        if (res.success) {
          this.types.SmokeBombModel = this.types.SmokeBombModel.concat(
            res.data
          );
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      this.$api.Dropdown.selectAllSupplier().then((res) => {
        if (res.success && res.data) {
          this.types.allSupplier = this.types.allSupplier.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    show1() {
      if (this.$route.query.id) {
        this.$api.emptyCartridges
          .smokeRecordApplicationInfo(this.$route.query)
          .then((res) => {
            if (res.success) {
              this.form = res.data;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onReady: onReady,
    onSubmit(evt) {
      evt.preventDefault();
      if (/edit/i.test(window.location.href)) {
        this.$api.emptyCartridges
          .smokeRecordApplicationUpdate(this.form)
          .then((res) => {
            if (res.success) {
              this.$router.go(-1);
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$api.emptyCartridges
          .smokeRecordApplicationAdd(this.form)
          .then((res) => {
            if (res.success) {
              this.$router.go(-1);
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        type: "",
        supplierId: "",
        applicationInstructions: "",
        modelNumber: [
          {
            id: "",
            number: 0,
            modelName: "",
          },
        ],
        remarks: "",
      };
      this.show = false;
      this.$nextTick(() => {
        // this.show = true;
        this.$router.go(-1);
      });
    },

    addModelNumber() {
      // if (this.form.modelNumber.length >= 3) {
      //   this.$bvToast.toast("modelNumber.length must be  <=3");
      //   return;
      // } else {
      let d = {
        id: "",
        number: 0,
        modelName: "",
      };
      this.form.modelNumber.push(d);
      // }
    },

    deleteModelNumber(index) {
      if (this.form.modelNumber.length > 1) {
        if (confirm("Are you sure you want to delete this element?")) {
          this.form.modelNumber.splice(index, 1);
        }
      } else {
        this.$bvToast.toast(">1");
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <!-- 设备信息 -->
    <b-card>
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Empty Cartridges Info</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row style="margin-left: 20px">
          <b-col lg="8">
            <label class="col-form-label">Affiliated Customer:</label>
            <span class="p-3">{{ info.supplierName }}</span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Application Type:</label>
            <span class="p-3">{{
              info.type == 1
                ? "The customer purchase"
                : info.type == 2
                ? "Abnormal compensation"
                : "Other"
            }}</span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Apply For:</label>
            <span class="p-3">{{ info.applicationInstructions }}</span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Model:</label>
            <span class="p-3">
              <b-table
                style="display: inline; vertical-align: text-top"
                striped
                hover
                :items="info.modelNumber"
                :fields="fields"
              ></b-table>
            </span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Remark:</label>
            <span class="p-3">{{ info.remarks }}</span>
          </b-col>
        </b-row>
      </b-container>
    </b-card>

    <b-card>
      <!-- 操作日志 -->
      <br />
      <b-row class="align-items-center">
        <b-col lg="12">
          <div class="blockquote">
            <h4>Review Opinion</h4>
          </div>
        </b-col>
      </b-row>
      <b-container>
        <b-row
          style="margin-left: 20px"
          v-if="info.status == 1 || info.status == 2"
        >
          <b-col lg="8">
            <label class="col-form-label">Review Opinion:</label>
            <span class="p-3">{{
              info.status == 1 ? "Passed" : "Not pass"
            }}</span>
          </b-col>
          <b-col lg="8">
            <label class="col-form-label">Review Instructions:</label>
            <span class="p-3">{{ info.reviewInstructions }}</span>
          </b-col>
        </b-row>
        <b-form
          @submit="onSubmit"
          @reset="onReset"
          v-if="show && info.status == 0"
        >
          <b-row cols="2">
            <b-form-group
              label="Review Opinion:"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="instructions"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="form.status"
                :options="types.statuss"
                required
                style="margin-top: 5px"
              ></b-form-radio-group>
            </b-form-group>
          </b-row>
          <b-row cols="2">
            <b-form-group
              label="Review Instructions:"
              label-cols-lg="4"
              label-align-lg="right"
              label-for="instructions"
            >
              <b-form-textarea
                id="instructions"
                v-model="form.reviewInstructions"
                placeholder="Enter Review Instructions"
                rows="4"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-row>
          <b-form-group label-cols-lg="2">
            <b-button type="submit" variant="primary">Confirm</b-button>
            <b-button type="reset" class="m-l-5 ml-1">Go Back</b-button>
          </b-form-group>
        </b-form>
      </b-container>
    </b-card>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Cartridge Info component
 */
export default {
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Order Status Details",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Device Mgmt",
          // href: "/"
        },
        {
          text: "Order Status Details",
          active: true,
        },
      ],
      info: {},
      fields: [
        {
          key: "modelName",
          label: "Cartridge model",
          class: "text-center",
        },
        {
          key: "number",
          label: "Quantity",
          class: "text-center",
        },
      ],
      show: true,
      form: {
        id: this.$route.query.id,
        status: "",
        reviewInstructions: "",
      },
      types: {
        statuss: [
          { text: "Passed", value: 1, disabled: false },
          { text: "Not pass", value: 2, disabled: false },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      if (this.$route.query.id) {
        this.$api.emptyCartridges
          .smokeRecordApplicationInfo(this.$route.query)
          .then((res) => {
            if (res.success) {
              this.info = res.data;
            } else {
              if (res.message) {
                this.$bvToast.toast(res.message);
              }
            }
          });
      } else {
        this.$bvToast.toast("id?");
      }
    },
    onSubmit(evt) {
      evt.preventDefault();
      this.$api.emptyCartridges.review(this.form).then((res) => {
        if (res.success) {
          this.$router.go(-1);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.form = {
        id: this.$route.query.id,
        status: "",
        reviewInstructions: "",
      };
      this.show = false;
      this.$nextTick(() => {
        this.$router.go(-1);
      });
    },
  },
};
</script>

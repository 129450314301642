var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Responsive embed video 16:9")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v("Aspect ratios can be customized with modifier classes.")
              ]),
              _c(
                "div",
                { staticClass: "embed-responsive" },
                [
                  _c("b-embed", {
                    staticClass: "embed-responsive-item",
                    attrs: {
                      type: "iframe",
                      aspect: "16by9",
                      src: "https://www.youtube.com/embed/1y_kfWUCFDQ"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Responsive embed video 21:9")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v("Aspect ratios can be customized with modifier classes.")
              ]),
              _c(
                "div",
                { staticClass: "embed-responsive" },
                [
                  _c("b-embed", {
                    staticClass: "embed-responsive-item",
                    attrs: {
                      type: "iframe",
                      aspect: "21by9",
                      src: "https://www.youtube.com/embed/1y_kfWUCFDQ"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Responsive embed video 4:3")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v("Aspect ratios can be customized with modifier classes.")
              ]),
              _c(
                "div",
                { staticClass: "embed-responsive" },
                [
                  _c("b-embed", {
                    staticClass: "embed-responsive-item",
                    attrs: {
                      type: "iframe",
                      aspect: "4by3",
                      src: "https://www.youtube.com/embed/1y_kfWUCFDQ"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "col-lg-6" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _c("h4", { staticClass: "card-title" }, [
                _vm._v("Responsive embed video 1:1")
              ]),
              _c("p", { staticClass: "card-title-desc" }, [
                _vm._v("Aspect ratios can be customized with modifier classes.")
              ]),
              _c(
                "div",
                { staticClass: "embed-responsive-1by1" },
                [
                  _c("b-embed", {
                    staticClass: "embed-responsive-item",
                    attrs: {
                      type: "iframe",
                      aspect: "1by1",
                      src: "https://www.youtube.com/embed/1y_kfWUCFDQ"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
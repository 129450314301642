<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <!-- 查询条件 -->
        <b-card>
          <b-row>
            <b-col lg="3">
              <b-form-group
                label="Email"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="email"
              >
                <b-form-input
                  v-model="search_condition.email"
                  id="email"
                  trim
                  type="email"
                  oninput="if(value.length > 30)value = value.slice(0, 30)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <b-form-group
                label="Nickname"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="nick-name"
              >
                <b-form-input
                  id="nick-name"
                  trim
                  v-model="search_condition.nickName"
                  oninput="if(value.length > 20)value = value.slice(0, 20)"
                ></b-form-input>
              </b-form-group>
            </b-col>

            <!-- <b-col lg="3">
              <b-form-group
                label="smokebombID"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="source"
              >
                <b-form-input
                  trim
                  v-model="search_condition.smoke_bomb_ID"
                ></b-form-input>
              </b-form-group>
            </b-col> -->

            <b-col lg="3">
              <b-form-group
                label="Last Used Time"
                label-cols-sm="4"
                label-align-sm="right"
                label-for="select-time"
              >
                <date-picker
                  v-model="selectTime"
                  format="MM/DD/YYYY"
                  range
                  append-to-body
                  lang="en"
                  placeholder="Select date"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col lg="3">
              <!-- <b-form-group label label-cols-sm="4" label-align-sm="right"> -->
              <b-button variant="primary" @click="search()">Search</b-button>
              <!-- </b-form-group> -->
            </b-col>
          </b-row>

          <b-row class="text-center">
            <b-col lg="12"></b-col>
          </b-row>
        </b-card>

        <b-card>
          <b-button-group>
            <b-button
              v-has="'usageRecord::Export'"
              @click="export_checkout"
              variant="info"
              >Export</b-button
            >
          </b-button-group>
          <!-- table -->
          <div class="table-responsive mb-0">
            <b-table
              show-empty
              empty-text="no records"
              id="device-table"
              head-variant="light"
              :striped="true"
              ref="table"
              :busy="isBusy"
              :items="provider"
              :fields="fields"
              :per-page="search_condition.pageSize"
              :current-page="search_condition.pageNum"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-includedfields="filterOn"
              @filtered="onFiltered"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>Loading...</strong>
                </div>
              </template>

              <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
              }}</template>

              <template v-slot:cell(address)> Permission required </template>

              <!-- <template v-slot:cell(actions)="row"> -->

              <!-- <b-button-group>
								<b-button size="sm" variant="primary"
									@click="show(row.item, row.index, $event.target)" class="mr-1">
									show
              </b-button>-->
              <!--
								<b-button size="sm" variant="info"
									@click="show(row.item, row.index, $event.target)" class="mr-1">
									edit
								</b-button>
              -->

              <!-- <b-button size="sm" variant="danger"
									@click="del(row.item, row.index, $event.target)" class="mr-1">
									del
								</b-button>

              </b-button-group>-->

              <!-- </template> -->
            </b-table>
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <b-pagination
                    v-model="search_condition.pageNum"
                    :per-page="10"
                    :total-rows="rows"
                    aria-controls="table"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->

    <!-- <div class="text-center">
			<b-spinner variant="primary" label="Text Centered"></b-spinner>
    </div>-->
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";

/**
 * User component
 */
export default {
  components: {
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
  },
  data() {
    return {
      title: "Usage Record",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "User Mgmt",
          // href: "/"
        },
        {
          text: "Usage Record",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      //"用户昵称", "烟弹ID", "烟弹口味", "烟弹类型", "最后使用时间", "使用位置", "总使用时长(s)"
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "nickName", label: "Nickname", class: "text-center" },
        {
          key: "createTime",
          label: "Last Used Time",
          formatter: (value) => {
            return timeZoneConversion.getLocalTimes(value);
          },
          class: "text-center",
        },
        { key: "address", label: "Lastest Loaction", class: "text-center" },
        {
          key: "totalDuration",
          label: "Total Duration(s)",
          class: "text-center",
        },
        // {key: 'actions', label: 'Opreration'},
      ],
      selectTime: ["", ""],
      source: ["", "Platform", "Supplier"], //平台
      search_condition: {
        pageNum: 1,
        pageSize: 10,
        startTime: "",
        email: "",
        nickName: "",
        endTime: "",
        // supplierId: localStorage.supplierId
      },
      isBusy: false,
      has_data: false,
    };
  },
  watch: {
    selectTime: function (val) {
      this.search_condition.startTime = val[0]
        ? timeZoneConversion.queryTime(val[0])
        : val[0];
      if (val[1]) {
        this.search_condition.endTime = timeZoneConversion.queryTime(
          val[1].getTime() + 24 * 3600 * 1000 - 1
        );
      } else {
        this.search_condition.endTime = val[1];
      }
    },
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  created() {},
  methods: {
    provider(ctx) {
      this.isBusy = true;
      return this.$api.UserUseage.list(this.search_condition, ctx)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            return x.data;
          } else {
            this.has_data = false;
            return [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          return [];
        });
    },
    gotoAdd() {
      this.$router.push("cartridgeListAdd");
    },
    del(a, b, c) {
      this.$api.UserUseage.del({ id: a.id }).then((res) => {
        if (res.message) {
          this.$bvToast.toast(res.message);
        }
        if (res.success) {
          this.$refs.table.refresh();
        }
      });
    },

    export_excel() {
      this.$api.UserUseage.export_excel(this.search_condition);
    },
    export_checkout() {
      //校验是否存在搜索条件
      if (
        !this.search_condition.email &&
        !this.search_condition.nickName &&
        !this.search_condition.startTime &&
        !this.search_condition.endTime
      ) {
        this.$bvToast.toast("Please select the search criteria first");
        return;
      }
      //时间范围三个月内
      if (this.search_condition.startTime && this.search_condition.endTime) {
        let three = 90 * 24 * 3600 * 1000;
        let date = new Date(this.search_condition.endTime).getTime();
        let threeMonths = date - three;
        if (threeMonths > new Date(this.search_condition.startTime).getTime()) {
          this.$bvToast.toast("Please select the time frame within 3 months");
          return;
        }
      }
      this.export_excel();
    },
    show(item, index, button) {
      this.$router.push("usageRecordInfo?id=" + item.id);
    },
    edit(item, index, button) {
      this.$router.push("UserUseageEdit?id=" + item.id);
    },
    search() {
      this.search_condition.pageNum = 1;
      this.$refs.table.refresh();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
